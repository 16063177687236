import { useState, useEffect } from "react";
import { createColumnHelper } from '@tanstack/react-table'

import axios from '../../../../interceptors/axiosInstance';
import { Table } from '../../../../components/Table/Table';
import { useNavigate } from "react-router-dom";
import { config } from '../../../../config'
import { handleDateFormat } from "../../../../utils/DateFormating";
import "./InvoiceList.css";
import { twoDecimal, pascalCase } from "../../../../utils/display";


export default function InvoiceList({contract, setSelectedInvoice, invoiceList}){
    
    const [invoices, setInvoices] = useState(invoiceList || []);
    const navigate = useNavigate()

    // const fetchAllInvoices = async () => {
    //     try{
    //         const res = await axios.get(`${config.BASE_URL}/api/invoice`);
    //         setInvoices(res.data)
    //     }
    //     catch(e){
    //         console.log(`the error is ${e}`)
    //         setInvoices([])
    //     }      
    // }

    // useEffect(() => {
    //     fetchAllInvoices()
    // }, []);

   useEffect(() => {
        setInvoices(invoiceList)
    }, [invoiceList]);

    const goToRecord = (content) =>{
        // navigate('Invoice/details', {state: { content, viewRole: "Generator"}} )
        setSelectedInvoice(content);
    }
        
    const columnHelper = createColumnHelper();
      
    const columns = [
        columnHelper.display({
            id: 'view-invoice-offtaker',
            cell: props => <img className="view-invoice-icon"src={"/icons/visibility.svg"} alt="View" onClick={() => {goToRecord(props.row.original)}} />,
            header: ''
        }),
        columnHelper.accessor('_id', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Invoice ID',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            'LGC',{
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'LGC/REC',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row => {
                if(contract.currency && contract.strikePrice)
                    return `${contract.strikePrice} ${contract.currency}`;
                return contract.strikePrice || '';
            }, {
            cell: info => `${info.getValue()}`,
            footer: info => info.column.id,
            header: 'Strike price',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('totalSuppliedVolume', {
            cell: info => `${twoDecimal(info.getValue())} MWh`,
            footer: info => info.column.id,
            header: 'Volume',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('dateOfIssue', {
            cell: info => handleDateFormat(info.getValue()),
            footer: info => info.column.id,
            header: 'Date Of Issue',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('invoiceStatus', {
            cell: info => {
                const value= info.getValue() || '';
                return <span className={value.toLowerCase()}>{pascalCase(value)}</span>
            },
            footer: info => info.column.id,
            header: 'Status',
            enableGlobalFilter: false,
        }),
    ];

    const passedInvoices = invoices.filter((invoice) => {
        return invoice.invoiceStatus === 'passed';
      });
    
      const failedInvoices = invoices.filter((invoice) => {
        return invoice.invoiceStatus === 'failed';
      });

    return (
        <div className="invoice-list-container">
            <div className="invoice-count-container">
                <div className="invoice-count">
                    <div className="invoice-count-text-container">
                        <span className="invoice-count-text">Passed Invoices</span>
                        <img src={"/icons/success.svg"} alt="success"/>
                    </div>
                    <span className="invoice-count-text invoice-count-value">{passedInvoices.length}</span>
                </div>
                <div className="invoice-count">
                    <div className="invoice-count-text-container">
                        <span className="invoice-count-text">Failed Invoices</span>
                        <img src={"/icons/failed.svg"} alt="failed"/>
                    </div>
                    <span className="invoice-count-text invoice-count-value">{failedInvoices.length}</span>
                </div>
            </div>
            <div className="invoice-list">
                <Table columns={columns} data={invoiceList} setData={setInvoices} showGlobalSearch={false} />
            </div>
        </div>
    )
}