import "./bannerWithSearchBar.css";

export default function BannerWithSearchBar({searchFilter = '', handleSearch, disableSearch=false, placeHolder}) {
    return(
        <div className="banner__container">
            <div className="banner-search-container">
                <input 
                    type="text" 
                    className="banner-search-bar" 
                    placeholder={placeHolder}
                    value={searchFilter}
                    onChange={handleSearch}
                    disabled={disableSearch}/>
                <img className="banner-search-bar-icon"src={"/icons/search.svg"} alt="search" />
            </div>
        </div>
    )
}