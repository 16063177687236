import React from "react";
import "./FileUpload.css";

export const FileUpload = ({getRootProps, getInputProps,  header= '', infoText = '', showDropdown=false, dropDownHeading='', dropDownValues=[],selectedDropDownValueValue, setSelectedDropDownValue}) => {
    
    const handleChange = (event) =>{
        setSelectedDropDownValue(event.target.value)
    }
    
    return (
        <div className="file-upload-container">
            <h1 className="file-upload-label">{header}</h1>
            {   showDropdown &&
                <div className="file-upload-form-group">
                    <label htmlFor="dropDown">{dropDownHeading}</label>
                    <select className="admin__contract__input" id="dropDown" name="dropDown" value={selectedDropDownValueValue} onChange={handleChange}>
                        {
                            dropDownValues.map((element, index) => <option id={element.key} value={element.key}>{element.value}</option>)
                        }
                    </select>
                </div>
            }
            
            <div {...getRootProps({className: 'file-upload'})}>
                <input {...getInputProps()} />
                <div className="file-upload-placeholder-container">
                    <img className="file-upload-icon" src={"/icons/cloud_upload.svg"} alt="upload"/>
                    <p className="file-upload-placeholder">Drag and drop a document, or <span className="text-highlight">browse</span></p>
                </div>
            </div>
            <div class="info-container">
                <span>{infoText}</span>
            </div>
        </div>
    )
}