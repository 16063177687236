import { useContext, useEffect, useState } from "react";
import { handleDateFormat } from "../../../../utils/DateFormating";
import "./invoiceReconciliationDetails.css";
import { twoDecimal } from "../../../../utils/display";



export default function InvoiceReconciliationDetails({ invoice, contract, role, retry=false, goToInvoiceUpload }) {
    const [errors,setErrors] = useState([]);
    const fields = ["generatorCompensation","offTakerCompensation"];

    const statusClass = invoice.invoiceStatus === 'passed' ? 'attribute_success' : (invoice.invoiceStatus === 'failed' ? 'attribute_error' : '');
    
    const camelCaseToWords = (s) => {
        const result = s.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const compareValue = (item) => {
        const calculatedItem = `calculated${item.charAt(0).toUpperCase() + item.slice(1)}`
        if ((Math.trunc(invoice[item]) === Math.trunc(invoice[calculatedItem]))) {
            return true;
        } else {
            return false;
        }
    }

    const download = function (data) { 
        const blob = new Blob([data], { type: 'text/csv' }); 
        const url = window.URL.createObjectURL(blob) 
        const a = document.createElement('a') 
        a.setAttribute('href', url) 
        a.setAttribute('download', `${invoice._id}.csv`); 
        a.click() 
    } 
    
    const csvmaker = function (data) { 
        let csvRows = []; 
        const headers = Object.keys(data); 
        csvRows.push(headers.join(',')); 
        const values = Object.values(data).map((item) => {
            if(item && JSON.stringify(item).includes(',')){
                return  `"${item}"`
            }
            return item;
        }).join(','); 
        csvRows.push(values) 
        return csvRows.join('\n') 
    } 

    const downloadCsv = () => {
        const data = {
            "Client Name":role==="offtaker"? contract.generator.clientName:contract.offtaker.clientName,
            "Invoice Id": invoice._id,
            "Location": role==="offtaker"? contract.generator.location:contract.offtaker.location,
            "Contact": role==="generator"? contract.offtaker.personName: contract.generator.personName,
            "Date Of Issue":invoice.dateOfIssue,
            "Region": invoice.location,
            "Spot Price":invoice.spotPrice,
            "Strike Price":contract.strikePrice,
            "MLF": invoice.MLF,
            "Eligible LGC's/REC's":invoice.LGC,
            "Total Generated Volume": invoice.totalGeneratedVolume,
            "Calculated Total Generated Volume": invoice.calculatedTotalGeneratedVolume,
            "Total Supplied Volume":invoice.totalSuppliedVolume,
            "Calculated Supplied Volume": invoice.calculatedSuppliedVolume,
            "Total Fixed Amount":invoice.totalFixedAmount,
            "Calculated Total Fixed Amount":invoice.calculatedTotalFixedAmount,
            "Total Floating Amount":invoice.totalFloatingAmount,
            "Calculated Floating Amount":invoice.calculatedTotalFloatingAmount,
            "Generator's Compensation":invoice.generatorCompensation,
            "Calculated Generator's Compensation":invoice.calculatedGeneratorCompensation,
            "Offtaker's Compensation":invoice.offTakerCompensation,
            "Calculated Offtaker's Compensation":invoice.calculatedOffTakerCompensation,
            "Net Amount":invoice.netAmount,
            "Calculated CFD":invoice.calculatedCFD,
            "Calculated Green Product Amount": invoice.calculatedGreenProductAmount,
            "Calculated Electricity Amount": invoice.calculatedElectricityAmount,
            "Calculated Negative Price Compensation": invoice.calculatedNegativePriceCompensation,
            "Volume ShortFall": invoice.volumeShortFall,
            "Calculated ShortFall Charge": invoice.calculatedShortFall,
            "Volume Surplus": invoice.volumeSurplus,
            "Calculated Surplus Charge": invoice.calculatedSurplus,
            "Invoice Status": invoice.invoiceStatus,
            "Transaction Hash": invoice.transactionHash
        }
        const csvdata = csvmaker(data); 
        download(csvdata);
    }

    const navigateToInvoice = (id) =>{
        goToInvoiceUpload(id);
    }

    useEffect(()=>{
        const calculatedErrors = [];
        if(invoice){
            fields.forEach((item)=>{
                if(!compareValue(item)){
                    calculatedErrors.push(`The ${camelCaseToWords(item)} listed in the invoice does not match the independently calculated values.`)
                }
            })
            setErrors(calculatedErrors)
        }
    },[invoice])

    return (
        <div className="invoice__reconcilication__wrapper">
            <div className="client__general__info">
                <div className="client__info__header">
                    <div className="client__name">
                        <p className="name"><span>Client name:</span> {role==="offtaker"? contract.generator.clientName:contract.offtaker.clientName}</p>
                    </div>
                    <div className="invoice__info">
                        <p className="name"><span>Invoice ID:</span> #{invoice._id}</p>
                    </div>
                </div>
                <div className="client__info__content__container">
                    <div className="client__info__card__container">
                        <div className="client__attribute__header">
                            <img src="/icons/add_location.svg" alt="header__icon" />
                            <p className="client__label">Location</p>
                        </div>
                        <div className="client__attribute__value__container">
                            <p className="client__attribute__lines">{role==="offtaker"? contract.generator.location:contract.offtaker.location}</p>
                        </div>
                    </div>
                    <div className="client__info__card__container">
                        <div className="client__attribute__header">
                            <img src="/icons/contact.svg" alt="header__icon" />
                            <p className="client__label">Contact</p>
                        </div>
                        <div className="client__attribute__value__container">
                            <p className="client__attribute__lines">{role==="generator"? contract.offtaker.personName: contract.generator.personName}</p>
                        </div>
                    </div>
                    <div className="client__info__card__container">
                        <div className="client__attribute__header">
                            <img src="/icons/info.svg" alt="header__icon" />
                            <p className="client__label">Invoice Status</p>
                        </div>
                        <div className="client__attribute__value__container">
                            <p className={"client__attribute__lines " + (invoice.invoiceStatus==='failed' ? 'failed' : 'passed')}>{invoice.invoiceStatus.charAt(0).toUpperCase() + invoice.invoiceStatus.slice(1)}</p>
                        </div>
                    </div>
                    <div className="client__info__card__container">
                        <div className="client__attribute__header">
                            <img src="/icons/calendar.svg" alt="header__icon" />
                            <p className="client__label">Date Of Issue</p>
                        </div>
                        <div className="client__attribute__value__container">
                            <p className="client__attribute__lines">{handleDateFormat(invoice.dateOfIssue)}</p>
                        </div>
                    </div>
                </div>
                <div className="client__info__transactionHash">
                    <div className="client__info__transactionHash_heading">
                        <p>Transaction Hash:</p>
                    </div>
                    <div className="client__info__transactionHash_value">
                        <p>{invoice.transactionHash || "Not Available"}</p>
                    </div>
                </div>
            </div>
            <div className="invoice__attribute__container">
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>Location</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{invoice.location}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>Volume (MWh)</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{twoDecimal(invoice.totalSuppliedVolume)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Spot Price (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{twoDecimal(invoice.spotPrice)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Strike Price (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{twoDecimal(contract.strikePrice)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Total Fixed Amount (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p >{twoDecimal(invoice.totalFixedAmount)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Total Floating Amount (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{twoDecimal(invoice.totalFloatingAmount)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Net Amount (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{twoDecimal(invoice.netAmount)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Generators Compensation (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p className={statusClass}>{twoDecimal(invoice.generatorCompensation)}</p>
                    </div>
                </div>
                {/* <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>Cash Receivable From Generator</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p class={invoice.cashReceivableFromGenerator!= invoice.calculatedCashReceivableFromGenerator? 'attribute_error':''}>{invoice.cashReceivableFromGenerator}</p>
                    </div>
                </div> */}
                {/* <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>Cash Receivable From Offtaker</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p class={invoice.cashReceivableFromOfftaker!= invoice.calculatedCashReceivableFromOfftaker? 'attribute_error':''}>{invoice.cashReceivableFromOfftaker}</p>
                    </div>
                </div> */}
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>{`Offtaker's Compenstation (${contract.currency})`}</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p className={statusClass}>{twoDecimal(invoice.offTakerCompensation)}</p>
                    </div>
                </div>
                <div className="invoice__attribute__card">
                    <div className="invoice__attribute__name">
                        <p>Eligible LGC's/REC's</p>
                    </div>
                    <div className="invoice__attribute__value">
                        <p>{twoDecimal(invoice.LGC)}</p>
                    </div>
                </div>
            </div>
            <div className="section__divider black">

            </div>
            <div className="invoice_reconcillation_footer">
                <div className="download__button">
                    <button onClick={downloadCsv}>Download Invoice</button>
                    { retry && <button onClick={() => navigateToInvoice(invoice._id)}>Retry Invoice Upload</button>}
                </div>
                <div className="invoice_error_container">
                    {errors.length >0 && <p className="invoice_error_header"> Error: Invoice Reconciliation Failed</p>}
                    {/* <div className="invoice_errors">
                        {
                            errors.map((item)=>(
                                <div className="invoice_errors_item"><p>{item}</p></div>
                            ))
                        }
                    </div> */}
                </div>
            </div>
            
        </div>
    )
}