import { useContext, useState, useEffect } from "react";
import { createColumnHelper } from '@tanstack/react-table'

import { Table } from '../Table/Table';
import { config } from '../../config';
import { LoaderContext } from "../../context/LoaderContext";
import axios from '../../interceptors/axiosInstance';
import "./contractRequest.css";
import { twoDecimal,pascalCase } from "../../utils/display";
import { MessageContext } from "../../context/MessageContext";

export default function ContractRequest({type}){

    const [contracts, setContracts] = useState([]);
    const {setLoading} = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    useEffect(() => {
        fetchAllContracts();
    }, [])

    const fetchAllContracts = async () => {
        try{
            setLoading(true);
            const res = await axios.get(`${config.BASE_URL}/api/contract/change-requests`);
            setContracts(res.data);
            setLoading(false);
        }
        catch(error){
            setLoading(false);
            setContracts([]);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }      
    }

    const goToRecord = (content) =>{
    //     navigate('Invoice/details', {state: { content, viewRole: "Generator"}
    // } )

    }
        
    const columnHelper = createColumnHelper();
      
    const columns = [
        columnHelper.accessor('contractId', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Contract ID',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('changeField', {
            cell: info => pascalCase(info.getValue()),
            footer: info => info.column.id,
            header: 'Field Name',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row => {
                return row.contract && row.contract[row.changeField] ? row.contract[row.changeField]: '0';
            },
            {
                cell: info => twoDecimal(info.getValue()),
                footer: info => info.column.id,
                header: 'Old Value',
                enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row=>{
                return row.requestedBy && row.requestedBy.userName ? row.requestedBy.userName : ''
            }, {
            cell: info => info.getValue() || '',
            footer: info => info.column.id,
            header: 'Requested By',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('changeValue', {
            cell: info => twoDecimal(info.getValue()),
            footer: info => info.column.id,
            header: 'New Value',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('state', {
            cell: info => {
                const value= info.getValue() || '';
                return <span className={value.toLowerCase()}>{pascalCase(value)}</span>
            },
            footer: info => info.column.id,
            header: 'Approval',
            enableGlobalFilter: false,
        }),

        columnHelper.accessor('', {
            cell: props => <><img className="view-invoice-icon"src={"/icons/success.svg"} alt="View" onClick={() => {goToRecord("approve")}} />  <img className="view-invoice-icon"src={"/icons/failed.svg"} alt="View" onClick={() => {goToRecord("reject")}} /></>,
            footer: info => info.column.id,
            header: 'Action',
            enableGlobalFilter: false,
        }),
    ];
    
    const userColumns = [
        columnHelper.accessor('contractId', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Contract ID',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('changeField', {
            cell: info => pascalCase(info.getValue()),
            footer: info => info.column.id,
            header: 'Field Name',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row => {
                return row.contract && row.contract[row.changeField] ? row.contract[row.changeField]: '0';
            },
            {
                cell: info => twoDecimal(info.getValue()),
                footer: info => info.column.id,
                header: 'Old Value',
                enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row=>{
                return row.requestedBy && row.requestedBy.userName ? row.requestedBy.userName : ''
            }, {
            cell: info => info.getValue() || '',
            footer: info => info.column.id,
            header: 'Requested By',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('changeValue',{
                cell: info => twoDecimal(info.getValue()),
                footer: info => info.column.id,
                header: 'New Value',
                enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row=>{
                if(row.state === 'pending'){
                    return 'Pending For Admin Approval'
                }
                return row.state;
            }, 
            {
            cell: info => {
                const value= info.getValue();
                return <span className={value.toLowerCase()}>{value}</span>
            },
            footer: info => info.column.id,
            header: 'Approval',
            enableGlobalFilter: false,
        })
    ];

    const approvedContracts = contracts.filter((contract) => {
        return contract.state === 'approved';
      });
    
      const rejectedContracts = contracts.filter((contract) => {
        return contract.state === 'pending';
      });

    return (
        <div className="invoice-list-container">
            <div className="invoice-count-container">
                <div className="invoice-count">
                    <div className="invoice-count-text-container">
                        <span className="invoice-count-text">Completed Requests</span>
                        <img src={"/icons/success.svg"} alt="success"/>
                    </div>
                    <span className="invoice-count-text invoice-count-value">{approvedContracts.length}</span>
                </div>
                <div className="invoice-count">
                    <div className="invoice-count-text-container">
                        <span className="invoice-count-text">Open Requests</span>
                        <img src={"/icons/failed.svg"} alt="failed"/>
                    </div>
                    <span className="invoice-count-text invoice-count-value">{rejectedContracts.length}</span>
                </div>
            </div>
            <div className="invoice-list">
            <div className="manage-contract-request-title">Manage Contract Request</div>
                <div className="manage-contract-request-table-container">
                <Table columns={type=='admin'? columns:userColumns} data={contracts} setData={setContracts}
                  placeholder="Search Requested by "  />

                </div>
            </div>
        </div>
    )
}