import "./ConfirmModal.css";

export const ConfirmModal = ({title="Confirm Details Before Proceeding", type="Confirm", toggleModal, data = {}, params={}, labels=null, handleSubmit, loading=false, showTermsAndCondition = false, content='', showAcceptButton= true}) => {
    const obj = {
        __html: `${content}`
    }
    return(
        <>
        <div className="modal__wrapper"></div>
        {
            !showTermsAndCondition &&
            <div className="confirm-modal-container">
                <div className="confirm-modal-header">
                    <p>{title}</p>
                    <p className="cursor" onClick={() => toggleModal(false)}>X</p>
                </div>
                <div className="confirm-modal-content">
                    {
                        data ? data.map(property => {
                            return (
                                <div className="confirm-modal-block" key={property.label}>
                                    <p className="confirm-modal-block-name">{property.label}</p>
                                    <p className="confirm-modal-block-value">{property.value? property.value: 'NA'}</p>
                                </div>
                            );
                        }) : labels.map(itm => {
                            return(
                                <div className="confirm-modal-block" key={itm.label}>
                                    <p className="confirm-modal-block-name">{itm.label}</p>
                                    <p className="confirm-modal-block-value">{params[itm.key]?params[itm.key]:'NA'}</p>
                                </div>
                            )
                        })

                    }
                </div>
                <div className="confirm-modal-actions">
                    <button className="confirm-button" disabled={loading} onClick={handleSubmit}>{type}</button>
                    <button className="cancel-button"  onClick={() => toggleModal(false)}>Cancel</button>
                </div>
            </div>
        }
        {
            showTermsAndCondition &&
            <div className="confirm-modal-container">
                    <div className="confirm-policy-modal-header">
                    {
                        !showAcceptButton && 
                        <p className="cursor" onClick={() => toggleModal(false)}>X</p>
                    }
                    </div>
                <div className="confirm-policy" dangerouslySetInnerHTML={obj}>
                </div>
                {   showAcceptButton &&
                    <div className="confirm-modal-actions-policy">
                        <button className="confirm-button" disabled={loading} onClick={handleSubmit}>{type}</button>
                    </div>
                }
            </div>
        }
        
        </>
    )
}