let accessToken = '';

export const getAccessToken = () =>{
  return accessToken;
}

export const setAccessToken = (token) => {
  accessToken = token;
}

export const removeAccessToken = () =>{
  accessToken = '';
}