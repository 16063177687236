//import axios from "axios";
import axios from "../../interceptors/axiosInstance";
import moment from "moment";
import { createContext, useContext, useState } from "react";
import { config } from "../../config";
import { MessageContext } from "../../context/MessageContext";
import { decimalNumberWithHigherLimitRegex, negativeNumberWithHigherLimitRegex, decimalNumberWith2DecimalRegex } from "../../utils/ValidationRules";

const PPAContext = createContext();

export const PPAContextProvider = ({ children }) => {
  const tabs = [
    { id: "1", label: "Select Model"},
    { id: "2", label: "Enter PPA Parameters" },
    { id: "3", label: "Modify Benchmark Parameters" },
    { id: "4", label: "Result" },
  ];

  // DO NOT CHANGE
  // These are hidden sheetnames in excel to ensure the correct template is being uploaded
  const templateNames = {
    benchmarkFile: "Benchmark",
    volume1CurveFile: "Volume1Curve",
    volume2CurveFile: "Volume2Curve",
    price1CurveFile: "Price1Curve",
    powerPriceCurveFile: "PowerPriceCurve",
    gooPriceCurveFile: "GOOPriceCurve",
    carbonIntensityOfGridFile: "CarbonIntensityOfGrid",
    volumeCurveFile: "volumeCurve",
    blackMarketPriceCurve: "blackMarketPriceCurve",
    greenMarketPriceCurve: "greenMarketPriceCurve",
    mlfCurve: "mlfCurve",
    dlfCurve: "dlfCurve",
    inflationCurve: "inflationCurve",
    riskFreeRateCurve: "riskFreeRateCurve",
    assetCreditRiskAdjustmentCurve: "assetCreditRiskAdjustmentCurve",
    liabilityCreditRiskAdjustmentCurve: "liabilityCreditRiskAdjustmentCurve",
    ppaEscalationCurve: "ppaEscalationCurve",
    ppaPriceCurve: "ppaPriceCurve",
    greenMarketPriceCurveAtInception: "greenMarketPriceCurveAtInception",
    blackMarketPriceCurveAtInception: "blackMarketPriceCurveAtInception"
  };

  const initialState = {
    formData: null,
    resultData: {},
    ppaErrors: [],
    isLoading: false,
    tabId: "1",
  };

  const [activeTabId, setActiveTabId] = useState(initialState.tabId);
  const [formData, setFormData] = useState(initialState.formData);
  const [errors, setErrors] = useState(initialState.ppaErrors);
  const [disableNext, setDisableNext] = useState(true);
  const [disableCalculate, setDisableCalculate] = useState(true);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [resultData, setResultData] = useState(initialState.resultData);
  const { ErrorToast } = useContext(MessageContext);

  const SetFormData = (newData) => {
    if (newData) {
      setFormData(newData);
      validateValues(newData);
    }
  };

  const SetErrors = (newErrors) => {
    if (newErrors) {
      setErrors(newErrors);
    }
  };

  const SetIsLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const SetActiveTabId = (tabId) => {
    if (tabId) {
      setActiveTabId(tabId);
    }
  };

  const SetResultData = (newData) => {
    if (newData) {
      setResultData(newData);
    }
  };

  const DownloadBenchmarkTemplate = async (country, modelType= 'new_PPA', benchMarkType='', startDate='', endDate) => {
    let params = {
      country,
    };
    
    const configs = {
      method: "post",
      url: `${config.BASE_URL}/api/ppa/downloadBenchmarkTemplate`,
      headers: {},
      data: params,
      responseType: "arraybuffer",
    };

    if(modelType === 'existing_PPA') {
      configs.url= `${config.BASE_URL}/api/ppa/download-valuation-template`;
      configs.data = {
        fileName: benchMarkType,
        country,
        startDate,
        endDate,
        freequency: 'monthly'
      }
    }
    try {
      let response = await axios(configs);

      if (!response || !response.data || response.data.byteLength <= 0) {
        ErrorToast(`Template not found.`);
        return;
      }

      var fileDataArray = [];
      fileDataArray.push(response.data);

      const url = window.URL.createObjectURL(
        new Blob(fileDataArray, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );

      const downloadFileName =  modelType === 'existing_PPA' ? benchMarkType : "Benchmark_Template_" + country;
      handleDownload(url, downloadFileName);
    } catch(error) {
      ErrorToast(error?.response?.data?.message || 'Something went wrong. Please try again');
    }
  };

  const DownloadFile = async (name, userID, startDate = '', endDate = '', freequency = '') => {
    var params = {
      fileName: name,
      country: formData.location,
      userId: userID,
      startDate,
      endDate,
      freequency
    };

    if(formData?.modelType === 'existing_PPA' && (!startDate || !endDate || !freequency)) {
      ErrorToast(`Please Provide Values For Model Start Date, Contract End Date and Valuation Freequency To Proceed.`);
      return;
    }

    var configs = {
      method: "post",
      url: `${config.BASE_URL}/api/ppa/downloadPPATemplate`,
      headers: {},
      data: params,
      responseType: "arraybuffer",
    };

    if([
      'volumeCurve', 
      'blackMarketPriceCurve', 
      'greenMarketPriceCurve', 
      'inflationCurve',
      'mlfCurve',
      'dlfCurve',
      'riskFreeRateCurve', 
      'assetCreditRiskAdjustmentCurve',
      'liabilityCreditRiskAdjustmentCurve',
      'ppaEscalationCurve',
      'ppaPriceCurve',
      'greenMarketPriceCurveAtInception',
      'blackMarketPriceCurveAtInception'
    ].includes(name)) {
      configs.url= `${config.BASE_URL}/api/ppa/download-valuation-template`;
    }
    try {
      let response = await axios(configs);

      if (!response || !response.data || response.data.byteLength <= 0) {
        ErrorToast(`Template not found.`);
        return;
      }

      var fileDataArray = [];
      fileDataArray.push(response.data);

      var url = window.URL.createObjectURL(
        new Blob(fileDataArray, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      handleDownload(url, name);
    } catch(error) {
      ErrorToast(error?.response?.data?.message || `Something went wrong. Please try again`);
    }
  };

  const handleDownload = (url, fileName) => {
    const a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    a.href = url;
    a.target = "_blank";
    a.download = fileName + ".xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const validateValues = (data) => {
    let errors = {};

    const re = /^\d+(\.\d{1,2})?$/;
    const customperentagere = /^0+(\.\d{1,2})?$/;
    const priceregex = /^\d+(\.\d{1,2})?$/;
    const pvregex = /^\d+(\.\d{1,2})?$/;
    const customnpvregex = /^-?\d+(\.\d{1,2})?$/;

    if(data?.modelType === 'existing_PPA') {
      
      if(!data?.ppaLocation) {
        errors.ppaLocation = true;
      }

      if(!data?.valuationAtInception) {
        errors.valuationAtInception = true;
      }

      if(data?.valuationAtInception === 'no') {
        if(!negativeNumberWithHigherLimitRegex.test(data?.calibrationAdjustment)) {
          errors.calibrationAdjustment = true;
        }

        if(data?.blackMarketPriceCurveAtInception === "" || data?.blackMarketPriceCurveAtInception === undefined) {
          errors.blackMarketPriceCurveAtInception = true;
        }

        if(data?.greenMarketPriceCurveAtInception === "" || data?.greenMarketPriceCurveAtInception === undefined) {
          errors.greenMarketPriceCurveAtInception = true;
        }
      }

      if(!data?.valuationDate) {
        errors.valuationDate = true;
      }

      if(!data?.modelStartDate) {
        errors.modelStartDate = true;
      }

      if(!data?.valuationFreequency) {
        errors.valuationFreequency = true;
      }

      if(!data?.contractStartDate) {
        errors.contractStartDate = true;
      }

      if(!data?.contractEndDate) {
        errors.contractEndDate = true;
      }

      if(!decimalNumberWithHigherLimitRegex.test(data?.counterPartyAllocation)) {
        errors.counterPartyAllocation = true;
      }

      if(!data?.ppaVolStructure) {
        errors.ppaVolStructure = true;
      }

      if(!data?.changingVolumeInPPA) {
        errors.changingVolumeInPPA = true;
      }

      if(data?.changingVolumeInPPA === 'no' && !decimalNumberWithHigherLimitRegex.test(data?.annualPPAVolumeCapacity)) {
        errors.annualPPAVolumeCapacity = true;
      }

      if(data?.changingVolumeInPPA === 'yes' && (data?.volumeCurveFile === "" || data?.volumeCurveFile === undefined)) {
        errors.volumeCurveFile = true;
      }

      if(!data?.ppaPricing) {
        errors.ppaPricing = true;
      }

      if(data?.ppaPricing !== 'custom' && !decimalNumberWithHigherLimitRegex.test(data?.ppaPrice)) {
        errors.ppaPrice = true;
      }

      if(data?.ppaPricing === 'custom' && (data?.ppaPriceCurve === "" || data?.ppaPriceCurve === undefined)) {
        errors.ppaPriceCurve = true;
      }

      if(data?.ppaEscalationCurve === "" || data?.ppaEscalationCurve === undefined) {
        errors.ppaEscalationCurve = true;
      }

      if(!data?.unbundlingApproach) {
        errors.unbundlingApproach = true;
      }

      if(data?.unbundlingApproach === 'flat_unbundling' && !decimalNumberWith2DecimalRegex.test(data?.unbundlingRoundingMultiple)) {
        errors.unbundlingRoundingMultiple = true;
      }

      if(!decimalNumberWithHigherLimitRegex.test(data?.curtailmentFactor)) {
        errors.curtailmentFactor = true;
      }

      if(!data?.RETSchemeEndDate) {
        errors.RETSchemeEndDate = true;
      }

      if(!data?.periodByPeriod) {
        errors.periodByPeriod = true;
      }

      if(!decimalNumberWithHigherLimitRegex.test(data?.excessCashflowDifferenceFactor)) {
        errors.excessCashflowDifferenceFactor = true;
      }

      if(!negativeNumberWithHigherLimitRegex.test(data?.lowTolerance)) {
        errors.lowTolerance = true;
      }

      if(!decimalNumberWithHigherLimitRegex.test(data?.highTolerance)) {
        errors.highTolerance = true;
      }

      if (Object.keys(errors).length === 0) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }

      if (!data?.iscustomised) {
        errors.iscustomised = true;
      }

      if(data.iscustomised === 'Yes') {
        if(!data?.blackMarketPrice) {
          errors.blackMarketPrice = true;
        }
        if(data?.blackMarketPrice === 'yes' && !data?.blackMarketPriceCurve) {
          errors.blackMarketPriceCurve = true;
        }

        if(data?.valuationAtInception === 'yes') {
          if(!data?.greenMarketPrice) {
            errors.greenMarketPrice = true;
          }
          
          if(data?.greenMarketPrice === 'yes' && !data?.greenMarketPriceCurve) {
            errors.greenMarketPriceCurve = true;
          }
        }

        if(!data?.uploadMLFCurve) {
          errors.uploadMLFCurve = true;
        }
        if(data?.uploadMLFCurve === 'yes' && !data?.mlfCurve) {
          errors.mlfCurve = true;
        }

        if(!data?.uploadDLFCurve) {
          errors.uploadDLFCurve = true;
        }
        if(data?.uploadDLFCurve === 'yes' && !data?.dlfCurve) {
          errors.dlfCurve = true;
        }

        if(!data?.inflation) {
          errors.inflation = true;
        }
        if(data?.inflation === 'yes' && !data?.inflationCurve) {
          errors.inflationCurve = true;
        }

        if(!data?.riskFreeRate) {
          errors.riskFreeRate = true;
        }
        if(data?.riskFreeRate === 'yes' && !data?.riskFreeRateCurve) {
          errors.riskFreeRateCurve = true;
        }

        if(!data?.assetCreditRiskAdjustment) {
          errors.assetCreditRiskAdjustment = true;
        }
        if(data?.assetCreditRiskAdjustment === 'yes' && !data?.assetCreditRiskAdjustmentCurve) {
          errors.assetCreditRiskAdjustmentCurve = true;
        }

        if(!data?.liabilityCreditRiskAdjustment) {
          errors.liabilityCreditRiskAdjustment = true;
        }
        if(data?.liabilityCreditRiskAdjustment === 'yes' && !data?.liabilityCreditRiskAdjustmentCurve) {
          errors.liabilityCreditRiskAdjustmentCurve = true;
        }
      }
      
    } else {
      if (data?.location === "" || data?.location === undefined) {
        errors.location = true;
      }
      if (data?.conslocation === "" || data?.conslocation === undefined) {
        errors.conslocation = true;
      }
      if (data?.ppastartdate === "" || data?.ppastartdate === undefined || data?.ppastartdate === null) {
        errors.ppastartdate = true;
      }
      if (data?.ppastartdate && data?.ppaenddate) {
        if (data?.ppaenddate < data?.ppastartdate) {
          errors.invaliddate = true;
        }
      }
      if (data?.ppaenddate === "" || data?.ppaenddate === undefined || data?.ppaenddate === null) {
        errors.ppaenddate = true;
      }
      if (data?.ppavolstructure === "" || data?.ppavolstructure === undefined) {
        errors.ppavolstructure = true;
      }
      if (
        data?.ppavolstructure === "Pay as produced" &&
        (data?.benchmarkvolume === "" || data?.benchmarkvolume === undefined)
      ) {
        errors.benchmarkvolume = true;
      }
      if (data?.benchmarkvolume === "No" && (data?.volume1CurveFile === "" || data?.volume1CurveFile === undefined)) {
        errors.volume1CurveFile = true;
      }
      if (data?.changevolume === "Yes" && (data?.volume2CurveFile === "" || data?.volume2CurveFile === undefined)) {
        errors.volume2CurveFile = true;
      }
      if (data?.ppapricing === "Custom" && (data?.price1CurveFile === "" || data?.price1CurveFile === undefined)) {
        errors.price1CurveFile = true;
      }
      if (data?.ppapricing === "" || data?.ppapricing === undefined) {
        errors.ppapricing = true;
      }
      if (data?.discountfactor === "" || data?.discountfactor === undefined) {
        errors.discountfactor = true;
      }
      if (
        data?.degradation === "Custom fixed" &&
        (data?.cuspercentage === undefined ||
          data?.cuspercentage === undefined ||
          !customperentagere.test(data?.cuspercentage))
      ) {
        errors.cuspercentage = true;
      }
      if (
        data?.discountfactor === "Custom fixed" &&
        (data?.customnpv === undefined || data?.customnpv === "" || !customnpvregex.test(data?.customnpv))
      ) {
        errors.customnpv = true;
      }
      if (
        (data?.ppapricing === "Fix" || data?.ppapricing === "Indexed with CPI") &&
        (data?.ppaprice === undefined || data?.ppaprice === "" || !priceregex.test(data?.ppaprice))
      ) {
        errors.ppaprice = true;
      }
      if (
        (data?.ppavolstructure === "Baseload" || data?.benchmarkvolume === "Yes") &&
        data?.changevolume === "No" &&
        (data?.annualvolcapacity === undefined || data?.annualvolcapacity === "" || !re.test(data?.annualvolcapacity))
      ) {
        errors.annualvolcapacity = true;
      }
      if (
        (data?.ppavolstructure === "Baseload" || data?.benchmarkvolume === "Yes") &&
        data?.changevolume === "No" &&
        (data?.annualVolumeUnit === undefined || data?.annualVolumeUnit === "")
      ) {
        errors.annualVolumeUnit = true;
      }
      if (
        data?.ppavolstructure === "Pay as produced" &&
        data?.benchmarkvolume === "Yes" &&
        data?.changevolume === "No" &&
        (data?.technology === "" || data?.technology === undefined)
      ) {
        errors.technology = true;
      }
      if (
        data?.technology === "Mix PV (fixed) & Wind (onshore)" &&
        data?.benchmarkvolume === "Yes" &&
        data?.changevolume === "No" &&
        (data?.pvshare === "" || data?.pvshare === undefined || !pvregex.test(data?.pvshare))
      ) {
        errors.pvshare = true;
      }
      if (
        (data?.ppavolstructure === "Baseload" || data?.benchmarkvolume === "Yes") &&
        (data?.changevolume === "" || data?.changevolume === undefined)
      ) {
        errors.changevolume = true;
      }
      if (
        (data?.technology === "PV (fixed)" || data?.technology === "Mix PV (fixed) & Wind (onshore)") &&
        data?.benchmarkvolume === "Yes" &&
        data?.changevolume === "No" &&
        (data?.degradation === "" || data?.degradation === undefined)
      ) {
        errors.degradation = true;
      }
  
      if (Object.keys(errors).length === 0) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
  
      // PPA Parameter Validation
  
      if (!data?.iscustomised || data?.iscustomised === "") {
        errors.iscustomised = true;
      }
  
      if (data?.iscustomised === "Yes") {
        if (!data?.powerPriceCurve || data?.powerPriceCurve === "") {
          errors.powerPriceCurve = true;
        }
  
        if (!data?.gooPriceCurve || data?.gooPriceCurve === "") {
          errors.gooPriceCurve = true;
        }
  
        if (!data?.carbonIntensityOfGrid || data?.carbonIntensityOfGrid === "") {
          errors.carbonIntensityOfGrid = true;
        }
  
        if (data?.powerPriceCurve === "Yes" && (!data?.powerPriceCurveFile || data?.powerPriceCurveFile == "")) {
          errors.powerPriceCurveFile = true;
        }
  
        if (data?.gooPriceCurve === "Yes" && (!data?.gooPriceCurveFile || data?.gooPriceCurveFile == "")) {
          errors.gooPriceCurveFile = true;
        }
  
        if (
          data?.carbonIntensityOfGrid === "Yes" &&
          (!data?.carbonIntensityOfGridFile || data?.carbonIntensityOfGridFile == "")
        ) {
          errors.carbonIntensityOfGridFile = true;
        }
      }
    }

    if (Object.keys(errors).length === 0) {
      setDisableCalculate(false);
    } else {
      setDisableCalculate(true);
    }

    SetErrors(errors);
  };

  const ValidateTemplate = async (file, templateName, fromBenchmark = false, payload ={}) => {
    try {
      if(formData?.modelType === 'new_PPA' || (fromBenchmark === true && payload.modelType === 'new_PPA')) {
        if (templateName != templateNames.benchmarkFile && (!formData.ppastartdate || !formData.ppaenddate)) {
          //ErrorToast(`Please Select Start And End Date To Procced.`);
          return (result = "Not Valid");
        } else {
          let form = new FormData();

          form.append("templateName", templateName);
          form.append("ppastartdate", formData?.ppastartdate ? moment(formData?.ppastartdate).format("YYYY-MM") : null);
          form.append("ppaenddate", formData?.ppaenddate ? moment(formData?.ppaenddate).format("YYYY-MM") : null);
          form.append("ppavolstructure", formData?.ppavolstructure ? formData?.ppavolstructure : null);
          form.append("template", file, templateName);

          SetIsLoading(true);
          var result = await axios
            .post(`${config.BASE_URL}/api/ppa/validateTemplate`, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              SetIsLoading(false);
              return response.data;
            });

          return result;
        }
      }
      else if(formData?.modelType === 'existing_PPA' || (fromBenchmark === true && payload.modelType === 'existing_PPA')) {
        const data = {};
        data.modelStartDate = fromBenchmark ? payload?.startDate : formData?.modelStartDate;
        data.contractEndDate = fromBenchmark ? payload?.endDate : formData?.contractEndDate;
        data.valuationFreequency = fromBenchmark ? 'monthly' : formData?.valuationFreequency;
        data.valuationAtInception = fromBenchmark ? 'no' : formData?.valuationAtInception;

        if (!data.modelStartDate || !data?.contractEndDate || !data?.valuationFreequency || !data?.valuationAtInception) {
          return (result = "bad_request");
        } else {
          let form = new FormData();

          form.append("templateName", templateName);
          form.append("startDate", data?.modelStartDate);
          form.append("endDate", data?.contractEndDate);
          form.append("valuationFreequency", data?.valuationFreequency);
          form.append("valuationAtInception", data?.valuationAtInception);
          form.append("template", file, templateName);

          SetIsLoading(true);
          var result = await axios
            .post(`${config.BASE_URL}/api/ppa/validate-valuation-template`, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              SetIsLoading(false);
              return response.data;
            });

          return result;
        }
      }
    } catch (err) {
      SetIsLoading(false);
      ErrorToast(`Unable to validate the template.`);
    }
  };

  return (
    <PPAContext.Provider
      value={{
        tabs: tabs,
        isLoading: isLoading,
        SetIsLoading,
        formData: formData,
        SetFormData,
        disableNext,
        disableCalculate,
        errors: errors,
        SetErrors,
        activeTabId: activeTabId,
        SetActiveTabId,
        resultData,
        SetResultData,
        DownloadFile,
        DownloadBenchmarkTemplate,
        templateNames,
        ValidateTemplate,
      }}
    >
      {children}
    </PPAContext.Provider>
  );
};

export default PPAContext;
