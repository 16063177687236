import { FETCH_ONE_USER, CREATE_USER } from "../actions/types";

export default function(state = null, action){
    console.log(action)
    switch (action.type){
        case FETCH_ONE_USER:
            return action.payload || false
        case CREATE_USER:
            return action.payload || false
        default:
            return state;
    }
}