import React, { useContext, useEffect, useReducer } from "react";
import "../Style/PPAStyles.css";

import CustomFileUpload from "../../../../components/PPA/CustomFileUpload/CustomFileUpload";
import { DatePicker } from "../../../../components/General/DataEntry/DatePicker/DatePicker";
import UserContext from "../../../../context/UserContext";
import PPAContext from "../../../../contexts/PPA/PPAContext";
import {
    CountryLocationList,
    ChangingVolumeList,
    PPAVolumeStructureList,
    PPAVolumeCapacityUnit,
    yesOrNoList
} from "../../../../utils/PPA/PPAMasterList";

const ValuationFreequencyOptions = [
    {label: 'Monthly', value: 'monthly'},
    {label: 'Quaterly', value: 'quaterly'},
    {label: 'Yearly', value: 'yearly'},
]

const TechnologyList = [
    { label: "PV (fixed)", value: "pv_fixed" },
    { label: "Wind (onshore)", value: "wind_onshore" }, 
    { label: "Mix PV (fixed) & Wind (onshore)", value: "mix_pv_wind" },
    { label: "Hydro", value: "hydro" },
    { label: 'Battery', value: 'battery'}
];

const PPAPricingList = [
   { label: "Fix", value: 'fix' }, 
   { label: "Indexed with CPI", value: 'indexed_with_cpi' },
   { label: "Custom", value: 'custom' }
];

const UnbundlingApproachList = [
    { label: "Flat Unbundling", value: 'flat_unbundling' }, 
    { label: "Dynamic Unbundling", value: 'dynamic_unbundling' }, 
    { label: "Annual Rolling", value: 'annual_rolling' }, 
    { label: "No Unbundling", value: 'no_unbundling' }, 
]


function ExistingPPA() {
    const { formData, SetFormData, errors, disableNext, SetActiveTabId, DownloadFile } = useContext(PPAContext);

    const { authUser } = useContext(UserContext);
    useEffect(() => {
        console.log(`The updated params ${JSON.stringify(formData, null, 2)}`);
    }, [formData]);

    const handleChange = ({ target }) => {
        let newState = {};
        if(target.name === 'valuationAtInception' && target.value === 'yes') {
            newState = { ...formData, [target.name]: target.value, calibrationAdjustment: 0 };
        } else {
            newState = { ...formData, [target.name]: target.value };
        }
        SetFormData(newState);
    };

    const startEvaluation = () => {
        SetFormData({
            ...formData,
            iscustomised: formData.iscustomised ? formData.iscustomised : "No",
        });
        SetActiveTabId("3");
    };

    const navigateBack = () => {
        SetActiveTabId('1');
    }

    const handleCalValue = (value, category) => {
        handleChange({ target: { name: category, value }});
    }

    const downloadTemplate = (e, fileName) => {
        e.preventDefault();
        const userID = authUser.userName;
        DownloadFile(fileName, userID, formData?.modelStartDate, formData?.contractEndDate, formData?.valuationFreequency);
    };

    return (
        <div className="add-evaluation_wrapper">
            <div className="add-evaluation-form-container col-md-12">
                <form className="add-evaluation-form col-md-12" onSubmit={() => {}}>
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="ppa-location">Asset Location *</label>

                        <div className="input-wrapper">
                            <select
                                className="admin__contract__input"
                                id="ppa-location"
                                name="ppaLocation"
                                value={formData?.ppaLocation}
                                onChange={handleChange}
                                required
                            >
                                <option value="" >Please Select Any Value </option>
                                {CountryLocationList.map((country) => (
                                    <option value={country} key={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                            <span className="cppa-custom-tooltip">
                                Location of the renewable assets, this influences the benchmark data which will be used for valuation
                            </span>
                        </div>
                        {errors.ppaLocation ? <p className="error">Please Select Asset Location</p> : null}
                    </div>
                    <DatePicker 
                        error={errors?.valuationDate ? 'Please Select Valuation Date' : ''} 
                        formClass={"col-md-3"}
                        label="Valuation Date * " 
                        CalValue={formData?.valuationDate} 
                        setCalValue={handleCalValue} 
                        id={"valuation-date"} 
                        placeholder={"Select Date (Required)"} 
                        category={"valuationDate"}
                        helperText="Valuation Date"
                    />
                    {
                        formData?.valuationDate && (
                            <div className="col-md-3">
                                <label className="avf-label" htmlFor="valuation-at-inception">Valuation At Inception *</label>
                                <div className="input-wrapper">
                                    <select
                                        className="admin__contract__input"
                                        id="valuation-at-inception"
                                        name="valuationAtInception"
                                        value={formData?.valuationAtInception}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Please Select Any Value</option>
                                        {   
                                            yesOrNoList.map(list => (
                                                <option value={list.value} key={list.value}>{list.label}</option>
                                            ))
                                        }
                                    </select>

                                    <span className="cppa-custom-tooltip">Select yes to find calibration adjustment</span>
                                </div>
                                {errors.valuationAtInception ? <p className="error">Please Select Valuation At Inception</p> : null}
                            </div>
                        )
                    }

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="calibration-factor">Calibration Adjustment *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="calibration-adjustment"
                                name="calibrationAdjustment"
                                value={formData?.calibrationAdjustment}
                                onChange={handleChange}
                                placeholder="0.00%"
                                disabled={formData?.valuationAtInception === 'yes'}
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                The Calibration Adjustment which will be applied for calculation.
                            </span>
                        </div>

                        { errors.calibrationAdjustment ? <p className="error">Please Provide Calibration Adjustment</p> : null}
                    </div>
                
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="valuation-freequency">Valuation Frequency *</label>
                        <div className="input-wrapper">
                            <select
                                className="admin__contract__input"
                                id="valuation-freequency"
                                name="valuationFreequency"
                                value={formData?.valuationFreequency}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Please Select Any Value</option>
                                {   
                                    ValuationFreequencyOptions.map(freequency => (
                                        <option value={freequency.value} key={freequency.value}>{freequency.label}</option>
                                    ))
                                }
                            </select>
                            <span className="cppa-custom-tooltip">Frequency at which valuation needs to be performed</span>
                        </div>
                        {errors.valuationFreequency ? <p className="error">Please select valuation freequency</p> : null}
                    </div>

                    <DatePicker 
                        error={errors?.modelStartDate ? 'Please Select Model Start Date' : ''} 
                        formClass={"col-md-3"}
                        label="Model Start Date * " 
                        CalValue={formData?.modelStartDate} 
                        setCalValue={handleCalValue} 
                        id={"model-start-date"} 
                        placeholder={"Select Date (Required)"} 
                        category={"modelStartDate"}
                        helperText="Model Start Date"
                    />
                    <DatePicker 
                        error={errors?.contractStartDate ? 'Please Select Contract Start Date' : ''} 
                        formClass={"col-md-3"}
                        label="Contract Start Date * " 
                        CalValue={formData?.contractStartDate} 
                        setCalValue={handleCalValue} 
                        id={"contract-start-date"} 
                        placeholder={"Select Date (Required)"} 
                        category={"contractStartDate"}
                        helperText="Start Of Agreement"
                    />
                    <DatePicker 
                        error={errors?.contractEndDate ? 'Please Select Contract End Date' : ''} 
                        formClass={"col-md-3"}
                        label="Contract End Date * " 
                        CalValue={formData?.contractEndDate} 
                        setCalValue={handleCalValue} 
                        id={"contract-end-date"} 
                        placeholder={"Select Date (Required)"} 
                        category={"contractEndDate"}
                        helperText="End Of Agreement"
                    />
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="counterParty-allocation">Buyers Share *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="counterParty-allocation"
                                name="counterPartyAllocation"
                                value={formData?.counterPartyAllocation}
                                onChange={handleChange}
                                placeholder="0.00%"
                                required
                            />
                            <span className="cppa-custom-tooltip">PPA Allocation to Counterparty</span>
                        </div>

                        { errors.counterPartyAllocation ? <p className="error">Please Enter Buyers Share</p> : null}
                    </div>

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="ppa_volume_structure">PPA Volume Structure *</label>
                        <div className="input-wrapper">
                            <select
                                className="admin__contract__input"
                                id="ppa-volume-structure"
                                name="ppaVolStructure"
                                value={formData?.ppaVolStructure}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Please Select Any Value</option>
                                {
                                    PPAVolumeStructureList.map(volume => (<option value={volume} key={volume}>{volume}</option>))
                                }
                            </select>
                            <span className="cppa-custom-tooltip">
                               PPA Volume Structure
                            </span>
                        </div>
                        {errors.ppaVolStructure ? <p className="error">Please Select PPA Volume Structure</p> : null}
                    </div>

                    {(formData?.ppaVolStructure === "Baseload" || formData?.ppaVolStructure === "Pay as produced") && (
                        <div className="col-md-3">
                            <label className="avf-label" htmlFor="changing-volume-in-ppa"> Changing Volumes in PPA *</label>
                            <div className="input-wrapper">
                                <select
                                    className="admin__contract__input"
                                    id="changing-volume-in-ppa"
                                    name="changingVolumeInPPA"
                                    value={formData?.changingVolumeInPPA}
                                    onChange={handleChange}
                                    required
                                >
                                <option value="">Please Select Any Value</option>
                                {
                                    yesOrNoList.map(list => ( <option value={list.value} key={list.value}>{list.label}</option>))
                                }
                                </select>

                                <span className="cppa-custom-tooltip"> Specify variable volume developments during PPA tenor</span>
                            </div>
                            {errors.changingVolumeInPPA ? <p className="error">Please Select Changing Volumes in PPA</p> : null}
                        </div>
                    )}

                    {(formData?.ppaVolStructure === "Baseload" || formData?.ppaVolStructure === "Pay as produced") && formData?.changingVolumeInPPA === "yes" && (
                        <div className="col-md-3">
                            <label className="avf-label" htmlFor="upload-volumecurve">Upload Volume Curve *</label>
                            <div className="uload_download_block">
                                <CustomFileUpload FormName="volumeCurveFile" UploadButtonText="+ Upload"></CustomFileUpload>
                                <span
                                    className="download_link collapsed-download-btn"
                                    onClick={(e) => downloadTemplate(e, "volumeCurve")}
                                >
                                    <img className="" src={"/icons/download.png"} alt="Download" />
                                    <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                                </span>
                            </div>
                            {errors.volumeCurveFile ? <p className="error">Please Upload</p> : null}
                        </div>
                    )}

                    {formData?.changingVolumeInPPA === "no" && (formData?.ppaVolStructure === "Baseload" || formData?.ppaVolStructure === "Pay as produced") && (
                        <>
                            <div className="col-md-3">
                                <label className="avf-label" htmlFor="annual-vol-capacity">Annual PPA Volume Capacity *</label>
                                <div className="input-wrapper">
                                    <input
                                        className="admin__contract__input"
                                        type="text"
                                        id="annual-ppa-vol-capacity"
                                        name="annualPPAVolumeCapacity"
                                        value={formData?.annualPPAVolumeCapacity}
                                        onChange={handleChange}
                                        placeholder="Annual PPA Volume Capacity"
                                        required
                                    />
                                    <span className="cppa-custom-tooltip">Generation capacity of the contracted assets in MW.</span>
                                </div>

                                {errors.annualPPAVolumeCapacity ? <p className="error">Please Enter Annual PPA Volume Capacity</p> : null}
                            </div>

                            {/* <div className="col-md-3">
                                <label className="avf-label" htmlFor="annual-volume-unit"> Annual PPA Volume Capacity Unit *</label>
                                <div className="input-wrapper">
                                    <select
                                        className="admin__contract__input"
                                        id="annual-volume-unit"
                                        name="annualVolumeUnit"
                                        value={formData?.annualVolumeUnit}
                                        onChange={handleChange}
                                        required
                                    >
                                    <option value="">Please Select Any Value</option>
                                    {PPAVolumeCapacityUnit.map((unit, index) => (
                                        <option value={unit} key={unit}>{unit}</option>
                                    ))}
                                    </select>
                                    <span className="cppa-custom-tooltip">
                                    For PV MW values are expressed in MWp, whereas the capacity for windfarms are stated in MW.
                                    </span>
                                </div>

                                {errors.annualVolumeUnit ? (
                                    <p className="error">Please Select Annual PPA Volume Capacity Unit</p>
                                ) : null}
                            </div> */}
                        </>
                    )}

                    { formData?.ppaVolStructure === "Pay as produced" && formData?.changingVolumeInPPA === 'no' && (
                        <div className="col-md-3">
                            <label className="avf-label" htmlFor="technology">Technology </label>
                            <div className="input-wrapper">
                                <select
                                    className="admin__contract__input"
                                    id="technology"
                                    name="technology"
                                    onChange={handleChange}
                                    value={formData?.technology}
                                    required
                                >
                                    <option value="">Please Select Any Value</option>
                                    {
                                        TechnologyList.map(technology => (
                                            <option value={technology.value} key={technology.value}>{technology.label}</option>
                                        ))
                                    }
                                </select>
                                <span className="cppa-custom-tooltip"> Renewable asset-class contracted via specified PPA </span>
                            </div>

                            {errors.technology ? <p className="error">Please select Technology</p> : null}
                        </div>
                    )}
                    
                    { formData?.ppaVolStructure === "Pay as produced" && formData?.technology === "mix_pv_wind" && formData?.changingVolumeInPPA === "no" && (
                        <div className="col-md-3">
                            <label className="avf-label" htmlFor="pv-share-on-total-production">PV Share On Total Production </label>
                            <div className="input-wrapper">
                                <input
                                    className="admin__contract__input"
                                    type="text"
                                    id="pv-share-on-total-production"
                                    name="pvShareOnTotalProduction"
                                    value={formData?.pvShareOnTotalProduction}
                                    onChange={handleChange}
                                    placeholder="0.00"
                                    required
                                />
                                <span className="cppa-custom-tooltip">
                                    PV Share On Total Production.
                                </span>
                            </div>

                            { errors.pvShareOnTotalProduction ? <p className="error">Please enter Annual PPA Volume Capacity</p> : null}
                         </div>
                    )}
                    { (formData?.technology === "mix_pv_wind" || formData?.technology === "pv_fixed" ) && formData?.ppaVolStructure === "Pay as produced" && formData?.changingVolumeInPPA === "no" && (
                        <div className="col-md-3">
                            <label className="avf-label" htmlFor="degradation-pv">Degradation P.A (PV) </label>
                            <div className="input-wrapper">
                                <input
                                    className="admin__contract__input"
                                    type="text"
                                    id="degradation-pv"
                                    name="degradationPV"
                                    value={formData?.degradationPV}
                                    onChange={handleChange}
                                    placeholder="0.00"
                                    required
                                />
                                <span className="cppa-custom-tooltip">
                                    Degradation P.A (PV)
                                </span>
                            </div>

                            { errors.degradationPV ? <p className="error">Please enter Annual PPA Volume Capacity</p> : null}
                        </div>
                    )}

                    {/* <div className="col-md-3">
                        <label className="avf-label" htmlFor="MLF">MLF *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="MLF"
                                name="MLF"
                                value={formData?.MLF}
                                onChange={handleChange}
                                placeholder="0.00"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                Generation capacity of the contracted assets either in MW or in MWh.
                            </span>
                        </div>

                        { errors.MLF ? <p className="error">Please enter MLF</p> : null}
                    </div>
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="DLF">DLF *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="DLF"
                                name="DLF"
                                value={formData?.DLF}
                                onChange={handleChange}
                                placeholder="0.00"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                Generation capacity of the contracted assets either in MW or in MWh.
                            </span>
                        </div>

                        { errors.DLF ? <p className="error">Please enter Annual PPA Volume Capacity</p> : null}
                    </div> */}

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="minimum-generation">Minimum Generation </label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="minimum-generation"
                                name="minimumGeneration"
                                value={formData?.minimumGeneration}
                                onChange={handleChange}
                                placeholder="0.00"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                Minimum Generation.
                            </span>
                        </div>

                        { errors.minimumGeneration ? <p className="error">Please Enter Minimum Generation</p> : null}
                    </div>

                    
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="ppa-pricing">PPA Pricing *</label>
                        <div className="input-wrapper">
                            <select
                                className="admin__contract__input"
                                id="ppa-pricing"
                                name="ppaPricing"
                                onChange={handleChange}
                                value={formData?.ppaPricing}
                                required
                            >
                                <option value="">Please Select Any Value</option>
                                {
                                    PPAPricingList.map(pricing => (
                                        <option value={pricing.value} key={pricing.value}>{pricing.label}</option>
                                    ))
                                }
                            </select>
                            <span className="cppa-custom-tooltip">PPA Pricing</span>
                        </div>

                        {errors.ppaPricing ? <p className="error">Please Select PPA Pricing</p> : null}
                    </div>
                    {
                        formData?.ppaPricing !== 'custom' && (
                            <div className="col-md-3">
                                <label className="avf-label" htmlFor="ppa-price">PPA Price *</label>
                                <div className="input-wrapper">
                                    <input
                                        className="admin__contract__input"
                                        type="text"
                                        id="ppa-price"
                                        name="ppaPrice"
                                        value={formData?.ppaPrice}
                                        onChange={handleChange}
                                        placeholder="0.00"
                                        required
                                    />
                                    <span className="cppa-custom-tooltip">PPA Price.</span>
                                </div>

                                { errors.ppaPrice ? <p className="error">Please Enter PPA Price</p> : null}
                            </div>
                        )
                    }
                    
                    { formData?.ppaPricing === 'custom' && (
                        <div className="col-md-3">
                            <label className="avf-label" htmlFor="ppa-price-curve">PPA Price Curve *</label>
                            <div className="uload_download_block">
                                <CustomFileUpload FormName="ppaPriceCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                                <span
                                    className="download_link collapsed-download-btn"
                                    onClick={(e) => downloadTemplate(e, "ppaPriceCurve")}
                                >
                                    <img className="" src={"/icons/download.png"} alt="Download" />
                                    <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                                </span>
                            </div>
                            {errors.ppaPriceCurve ? <p className="error">Please Upload</p> : null}
                        </div>
                    )}

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="price1CurveFile">PPA Escalation Curve *</label>
                        <div className="uload_download_block">
                            <CustomFileUpload FormName="ppaEscalationCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                            <span
                                className="download_link collapsed-download-btn"
                                onClick={(e) => downloadTemplate(e, "ppaEscalationCurve")}
                            >
                                <img className="" src={"/icons/download.png"} alt="Download" />
                                <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                            </span>
                        </div>
                        {errors.ppaEscalationCurve ? <p className="error">Please Upload</p> : null}
                    </div>

                    {/* <div className="col-md-3">
                        <label className="avf-label" htmlFor="mlf">PPA Escalation Factor *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="mlf"
                                name="mlf"
                                value={formData?.mlf}
                                onChange={handleChange}
                                placeholder="0.00"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                Generation capacity of the contracted assets either in MW or in MWh.
                            </span>
                        </div>

                        { errors.mlf ? <p className="error">Please enter MLF</p> : null}
                    </div> */}
                    
                    
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="unbundling-approach">Unbundling Approach *</label>
                        <div className="input-wrapper">
                            <select
                                className="admin__contract__input"
                                id="unbundling-approach"
                                name="unbundlingApproach"
                                value={formData?.unbundlingApproach}
                                onChange={handleChange}
                                required
                            >
                            <option value="">Please Select Any Value</option>
                            {
                                UnbundlingApproachList.map(approach => ( <option value={approach.value} key={approach.value}>{approach.label}</option>))
                            }
                            </select>

                            <span className="cppa-custom-tooltip">Unbundling approach which will be used for valuation.</span>
                        </div>
                        {errors.unbundlingApproach ? <p className="error">Please Select Unbundling Approach</p> : null}
                    </div>
                    {
                        formData?.unbundlingApproach === 'flat_unbundling' && (
                            <div className="col-md-3">
                                <label className="avf-label" htmlFor="unbundling-rounding-multiple">Unbundling Rounding Multiple *</label>
                                <div className="input-wrapper">
                                    <input
                                        className="admin__contract__input"
                                        type="text"
                                        id="unbundling-rounding-multiple"
                                        name="unbundlingRoundingMultiple"
                                        value={formData?.unbundlingRoundingMultiple}
                                        onChange={handleChange}
                                        placeholder="0.00"
                                        required
                                    />
                                    <span className="cppa-custom-tooltip">
                                       The rounding multiple which will be applied for flat unbundling approach.
                                    </span>
                                </div>

                                { errors.unbundlingRoundingMultiple ? <p className="error">Please Enter Unbundling Rounding Multiple</p> : null}
                            </div>
                        )
                    }

                    { formData?.valuationAtInception === 'no' && (
                        <>
                            <div className="col-md-3">
                                <label className="avf-label" htmlFor="black-market-price-curve-inception">Black Market Price Curve At Inception*</label>
                                <div className="uload_download_block">
                                    <CustomFileUpload FormName="blackMarketPriceCurveAtInception" UploadButtonText="+ Upload"></CustomFileUpload>
                                    <span
                                        className="download_link collapsed-download-btn"
                                        onClick={(e) => downloadTemplate(e, "blackMarketPriceCurveAtInception")}
                                    >
                                        <img className="" src={"/icons/download.png"} alt="Download" />
                                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                                    </span>
                                </div>
                                {errors.blackMarketPriceCurveAtInception ? <p className="error">Please Upload</p> : null}
                            </div>
                            <div className="col-md-3">
                                <label className="avf-label" htmlFor="green-market-price-curve-inception">Green Market Price Curve At Inception*</label>
                                <div className="uload_download_block">
                                    <CustomFileUpload FormName="greenMarketPriceCurveAtInception" UploadButtonText="+ Upload"></CustomFileUpload>
                                    <span
                                        className="download_link collapsed-download-btn"
                                        onClick={(e) => downloadTemplate(e, "greenMarketPriceCurveAtInception")}
                                    >
                                        <img className="" src={"/icons/download.png"} alt="Download" />
                                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                                    </span>
                                </div>
                                {errors.greenMarketPriceCurveAtInception ? <p className="error">Please Upload</p> : null}
                            </div>
                        </>
                    )}
                    
                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="curtailment">Curtailment Factor *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="curtailment-factor"
                                name="curtailmentFactor"
                                value={formData?.curtailmentFactor}
                                onChange={handleChange}
                                placeholder="0.00"
                                required
                            />
                            <span className="cppa-custom-tooltip">Curtailment Factor</span>
                        </div>

                        { errors.curtailmentFactor ? <p className="error">Please Enter Curtailment Factor</p> : null}
                    </div>

                    <DatePicker 
                        error={errors?.RETSchemeEndDate ? 'Please Select RET Scheme End Date' : ''} 
                        formClass={"col-md-3"}
                        label="RET Scheme End Date *" 
                        CalValue={formData?.RETSchemeEndDate} 
                        setCalValue={handleCalValue} 
                        id={"RET-schem-end-date"} 
                        placeholder={"Select Date (Required)"} 
                        category={"RETSchemeEndDate"}
                        helperText="RET Scheme End Date"
                    />

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="scenario-at-inception">Period By Period *</label>
                        <div className="input-wrapper">
                            <select
                                className="admin__contract__input"
                                id="scenario-at-inception"
                                name="periodByPeriod"
                                value={formData?.periodByPeriod}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Please Select Any Value</option>
                                {   
                                    yesOrNoList.map(list => (
                                        <option value={list.value} key={list.value}>{list.label}</option>
                                    ))
                                }
                            </select>

                            <span className="cppa-custom-tooltip">A flag which will be used in present value factor calculations.</span>
                        </div>
                        {errors.periodByPeriod ? <p className="error">Please Select Period By Period</p> : null}
                    </div>

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="curtailment">Excess Cashflow Difference Factor *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="excess-cashflow-difference-factor"
                                name="excessCashflowDifferenceFactor"
                                value={formData?.excessCashflowDifferenceFactor}
                                onChange={handleChange}
                                placeholder="0.00"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                The difference factor which will used in excess cashflow calculation. For example 1000
                            </span>
                        </div>

                        { errors.excessCashflowDifferenceFactor ? <p className="error">Please Enter Excess Cashflow Difference Factor</p> : null}
                    </div>

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="curtailment">Low Tolerance *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="low-tolerance"
                                name="lowTolerance"
                                value={formData?.lowTolerance}
                                onChange={handleChange}
                                placeholder="0.00%"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                The low tolerance percentage of final valuation
                            </span>
                        </div>

                        { errors.lowTolerance ? <p className="error">Please Enter Low Tolerance</p> : null}
                    </div>

                    <div className="col-md-3">
                        <label className="avf-label" htmlFor="curtailment">High Tolerance *</label>
                        <div className="input-wrapper">
                            <input
                                className="admin__contract__input"
                                type="text"
                                id="high-tolerance"
                                name="highTolerance"
                                value={formData?.highTolerance}
                                onChange={handleChange}
                                placeholder="0.00%"
                                required
                            />
                            <span className="cppa-custom-tooltip">
                                The high tolerance percentage of final valuation
                            </span>
                        </div>

                        { errors.highTolerance ? <p className="error">Please Enter High Tolerance</p> : null}
                    </div>

                    {/* <div className="col-md-3">
                        <label className="avf-label" htmlFor="market-inflation-escalation">Market Inflation Escalation *</label>
                        <div className="uload_download_block">
                            <CustomFileUpload FormName="marketInflationEscalation" UploadButtonText="+ Upload"></CustomFileUpload>
                            <span
                                className="download_link collapsed-download-btn"
                                onClick={(e) => downloadTemplate(e, "price1CurveFile")}
                            >
                                <img className="" src={"/icons/download.png"} alt="Download" />
                                <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                            </span>
                        </div>
                        {errors.marketInflationEscalation ? <p className="error">Please upload</p> : null}
                    </div> */}

                    <div className="col-md-3"></div>
                </form>
                <div className="ppa__contract__options ppa-btn-wrapper">
                <button className="forward__option btn-secondary" onClick={navigateBack}>
                    Back
                </button>
                <button disabled={disableNext} className="forward__option btn-primary" onClick={startEvaluation}>
                    Next
                </button>
                </div>
            </div>
            <div className="info-container">
                <span>
                NOTE: Fields marked (*) are mandatory.
                <br />
                All the numeric fields must be in English format.
                <br />
                </span>
            </div>
        </div>
    );
}
export default ExistingPPA;
