import { useRef,useEffect } from "react";
import 'react-calendar/dist/Calendar.css';
import "./customcalendar.css";
import Calendar from 'react-calendar';


export default function CustomCalendar({value, onChange, minDate, onClickOutside}){
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside && onClickOutside(event);
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [ onClickOutside ]);
    return(
        <div ref={ref} className="calendar__container">
            <Calendar onChange={onChange} value={value} minDate={minDate ? minDate:null}/>
        </div>
    )
}