import React from "react";
import "./Loader.css";

export default function Loader() {
  return (
    <div id="preloader" className="modal">
      <div></div>
    </div>
  );
}
