import "./successModal.css"

export default function SuccessModal({title, message, toggleModal}){
    return(
        <>
        <div className="modal__wrapper"></div>
        <div className="success__modal__container">
            <div className="success__modal__header">
                <div className="success__modal__header__title">
                    <p>{!title ? "Success": title}</p>
                    <img className="success__modal__header__icon" src={"/icons/success.svg"} alt="success"/>
                </div>
                
                <p className="close-modal" onClick={() => toggleModal(false)}>X</p>
            </div>
            <div className="success__modal__content__message">
                <p>
                    {message}
                </p>
            </div>
            <div className="success-modal-footer">
                <button className="button__finish" onClick={() => toggleModal(false)} >Done</button>
            </div>
        </div>
        </>
    )
}