import React, { useContext, useEffect } from "react";
import "../Style/PPAStyles.css";
// import "../Style/primereact.css";

import { Calendar } from "primereact/calendar";
//import "../../../../../node_modules/primereact/resources/primereact.css"; // core css
//import "../../../../../node_modules/primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import CustomFileUpload from "../../../../components/PPA/CustomFileUpload/CustomFileUpload";
import UserContext from "../../../../context/UserContext";
import PPAContext from "../../../../contexts/PPA/PPAContext";
//import { CountryList } from "../../../../utils/CountryList";
import {
  ChangingVolumeList,
  CountryLocationList,
  DegradationList,
  DiscountingFactorList,
  PPAPricingList,
  PPAVolumeCapacityUnit,
  PPAVolumeStructureList,
  TechnologyList,
  UseBenchMarkList,
} from "../../../../utils/PPA/PPAMasterList";

function NewPPA() {
  const { formData, SetFormData, errors, disableNext, SetActiveTabId, DownloadFile } = useContext(PPAContext);
  const { authUser } = useContext(UserContext);
  useEffect(() => {
    console.log(`The updated params ${JSON.stringify(formData, null, 2)}`);
  }, [formData]);

  const handleChange = ({ target }) => {
    const newState = { ...formData, [target.name]: target.value };

    if (target.name === "location") {
      SetFormData({
        ...formData,
        conslocation: target.value,
        location: target.value,
      });
    } else if (target.name === "ppastartdate") {
      SetFormData({
        ...formData,
        ppastartdate: target.value,
      });
    } else if (target.name === "ppaenddate") {
      SetFormData({
        ...formData,
        ppaenddate: target.value,
      });
    } else if (target.name === "ppavolstructure" && target.value === "Pay as produced") {
      SetFormData({
        ...formData,
        benchmarkvolume: "",
        changevolume: "",
        technology: "",
        volume1CurveFile: undefined,
        ppavolstructure: target.value,
      });
    } else if (target.name === "ppavolstructure" && target.value === "Baseload") {
      SetFormData({
        ...formData,
        changevolume: "",
        benchmarkvolume: "",
        ppavolstructure: target.value,
      });
    } else if (target.name === "benchmarkvolume" && target.value === "Yes") {
      SetFormData({
        ...formData,
        changevolume: "",
        volume1CurveFile: undefined,
        benchmarkvolume: target.value,
      });
    } else if (target.name === "benchmarkvolume" && target.value === "No") {
      SetFormData({
        ...formData,
        changevolume: "",
        volume2CurveFile: undefined,
        benchmarkvolume: target.value,
      });
    } else if (target.name === "technology" && target.value === "PV (fixed)") {
      SetFormData({
        ...formData,
        cuspercentage: undefined,
        technology: target.value,
      });
    } else if (target.name === "technology" && target.value === "Mix PV (fixed) & Wind (onshore)") {
      SetFormData({
        ...formData,
        technology: target.value,
      });
    } else if (target.name === "technology" && target.value === "Wind (onshore)") {
      SetFormData({
        ...formData,
        degradation: "",
        technology: target.value,
      });
    } else if (target.name === "degradation" && target.value === "Custom fixed") {
      SetFormData({
        ...formData,
        cuspercentage: undefined,
        degradation: target.value,
      });
    } else if (target.name === "ppapricing" && target.value === "Fix") {
      SetFormData({
        ...formData,
        ppaprice: undefined,
        price1CurveFile: undefined,
        ppapricing: target.value,
      });
    } else if (target.name === "ppapricing" && target.value === "Indexed with CPI") {
      SetFormData({
        ...formData,
        ppaprice: undefined,
        price1CurveFile: undefined,
        ppapricing: target.value,
      });
    } else if (target.name === "discountfactor" && target.value === "Custom fixed") {
      SetFormData({
        ...formData,
        customnpv: undefined,
        discountfactor: target.value,
      });
    } else if (target.name === "changevolume" && target.value === "No") {
      SetFormData({
        ...formData,
        annualvolcapacity: undefined,
        annualVolumeUnit: "",
        technology: "",
        degradation: "",
        pvshare: undefined,
        cuspercentage: undefined,
        changevolume: target.value,
      });
    } else if (target.name === "changevolume" && target.value === "Yes") {
      SetFormData({
        ...formData,
        volume2CurveFile: undefined,
        changevolume: target.value,
      });
    } else {
      SetFormData(newState);
    }
  };

  function setDefaultValue() {
    SetFormData({
      ...formData,

      conslocation: "Afghanistan",

      location: "Afghanistan",

      ppastartdate: new Date("2025-05"),

      ppaenddate: new Date("2030-12"),

      ppavolstructure: "Pay as produced",

      benchmarkvolume: "Yes",

      changevolume: "No",

      annualvolcapacity: "30",

      annualVolumeUnit: "MW",

      technology: "PV (fixed)",

      degradation: "0.5 %",

      ppapricing: "Indexed with CPI",

      ppaprice: "155",

      discountfactor: "Custom fixed",

      customnpv: "5",
    });
  }

  const downloadTemplate = (e, fileName) => {
    e.preventDefault();

    //TODO: Populate UserID correctly
    let userID = authUser.userName;
    DownloadFile(fileName, userID);
  };

  const startEvaluation = () => {
    // setting already selected value OR default No as its not a mandatory field.
    SetFormData({
      ...formData,
      iscustomised: formData.iscustomised ? formData.iscustomised : "No",
    });
    SetActiveTabId("3");
  };

  const navigateBack = () => {
    SetActiveTabId('1');
  }

  return (
    <div className="add-evaluation_wrapper">
      <div className="add-evaluation-form-container col-md-12">
        <form className="add-evaluation-form col-md-12" onSubmit={() => {}}>
          <div className="col-md-3">
            <label className="avf-label" htmlFor="ppa-location">
              PPA Location *
            </label>

            <div className="input-wrapper">
              <select
                className="admin__contract__input"
                id="location"
                name="location"
                value={formData?.location}
                onChange={handleChange}
                required
              >
                <option id="-1" value="" key={-1}>
                  Please Select Any Value
                </option>
                {CountryLocationList.map((country, index) => (
                  <option id={index} value={country} key={index}>
                    {country}
                  </option>
                ))}
              </select>
              <span className="cppa-custom-tooltip">
                Location of the renewable assets, this influences the generation profile of the asset as well as the
                benchmark power & GOO price and according capture rate.
              </span>
            </div>
            {errors.location ? <p className="error">Please select PPA Location</p> : null}
          </div>
          <div className="col-md-3">
            <label className="avf-label" htmlFor="consumption-location">
              Consumption Location *
            </label>
            <div className="input-wrapper">
              <select
                className="admin__contract__input"
                id="conslocation"
                name="conslocation"
                value={formData?.conslocation}
                onChange={handleChange}
                required
              >
                <option id="-1" value="" key={-1}>
                  Please Select Any Value
                </option>
                {CountryLocationList.map((country, index) => (
                  <option id={index} value={country} key={index}>
                    {country}
                  </option>
                ))}
              </select>
              <span className="cppa-custom-tooltip">
                Location of the consumption of green power, this influences the emissions savings since it is dependent
                on the carbon intensity of the grid at the point of consumption
              </span>
            </div>
            {errors.conslocation ? <p className="error">Please select Consumption Location</p> : null}
          </div>
          <div className="col-md-3">
            <label className="avf-label">PPA Start Date *</label>
            <div className="input-wrapper">
              <Calendar
                value={formData?.ppastartdate}
                name="ppastartdate"
                onChange={handleChange}
                view="month"
                dateFormat="yy-mm"
              />
              <span className="cppa-custom-tooltip">Start date of power delivery according to the specified PPA</span>
            </div>
            {errors.ppastartdate ? <p className="error">Please enter PPA Start Date</p> : null}
            {errors.invaliddate ? <p className="error">Start Date should be lesser than End Date</p> : null}
          </div>
          <div className="col-md-3">
            <label className="avf-label">PPA End Date *</label>
            <div className="input-wrapper">
              <Calendar
                value={formData?.ppaenddate}
                name="ppaenddate"
                onChange={handleChange}
                view="month"
                dateFormat="yy-mm"
              />
              <span className="cppa-custom-tooltip">End date of power delivery according to the specified PPA</span>
            </div>
            {errors.ppaenddate ? <p className="error">Please enter PPA End Date</p> : null}
            {errors.invaliddate ? <p className="error">End Date should be greater than start date</p> : null}
          </div>
          <div className="col-md-3">
            <label className="avf-label" htmlFor="ppa_volume_structure">
              PPA Volume Structure *
            </label>
            <div className="input-wrapper">
              <select
                className="admin__contract__input"
                id="volume"
                name="ppavolstructure"
                value={formData?.ppavolstructure}
                onChange={handleChange}
                required
              >
                <option id="-1" value="" key={-1}>
                  Please Select Any Value
                </option>
                {PPAVolumeStructureList.map((volume, index) => (
                  <option id={index} value={volume} key={index}>
                    {volume}
                  </option>
                ))}
              </select>
              <span className="cppa-custom-tooltip">
                Contracted order of power to be delivered during the PPA tenor
              </span>
            </div>
            {errors.ppavolstructure ? <p className="error">Please select PPA Volume Structure</p> : null}
          </div>
          {formData?.ppavolstructure === "Pay as produced" && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="Is-benchmark-data">
                Use Benchmark Data For Volume *
              </label>
              <div className="input-wrapper">
                <select
                  className="admin__contract__input"
                  id="benchmarkvolume"
                  name="benchmarkvolume"
                  value={formData?.benchmarkvolume}
                  onChange={handleChange}
                  required
                >
                  <option id="-1" value="" key={-1}>
                    Please Select Any Value
                  </option>
                  {UseBenchMarkList.map((benchmark, index) => (
                    <option id={index} value={benchmark} key={index}>
                      {benchmark}
                    </option>
                  ))}
                </select>
                <span className="cppa-custom-tooltip">
                  Apply EY benchmark generation profiles to calculate monthly PPA volume from yearly volume. Otherwise
                  upload monthly PPA volume.
                </span>
              </div>
              {errors.benchmarkvolume ? (
                <p className="error">Please select whether to use Benchmark Data for PPA Volume</p>
              ) : null}
            </div>
          )}

          {formData?.benchmarkvolume === "No" && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="contractFile">
                Upload Volume Curve (volume 1) *
              </label>
              <div className="uload_download_block">
                <CustomFileUpload FormName="volume1CurveFile" UploadButtonText="+ Upload"></CustomFileUpload>
                <span
                  className="download_link collapsed-download-btn"
                  onClick={(e) => downloadTemplate(e, "volume1CurveFile")}
                >
                  <img className="" src={"/icons/download.png"} alt="Download" />
                  <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                </span>
              </div>
              {errors.volume1CurveFile ? <p className="error">Please upload</p> : null}
            </div>
          )}

          {(formData?.ppavolstructure === "Baseload" || formData?.benchmarkvolume === "Yes") && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="location">
                Changing Volumes in PPA *
              </label>
              <div className="input-wrapper">
                <select
                  className="admin__contract__input"
                  id="changevolume"
                  name="changevolume"
                  value={formData?.changevolume}
                  onChange={handleChange}
                  required
                >
                  <option id="-1" value="" key={-1}>
                    Please Select Any Value
                  </option>
                  {ChangingVolumeList.map((changevolume, index) => (
                    <option id={index} value={changevolume} key={index}>
                      {changevolume}
                    </option>
                  ))}
                </select>

                <span className="cppa-custom-tooltip"> Specify irregular volume developments during PPA tenor</span>
              </div>
              {errors.changevolume ? <p className="error">Please select Changing Volumes in PPA</p> : null}
            </div>
          )}
          {formData?.changevolume === "Yes" && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="uploadvolumecurve2">
                Upload Volume Curve (Volume 2) *
              </label>
              <div className="uload_download_block">
                <CustomFileUpload FormName="volume2CurveFile" UploadButtonText="+ Upload"></CustomFileUpload>
                <span
                  className="download_link collapsed-download-btn"
                  onClick={(e) => downloadTemplate(e, "volume2CurveFile")}
                >
                  <img className="" src={"/icons/download.png"} alt="Download" />
                  <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                </span>
              </div>
              {errors.volume2CurveFile ? <p className="error">Please upload</p> : null}
            </div>
          )}
          {formData?.changevolume === "No" &&
            (formData?.ppavolstructure === "Baseload" || formData?.benchmarkvolume === "Yes") && (
              <>
                <div className="col-md-3">
                  <label className="avf-label" htmlFor="annualvolcapacity">
                    Annual PPA Volume Capacity *
                  </label>
                  <div className="input-wrapper">
                    <input
                      className="admin__contract__input"
                      type="text"
                      id="annualvolcapacity"
                      name="annualvolcapacity"
                      value={formData?.annualvolcapacity}
                      onChange={handleChange}
                      placeholder="Annual PPA Volume Capacity"
                      required
                    />
                    <span className="cppa-custom-tooltip">
                      Generation capacity of the contracted assets either in MW or in MWh.
                    </span>
                  </div>

                  {errors.annualvolcapacity ? <p className="error">Please enter Annual PPA Volume Capacity</p> : null}
                </div>

                <div className="col-md-3">
                  <label className="avf-label" htmlFor="annualVolumeUnit">
                    Annual PPA Volume Capacity Unit *
                  </label>
                  <div className="input-wrapper">
                    <select
                      className="admin__contract__input"
                      id="annualVolumeUnit"
                      name="annualVolumeUnit"
                      value={formData?.annualVolumeUnit}
                      onChange={handleChange}
                      required
                    >
                      <option id="-1" value="" key={-1}>
                        Please Select Any Value
                      </option>
                      {PPAVolumeCapacityUnit.map((unit, index) => (
                        <option id={index} value={unit} key={index}>
                          {unit}
                        </option>
                      ))}
                    </select>
                    <span className="cppa-custom-tooltip">
                      For PV MW values are expressed in MWp, whereas the capacity for windfarms are stated in MW.
                    </span>
                  </div>

                  {errors.annualVolumeUnit ? (
                    <p className="error">Please select Annual PPA Volume Capacity Unit</p>
                  ) : null}
                </div>
              </>
            )}

          {formData?.ppavolstructure === "Pay as produced" &&
            formData?.benchmarkvolume === "Yes" &&
            formData?.changevolume === "No" && (
              <div className="col-md-3">
                <label className="avf-label" htmlFor="technology">
                  Technology *
                </label>
                <div className="input-wrapper">
                  <select
                    className="admin__contract__input"
                    id="technology"
                    name="technology"
                    onChange={handleChange}
                    value={formData?.technology}
                    required
                  >
                    <option id="-1" value="" key={-1}>
                      Please Select Any Value
                    </option>
                    {TechnologyList.map((technology, index) => (
                      <option id={index} value={technology} key={index}>
                        {technology}
                      </option>
                    ))}
                  </select>
                  <span className="cppa-custom-tooltip"> Renewable asset-class contracted via specified PPA </span>
                </div>

                {errors.technology ? <p className="error">Please select Technology</p> : null}
              </div>
            )}
          {formData?.technology === "Mix PV (fixed) & Wind (onshore)" &&
            formData?.benchmarkvolume === "Yes" &&
            formData?.changevolume === "No" && (
              <div className="col-md-3">
                <label className="avf-label" htmlFor="floatingAmount">
                  PV Share On Total Production *
                </label>
                <input
                  className="admin__contract__input"
                  type="text"
                  id="pvshare"
                  name="pvshare"
                  onChange={handleChange}
                  value={formData?.pvshare}
                  placeholder="Enter PV Share On Total Production"
                  required
                />
                {errors.pvshare ? (
                  <p className="error">Please enter PV Share On Total Production in XX.XX format</p>
                ) : null}
              </div>
            )}
          {(formData?.technology === "PV (fixed)" || formData?.technology === "Mix PV (fixed) & Wind (onshore)") &&
            formData?.benchmarkvolume === "Yes" &&
            formData?.changevolume === "No" && (
              <div className="col-md-3">
                <label className="avf-label" htmlFor="degradation">
                  Degradation p.a. (PV) *
                </label>
                <select
                  className="admin__contract__input"
                  id="degradation"
                  onChange={handleChange}
                  name="degradation"
                  value={formData?.degradation}
                  required
                >
                  <option id="-1" value="" key={-1}>
                    Please Select Any Value
                  </option>
                  {DegradationList.map((degradation, index) => (
                    <option id={index} value={degradation} key={index}>
                      {degradation}
                    </option>
                  ))}
                </select>
                {errors.degradation ? <p className="error">Please select Degradation P.A (P.V)</p> : null}
              </div>
            )}

          {(formData?.technology === "PV (fixed)" || formData?.technology === "Mix PV (fixed) & Wind (onshore)") &&
            formData?.benchmarkvolume === "Yes" &&
            formData?.changevolume === "No" &&
            formData?.degradation === "Custom fixed" && (
              <div className="col-md-3">
                <label className="avf-label" htmlFor="floatingAmount">
                  Custom Percentage *
                </label>

                <input
                  className="admin__contract__input"
                  type="text"
                  id="cuspercentage"
                  name="cuspercentage"
                  value={formData?.cuspercentage}
                  onChange={handleChange}
                  placeholder="Enter Discount Factor"
                  required
                />
                {errors.cuspercentage ? <p className="error">Please enter Custom Degradation in 0.X format</p> : null}
              </div>
            )}
          <div className="col-md-3">
            <label className="avf-label" htmlFor="ppa_pricing">
              PPA Pricing *
            </label>
            <div className="input-wrapper">
              <select
                className="admin__contract__input"
                id="ppapricing"
                name="ppapricing"
                value={formData?.ppapricing}
                onChange={handleChange}
                required
              >
                <option id="-1" value="" key={-1}>
                  Please Select Any Value
                </option>
                {PPAPricingList.map((ppaprice, index) => (
                  <option id={index} value={ppaprice} key={index}>
                    {ppaprice}
                  </option>
                ))}
              </select>
              <span className="cppa-custom-tooltip">
                Contracted payment plan during delivery of power during the PPA tenor
              </span>
            </div>
            {errors.ppapricing ? <p className="error">Please select PPA Pricing</p> : null}
          </div>
          {(formData?.ppapricing === "Fix" || formData?.ppapricing === "Indexed with CPI") && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="ppa_price">
                PPA Price including GoOs *
              </label>
              <div className="input-wrapper">
                <input
                  className="admin__contract__input"
                  type="text"
                  id="ppaprice"
                  name="ppaprice"
                  value={formData?.ppaprice}
                  onChange={handleChange}
                  placeholder="Enter PPA Price"
                  required
                />
                <span className="cppa-custom-tooltip">
                  Price per MWh as specified in the PPA for the delivery of power during the contract duration
                </span>
              </div>

              {errors.ppaprice ? <p className="error">Please enter PPA Price in XX.XX format </p> : null}
            </div>
          )}
          {formData?.ppapricing === "Custom" && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="price1CurveFile">
                Upload Price Curve *
              </label>
              <div className="uload_download_block">
                <CustomFileUpload FormName="price1CurveFile" UploadButtonText="+ Upload"></CustomFileUpload>
                <span
                  className="download_link collapsed-download-btn"
                  onClick={(e) => downloadTemplate(e, "price1CurveFile")}
                >
                  <img className="" src={"/icons/download.png"} alt="Download" />
                  <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                </span>
              </div>
              {errors.price1CurveFile ? <p className="error">Please upload</p> : null}
            </div>
          )}
          <div className="col-md-3">
            <label className="avf-label" htmlFor="location">
              Discount Factor (NPV) *
            </label>
            <div className="input-wrapper">
              <select
                className="admin__contract__input"
                id="discountfactor"
                name="discountfactor"
                onChange={handleChange}
                value={formData?.discountfactor}
                required
              >
                <option id="-1" value="" key={-1}>
                  Please Select Any Value
                </option>
                {DiscountingFactorList.map((discountfactor, index) => (
                  <option id={index} value={discountfactor} key={index}>
                    {discountfactor}
                  </option>
                ))}
              </select>
              <span className="cppa-custom-tooltip">
                Specify if company-specific discount factor or risk free rate shall be used for discounting
              </span>
            </div>
            {errors.discountfactor ? <p className="error">Please select Discount Factor (NPV)</p> : null}
          </div>
          {formData?.discountfactor === "Custom fixed" && (
            <div className="col-md-3">
              <label className="avf-label" htmlFor="floatingAmount">
                Custom Discount Factor *
              </label>
              <div className="input-wrapper">
                <input
                  className="admin__contract__input"
                  type="text"
                  id="customnpv"
                  name="customnpv"
                  onChange={handleChange}
                  value={formData?.customnpv}
                  placeholder="Enter Custom NPV"
                  required
                />
                <span className="cppa-custom-tooltip">
                  Specify your company-specific discount factor. E.g. enter "5" for 5%.
                </span>
              </div>
              {errors.customnpv ? <p className="error">Please enter Custom Discount Factor in XX.XX format</p> : null}
            </div>
          )}

          <div className="col-md-3"></div>
        </form>
        <div className="ppa__contract__options ppa-btn-wrapper">
          <button className="forward__option btn-secondary" onClick={navigateBack}>
            Back
          </button>
          <button disabled={disableNext} className="forward__option btn-primary" onClick={startEvaluation}>
            Next
          </button>
        </div>
      </div>
      <div className="info-container">
        <span>
          NOTE: Fields marked (*) are mandatory.
          <br />
          All the numeric fields must be in English format.
          <br />
        </span>
      </div>
    </div>
  );
}
export default NewPPA;
