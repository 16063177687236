import React, { useState, useContext, useEffect, useReducer } from "react";
import { useLocation } from 'react-router-dom';

import axios from "../../../interceptors/axiosInstance";
import { handleDateFormat } from "../../../utils/DateFormating";
import { ConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import { DatePicker } from "../../../components/General/DataEntry/DatePicker/DatePicker";
import { config } from '../../../config'
import { LoaderContext } from "../../../context/LoaderContext";
import "./AddUser.css";
import { emailRegEx, phoneNumberRegex } from "../../../utils/ValidationRules";
import { whiteSpaceStringRegex } from "../../../utils/ValidationRules";
import { pascalCase } from "../../../utils/display";
import { MessageContext } from "../../../context/MessageContext";
import SuccessModal from "../../../components/SuccessModal";

const offtakerAccessLevels = [
    { key:'offtaker', value:'Offtaker'}
];

const adminAccessLevels = [
    { key:'ppa_valuation', value:'PPA Valuation'},
    { key:'ey_consultant', value:'EY Consultant'},
    { key:'admin', value:'Admin'}
]

const generatorAccessLevels = [
    { key:'generator', value:'Generator'}
]

const requiredFields = ["userName", "email", "phoneNum", "organization", "accessLevel", "accessendDate"
];

const initialUserState = {
    "userName": '',
    "email":'',
    "phoneNum":'',
    "organization":'',
    "organizationName":'',
    "role":'',
    "accessLevel":'',
    "accessendDate":''
}

const initialValidityState = {
    "userName": '',
    "email":'',
    "phoneNum":'',
    "organization":'',
    "role":'',
    "accessLevel":'',
    "accessendDate":'',
    "isFormValid": false
}

const formReducer = (state, action) => {
    if (action.type === "RESET_FORM") {
        return initialUserState;
    } else {
        const { name, value } = action.type;
        return {
            ...state, [name]: value,
        }
    }
}

const formValidityReducer = (state , action)=> {
    let isValid= false;
    const {userName='', email='', phoneNum='', organization='', role='', accessLevel='', accessendDate=''} = action.payload || {};
    let errorData = {...state};
    if (action.type === "RESET_FORM_VALIDITY") {
        return action.payload;
    } else {
        switch(action.type){
            case "USERNAME": {}
                isValid = userName.length > 0  && whiteSpaceStringRegex.test(userName)? true: false;
                errorData = {...errorData, userName: isValid? '': 'Enter Valid User Name'};
                break;
            case "EMAIL": 
                isValid = email.length > 0 && emailRegEx.test(email)? true: false;
                errorData = {...errorData, email: isValid? '': 'Enter Valid Email'};
                break;
            case "PHONENUM": 
                isValid = (phoneNum.length > 0 && phoneNumberRegex.test(phoneNum)) ? true: false
                errorData = {...errorData, phoneNum: isValid? '': 'Enter Valid Phone Number'};
                break;
            case "ACCESSENDDATE": 
                isValid = accessendDate && accessendDate.length > 0 ? true: false;
                errorData = {...errorData, accessendDate: isValid? '': 'Select Access End Date'};
                break;
            case "ORGANIZATION": 
                isValid = organization && organization.length > 0 && whiteSpaceStringRegex.test(organization)? true: false;
                errorData = {...errorData, organization: isValid? '': 'Select Organization'};
                break;
            case "ACCESSLEVEL": 
                isValid = accessLevel && accessLevel.length > 0 ? true: false;
                errorData = {...errorData, accessLevel: isValid? '': 'Select Access Level'};
                break;
        }
        const { isFormValid, ...errors } = errorData;
        if (requiredFields.some((key) => (action.payload[key] === '')) || Object.keys(errors).some((key) => (errors[key] !== ''))) {
            errorData.isFormValid = false;
        } else {
            errorData.isFormValid = true;
        }
        return errorData;
    }   
}

export const AddUser = ({title}) => {
    const [showSuccess, setShowScuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userParams, setUserParams] = useReducer(formReducer, initialUserState);
    const [organizations, setOrganizations] = useState([]);
    const {loading, setLoading } = useContext(LoaderContext);
    const {email, accessLevel, organization} = userParams;
    const location = useLocation();
    const [accessLevels,setAccessLevel] = useState([]);
    const { ErrorToast } = useContext(MessageContext);
    
    const [userFormValidity, setUserFormValidity] = useReducer(formValidityReducer, initialValidityState);

    useEffect(()=>{
        if(emailRegEx.test(email)){
            try {
                const identifier = setTimeout(async()=>{
                    setLoading(true);
                    const domainIndex = email.indexOf('@');
                    const domainStr = email.slice(domainIndex+1);
                    const result = await axios.get(`${config.BASE_URL}/api/client?domain=${domainStr}`);
                    if(result.data && result.data.length !== 0){
                        const organizations = [];
                        if(result.data.length >0){
                            result.data.forEach(element => {
                                organizations.push({client:element.clientName, id:element._id, type:element.type})
                            });
                        }
                        setOrganizations(organizations);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        ErrorToast("Organization Not Onboarded!");
                        setUserParams({ type: {name: 'organization', value:''} })
                        setUserParams({ type: {name: 'organizationName', value:''} })
                        setUserParams({ type: {name: 'accessLevel', value:''} });
                        setOrganizations([]);
                        setAccessLevel([]);
                    }
                },1000);
                return ()=>{
                    clearTimeout(identifier)
                }
            } catch (error) {
                setLoading(false);
                ErrorToast("Something Went Wrong!");
            }
        } else {
            setUserParams({ type: {name: 'organization', value:''} })
            setUserParams({ type: {name: 'organizationName', value:''} })
            setUserParams({ type: {name: 'accessLevel', value:''} })
            setOrganizations([]);
            setAccessLevel([]);
        }
    },[email])

    useEffect(() => {
        if(organization){
             const organizationData = organizations.find((item)=>{
                 return item.id === organization
             })
             if(organizationData && organizationData.type === "generator"){
                 setAccessLevel(generatorAccessLevels);
             } else if(organizationData && organizationData.type === "offtaker"){
                setAccessLevel(offtakerAccessLevels)
             } else if(organizationData && organizationData.type === "admin"){
                setAccessLevel(adminAccessLevels)
             } else {
                 setAccessLevel([]);
             }
        } else {
            setAccessLevel([]);
        }
     }, [organization])

    useEffect(() => {
        try {
            if(emailRegEx.test(email) && (accessLevel === 'admin' || accessLevel === 'ppa_valuation' || accessLevel === 'ey_consultant')){
                const domainIndex = email.indexOf('@');
                const domainStr = email.slice(domainIndex + 1);
                if(!domainStr.toLowerCase().includes('ey.com')){
                    ErrorToast("Only EY Users Can Have Admin/PPA Valuation Access");
                    setUserParams({ type: {name: 'accessLevel', value:''} });
                }
            }
        } catch (error) {
            setLoading(false);
            ErrorToast("Something Went Wrong!");
        }

    }, [accessLevel])

    

    const submitUserDetails = async () => {
        try{
            setLoading(true);
            const res = await axios.post(`${config.BASE_URL}/api/user`, { ...userParams});
            setShowScuccess(true);
            setShowModal(false);
            setUserParams({ type: "RESET_FORM" });
            setUserFormValidity({ type: "RESET_FORM_VALIDITY", payload: initialValidityState });
            setLoading(false);
            if(location && location.state && location.state.id){
                const res = await axios.patch(`${config.BASE_URL}/api/support/${location.state.id}`, {status:"Approved"});
            }
        } catch(err){
            setShowModal(false);
            setLoading(false);
            if(err.response && err.response.data && err.response.data.message){
                if(err.response.data.message.includes("already exist")){
                    ErrorToast("A User With Same Email Has Already Been Added");
                } else {
                    ErrorToast(err.response.data.message);
                }
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        } 
    }

    const handleSubmit = async () => {
        window.scrollTo(0,0);
        setShowModal(true);
    }

    const handleCalendarChange = (value) => {
        const fdate = value ? handleDateFormat(value): value;
        setUserParams({ type: {name: 'accessendDate', value:fdate} })
        setUserFormValidity({type:"ACCESSENDDATE", payload: { ...userParams, 'accessendDate': value }})
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        if(name === 'organization'){
            const organization = organizations.find((client)=>{
                return client.id === value;
            })
            let selectedOrganization = '';
            let selectedOrganizationName = '';
            if(organization){
                selectedOrganization = organization.id;
                selectedOrganizationName = organization.client;
            } 
            setUserParams({ type: {name: 'organization', value:selectedOrganization} })
            setUserParams({ type: {name: 'organizationName', value:selectedOrganizationName} })
            setUserFormValidity({ type: name.toUpperCase(), payload: { ...userParams, [name]: value} })
        } else if(name === 'accessLevel'){
            const access = accessLevels.find((item)=>{
                return item.key === value;
            })
            let selectedAccess = '';
            if(access){
                selectedAccess = access.key;
            }
            setUserParams({ type: {name: 'accessLevel', value:selectedAccess} })
            setUserFormValidity({ type: name.toUpperCase(), payload: { ...userParams, [name]: value} })
        } else{
            setUserParams({ type: e.target })
        }
    }

    const handleCancel= () =>{
        setUserParams({ type: "RESET_FORM" });
        setUserFormValidity({ type: "RESET_FORM_VALIDITY", payload: initialValidityState });
    }

    const handleValidation = (e) => {
        setUserFormValidity({ type: e.target.name.toUpperCase(), payload: userParams });
    }
    
    return(
    <div className="add-user_wrapper"> 
        <div className="add-user-title">Add User Details</div>
        <div className="add-user-form-container">        
            <form className="add-user-form" onSubmit={() => {}} autocomplete="off">
                <div className="add-user-form-group">
                    <label htmlFor="username">Name *</label>
                    <input 
                        onChange={handleChange} 
                        onKeyUp={handleValidation}
                        value={userParams.userName} 
                        className="add-user-input" 
                        type="text" id="username" 
                        name="userName" 
                        placeholder="Enter Full Name" 
                        required 
                    />
                     {userFormValidity.userName && <p class="text_error">{userFormValidity.userName}</p>}
                </div>
                <div className="add-user-form-group">
                    <label htmlFor="email-address">Email Address *</label>
                    <input 
                        onChange={handleChange} 
                        onKeyUp={handleValidation}
                        value={userParams.email} 
                        className="add-user-input" 
                        type="text" 
                        id="email-address" 
                        name="email"
                        placeholder="Enter Email Address" 
                        required 
                    />
                    {userFormValidity.email && <p class="text_error">{userFormValidity.email}</p>}
                </div>
                <div className="add-user-form-group">
                    <label htmlFor="phone-number">Phone Number *</label>
                    <input 
                        onChange={handleChange} 
                        onKeyUp={handleValidation}
                        value={userParams.phoneNum} 
                        className="add-user-input" 
                        type="text" 
                        id="phone-number" 
                        name="phoneNum" 
                        placeholder="Enter Phone Number" 
                    />
                    {userFormValidity.phoneNum && <p class="text_error">{userFormValidity.phoneNum}</p>}
                </div>
                <div className="add-user-form-group">
                    <label htmlFor="organization">Organization *</label>
                    <select 
                        onChange={handleChange} 
                        value={userParams.organization} 
                        className="add-user-input" 
                        type="text" 
                        id="organization" 
                        name="organization" 
                        required 
                    >
                        <option value="">Select Organization</option>
                        {
                            organizations.map((organization, index) =>  <option id={organization.client} value={organization.id}>{organization.client}</option>)
                        }
                    </select>
                </div>
                <div className="add-user-form-group">
                    <label htmlFor="role">Role</label>
                    <input 
                        onChange={handleChange} 
                        value={userParams.role} 
                        className="add-user-input" 
                        type="text" 
                        id="role" 
                        name="role" 
                        placeholder="Enter Role" 
                    />
                </div>
                <div className="add-user-form-group">
                    <label htmlFor="access-type">Type Of Access * </label>
                    <select onChange={handleChange} value={userParams.accessLevel} className="add-user-input add-user-select-input" type="text" id="access-type" name="accessLevel" required>
                        <option value="">Select Type Of Access</option>
                        {
                            accessLevels.map((access, index) =>  <option id={access.key} value={access.key}>{access.value}</option>)
                        }
                    </select>
                </div>
                <div className="add-user-form-group">
                    <DatePicker minDate={new Date()} formClass={"admin__contract__form__group"} label="Access End Date *" CalValue={userParams.accessendDate} setCalValue={handleCalendarChange} id={"accessendDate"} placeholder={"Select Access End Date"} category={"accessendDate"} />
                </div>

            </form>
            { 
                showModal && 
                <ConfirmModal 
                    data={[
                        {
                            label: 'Name',
                            value: userParams.userName
                        },
                        {
                            label: 'Email',
                            value: userParams.email
                        },
                        {
                            label: 'Phone',
                            value: userParams.phoneNum
                        },
                        {
                            label: 'Organization',
                            value: userParams.organizationName
                        },
                        {
                            label: 'Role',
                            value: userParams.role
                        },
                        {
                            label: 'Type Of Access',
                            value: pascalCase(userParams.accessLevel,'_')
                        },
                        {
                            label: 'Access End Date',
                            value: userParams.accessendDate
                        },
                    ]} 
                    toggleModal={setShowModal} 
                    handleSubmit={submitUserDetails}
                    loading={loading}
                />
            }
            {
                showSuccess ? <SuccessModal title="User Added" message="User Added Successfully!" toggleModal={setShowScuccess}/>: null 
            }
            <div className="add-user-actions">
                <button 
                    disabled={ !userFormValidity.isFormValid} 
                    className="confirm-button" 
                    onClick={handleSubmit}
                >
                    Confirm
                </button>
                <button className="cancel-button"  onClick={handleCancel}>Cancel</button>
            </div>
        </div>
        <div class="info-container">
            <span>NOTE: Fields marked (*) are mandatory</span>
        </div>
    </div>
)
}