import axios from '../interceptors/axiosInstance';
import { FETCH_USER , FETCH_ONE_USER} from './types';

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/test');
    
    dispatch({ type: FETCH_USER, payload: res });
};

export const fetchOneUser = (userid) => async dispatch => {
    const res = await axios.get('/api/user/'+ userid);
    
    dispatch({ type: FETCH_ONE_USER, payload: res });
};

export const createUser = (values) => async dispatch => {
    const res = await axios.post('/api/user',values);
    
    dispatch({ type: FETCH_ONE_USER, payload: res });
};
