import React, { useRef, useEffect } from 'react';
import Toast from "../Toast/Toast";
import "./ToastList.css";

const ToastList = ({ data, removeToast }) => {
    const listRef = useRef(null);

    const handleScrolling = (el) => {
          el?.scrollTo(0, el.scrollHeight);
      };
    
      useEffect(() => {
        handleScrolling(listRef.current);
      }, [ data]);

    return (
        data.length > 0 && (
            <div
              className={`toast-list toast-list--top-right`}
              aria-live="assertive"
              ref={listRef}
            >
              {data.map((toast) => (
                <Toast
                  key={toast.id}
                  message={toast.message}
                  type={toast.type}
                  onClose={() => removeToast(toast.id)}
                />
              ))}
            </div>
          )
      );
};

export default ToastList;