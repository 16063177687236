import "./editConfirmationModal.css";

export default function EditConfirmationModal({toggleModal, submitRequest}){
   
    const handleFinalConfirmation = () => {
        submitRequest();
        toggleModal(false);
    }

    return (
        <>
        <div className="modal__wrapper">
        <div className="edit__confirmation__modal__container_final">
            <div className="edit__confirmation__header">
                <p className="confirmation__title">Note:</p>
                <p className="modal__close" onClick={() => toggleModal(false)}>X</p>
            </div>
            <div className="edit__confirmation__message">
                <p>
                    Your request to change / modify the terms of
                    Contract will be sent to the admin for action.
                </p>
            </div>
            <div className="edit__confirmation__button">
                <button className="confirmation__confirm" onClick={handleFinalConfirmation}>Confirm</button>
                <button className="confirmation__cancel" onClick={() => toggleModal(false)}>Decline</button>
            </div>
        </div>
        </div>
        
        </>
    )
}