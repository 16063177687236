import "./highLightFigure.css";

export const HighlightFigure = ({label, value, color, iconPath}) => {
    return (
        <div className={`metrics__card ${color}`}>
            <div className={`metric__name`}>
              <p>{label}</p>
              {iconPath && <img className="icon_img" src={iconPath} alt={`visibility`}/>}
             </div>
             <div className={`metric__value`}>
              {value}
             </div>
        </div>
    )
}