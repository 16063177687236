import axios from "../../../../interceptors/axiosInstance";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import CustomFileUpload from "../../../../components/PPA/CustomFileUpload/CustomFileUpload";
import { config } from "../../../../config";
import UserContext from "../../../../context/UserContext";
import PPAContext from "../../../../contexts/PPA/PPAContext";
import {
  CarbonIntensityOfGridList,
  GOOPriceCurveList,
  PowerPriceCurveList,
  UseBenchMarkList,
  yesOrNoList
} from "../../../../utils/PPA/PPAMasterList";
import "../Style/PPAStyles.css";
import { MessageContext } from "../../../../context/MessageContext";

export default function PPAParameter() {
  const { formData, SetFormData, errors, SetActiveTabId, SetResultData, SetIsLoading, DownloadFile, disableCalculate } =
    useContext(PPAContext);
    const { ErrorToast } = useContext(MessageContext);

  const { authUser } = useContext(UserContext);

  useEffect(() => {
    console.log(`The updated params ${JSON.stringify(formData, null, 2)}`);
  }, [formData]);

  const handleChange = ({ target }) => {
    let newState = { ...formData, [target.name]: target.value };

    switch (target.name) {
      case "iscustomised":
        if (target.value === "No") {
          newState = {
            ...newState,
            powerPriceCurve: null,
            powerPriceCurveFile: null,
            gooPriceCurve: null,
            gooPriceCurveFile: null,
            carbonIntensityOfGrid: null,
            carbonIntensityOfGridFile: null,
            blackMarketPrice: '',
            blackMarketPriceCurve: null,
            greenMarketPrice: '',
            greenMarketPriceCurve: null,
            inflation: '',
            inflationCurve: null,
            riskFreeRate: '',
            riskFreeRateCurve: null,
            assetCreditRiskAdjustment: '',
            assetCreditRiskAdjustmentCurve: null,
            liabilityCreditRiskAdjustment: '',
            liabilityCreditRiskAdjustmentCurve: null,
            uploadMLFCurve: '',
            mlfCurve: null,
            uploadDLFCurve: '',
            dlfCurve: null
          };
        }
        break;
      case "powerPriceCurve":
        if (target.value === "No") {
          newState = { ...newState, powerPriceCurveFile: null };
        }
        break;
      case "gooPriceCurve":
        if (target.value === "No") {
          newState = { ...newState, gooPriceCurveFile: null };
        }
        break;
      case "carbonIntensityOfGrid":
        if (target.value === "No") {
          newState = { ...newState, carbonIntensityOfGridFile: null };
        }
        break;
      case "blackMarketPrice":
        if (target.value === "no") {
          newState = { ...newState, blackMarketPriceCurve: null };
        }
        break;
      case "greenMarketPrice":
        if (target.value === "no") {
          newState = { ...newState, greenMarketPriceCurve: null };
        }
        break;
      case "inflation":
        if (target.value === "no") {
          newState = { ...newState, inflationCurve: null };
        }
        break;
      case "riskFreeRate":
        if (target.value === "no") {
          newState = { ...newState, riskFreeRateCurve: null };
        }
        break;
      case "assetCreditRiskAdjustment":
        if (target.value === "no") {
          newState = { ...newState, assetCreditRiskAdjustmentCurve: null };
        }
        break;
      case "liabilityCreditRiskAdjustment":
        if (target.value === "no") {
          newState = { ...newState, liabilityCreditRiskAdjustmentCurve: null };
        }
        break;
      default:
        break;
    }

    SetFormData(newState);
    console.log(`The updated params is ${JSON.stringify(formData, null, 2)}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const submitDetails = async () => {
    try {
      let form = new FormData();
      let technology;
      let url = ''

      if(formData?.modelType === 'new_PPA') {
        url= `${config.BASE_URL}/api/ppa/save`;
        if (formData.technology) {
          technology =
            formData.technology.toString().trim() === "PV (fixed)"
              ? "PV"
              : formData.technology.toString().trim() === "Wind (onshore)"
              ? "Wind"
              : formData.technology.toString().trim() === "Mix PV (fixed) & Wind (onshore)"
              ? "Mix PV"
              : null;
        }

        //TODO: Set userId as per logged in user
        var userId = authUser.userName;
        form.append("userId", userId);

        form.append("location", !formData.location ? null : formData.location);
        form.append("conslocation", !formData.conslocation ? null : formData.conslocation);

        form.append("ppastartdate", formData.ppastartdate ? moment(formData.ppastartdate).format("YYYY-MM") : null);
        form.append("ppaenddate", formData.ppaenddate ? moment(formData.ppaenddate).format("YYYY-MM") : null);

        form.append("ppavolstructure", !formData.ppavolstructure ? null : formData.ppavolstructure);

        form.append("benchmarkvolume", !formData.benchmarkvolume ? null : formData.benchmarkvolume);

        form.append("changevolume", !formData.changevolume ? null : formData.changevolume);

        form.append("annualvolcapacity", !formData.annualvolcapacity ? null : formData.annualvolcapacity);
        form.append("annualVolumeUnit", !formData.annualVolumeUnit ? null : formData.annualVolumeUnit);

        form.append("technology", !formData.technology ? null : technology);
        form.append("pvshare", !formData.pvshare ? null : formData.pvshare);
        form.append("degradation", !formData.degradation ? null : formData.degradation);
        form.append("cuspercentage", !formData.cuspercentage ? null : formData.cuspercentage);
        form.append("ppapricing", !formData.ppapricing ? null : formData.ppapricing);
        form.append("ppaprice", !formData.ppaprice ? null : formData.ppaprice);

        form.append("discountfactor", !formData.discountfactor ? null : formData.discountfactor);
        form.append("customnpv", !formData.customnpv ? null : formData.customnpv);
        form.append("iscustomised", !formData.iscustomised ? "No" : formData.iscustomised);

        form.append("powerPriceCurve", !formData.powerPriceCurve ? "No" : formData.powerPriceCurve);
        form.append("gooPriceCurve", !formData.gooPriceCurve ? "No" : formData.gooPriceCurve);
        form.append("carbonIntensityOfGrid", !formData.carbonIntensityOfGrid ? "No" : formData.carbonIntensityOfGrid);

        if (formData.volume1CurveFile) {
          form.append("files", formData.volume1CurveFile, "volume1CurveFile");
        }

        if (formData.volume2CurveFile) {
          form.append("files", formData.volume2CurveFile, "volume2CurveFile");
        }

        if (formData.price1CurveFile) {
          form.append("files", formData.price1CurveFile, "price1CurveFile");
        }

        if (formData.powerPriceCurveFile) {
          form.append("files", formData.powerPriceCurveFile, "powerPriceCurveFile");
        }

        if (formData.gooPriceCurveFile) {
          form.append("files", formData.gooPriceCurveFile, "gooPriceCurveFile");
        }

        if (formData.carbonIntensityOfGridFile) {
          form.append("files", formData.carbonIntensityOfGridFile, "carbonIntensityOfGridFile");
        }
      }
      else if (formData?.modelType === 'existing_PPA') {
        url = `${config.BASE_URL}/api/ppa/existing-ppa-valuation`

        form.append("ppaLocation", formData?.ppaLocation);
        form.append("modelStartDate", formData?.modelStartDate);
        form.append("contractStartDate", formData?.contractStartDate);
        form.append("contractEndDate", formData?.contractEndDate);
        form.append("valuationDate", formData?.valuationDate);
        form.append("valuationFreequency", formData?.valuationFreequency);
        form.append("RETSchemeEndDate", formData?.RETSchemeEndDate);
        form.append("unbundlingApproach", formData?.unbundlingApproach);
        form.append("ppaPricing", formData?.ppaPricing);
        form.append("excessCashflowDifferenceFactor", formData?.excessCashflowDifferenceFactor);
        form.append("periodByPeriod", formData?.periodByPeriod);
        form.append("counterPartyAllocation", formData?.counterPartyAllocation);
        form.append("changingVolumeInPPA", formData?.changingVolumeInPPA);
        form.append("curtailmentFactor", formData?.curtailmentFactor);
        form.append("highTolerance", formData?.highTolerance);
        form.append("lowTolerance", formData?.lowTolerance);
        form.append("valuationAtInception", formData?.valuationAtInception);
        form.append("ppaVolStructure", formData?.ppaVolStructure);
        form.append("iscustomised", formData?.iscustomised?.toLowerCase());
        form.append("calibrationAdjustment", formData?.calibrationAdjustment);
        
        if(formData?.ppaPricing === 'custom') {
          form.append("files", formData.ppaPriceCurve, "ppaPriceCurve");
        } else {
          form.append("ppaPrice", formData?.ppaPrice);
        }

        if(formData?.unbundlingApproach === 'flat_unbundling') {
          form.append("unbundlingRoundingMultiple", formData?.unbundlingRoundingMultiple);
        }

        if (formData?.changingVolumeInPPA === 'yes' && formData.volumeCurveFile) {
          form.append("files", formData.volumeCurveFile, "volumeCurve");
        } else if(formData?.changingVolumeInPPA === 'no') {
          form.append("annualPPAVolumeCapacity", formData?.annualPPAVolumeCapacity);
        }

        if (formData?.blackMarketPrice === 'yes' && formData.blackMarketPriceCurve) {
          form.append("blackMarketPrice", formData?.blackMarketPrice);
          form.append("files", formData?.blackMarketPriceCurve, "blackMarketPriceCurve");
        }

        if (formData?.greenMarketPrice === 'yes' && formData.greenMarketPriceCurve) {
          form.append("greenMarketPrice", formData?.greenMarketPrice);
          form.append("files", formData?.greenMarketPriceCurve, "greenMarketPriceCurve");
        }

        if (formData?.uploadMLFCurve === 'yes' && formData.mlfCurve) {
          form.append("uploadMLFCurve", formData?.uploadMLFCurve);
          form.append("files", formData?.mlfCurve, "mlfCurve");
        }

        if (formData?.uploadDLFCurve === 'yes' && formData.dlfCurve) {
          form.append("uploadDLFCurve", formData?.uploadDLFCurve);
          form.append("files", formData?.dlfCurve, "dlfCurve");
        }

        if (formData?.inflation === 'yes' && formData.inflationCurve) {
          form.append("inflation", formData?.inflation);
          form.append("files", formData?.inflationCurve, "inflationCurve");
        }

        if (formData?.riskFreeRate === 'yes' && formData.riskFreeRateCurve) {
          form.append("riskFreeRate", formData?.riskFreeRate);
          form.append("files", formData?.riskFreeRateCurve, "riskFreeRateCurve");
        }

        if (formData?.assetCreditRiskAdjustment === 'yes' && formData.assetCreditRiskAdjustmentCurve) {
          form.append("assetCreditRiskAdjustment", formData?.assetCreditRiskAdjustment);
          form.append("files", formData?.assetCreditRiskAdjustmentCurve, "assetCreditRiskAdjustmentCurve");
        }

        if (formData?.liabilityCreditRiskAdjustment === 'yes' && formData.liabilityCreditRiskAdjustmentCurve) {
          form.append("liabilityCreditRiskAdjustment", formData?.liabilityCreditRiskAdjustment);
          form.append("files", formData?.liabilityCreditRiskAdjustmentCurve, "liabilityCreditRiskAdjustmentCurve");
        }

        if (formData.ppaEscalationCurve) {
          form.append("files", formData?.ppaEscalationCurve, "ppaEscalationCurve");
        }

        if (formData?.valuationAtInception === 'no') {
          if(formData?.greenMarketPriceCurveAtInception) {
            form.append("files", formData?.greenMarketPriceCurveAtInception, "greenMarketPriceCurveAtInception");
          }
          
          if(formData?.blackMarketPriceCurveAtInception) {
            form.append("files", formData?.blackMarketPriceCurveAtInception, "blackMarketPriceCurveAtInception");
          }
        }
      }

      SetIsLoading(true);
      await axios
        .post(url, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          SetIsLoading(false);
          if (!response.data.isSuccess) {
            ErrorToast(response.data.message);
          } else {
            SetResultData(response.data.result);
            SetActiveTabId("4");
          }
        });
    } catch (err) {
      SetIsLoading(false);
      console.log(`Error: Save PPA : ${JSON.stringify(err, null, 2)}`);
      if(err?.response?.data?.message) {
        ErrorToast(err?.response?.data?.message);
      } else {
        ErrorToast(`Something Went Wrong!. Please Try Again`);
      }
    }
  };

  const naviageToStartEvaluation = () => {
    SetActiveTabId("2");
  };

  const downloadTemplate = (e, fileName) => {
    e.preventDefault();
    //TODO: Populate UserID correctly
    let userID = authUser.userName;
    DownloadFile(fileName, userID, formData?.modelStartDate, formData?.contractEndDate, formData?.valuationFreequency);
  };

  return (
    <div className="add-evaluation_wrapper ppa_parameter">
      <div className="add-evaluation-form-container">
        <form className="add-evaluation-form ppa_parameter_form" onSubmit={handleSubmit}>
          <div className="admin__contract__form__group col-md-3 mb-30">
            <label htmlFor="iscustomised">Upload Customised Data</label>
            <div>
              <div>
                <select
                  className="admin__contract__input ppa_param_select"
                  id="iscustomised"
                  name="iscustomised"
                  value={formData?.iscustomised}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  <option id="-1" value="" key="-1">
                    Please Select Any Value
                  </option>
                  {UseBenchMarkList.map((benchmark, index) => (
                    <option id={index} value={benchmark} key={index}>
                      {benchmark}
                    </option>
                  ))}
                </select>
                {errors?.iscustomised && <p className="error">Please select whether to upload custom data.</p>}
              </div>
            </div>
          </div>

          {formData?.iscustomised === "Yes" && formData?.modelType ==='new_PPA' && (
            <div>
              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="powerPriceCurve">Upload Power Price Curve *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="powerPriceCurveId"
                      name="powerPriceCurve"
                      value={formData?.powerPriceCurve}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option id="-1" value="" key="-1">
                        Please Select Any Value
                      </option>
                      {PowerPriceCurveList.map((powerPrice, index) => (
                        <option id={index} value={powerPrice} key={index}>
                          {powerPrice}
                        </option>
                      ))}
                    </select>
                    {errors?.powerPriceCurve && (
                      <p className="error">Please select whether to upload Power price curve.</p>
                    )}
                  </div>
                </div>

                {formData?.powerPriceCurve && formData?.powerPriceCurve != "No" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="powerPriceCurveFile">Power Price Curve *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="powerPriceCurveFile" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "powerPriceCurveFile")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.powerPriceCurveFile && <p className="error">Please upload</p>}
                  </div>
                )}
              </div>
              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="gooPriceCurve">Upload GOO Price Curve *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="gooPriceCurveId"
                      name="gooPriceCurve"
                      value={formData?.gooPriceCurve}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option id="-1" value="" key="-1">
                        Please Select Any Value
                      </option>
                      {GOOPriceCurveList.map((gooPrice, index) => (
                        <option id={index} value={gooPrice} key={index}>
                          {gooPrice}
                        </option>
                      ))}
                    </select>
                    {errors?.gooPriceCurve && <p className="error">Please select whether to upload GOO price curve.</p>}
                  </div>
                </div>

                {formData?.gooPriceCurve && formData?.gooPriceCurve != "No" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="gooPriceCurveFile">GOO Price Curve *</label>

                    <div className="uload_download_block">
                      <CustomFileUpload FormName="gooPriceCurveFile" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "gooPriceCurveFile")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.gooPriceCurveFile && <p className="error">Please upload</p>}
                  </div>
                )}
              </div>
              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="carbonIntensityOfGrid">Upload Carbon Intensity Of Grid *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="carbonIntensityOfGridId"
                      name="carbonIntensityOfGrid"
                      value={formData?.carbonIntensityOfGrid}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option id="-1" value="" key="-1">
                        Please Select Any Value
                      </option>
                      {CarbonIntensityOfGridList.map((cig, index) => (
                        <option id={index} value={cig} key={index}>
                          {cig}
                        </option>
                      ))}
                    </select>
                    {errors?.carbonIntensityOfGrid && (
                      <p className="error">Please select whether to upload Carbon intensity of grid.</p>
                    )}
                  </div>
                </div>

                {formData?.carbonIntensityOfGrid && formData?.carbonIntensityOfGrid != "No" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="carbonIntensityOfGridFile">Carbon Intensity Of Grid *</label>

                    <div className="uload_download_block">
                      <CustomFileUpload
                        FormName="carbonIntensityOfGridFile"
                        UploadButtonText="+ Upload"
                      ></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "carbonIntensityOfGridFile")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.carbonIntensityOfGridFile && <p className="error">Please upload</p>}
                  </div>
                )}
              </div>
            </div>
          )}

          {formData?.iscustomised === "Yes" && formData?.modelType ==='existing_PPA' && (
            <div>
              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="black-market-price">Upload Black Market Price Curve *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="black-market-price"
                      name="blackMarketPrice"
                      value={formData?.blackMarketPrice}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}>
                          { list.label }
                        </option>
                      ))}
                    </select>
                    {errors?.blackMarketPrice && (
                      <p className="error">Please Select Wether To Upload Black Market Price Curve.</p>
                    )}
                  </div>
                </div>

                {formData?.blackMarketPrice && formData?.blackMarketPrice === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="black-market-price-curve">Black Market Price Curve *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="blackMarketPriceCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "blackMarketPriceCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.blackMarketPriceCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>

              {
                formData?.valuationAtInception === 'yes' && (
                  <div className="col-md-3 ppa_parameter_upload_block">
                    <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                      <label htmlFor="green-market-price">Upload Green Market Price Curve  At Inception*</label>
                      <div>
                        <select
                          className="admin__contract__input ppa_param_select"
                          id="green-market-price"
                          name="greenMarketPrice"
                          value={formData?.greenMarketPrice}
                          onChange={(e) => handleChange(e)}
                          required
                        >
                          <option value=""> Please Select Any Value</option>
                          { yesOrNoList.map(list => (
                            <option value={list.value} key={list.value}> { list.label }</option>
                          ))}
                        </select>
                        {errors?.greenMarketPrice && (
                          <p className="error">Please Select Whether To Upload Green Market Price Curve.</p>
                        )}
                      </div>
                    </div>

                    {formData?.greenMarketPrice && formData?.greenMarketPrice === "yes" && (
                      <div className="admin__contract__form__group">
                        <label htmlFor="green-market-price-curve">Green Market Price Curve *</label>
                        <div className="uload_download_block">
                          <CustomFileUpload FormName="greenMarketPriceCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                          <span
                            className="download_link collapsed-download-btn"
                            onClick={(e) => downloadTemplate(e, "greenMarketPriceCurve")}
                          >
                            <img className="" src={"/icons/download.png"} alt="Download" />
                            <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                          </span>
                        </div>
                        {errors?.greenMarketPriceCurve && <p className="error">Please Upload</p>}
                      </div>
                    )}
                  </div>
                )
              }

              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="upload-mlf-curve">Upload MLF Curve *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="upload-mlf-curve"
                      name="uploadMLFCurve"
                      value={formData?.uploadMLFCurve}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}> { list.label }</option>
                      ))}
                    </select>
                    {errors?.uploadMLFCurve && (
                      <p className="error">Please Select Whether To Upload MLF Curve.</p>
                    )}
                  </div>
                </div>

                { formData?.uploadMLFCurve === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="mlf-curve">MLF Curve *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="mlfCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "mlfCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.mlfCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>

              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="upload-mlf-curve">Upload DLF Curve *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="upload-dlf-curve"
                      name="uploadDLFCurve"
                      value={formData?.uploadDLFCurve}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}> { list.label }</option>
                      ))}
                    </select>
                    {errors?.uploadDLFCurve && (
                      <p className="error">Please Select Whether To Upload DLF Curve.</p>
                    )}
                  </div>
                </div>

                { formData?.uploadDLFCurve === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="mlf-curve">DLF Curve *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="dlfCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "dlfCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.dlfCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>

              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="inflation">Upload Inflation Curve *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="inflation"
                      name="inflation"
                      value={formData?.inflation}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}> { list.label }</option>
                      ))}
                    </select>
                    {errors?.inflation && (
                      <p className="error">Please Select Whether To Upload Inflation Curve.</p>
                    )}
                  </div>
                </div>

                {formData?.inflation && formData?.inflation === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="inflation-curve">Inflation Curve *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="inflationCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "inflationCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.inflationCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>

              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="risk-free-rate">Upload Risk Free Rate *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="risk-free-rate"
                      name="riskFreeRate"
                      value={formData?.riskFreeRate}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}> { list.label }</option>
                      ))}
                    </select>
                    {errors?.riskFreeRate && (
                      <p className="error">Please Select Whether To Upload Risk Free Rate.</p>
                    )}
                  </div>
                </div>

                {formData?.riskFreeRate && formData?.riskFreeRate === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="risk-free-rate-curve">Risk Free Rate *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="riskFreeRateCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "riskFreeRateCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.riskFreeRateCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>

              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="upload-asset-credit-risk-adjustment">Upload Asset Credit Risk Adjustment *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="upload-asset-credit-risk-adjustment"
                      name="assetCreditRiskAdjustment"
                      value={formData?.assetCreditRiskAdjustment}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}> { list.label }</option>
                      ))}
                    </select>
                    {errors?.assetCreditRiskAdjustment && (
                      <p className="error">Please Select Whether To Upload Asset Credit Risk Adjustment.</p>
                    )}
                  </div>
                </div>

                {formData?.assetCreditRiskAdjustment && formData?.assetCreditRiskAdjustment === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="upload-asset-credit-risk-adjustment-curve">Asset Credit Risk Adjustment *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="assetCreditRiskAdjustmentCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "assetCreditRiskAdjustmentCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.assetCreditRiskAdjustmentCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>

              <div className="col-md-3 ppa_parameter_upload_block">
                <div className="admin__contract__form__group ppa_param_up_dn_wrapper">
                  <label htmlFor="upload-liability-credit-risk-adjustment">Upload Liability Credit Risk Adjustment *</label>
                  <div>
                    <select
                      className="admin__contract__input ppa_param_select"
                      id="upload-liability-credit-risk-adjustment"
                      name="liabilityCreditRiskAdjustment"
                      value={formData?.liabilityCreditRiskAdjustment}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value=""> Please Select Any Value</option>
                      { yesOrNoList.map(list => (
                        <option value={list.value} key={list.value}> { list.label }</option>
                      ))}
                    </select>
                    {errors?.liabilityCreditRiskAdjustment && (
                      <p className="error">Please Select Whether To Upload Liability Credit Risk Adjustment.</p>
                    )}
                  </div>
                </div>

                {formData?.liabilityCreditRiskAdjustment && formData?.liabilityCreditRiskAdjustment === "yes" && (
                  <div className="admin__contract__form__group">
                    <label htmlFor="upload-asset-credit-risk-adjustment-curve">Liability Credit Risk Adjustment *</label>
                    <div className="uload_download_block">
                      <CustomFileUpload FormName="liabilityCreditRiskAdjustmentCurve" UploadButtonText="+ Upload"></CustomFileUpload>
                      <span
                        className="download_link collapsed-download-btn"
                        onClick={(e) => downloadTemplate(e, "liabilityCreditRiskAdjustmentCurve")}
                      >
                        <img className="" src={"/icons/download.png"} alt="Download" />
                        <span className="cppa-custom-tooltip tooltip-dn-btn">Download Template</span>
                      </span>
                    </div>
                    {errors?.liabilityCreditRiskAdjustmentCurve && <p className="error">Please Upload</p>}
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
        <div className="ppa__contract__options ppa-btn-wrapper">
          <button className="forward__option btn-secondary" onClick={naviageToStartEvaluation}>
            Back
          </button>

          <button disabled={disableCalculate} className="forward__option btn-primary" onClick={() => submitDetails()}>
            Calculate
          </button>
        </div>
        <div className="info-container m-0">
          <span className="error-msg">NOTE: Fields marked (*) are mandatory</span>
        </div>
      </div>
    </div>
  );
}
