import "./contractTerminationConfirmation.css";

export default function ContractTerminationConfirmationModal({toggleModal,showFinalStep, contract}) {

    const handleConfirmation = () => {
        showFinalStep(true)
        toggleModal(false)
    }

    return (
        <>
        <div className="modal__wrapper">
        <div className="contract__termination__confirmation__modal__container">
            <div className="contract__termination__confirmation__header">
                    <p>Please Confirm Before Proceeding</p>
                    <p onClick={() => toggleModal(false)}>X</p>
            </div>
            <div className="contract__termination__confirmation__content">
                <div className="admin__onboard__contract__params">
                        <p className="admin__onboard__param__name">&nbsp;&nbsp;Contract Termination Date</p>
                        <p className="admin__onboard__param__value">&nbsp;&nbsp;{contract.early_end_date}</p>
                    </div>
                    <div className="admin__onboard__contract__params">
                        <p className="admin__onboard__param__name">&nbsp;&nbsp;Termination Fee(AUD)</p>
                        <p className="admin__onboard__param__value">&nbsp;&nbsp;{contract.termination_fee}</p>
                </div>
            </div>
            <div className="contract__termination__confirmation__modal__button">
                <button className="contract__termination__confirmation__confirm" onClick={handleConfirmation}>Confirm</button>
                <button className="contract__termination__confirmation__cancel" onClick={() => toggleModal(false)}>Cancel</button>
            </div>
        </div>
        </div>
        
        </>
    )
}