import { useContext, useEffect, useState } from "react";
import HorizontalTabNavigation from "../../../../components/HorizontalTabNavigation";
import "./contractDetailedTabView.css"
import EditContract from "../EditContract/editContract";
import { useLocation, useNavigate  } from "react-router-dom";
import axios from "../../../../interceptors/axiosInstance";
import SimpleContractEdit from "../SimpleContractEdit/simpleContractEdit"
import ContractRequestTermination from "../ContractRequestTermination"
import ContractRevisePaymentTerms from "../ContractRevisePaymentTerms";
import { config } from '../../../../config';
import ContractOverview from '../ContractOverView';
import { InvoiceReconciliation } from "../../../Generator/InvoiceReconviliation/InvoiceReconciliation";
import { UploadInvoice } from "../../../Generator/UploadInvoice/UploadInvoice";
import { LoaderContext } from "../../../../context/LoaderContext";
import { twoDecimal } from "../../../../utils/display";
import { MessageContext } from "../../../../context/MessageContext";

export default function OffTakerContractDetailedOverview() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('tab1');
    const [tabTitle, setTabtitle] = useState("");
    const [selectedContract, setSelectedContract] = useState({})
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [suppliedVolume,setSuppliedVolume] = useState('');
    const [surplusShortFallValue,setSurplusShortFallValue] = useState('NA');
    const { ErrorToast } = useContext(MessageContext);
    const [invoiceId, setInvoiceId] = useState('');

    const {state} = useLocation();
    let contractID = '';
    const {setLoading} = useContext(LoaderContext);
    

    const handleChange = (tab) => {
        setActiveTab(tab.id);
        setTabtitle(tab.label);
        setSelectedInvoice(null);
    }

    const navigateBack = () => {
        if(activeTab != 'invoice-reconcillation'){
            navigate(-1);
        } else {
            if(!selectedInvoice){
                navigate(-1);
            } else {
                setSelectedInvoice(null)
            }
        }
    }

    const selectInvoice = (item) =>{
        setSelectedInvoice(item);
    }

    const navigateToInvoiceUpload = (id) => {
        if(id){
            setInvoiceId(id);
            setActiveTab('upload-invoice');
            setTabtitle('Upload Invoice');
        }
    }

    const fetchSelectedContract = async () => {
        try{
            setLoading(true)
            const res = await axios.get(`${config.BASE_URL}/api/contract/${contractID}`);
            setSelectedContract(res.data);
            let volume = 0;
            if(res.data && res.data.invoices && res.data.invoices.length > 0){
                res.data.invoices.filter(invoice => invoice.retry == false).forEach((invoice)=>{
                    volume = volume + invoice.calculatedSuppliedVolume;
                })
                setSuppliedVolume(volume);
                let volumeShortFall = 0;
                let volumeSurplus = 0;
                const volumeDifference = res.data.volume - volume;
                if(volumeDifference >= 0) {
                    volumeShortFall = volumeDifference;
                } else {
                    volumeSurplus = volumeDifference * -1;
                }
                setSurplusShortFallValue(`${ twoDecimal(volumeSurplus)} / ${twoDecimal(volumeShortFall)}`)
            }
            setLoading(false)
        } catch (error) {
            setSelectedContract({})
            setLoading(false)
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }

    useEffect(() => {
        if(!state || !state.contractID){
            navigate('/contracts');
        } else {
            contractID = state.contractID;
            fetchSelectedContract();
        }
    },[])

    const tabs = [
        {id: 'tab1', label: 'Overview'},
        {id: 'tab2', label: 'Update Strike Price'},
        {id: 'tab3', label: 'Update Volume'},
        // {id: 'tab4', label: 'Request extension'},
        // /**
        //  * Hiding this tab for now.
        //  */
        // // {id: 'tab5', label: 'Request termination'},
        // {id: 'tab6', label: 'Revise payment terms'},
        {id: 'invoice-reconcillation', label: 'Invoice Reconciliation'},
        {id: 'upload-invoice', label: 'Upload Invoice'},
    ]

    return (
        <>
            <div className="offtaker__contract__details__wrapper">
            <div className="backbutton">
                <button className="back__button" onClick={navigateBack}>Back</button>
            </div>
            <div className="offtaker-contract-tabs">
                <HorizontalTabNavigation tabs={tabs} initialTab={activeTab} onTabChange={handleChange} disableSearch={true}/>
                {activeTab === 'tab1' &&  <ContractOverview contract={selectedContract} />}
                {activeTab === 'tab2' &&  <SimpleContractEdit contract={selectedContract} tileFeilds={[
                    {TileName: "Current Strike Price", value: (`${twoDecimal(selectedContract.strikePrice)} ${selectedContract.currency}` )},
                    {TileName: "Total Cost", value: `${twoDecimal(selectedContract.volume * selectedContract.strikePrice)} ${selectedContract.currency}` },
                    {TileName: "Ceiling Price", value: `${twoDecimal(selectedContract.ceilingPrice)} ${selectedContract.currency}`}
                ]} updatingValue="strikePrice" label="Strike Price"/>}
                {activeTab === 'tab3' &&   <SimpleContractEdit contract={selectedContract} tileFeilds={[
                    {TileName: "Total Volume Expected", value: (twoDecimal(selectedContract.volume) + " MWh")},
                    {TileName: "Total Volume Supplied", value: (suppliedVolume? twoDecimal(suppliedVolume) + " MWh":'NA')},
                    {TileName: "Surplus/Shortfall", value: surplusShortFallValue !='NA'? `${surplusShortFallValue} MWh`: `NA`}
                ]} updatingValue="volume" label="Volume"/>}
                {/* {activeTab === 'tab4' &&   <SimpleContractEdit contract={selectedContract} tileFeilds={[
                    {TileName: "Current start date", value: selectedContract.activeSince},
                    {TileName: "Contract end date", value: selectedContract.endDate},
                    {TileName: "Outstanding conract period", value: "14 months"}
                ]} updatingValue="End date"/>} */}
                {/* {activeTab === 'tab5' &&  <ContractRequestTermination contract={selectedContract} />} */}
                {/* {activeTab === 'tab6' &&  <ContractRevisePaymentTerms contract={selectedContract}/>} */}
                {activeTab === 'invoice-reconcillation' &&   <InvoiceReconciliation onInvoiceRetrySelected={navigateToInvoiceUpload} contract={selectedContract} role={"offtaker"} invoiceSelected={selectInvoice} selectedInvoice={selectedInvoice}/>}
                {activeTab === 'upload-invoice' &&   <UploadInvoice contract={selectedContract} invoiceId={invoiceId} setInvoice={setInvoiceId}/>}
            </div>
            </div>
        </>
    )
}