import InvoiceReconciliationDetails from "./Details/invoiceReconciliationDetails";
import InvoiceList from "./InvoiceList/InvoiceList";
import { LoaderContext } from "../../../context/LoaderContext";
import { config } from "../../../config";
import axios from "../../../interceptors/axiosInstance";
import {useState, useEffect, useContext} from 'react';
import { MessageContext } from "../../../context/MessageContext";

export const InvoiceReconciliation = ({contract, role, invoiceSelected, selectedInvoice, onInvoiceRetrySelected}) => {
    const {setLoading} = useContext(LoaderContext);
    const [invoices,setInvoices] = useState([]);
    const [invoicePeriodStatus, setInvoicePeriodStatus] = useState({});
    const [retryEnabled, setRetryEnabled] = useState(false);
    const { ErrorToast } = useContext(MessageContext);

    const fetchInvoices = async ()=>{
        try {
            if(contract){
                setLoading(true)
                const res = await axios.get(`${config.BASE_URL}/api/invoice/contract/${contract._id}`);
                processInvoiceDetails(res.data);
                setInvoices(res.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false);
            setInvoices([]);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }

    const processInvoiceDetails = (invoiceList) =>{
        let invoicePeriodList = {};
        for(let i=0; i<invoiceList.length; i++){
            if(invoiceList[i].invoiceStatus === 'failed'){
                invoicePeriodList[invoiceList[i].invoicePeriod] = true;
            } else {
                invoicePeriodList[invoiceList[i].invoicePeriod] = false;
            }
        }
        setInvoicePeriodStatus(invoicePeriodList);
    }

    useEffect(()=>{
        fetchInvoices();
    },[])

    useEffect(()=>{
        if(selectedInvoice){
            setRetryEnabled(invoicePeriodStatus[selectedInvoice.invoicePeriod]);
        }
    },[selectedInvoice])


    const selectInvoice = (item) => {
        invoiceSelected(item)
    }

    return (
        <div>
            {selectedInvoice ? 
                <InvoiceReconciliationDetails goToInvoiceUpload={onInvoiceRetrySelected} role={role} contract={contract} invoice={selectedInvoice} retry={retryEnabled} /> : 
                <InvoiceList contract={contract} invoiceList={invoices} setSelectedInvoice={selectInvoice}/>    
            }
        </div>
    )
}