import { handleDateFormat } from "../../../../utils/DateFormating";
import CustomCalendar from "../../../../utils/components/CustomCalendar";
import "./datePicker.css";
import {useState, useRef, useEffect } from 'react';


export const DatePicker = ({label,CalValue, setCalValue,id,placeholder, category="", formClass="", disabled=false, error="",minDate=null, helperText=false}) => {
    const inputRef = useRef(null)
    const [showCal, setShowCal] = useState(false)
    const [showCross, setShowCross] = useState(true)
    const handleCalToggle = (event) => {
        event.stopPropagation();
        setShowCal(prev => !prev)
    }

    const handleOnChange = (event) => {
        if(category){
            setCalValue(handleDateFormat(event), category)
        }else{
            setCalValue(handleDateFormat(event))
        }
        setShowCal(false)
    }

    const clearInput =  () => {
        inputRef.current.value = null
        if(category){
            setCalValue(null, category)
        }else{
            setCalValue(null)
        }
        setShowCross(false)
    }

    useEffect(() => {
        if(CalValue) {
            setShowCross(true)
        }else{
            setShowCross(false)
        }
    }, [CalValue])

    return (
        <div className={formClass? formClass : "form__group"}>
            <label htmlFor={id}>{label}</label>
            <div className="options__icons">
                {showCross ? <img className="cross__icon" onClick={clearInput} src={"/icons/cross_black.svg"} alt="clear_cross" /> : null}
                {!disabled ? <img className="cal__icon_img" onClick={ handleCalToggle } src={"/icons/calendar.svg"} alt="cal" /> : null}
            </div>
            {showCal && <CustomCalendar onClickOutside={handleCalToggle} value={CalValue} onChange={(event) => handleOnChange(event, id)} minDate={minDate}/>}
            <input ref={inputRef} disabled={disabled} className="admin__contract__input" value={CalValue} type="text" id={id} name={id} placeholder={placeholder} onClick={ handleCalToggle} required />
            {helperText && <span className="cppa-custom-tooltip datepicker-tooltip">{helperText}</span>}
            {error && <p class="text_error">{error}</p>}
        </div>
    )
}