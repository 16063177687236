import { useNavigate } from "react-router-dom";
import SideNavItems from "../SideNavItems/SideNavItems";
import "./collapsiblesidenav.css"
import { useState, useRef } from 'react';

const generatorMenu = [
    {
        url: "/", label: "Dashboard", icon: '/icons/dashboard.svg', submenu: null
    },
    {
        url: "/contracts", label: "PPA Contracts", icon: "/icons/contract.svg", submenu: null
    },
    {
        url: "/upload-data", label: "Upload Data", icon: "/icons/upload.svg", submenu: null
    },
    {
        url: "/contract/requests", label: "Change Request", icon: "/icons/approve_reject.svg", submenu: null
    },
    {
        url: "/support", label: "Support", icon: "/icons/support.svg", submenu: null
    }
]

const offTakerMenu = [
    {
        url: "/", label: "Dashboard", icon: '/icons/dashboard.svg', submenu: null
    },
    {
        url: "/contracts", label: "PPA Contracts", icon: "/icons/contract.svg", submenu: null
    },
    {
        url: "/contract/requests", label: "Change Request", icon: "/icons/approve_reject.svg", submenu: null
    },
    {
        url: "/support", label: "Support", icon: "/icons/support.svg", submenu: null
    }
]

const adminMenu = [
    // {
    //     url: "/", label: "Dashboard", icon :'/icons/dashboard.svg', submenu: null
    // },
    {
        url: "/ppa-valuations", label: "PPA Valuations", icon: '/icons/contract.svg', submenu: [
            {
                url: "/PPAValuation",
                label: "Calculation",
                active: false,
            },
            {
                url: "/Consultant",
                label: "Benchmark Data",
                active: false,
            },
        ],
    },
    {
        url: "/", label: "Manage Users", icon: "/icons/people.svg", submenu: [
            {
                url: "/manage-users/add",
                label: "Add User",
                active: false
            },
            {
                url: "/manage-users/remove",
                label: "Remove User",
                active: false
            },
            {
                url: "/manage-users/modify",
                label: "Modify User",
                active: false
            },
            {
                url: "/client/onboard",
                label: "Onboard Client",
                active: false
            },
            {
                url: "/client/remove",
                label: "Remove Client",
                active: false
            }
        ]
    },
    {
        url: "/admin/", label: "PPA Contracts", icon: "/icons/contract.svg", submenu: [
            {
                url: "/contract/manage",
                label: "Manage PPA Contracts",
                active: false
            },
            {
                url: "/contract/onboard",
                label: "Onboard Contract",
                active: false
            },
        ]
    },
    {
        url: "/admin/", label: "Change Request", icon: "/icons/approve_reject.svg", submenu: [
            {
                url: "/contract/request",
                label: "Manage Contract Request",
                active: false
            },
            {
                url: "/user/request",
                label: "Manage Support Request",
                active: false
            },
        ]
    },
    {
        url: "/data/upload", label: "Data Upload", icon: '/icons/upload.svg', submenu: null
    },
]
const ppaValuationsMenu = [
    {
        url: "/ppa-valuations",
        label: "PPA Valuations",
        icon: "/icons/contract.svg",
        submenu: [
            {
                url: "/PPAValuation",
                label: "Calculation",
                active: false,
            },
            {
                url: "/Consultant",
                label: "Benchmark Data",
                active: false,
            },
        ],
    },
];

const eyConsultantMenu = [

    {
        url: "/ppa-valuations",
        label: "PPA Valuations",
        icon: "/icons/contract.svg",
        submenu: [
            {
                url: "/PPAValuation",

                label: "Calculation",

                active: false,
            }
        ],
    },
];

export default function CollapsibleSideNav({ toggleSideNav, isCollapsed, role }) {
    const navigate = useNavigate()
    const [indexSelected, setIndexSelected] = useState(null)
    const [indexSubSelected, setSubIndexSelected] = useState(null)

    const handleNavClick = (url, index, menu) => {
        setIndexSelected(index)
        setSubIndexSelected(null)
        if (!menu.submenu) {
            navigate(url)
        }
        toggleSideNav(true)
    }

    const handleSubNavClick = (url, parentIndex, subIndex) => {
        setIndexSelected(parentIndex)
        setSubIndexSelected(subIndex)
        navigate(url)
    }

    let menu = [];

    if (role === 'generator') {
        menu = generatorMenu;
    } else if (role === 'offtaker') {
        menu = offTakerMenu;
    } else if (role === "admin") {
        menu = adminMenu;
    } else if (role === "ppa_valuation") {
        menu = ppaValuationsMenu;
    }
    else if (role === "ey_consultant") {
        menu = eyConsultantMenu;
    }



    return (
        <div className={`sidebar__container ${isCollapsed ? '' : 'open'}`} >
            <div className="sidebar__nav__list">
                <ul className="nav__list">
                    {
                        menu.length > 0 && menu.map((item, index) => (
                            <SideNavItems
                                menu={item}
                                highlight={index === indexSelected}
                                subHighlighted={indexSubSelected}
                                isCollapsed={isCollapsed}
                                itr={index}
                                key={index}
                                handleNavClick={handleNavClick}
                                handleSubNavClick={handleSubNavClick}
                            />
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}