import { useState, useReducer, useContext } from "react";

import axios from "../../interceptors/axiosInstance";
import "./support.css";
import { config } from '../../config';
import { emailRegEx, phoneNumberRegex, whiteSpaceStringRegex, textAreaRegex } from '../../utils/ValidationRules';
import { LoaderContext } from "../../context/LoaderContext";
import SuccessModal from "../../components/SuccessModal";
import { MessageContext } from "../../context/MessageContext";

const initialClientFormState = {
    "name": "",
    "email":"",
    "phoneNumber":"",
    "requestType":"add_user",
    "message":""
}

const initialValidityState = {
    "nameError": '',
    "emailError":'',
    "phoneNumberError":'',
    "messageError":'',
    "isFormValid": false
}

const formReducer = (state, action) => {
    if(action.type === "RESET_FORM"){
        return initialClientFormState
    } else{
        const {name, value} = action.type
        return{
            ...state, [name]: value, 
        }
    }
}

const formValidityReducer = (state , action)=> {
    let isValid= false;
    const {name='', email='', phoneNumber='', message=''} = action.payload || {};
    if(action.type === "RESET_FORM_VALIDITY"){
        return action.payLoad;
    } else{
        let errorData = {...state};
        switch(action.type){
            case "NAME": {}
                isValid = (name.length > 0 && whiteSpaceStringRegex.test(name))? true: false;
                errorData = {...errorData, nameError: isValid? '': 'Enter Name'};
                break;
            case "EMAIL": 
                isValid = (email.length > 0 && emailRegEx.test(email)) ? true: false;
                errorData = {...errorData, emailError: isValid? '': 'Enter Valid Email'};
                break;
            case "PHONENUMBER": 
                isValid = (phoneNumber.length > 0 && phoneNumberRegex.test(phoneNumber)) ? true: false
                errorData = {...errorData, phoneNumberError: isValid? '': 'Enter Valid Phone Number'};
                break;
            case "MESSAGE": 
                isValid = (message.length > 0 && textAreaRegex.test(message))? true: false;
                errorData = {...errorData, messageError: isValid? '': 'Enter Message'};
                break;
        }
        const formValidity = name && email && phoneNumber && message 
                            && !errorData.nameError && !errorData.emailError && !errorData.phoneNumberError && !errorData.messageError;
        errorData.isFormValid = formValidity;
        return errorData;
    }
}

export default function Support() {
    const [clientParams, setClientParams] = useReducer(formReducer, initialClientFormState)
    const [clientValidity, setClientValidity] = useReducer(formValidityReducer, initialValidityState)
    const { setLoading } = useContext(LoaderContext);
    const [finalModal, setFinalModal] = useState(false);  
    const { ErrorToast } = useContext(MessageContext); 

    const handleChange = (e) => {
        setClientParams({type:e.target})
    }

    const handleValidation = (e) => {
        setClientValidity({type:e.target.name.toUpperCase(), payload:clientParams});
    }

    const handleSubmit = async () => {
        window.scrollTo(0,0);
        try{
            setLoading(true);
            const res = await axios.post(`${config.BASE_URL}/api/support`, {...clientParams, message: clientParams.message.trim()});
            setClientParams({type:"RESET_FORM"});
            setClientValidity({type:"RESET_FORM_VALIDITY", payLoad: initialValidityState});
            setLoading(false);
            setFinalModal(true);
        }catch(error){
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }

    return (
        <div className="support_container">
            <div className="support_content_left">
                <div className="support_content_text">
                    <h1>Contact Us</h1>
                    <p>
                        At EY, our purpose is building a better working world. The insights and services we provide help to create long-term value for clients, people and society, and to build trust in the capital markets.                    
                    </p>
                </div>
                <div className="support_content_contact_details">
                    <div className="support_content_contact_details_text">
                        <img className="support_icon" src="/icons/location.svg" alt="sidenav__icon" />
                        <p><a href="https://www.ey.com/en_au/locations">www.ey.com/en_au/locations</a></p>
                    </div>
                    <div className="support_content_contact_details_text">
                        <img className="support_icon" src="/icons/phone.svg" alt="sidenav__icon" />
                        <p>+21-1234-1234</p>
                    </div>
                    <div className="support_content_contact_details_text">
                        <img className="support_icon" src="/icons/mail.svg" alt="sidenav__icon" />
                        <p>support@ppa.ey.com</p>
                    </div>
                </div>
            </div>
            <div className="support_content_right">
                <form className="admin__client__onboarding__form" onSubmit={() => { }} autocomplete="off">
                    <div className="support_content_right_wrapper">
                        <label for="name">Name*</label>
                        <input type="text" name="name" value={clientParams.name} onChange={handleChange} onKeyUp={handleValidation}></input>
                        {clientValidity.nameError && <span className="admin_input_error">{clientValidity.nameError}</span>}
                    </div>
                    <div className="support_content_right_wrapper">
                        <label for="email">Email*</label>
                        <input type="text" name="email" value={clientParams.email} onChange={handleChange} onKeyUp={handleValidation}></input>
                        {clientValidity.emailError && <span className="admin_input_error">{clientValidity.emailError}</span>}
                    </div>
                    <div className="support_content_right_wrapper">
                        <label for="phoneNumber">Phone Number*</label>
                        <input type="text" name="phoneNumber" value={clientParams.phoneNumber} onChange={handleChange} onKeyUp={handleValidation}></input>
                        {clientValidity.phoneNumberError && <span className="admin_input_error">{clientValidity.phoneNumberError}</span>}
                    </div>
                    <div className="support_content_right_wrapper">
                        <label for="requestType">Type Of Request*</label>
                        <select name="requestType" id="requestType" value={clientParams.requestType} onChange={handleChange} onKeyUp={handleValidation}>
                            <option value="add_user">Add User</option>
                            <option value="remove_user">Remove User</option>
                            <option value="modify_user">Modify User</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="support_content_right_wrapper">
                        <label for="message">Message*</label>
                        <textarea name="message" value={clientParams.message} onChange={handleChange} onKeyUp={handleValidation}></textarea>
                        {clientValidity.messageError && <span className="admin_input_error">{clientValidity.messageError}</span>}
                    </div>
                </form>
                <button disabled={!clientValidity.isFormValid} onClick={handleSubmit}>Send Message</button>
            </div>
            {finalModal ?  <SuccessModal className="support_success_modal" title = "Success" message="Your Request Has Been Submitted Successfully" toggleModal={setFinalModal}/>:null }
        </div>
    )
}