import { createColumnHelper } from '@tanstack/react-table'
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import * as moment from 'moment';

import axios from '../../../../interceptors/axiosInstance';
import { Table } from '../../../../components/Table/Table';
import BannerWithSearchBar from "../../../../components/General/BannerWithSearchBar/BannerWithSearchBar";
import { config } from '../../../../config';
import { LoaderContext } from '../../../../context/LoaderContext';
import "./listGeneratorContract.css";
import { pascalCase } from '../../../../utils/display';
import { MessageContext } from '../../../../context/MessageContext';

export default function ListGeneratorContract() {
    const [contracts, setContracts] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [disableSearch, setDisableSearch] = useState(false);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);
    const navigate = useNavigate()

    const fetchAllContracts = async () => {
        try{
            setLoading(true);
            const res = await axios.get(`${config.BASE_URL}/api/contract`);
            setContracts(res.data);
            setLoading(false);
        }
        catch(error){
            setLoading(false);
            setContracts([])
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }      
    }

    const goToRecord = (content) =>{
        
        navigate('/manage/contract', {state: { contractID: content},
        } )

    }

    const handleSearch = (e) => {
        console.log(`the value entered is ${e.target.value}`)
        setSearchFilter(String(e.target.value))
    }

    const enableSearch = () => {
        setDisableSearch(false)
    }

    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.display({
            id: 'view-contract-generator',
            cell: props => <img className="view-contract-icon"src={"/icons/visibility.svg"} alt="View" onClick={() => {goToRecord(props.row.original._id)}} />,
            header: ''
        }),
        columnHelper.accessor('_id', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Contract ID',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('contractTitle', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Contract Title',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor( 
            row => {
                if(row.offtaker && row.offtaker.clientName)
                    return row.offtaker.clientName;
                return '';
            },
            {
                cell: info => info.getValue(),
                footer: info => info.column.id,
                header: 'Offtaker Name',
                enableGlobalFilter: true,
            }
        ),
        columnHelper.accessor('volume', {
            cell: info => `${info.getValue()}`,
            footer: info => info.column.id,
            header: 'Volume (MWh)',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('LGC', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'LGC/REC',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('contractStartDate', {
            cell: info => {
                const startDate= info.getValue();                
                return startDate ? moment(startDate).format('YYYY-MM-DD') : '';
            },
            footer: info => info.column.id,
            header: 'Start Date',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('contractSunsetDate', {
            cell: info => {
                const endDate= info.getValue();                
                return endDate ? moment(endDate).format('YYYY-MM-DD') : '';
            },
            footer: info => info.column.id,
            header: 'End Date',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row =>{
            if(row.status === 'active'){
                const currentDate = moment(new Date());
                const contractEndDate = moment(row.contractSunsetDate); 
                const duration = contractEndDate.diff(currentDate, 'months');
                if(duration < 12){
                    return 'Near Renewal';
                }
            }
            return pascalCase(row.status, '-');
        }, {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Status',
            enableGlobalFilter: false,
        }),
    ];

    useEffect(() => {
        fetchAllContracts()
    }, []);

    return(
        <div className="list__contract__container">
            <div className="generator__banner__search__component">
                <BannerWithSearchBar searchFilter={searchFilter} handleSearch={handleSearch} disableSearch={disableSearch} placeHolder="Search By Offtaker Name"/>
            </div>
            <div className="generator__contract__table__view">
                <Table 
                    columns={columns} 
                    data={contracts} 
                    setData={setContracts} 
                    showGlobalSearch={false} 
                    internalSearchFilter={searchFilter}
                    setInternalSearchFilter={setSearchFilter}
                    setDisableInternalSearch={setDisableSearch}
                    enableInternalSearch={enableSearch}
                />
            </div>
        </div>
    )
}
