import { useState,useEffect } from "react";
import "./header.css";
import UserInfoCard from "../UserInfoCard/UserInfoCard";
export default function Header({toggleSideNav, isCollapsed, user}) {
    const [showInfo, setShowInfo] = useState(false);
    const [collapsed, setCollapsed] = useState(false)
    const [userIcon,setUserIcon] = useState('');
    useEffect(()=>{
        let userIcon = '';
        const userName = user.userName;
        if(userName){
            const userArray  = userName.split(' ');
            if(userArray.length >= 2){
                userIcon = userArray[0].charAt(0) + userArray[userArray.length-1].charAt(0);
                setUserIcon(userIcon); 
            } else {
                userIcon = userArray[0].charAt(0);
                setUserIcon(userIcon); 
            }
        }
    },[user])

    const handleToggle=()=>{
        setShowInfo(prev => !prev);
    }

    const handleClick=(event)=>{
        event.stopPropagation();
        setShowInfo(prev => !prev);
    }

    return (
        <>
            <div className="header__container">
                    <div className="brand__option">
                        <img className="collapsible__option" onClick={() => toggleSideNav(isCollapsed)} src={'/icons/hamburger.png'} alt="collapse__menu" />
                        <div className="ppa__brand">
                            <img className="ppa__logo__img" src={ '/ey_logo.png'} alt="ppa__logo" />
                            <p className="ppa__brand__caption">Corporate PPA Platform</p>
                        </div>
                    </div>
                <div className="user__info">
                    {/* <div className='user__notificaiton'>
                    <img  src={'/icons/notification.svg'}  alt="notify__icon" />
                        <div className='user__notify'>
                            4
                        </div>
                    </div> */}
                    <div className='user__avatar' onClick={ handleToggle }><p>{userIcon.toUpperCase()}</p></div>
                </div>
            </div>
           {showInfo && <UserInfoCard onClickOutside={handleClick} user={user} userIcon={userIcon}/>}
        </>
    )
}