import { useEffect, useState } from "react"
import "./adminModifyContractConfirmationModal.css"
import { HighlightFigure } from "../General/DataEntry/HighlightFigure/HighlightFigure"

export default function AdminModifyContractConfirmationModal({title="", contract, setShowScuccess, toggleModal}){
    console.log(`the contract received on Admin modify confirmation is ${JSON.stringify(contract, null, 2)}`)


    const handleConfirmation = () => {
        setShowScuccess(true)
        toggleModal(false)
    }

    useEffect(() => {
        // todo: call API to store the updated contract params.
    }, [])

    return (
        <>
            <div className="modal__wrapper"></div>
        <div className="admin__contract__modify__confirmation__modal__container ">
                <div className="admin__confirmation__modal__header">
                    <p>{title}</p>
                    <p onClick={() => toggleModal(false)}>X</p>
                </div>
                <div className="admin__modify__contract__content">
                    <div className="admin__modify__confirmation__left">
                        <div className="admin__modify__contract_col_1">
                            <HighlightFigure label="Contract title" value={contract["title"]} color="grey"/>
                            <HighlightFigure label="Contract description" value={contract["description"]} color={"grey"} />
                        </div>
                    </div>
                    
                    <div className="admin__modify__contract_col_2">
                        <div className="admin__modify__confirmation__right">
                                <HighlightFigure label="Strike price" value={contract["strikePrice"]} color="grey"/>
                                <HighlightFigure label="Volume" value={contract["volume"]} color="grey"/>
                                <HighlightFigure label="Duration" value={contract["duration"]} color="grey"/>
                                <HighlightFigure label="Terms of payment" value={contract["payment"]} color="grey"/>
                        </div>
                    </div>
                </div>
                <div className="admin__confirmation__modal__footer__options">
                    <button className="admin__confirmation__footer__forward" onClick={() => handleConfirmation()}>Modify</button>
                    <button className="admin__confirmation__footer__cancel" onClick={() => toggleModal(false)}>Cancel</button>
                </div>
        </div>
        
        </>
    )

}