import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createColumnHelper } from '@tanstack/react-table'
import axios from '../../interceptors/axiosInstance';
import { Table } from "../Table/Table";
import { config } from "../../config";
import { LoaderContext } from "../../context/LoaderContext";
import { pascalCase } from "../../utils/display";
import './userRequests.css'
import { MessageContext } from "../../context/MessageContext";


export default function UserRequest(){
    
    const [users, setUsers] = useState([]);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);
    
    const navigate = useNavigate();

    const fetchAllUsers = async () => {
        try{
            setLoading(true);
            const res = await axios.get(`${config.BASE_URL}/api/support`);
            setUsers(res.data);
            setLoading(false);
        }
        catch(error){
            setLoading(false);
            setUsers([]);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }      
    }

    const goToRecord = (content) =>{
        const type = content.original.requestType;
        let url;
        if(!(content.original.status === "Approved" || content.original.status === "Rejected" )){
            if(type === 'add_user'){
                url = '/manage-users/add'
            } else if(type === 'remove_user'){
                url = '/manage-users/remove'
            } else if(type === 'modify_user'){
                url = '/manage-users/modify'
            } else{
                url = '/ppa-valuations'
            }
            navigate(url, {state: {id: content.original._id}});
        }
    }
        
    useEffect(()=>{
        fetchAllUsers();
    },[])

    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('_id', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Request ID',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('requestType', {
            cell: info => pascalCase(info.getValue(),'_'),
            footer: info => info.column.id,
            header: 'Request Type',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('name', {
            cell: info => `${info.getValue()}`,
            footer: info => info.column.id,
            header: 'Name',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('email', {
            cell: info => `${info.getValue()}`,
            footer: info => info.column.id,
            header: 'Email',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('phoneNumber', {
            cell: info => `${info.getValue()}`,
            footer: info => info.column.id,
            header: 'Phone Number',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('message', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Message',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('status', {
            cell: info => pascalCase(info.getValue()),
            footer: info => info.column.id,
            header: 'Status',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('', {
            cell: props => <>
            { 
                (props.row.original.status === "pending" && props.row.original.requestType !== "other") &&
                <img className="view-invoice-icon" src={"/icons/success.svg"} alt="View" onClick={() => {goToRecord(props.row)}} />
            }
            </>,
            footer: info => info.column.id,
            header: 'Action',
            enableGlobalFilter: false,
        }),
    ];

    const approvedRequest = users.filter((user) => {
        return user.status === 'Approved';
      });

    const pendingRequests = users.filter((user) => {
        return user.status === 'pending';
      });

    return (
        <div className="invoice-list-container">
            <div className="invoice-count-container">
                <div className="invoice-count">
                    <div className="invoice-count-text-container">
                        <span className="invoice-count-text">Completed Requests</span>
                        <img src={"/icons/success.svg"} alt="success"/>
                    </div>
                    <span className="invoice-count-text invoice-count-value">{approvedRequest.length}</span>
                </div>
                <div className="invoice-count">
                    <div className="invoice-count-text-container">
                        <span className="invoice-count-text">Open Requests</span>
                        <img src={"/icons/failed.svg"} alt="failed"/>
                    </div>
                    <span className="invoice-count-text invoice-count-value">{pendingRequests.length}</span>
                </div>
            </div>
            <div className="invoice-list">
            <div className="user-request-title">Manage Support Request</div>
                <div className="user-request-table-container">
                <Table columns={columns} data={users} setData={setUsers} placeholder="Search by Name or Email"/>
                </div>
            </div>
        </div>
    )
}