import { useState, useEffect, useContext } from "react";

import axios from "../../../interceptors/axiosInstance";
import HorizontalTabNavigation from "../../../components/HorizontalTabNavigation";
import { ModifyContract } from "./ModifyContract/ModifyContract";
import { LoaderContext } from "../../../context/LoaderContext";
import { config } from '../../../config';
import "./manageContract.css";
import { MessageContext } from "../../../context/MessageContext";


export default function ManageContract() {
    const [activeTab, setActiveTab] = useState('view-contract');
    const [contracts, setContracts] = useState([]);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    useEffect(() => {
        fetchContracts();
    }, []);

    const fetchContracts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.BASE_URL}/api/contract`);
            setContracts(response.data);
            setLoading(false);
        } catch(error) {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }        
    }

    const handleChange = (tab) => {
        setActiveTab(tab.id)
    }

    const tabs = [
        {id: 'view-contract', label: 'View Contract'},
        {id: 'terminate-contract', label: 'Terminate Contract'},
        // {id: 'delete-contract', label: 'Delete contract'}
    ]

    return(
        <div className="tabs__container__box">
            <HorizontalTabNavigation tabs={tabs} initialTab={activeTab} onTabChange={handleChange} disableSearch={false}/>
            {activeTab === 'view-contract' &&  <ModifyContract title="View" contracts={contracts} setContracts={setContracts}/>}
            {activeTab === 'terminate-contract' &&  <ModifyContract title="Terminate" contracts={contracts} setContracts={setContracts} fetchContracts={fetchContracts} />}
            {/* {activeTab === 'delete-contract' &&  <ModifyContract title="Delete"/>} */}
        </div>
    )
}