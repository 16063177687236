import React, { useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";

import axios from "../../../interceptors/axiosInstance";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import SuccessModal from "../../../components/SuccessModal";
import { LoaderContext } from "../../../context/LoaderContext";
import { config } from "../../../config";
import "./DataUpload.css";
import { MessageContext } from "../../../context/MessageContext";

const dataTypes = [
    { key:'', value: 'Select Type of Data'},
    { key: 'volume', value:'Volume' },
    { key: 'spotPrice', value:'Spot Price' },
    { key: 'mlf', value:'MLF' },
    { key: 'lgc', value:'LGC/REC' },
    { key: 'forecastData', value:'Power Price Forecast' }
];

export const DataUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [showSuccess, setShowScuccess] = useState(false);
    const [error, setError] = useState(false);
    const [uploadDataType, setUploadDataType] = useState('');
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const onDrop = useCallback(files => {
        if(files.length > 5){
            ErrorToast("Cannot upload more than 5 files");
        } else if (files.length > 0) {
            setSelectedFiles(files);
            const reader = new FileReader();
            reader.onload = () => {
                setLoading(true);
            }
            reader.onloadend = () =>{
                setLoading(false);
            }
        }
      }, [])

      const onDropRejected = useCallback(fileRejections=>{
        if(fileRejections.length > 0 && fileRejections[0] && fileRejections[0].errors && fileRejections[0].errors[0]){
            ErrorToast(fileRejections[0].errors[0].message)
        }
      })

    const { fileRejections, getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'text/csv': ['.csv'],
            'application/xml': ['.xml']
        }
    });

    const removeFile = (event,file) =>{
        console.log(selectedFiles[0].name,file.name)
        const result = selectedFiles.filter(item=> item.name!=file.name);
        setSelectedFiles(result);
        
    }

    const setDropDownValue = (value) =>{
        setUploadDataType(value)
    }

    const onUploadProgress= (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
    }

    const handleSubmit = async () => {
        try {
            if(selectedFiles && uploadDataType) {
                setError(false);
                setProgress(0);
                let url = '';
                if(uploadDataType === 'volume'){
                    url = 'api/aemo/upload-volume';
                } else if(uploadDataType === 'spotPrice'){
                    url = 'api/aemo/upload-spot-price';
                } else if(uploadDataType === 'mlf'){
                    url = 'api/aemo/upload-mlf';
                } else if(uploadDataType === 'forecastData'){
                    url = 'api/aemo/upload-forecast-data';
                } else {
                    url = 'api/aemo/upload-lgc';
                }
                let formData = new FormData();
                selectedFiles.map(file =>{
                    formData.append("aemoFile", file);
                })
                setLoading(true);
                await axios.post(`${config.BASE_URL}/${url}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress,
                });
                setLoading(false);
                setShowScuccess(true);
                setProgress(0);
            } else {
                if(!selectedFiles) {
                    ErrorToast('Upload a file')
                }
                
                setError(true);
                setLoading(false);
                console.log('All required data not provided');
            }
        } catch (error) {
            setProgress(0);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
            setLoading(false)
        }
        setSelectedFiles([]);
        setUploadDataType('');
    }

    const resetForm = () => {
        setError(false);
        setProgress(0);
        setUploadDataType('');
        setSelectedFiles([]);
    }

    return (
        <>
            <div className="admin-upload-data-container">
                <FileUpload 
                    showDropdown={true}
                    dropDownHeading="Select Data Type"
                    dropDownValues={dataTypes}
                    selectedDropDownValueValue={uploadDataType}
                    setSelectedDropDownValue = {setDropDownValue}
                    getRootProps={getRootProps} 
                    getInputProps={getInputProps} 
                    header="Upload Data" 
                    infoText="File format supported: Files can include XML and .CSV. "
                />
                { selectedFiles.length > 0 && selectedFiles.map(file=>{
                    return (
                        <div className="admin-upload-data-active-data-container">
                            <div className="data-meta-container">
                                <p className="data-path">
                                    <img className="data-file-icon" src={"/icons/file_16x16.svg"} alt="file"/>
                                    <span>{file.path}</span>
                                </p>
                                <img className="data-close-icon" onClick={(event)=>{removeFile(event,file)}} src={"/icons/close_24x24.svg"} alt="file"/>
                            </div>
                            { progress !== 0  && (<progress value={progress} max="100">{progress}%</progress>) }
                        </div>
                    )
                })}
                {
                    error && (
                        <div class="upload-error-container">
                            <img className="error-icon" src={"/icons/error_24x24.svg"} alt="error"/>
                            <span>Something went wrong during upload.</span>
                        </div>
                    )
                }
            </div>
            <div className="file-upload-actions">
                <button  
                    className="confirm-button" 
                    onClick={handleSubmit}
                    disabled={!(selectedFiles.length > 0 && uploadDataType)}
                >
                    Upload
                </button>
                <button className="cancel-button"  onClick={resetForm}>Cancel</button>
            </div>
            {
                showSuccess ? <SuccessModal title="Data uploaded" message="Do you want to upload another data?" toggleModal={setShowScuccess}/>: null 
            }
        </>
    )
}