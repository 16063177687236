import React, { useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";

import axios from "../../../interceptors/axiosInstance";
import { FileUpload } from '../../../components/FileUpload/FileUpload';
import SuccessModal from "../../../components/SuccessModal";
import { config } from '../../../config'
import { LoaderContext } from "../../../context/LoaderContext";
import "./UploadInvoice.css";
import { MessageContext } from "../../../context/MessageContext";


export const UploadInvoice = ({contract, invoiceId='', setInvoice}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [showSuccess, setShowScuccess] = useState(false);
    const [error, setError] = useState(false);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const onDrop = useCallback(files => {
        if (files.length > 0) {
            setSelectedFiles(files);
            setCurrentFile(files[0]);
            const reader = new FileReader();
            reader.onload = () => {
                setLoading(true);
            }
            reader.onloadend = () =>{
                setLoading(false);
            }
        }
      }, [])

      const onDropRejected = useCallback(fileRejections=>{
        if(fileRejections.length > 0 && fileRejections[0] && fileRejections[0].errors && fileRejections[0].errors[0]){
            ErrorToast(fileRejections[0].errors[0].message)
        }
      })

    const { fileRejections, getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'text/csv': ['.csv'],
            'application/xml': ['.xml']
        }
    });

    const onUploadProgress= (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
    }

    const handleSubmit = async () => {
        try {
            if(currentFile) {
                setLoading(true);
                setError(false);
                setProgress(0);

                let formData = new FormData();

                formData.append("invoiceFile", currentFile);
                formData.append("contractId", contract._id);

                if(invoiceId){
                    formData.append("retryInvoiceId", invoiceId);
                }

                await axios.post(`${config.BASE_URL}/api/invoice/upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress,
                });
                setShowScuccess(true);
                setCurrentFile(undefined);
                setProgress(0);
                setLoading(false);
                setInvoice('')
            } else {
                if(!currentFile) {
                    ErrorToast('Upload a file')
                }
                
                setError(true);
                console.log('All required data not provided');
            }
        } catch (error) {
            setLoading(false);
            setProgress(0);
            setCurrentFile(undefined);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
        setSelectedFiles([]);
    }

    const resetForm = () => {
        setError(false);
        setProgress(0);
        setCurrentFile(undefined);
        setInvoice('');
    }

    const removeFile = () =>{
        setCurrentFile(undefined);
        setSelectedFiles([]);
    }

    return (
        <>
            <div className="upload-invoice-container">
                <FileUpload 
                    getRootProps={getRootProps} 
                    getInputProps={getInputProps} 
                    header={invoiceId ? "Retry Invoice Upload" : "Upload Invoice"} 
                    infoText="File format supported: Files can include XML and .CSV."
                />
            </div>
            { currentFile && (
                <div className="active-invoice-container">
                    <div className="invoice-meta-container">
                        <p className="invoice-path">
                            <img className="invoice-file-icon" src={"/icons/file_16x16.svg"} alt="file"/>
                            <span>{currentFile.path}</span>
                        </p>
                        <img className="invoice-close-icon" onClick={removeFile} src={"/icons/close_24x24.svg"} alt="file"/>
                    </div>
                    { progress !== 0  && (<progress value={progress} max="100">{progress}%</progress>) }
                </div>
                
            )}
            {
                error && (
                    <div class="upload-error-container">
                        <img className="error-icon" src={"/icons/error_24x24.svg"} alt="error"/>
                        <span>Something went wrong during upload.</span>
                    </div>
                )
            }
            <div className="file-upload-actions">
                <button  
                    className="confirm-button" 
                    onClick={handleSubmit}
                    disabled={!currentFile}
                >
                    Upload
                </button>
                <button className="cancel-button"  onClick={resetForm}>Cancel</button>
            </div>
            {
                showSuccess ? <SuccessModal title="Invoice uploaded" message="Do you want to upload another invoice?" toggleModal={setShowScuccess}/>: null 
            }
        </>
    )
}