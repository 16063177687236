import { useContext, useEffect, useState } from "react";
import HorizontalTabNavigation from "../../../../components/HorizontalTabNavigation";
import "./contractDetailedTabView.css"
import EditContract from "../EditContract/editContract";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../interceptors/axiosInstance";
import PowerBI from "../../../../components/PowerBI";
import ClientOverview from "../../ClientOverview";
import { InvoiceReconciliation } from "../../InvoiceReconviliation/InvoiceReconciliation";
import { UploadInvoice } from "../../UploadInvoice/UploadInvoice";
import { config } from '../../../../config';
import { LoaderContext } from "../../../../context/LoaderContext";
import PowerBIReport from "../../../../components/PowerBIReport";
import { MessageContext } from "../../../../context/MessageContext";


export default function ContractDetailedTabView() {
    const navigate =  useNavigate();
    const [activeTab, setActiveTab] = useState('tab1');
    const [tabTitle, setTabtitle] = useState("");
    const [selectedContract, setSelectedContract] = useState({})
    const { setLoading } = useContext(LoaderContext);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoiceId, setInvoiceId] = useState('');
    const {state} = useLocation();
    const { ErrorToast } = useContext(MessageContext);
    let contractID= '';

    const handleChange = (tab) => {
        setActiveTab(tab.id);
        setTabtitle(tab.label);
        setSelectedInvoice(null);
    }

    const fetchSelectedContract = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${config.BASE_URL}/api/contract/${contractID}`)
            setSelectedContract(res.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setSelectedContract({})
            ErrorToast('Something Went Wrong!. Please Try Again')
        }
    }

    useEffect(() => {
        if(!state || !state.contractID){
            navigate('/contracts');
        } else {
            contractID = state.contractID;
            fetchSelectedContract();
        }
    },[])

    const navigateToInvoiceUpload = (id) => {
        if(id){
            setInvoiceId(id);
            setActiveTab('upload-invoice');
            setTabtitle('Upload Invoice');
        }
    }

    const navigateBack = () => {
        if(activeTab != 'tab3'){
            navigate(-1);
        } else {
            if(!selectedInvoice){
                navigate(-1);
            } else {
                setSelectedInvoice(null)
            }
        }
    }

    const selectInvoice = (item) =>{
        setSelectedInvoice(item);
    }

    const tabs = [
        {id: 'tab1', label: 'Overview'},
        {id: 'tab2', label: 'View / Edit Contract'},
        {id: 'tab3', label: 'Invoice Reconciliation'},
        // {id: 'tab4', label: 'Client Profile'},
        { id: 'upload-invoice', label: 'Upload Invoice'},
    ]

    return (
        <div className="generator__contract__details__wrapper">
            <div className="backbutton">
                <button className="back__button" onClick={navigateBack}>Back</button>
            </div>
            <div className="generator-contract-tabs">
                {activeTab === 'tab1' && <h1 className="contract__module__title"></h1>}
                <HorizontalTabNavigation tabs={tabs} initialTab={activeTab} onTabChange={handleChange} disableSearch={true} />
                {activeTab === 'tab1' && <PowerBIReport showTab={false} type="Multi_Tenancy_Contract_View" contractTitle={selectedContract.contractTitle}/>}
                {activeTab === 'tab2' && <EditContract contract={selectedContract} />}
                {activeTab === 'tab3' && <InvoiceReconciliation onInvoiceRetrySelected={navigateToInvoiceUpload} contract={selectedContract} role={"generator"} invoiceSelected={selectInvoice} selectedInvoice={selectedInvoice}/>}
                {/* {activeTab === 'tab4' &&   <ClientOverview contract={selectedContract}/>} */}
                {activeTab === 'upload-invoice' && <UploadInvoice contract={selectedContract} invoiceId={invoiceId} setInvoice={setInvoiceId}/>}
            </div>
        </div>
    )
}