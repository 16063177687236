import { useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import "./userinfo.css"

export default function UserInfoCard({user, userIcon, onClickOutside}) {
    const ref = useRef(null);
    const { instance } = useMsal();
    const signOut = () =>{
        instance.logoutRedirect();
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside && onClickOutside(event);
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [ onClickOutside ]);

    return(
        <>
        { user && (
            <div ref={ref} className="user__info__container">
                <div className="user__info__avatar__name">
                    <div className='user__avatar'><span>{userIcon.toUpperCase()}</span></div>
                    <p className="user__full__name">{user.userName}</p>
                </div>
                <div className="user__info__email">
                    <p className="user__email">{user.email}</p>
                    <p className="user__role">{user.role ? user.role: user.accessLevel}</p>
                </div>
                <div className="user__info__signout__opt">
                    <img  src={'/icons/logout.svg'}  alt="logout__icon" />
                    <p onClick={signOut}>Sign out</p>
                </div>
            </div>
        )}
     </>
    ) 
}