import "./ViewInvoice.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import axios from '../../../../interceptors/axiosInstance';
import { config } from '../../../../config'
import { LoaderContext } from "../../../../context/LoaderContext";
import { MessageContext } from "../../../../context/MessageContext";

export const InvoiceReconciliationDetails =() =>{
    const {state} = useLocation();
    const [invoice, setInvoice] = useState([]);
    const [client, setClient] = useState([]);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);
    const navigate = useNavigate()
    const invoiceId = state
    let invoice_info


    const fetchInvoice = async (invoiceId) => {
        
        try{
            setLoading(true);
            let url = `${config.BASE_URL}/api/invoice/${invoiceId}`
            const res = await axios.get(url);
            setInvoice(res.data)
            setLoading(false);
        }catch(error){
            setLoading(false);
            setInvoice([]);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
        
    }

    const fetchClient = async (clientId) => {
        try{
            setLoading(true)
            let url = `${config.BASE_URL}/api/client/${clientId}`
            const res = await axios.get(url);
            setClient(res.data)
            setLoading(false)
        }catch(error){
            setLoading(false)
            setClient([])
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }        
    }

    const handleBackClick = () => {
        navigate(-1);
      };

    useEffect(() => {
        fetchInvoice(invoiceId.content)
        fetchClient("64c0fe29eea8673f6bd18865")
    }, []);

    const client_info = [
        {
            label: "Location",
            icon : "/icons/add_location.svg",
            details: [
                client.location
            ]
        },
        {
            label: "Contact",
            icon: "/icons/contact.svg",
            details: [
                client.contactName,
                client.contactRole,
                "Phone:" + client.contactPhone
            ]
        },
        {
            label: "Invoice status",
            icon: "/icons/info.svg",
            details: [
                invoice.status
            ]
        },
        {
            label: "Date of issue",
            icon: "/icons/add_location.svg",
            details: [
                invoice.issueDate
            ]
        }
    ]
    if(invoiceId.viewRole == "Offtaker"){
        invoice_info = [
            {
                label: "Location",
                info: invoice.location
            },
            {
                label: "Volume",
                info: invoice.volume + " MWh"
            },
            {
                label: "Offtakers compensation",
                info: "$" + invoice.offtakersCompensation + " AUD"
            },
            {
                label: "Strike price",
                info: "$" +invoice.strikePrice + " AUD"
            },
            {
                label: "Total fixed amount",
                info: "$" + invoice.totalFixedAmount + " AUD"
            },
            {
                label: "Total floating amount",
                info: "$" + invoice.totalFloatingAmount
            },
            {
                label: "Net Amount",
                info: "$" +invoice.netAmount + " AUD"
            },
            {
                label: "Cash Receivable from Generator",
                info: "$" + invoice.cashReceivableGenerator + " AUD"
            },
            {
                label: "Cash Receivable from Offtaker",
                info: "$" + invoice.cashReceivableOfftaker + " AUD"
            },
            {
                label: "Eligible LGCs",
                info: invoice.eligibleLGCs
            }
        ]
    }else{
        invoice_info = [
            {
                label: "Location",
                info: invoice.location
            },
            {
                label: "Volume",
                info: invoice.volume + " MWh"
            },
            {
                label: "Generators compensation",
                info: "$" + invoice.offtakersCompensation + " AUD"
            },
            {
                label: "Strike price",
                info: "$" +invoice.strikePrice + " AUD"
            },
            {
                label: "Total fixed amount",
                info: "$" + invoice.totalFixedAmount + " AUD"
            },
            {
                label: "Total floating amount",
                info: "$" + invoice.totalFloatingAmount
            },
            {
                label: "Net Amount",
                info: "$" +invoice.netAmount + " AUD"
            },
            {
                label: "Cash Receivable from Offtaker",
                info: "$" + invoice.cashReceivableGenerator + " AUD"
            },
            {
                label: "Cash Receivable from Generator",
                info: "$" + invoice.cashReceivableOfftaker + " AUD"
            },
            {
                label: "Eligible LGCs",
                info: invoice.eligibleLGCs
            }
        ]
    }

    return (
        <div className="invoice__reconcilication__wrapper">
            <button className="button__back__icon" onClick={handleBackClick}> 
            <img src="/icons/page_prev_1.svg" alt="back_btn" />
                Back
            </button>
            <div className="client__general__info">
                <div className="client__info__header">
                    <div className="client__name">
                        <p className="name"><span>Client name:</span> {client.clientName}</p>
                    </div>
                    <div className="invoice__info">
                        <p className="name"><span>Invoice ID:</span> #3456789121</p>
                    </div>
                </div>
                <div className="client__info__content__container">
                    {client_info.map((client,index) => (
                            <div className="client__info__card__container" key={index}>
                                <div className="client__attribute__header">
                                    <img src={client.icon} alt="header__icon" />
                                    <p className="client__label">{client.label}</p>
                                </div>
                                <div className="client__attribute__value__container">
                                    {
                                    client.details.map(detail => (
                                            <p className="client__attribute__lines">
                                                <span className={detail === 'Pass' ? 'passed' : detail === 'Failed' ? 'failed': ''}>{detail}</span>
                                            </p>
                                    ))
                                    }
                                </div>
                        </div>
                    ))}
                    
                </div>

            </div>
            <div className="invoice__attribute__container">
                    {
                        invoice_info.slice(0, invoice_info.length).concat((new Array(15 - invoice_info.length).fill({"label": "-", "info": "-"}))).map((element, index) => (
                        <div className="invoice__attribute__card" key={index}>
                            <div className="invoice__attribute__name">
                                <p>{element.label}</p>
                            </div>
                            <div className="invoice__attribute__value">
                                <p>{element.info}</p>
                            </div>
                        </div>
                        ))
                    }
                        
            </div>
            <div className="section__divider black">

            </div>
            <div className="download__button">
                    <button>Download Invoice</button>
            </div>
        </div>
    )
}