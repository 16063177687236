import 'regenerator-runtime';
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { Chart } from "primereact/chart";
import React, { useContext, useEffect, useRef, useState } from "react";

import PPAContext from "../../../../contexts/PPA/PPAContext";
import { existingPPASenarioList,CountryCurrencyList } from "../../../../utils/PPA/PPAMasterList";
import "../Style/PPAStyles.css";
import { pascalCase } from '../../../../utils/display';
import { MessageContext } from "../../../../context/MessageContext";

export default function ExistingPPAValuationResult(props) {
  const [QDisCountedCFChart, setQDisCountedCFChart] = useState({});
  const [UnDiscountedCFChart, setUnDiscountedCFChart] = useState({});
  const [benchmarkDiscountedChart, setBenchmarkDiscountedChart] = useState({});
  const [ChartOptions, setChartOptions] = useState({});
  const [ChartYear, setChartYear] = useState("All");
  const [ChartScenario, setChartScenario] = useState("All");
  const[currency, setCurrency] = useState("EUR");
  const [valuationYears, setValuationYears] = useState([]);
  const { resultData, SetActiveTabId, SetFormData, formData } = useContext(PPAContext);
  const { ErrorToast } = useContext(MessageContext);

  const UnDiscountedChartRef = useRef(null);
  const QDisCountedChartRef = useRef(null);
  const benchmarkDiscountedChartRef = useRef(null);

  useEffect(() => {
    getCurrency();
    getValuationYears();
    configureGraphData("All", "All");
    console.log("data", formData);
  }, []);

  const navigateBack = () => {
    SetActiveTabId('3');
  }

  const getValuationYears = () => {
    const years = []
    resultData?.timeSeries?.forEach(item => {
        years.push(item.split('-')[0]);
    });
    setValuationYears([... new Set(years)]);
  }

  const getCurrency = () => {
    CountryCurrencyList.find((x) => {
      if (x.country == formData.ppaLocation) {
        setCurrency(x.currency);
      }
    });
  }

  const configureGraphData = (year, scenario) => {
    let graphData;
    if (year != "All") graphData = processGraphData(year);
    else graphData = processGraphData();

    const { 
        labels, 
        discountedCashFlowData, 
        unDiscountedCashFlowData, 
        discountedCashFlowDataWithHighTolerance, 
        unDiscountedCashFlowDataWithHighTolerance,
        discountedCashFlowDataWithLowTolerance,
        unDiscountedCashFlowDataWithLowTolerance,
        benchmarkDiscountedCashflow 
    } = graphData;

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const QDisCountedCFData = {
      labels: labels,
      datasets: [
        {
          label: "High",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "High" || scenario === "All" ? discountedCashFlowDataWithHighTolerance : null,
        },
        {
          label: "Medium",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: scenario === "Medium" || scenario === "All" ? discountedCashFlowData : null,
        },
        {
          label: "Low",
          backgroundColor: documentStyle.getPropertyValue("--orange-500"),
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          data: scenario === "Low" || scenario === "All" ? discountedCashFlowDataWithLowTolerance : null,
        },
      ],
    };

    const UnDiscountedCFData = {
      labels: labels,
      datasets: [
        {
          label: "High",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "High" || scenario === "All" ? unDiscountedCashFlowDataWithHighTolerance : null,
        },
        {
          label: "Medium",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: scenario === "Medium" || scenario === "All" ? unDiscountedCashFlowData: null,
        },
        {
          label: "Low",
          backgroundColor: documentStyle.getPropertyValue("--orange-500"),
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          data: scenario === "Low" || scenario === "All" ? unDiscountedCashFlowDataWithLowTolerance : null,
        },
      ],
    };

    const benchmarkDiscountedCFData = {
      labels: labels,
      datasets: [
        {
          label: "Custom",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "Custom" || scenario === "All" ? discountedCashFlowData : null,
        },
        {
          label: "EY",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: scenario === "EY" || scenario === "All" ? benchmarkDiscountedCashflow: null,
        },
      ],
    };

    const options = {
      maintainAspectRatio: true,
      aspectRatio: 0.0,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            drawBorder: true,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: true,
          },
        },
      },
    };

    setQDisCountedCFChart(QDisCountedCFData);
    setUnDiscountedCFChart(UnDiscountedCFData);
    setBenchmarkDiscountedChart(benchmarkDiscountedCFData);

    setChartOptions(options);
  }

  const getFilteredGraphData = (data, yearFilter) => {
    let filteredData = data?.map(item => {
        return {
            ...item,
            year: item?.timestamp?.split('-')[0],
        }
    });
    if(yearFilter) {
        filteredData = filteredData?.filter(item => item.year === yearFilter)
    }
    return filteredData;
  }

  const processGraphData = (yearFilter = '') => {

    const labels = [];
    const discountedCashFlowData= [];
    const unDiscountedCashFlowData = [];
    const discountedCashFlowDataWithLowTolerance = [];
    const unDiscountedCashFlowDataWithLowTolerance = [];
    const discountedCashFlowDataWithHighTolerance = [];
    const unDiscountedCashFlowDataWithHighTolerance = []; 
    const benchmarkDiscountedCashflow = [];


    const filteredDiscountedCashFlowData = getFilteredGraphData(resultData?.presentValueOfVariance, yearFilter);
    const filteredUnDiscountedCashFlowData = getFilteredGraphData(resultData?.cashflowVariance, yearFilter);
    const filteredDiscountedCashFlowDataWithLowTolerance = getFilteredGraphData(resultData?.presentValueOfVarianceWithLowTolerance, yearFilter);
    const filteredUnDiscountedCashFlowDataWithLowTolerance = getFilteredGraphData(resultData?.cashflowVarianceWithLowTolerance, yearFilter);
    const filteredDiscountedCashFlowDataWithHighTolerance = getFilteredGraphData(resultData?.presentValueOfVarianceWithHighTolerance, yearFilter);
    const filteredUnDiscountedCashFlowDataHighTolerance = getFilteredGraphData(resultData?.cashflowVarianceWithHighTolerance, yearFilter);
    const filteredBenchmarkDiscountedCashflow = getFilteredGraphData(resultData?.benchmarkPresentValueOfVariance, yearFilter);

    filteredDiscountedCashFlowData?.forEach((data, index) => {
        labels.push(data?.timestamp);
        discountedCashFlowData.push(data?.variance);
        unDiscountedCashFlowData.push(filteredUnDiscountedCashFlowData?.[index]?.cashflow);
        discountedCashFlowDataWithLowTolerance.push(filteredDiscountedCashFlowDataWithLowTolerance?.[index]?.variance);
        unDiscountedCashFlowDataWithLowTolerance.push(filteredUnDiscountedCashFlowDataWithLowTolerance?.[index]?.cashflow);
        discountedCashFlowDataWithHighTolerance.push(filteredDiscountedCashFlowDataWithHighTolerance?.[index]?.variance);
        unDiscountedCashFlowDataWithHighTolerance.push(filteredUnDiscountedCashFlowDataHighTolerance?.[index]?.cashflow);
        benchmarkDiscountedCashflow.push(filteredBenchmarkDiscountedCashflow?.[index]?.variance);
    });

    return {
        labels,
        discountedCashFlowData,
        unDiscountedCashFlowData,
        discountedCashFlowDataWithLowTolerance,
        unDiscountedCashFlowDataWithLowTolerance,
        discountedCashFlowDataWithHighTolerance,
        unDiscountedCashFlowDataWithHighTolerance,
        benchmarkDiscountedCashflow
    };
  };

  const exportResult = () => {
    saveAsExcel("Result_Export");
  };

  const newPPACalculation = () => {
    SetFormData({});
    SetActiveTabId("1");
  };

  const saveAsExcel = async (workBookName) => {
    const workbook = new Excel.Workbook();

    try {
      const fileName = workBookName;

      //create worksheet for inputs
      const inputWorkSheet = workbook.addWorksheet("Inputs");
      generateValuationInputSheet(inputWorkSheet);

      // create worksheet for result
      const resultWorksheet = workbook.addWorksheet("Result");
      generateResultWorkSheet(workbook, resultWorksheet);

      // create worksheet for unbundling
      const unbundleWorksheet = workbook.addWorksheet("Unbundle");
      generateUnbundlingWorkSheet(unbundleWorksheet);

      // create worksheet for calculations
      const calculationsWorksheet = workbook.addWorksheet("CFD");
      generateCalculationWorkSheet(calculationsWorksheet);

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
      ErrorToast(`Something Went Wrong!. Please Try Again`);
    } finally {
    }
  };

  const writeExcelSheet = (worksheet, data, addColumns = true, addRows= true) => {
    worksheet.properties.defaultRowHeight = 16;

    if(addColumns) {
      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = data.columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true, size: 12 };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 30;
        column.alignment = { horizontal: "center" };
      });
    }

    if(addRows) {
      // loop through data and add each one to worksheet
      data.rows.forEach((row) => {
        worksheet.addRow(row);
      });
    }

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      // store each cell to currentCell
      const currentCell = row._cells;
      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;
        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
  };

  const generateValuationResultData = () => {
    let columns = [
      { header: "Scenario", key: "SC" },
      { header: "NPV", key: "NPV" },
      { header: "NPV per MWh", key: "NPVperMWh" },
    ];

    let rows = [
      {
        SC: "LOW",
        NPV: `${resultData?.totalValuationWithLowTolerance?.toLocaleString("en")} ${currency}`, 
        NPVperMWh: `${resultData?.lowValuationPerMWh} ${currency}`,
      },
      {
        SC: "MEDIUM",
        NPV: `${resultData?.totalValuation?.toLocaleString("en")} ${currency}`, 
        NPVperMWh: `${resultData?.valuationPerMWh} ${currency}`,
      },
      {
        SC: "HIGH",
        NPV: `${resultData?.totalValuationWithHighTolerance?.toLocaleString("en")} ${currency}`, 
        NPVperMWh: `${resultData?.highValuationPerMWh} ${currency}`,
      },
    ];

    if(resultData?.performBenchmarkCalculations) {
      rows.push({
        SC: "EY",
        NPV: `${resultData?.benchmarkTotalValuation?.toLocaleString("en")} ${currency}`, 
        NPVperMWh: `${resultData?.benchmarkValuationPerMWh} ${currency}`,
      })
    }

    return { rows, columns };
  };

  const generateResultWorkSheet = (workbook, worksheet) => {
    const data = generateValuationResultData();
    writeExcelSheet(worksheet, data);

    const UnDiscountedChart = workbook.addImage({
      base64: UnDiscountedChartRef.current.getBase64Image(),
      extension: "png",
    });

    const QDisCountedChart = workbook.addImage({
      base64: QDisCountedChartRef.current.getBase64Image(),
      extension: "png",
    });

    let benchmarkChart = '';
    if(resultData?.performBenchmarkCalculations) {
      benchmarkChart = workbook.addImage({
        base64: benchmarkDiscountedChartRef.current.getBase64Image(),
        extension: "png",
      });
    }

    if(formData?.valuationAtInception === 'yes') {
      const a6 = worksheet.getCell("A6");
      a6.value = "Calibration Adjustment";
      a6.alignment = { horizontal: "center" };
      a6.font = { bold: true };

      const b6 = worksheet.getCell("B6");
      b6.value = resultData?.calibrationAdjustment;
      b6.alignment = { horizontal: "center" };
    }

    worksheet.mergeCells("G4:J4");
    worksheet.getCell("G4").value = "Undiscounted Cashflow";
    worksheet.getCell("G4").alignment = { horizontal: "center" };
    worksheet.getCell("G4").font = { bold: true, size: 14 };
    worksheet.getCell("G4").height = 20

    worksheet.addImage(UnDiscountedChart, "G5:AA35");

    worksheet.mergeCells("G39:J39");
    worksheet.getCell("G39").value = "Cumulative Discounted Cashflow";
    worksheet.getCell("G39").alignment = { horizontal: "center" };
    worksheet.getCell("G39").font = { bold: true, size: 14 };
    worksheet.getCell("G39").height = 20

    worksheet.addImage(QDisCountedChart, "G40:AA70");

    if(resultData?.performBenchmarkCalculations) {
      worksheet.mergeCells("G74:J74");
      worksheet.getCell("G74").value = "Discounted Cashflow - EY vs Custom";
      worksheet.getCell("G74").alignment = { horizontal: "center" };
      worksheet.getCell("G74").font = { bold: true };

      worksheet.addImage(benchmarkChart, "G75:AA105");
    }

  };

  const generateValuationInputSheet = (worksheet) => {
    //  let data = GenerateTableDataForInput();
    // writeExcelSheet(workbook, worksheet, data);
    let data = {};
    data.columns = [
      { header: "Input", key: "input" },
      { header: "Value", key: "value" },
    ];

    let inputRows = [
      {
        input: "Valuation Date",
        value: formData?.valuationDate,
      },
      {
        input: "Valuation At Inception",
        value: pascalCase(formData?.valuationAtInception),
      },
      {
        input: "Calibration Adjustmentration",
        value: resultData?.calibrationAdjustment,
      },
      {
        input: "Model Start Date",
        value: formData?.modelStartDate,
      },
      {
        input: "Start Of Agreement",
        value: formData?.contractStartDate,
      },
      {
        input: "End Of Agreement",
        value: formData?.contractEndDate,
      },
      {
        input: "Valuation Freequency",
        value: pascalCase(formData?.valuationFreequency),
      },
      {
        input: "PPA Allocation to Counterparty",
        value: Number(formData?.counterPartyAllocation),
      },
      // {
      //   input: "DLF",
      //   value: Number(formData?.DLF),
      // },
      // {
      //   input: "MLF",
      //   value: Number(formData?.MLF),
      // },
      {
        input: "PPA Pricing",
        value: pascalCase(formData?.ppaPricing,'_'),
      }
    ];

    if(formData?.ppaPricing !== 'custom') {
      inputRows.push({
        input: "PPA Price",
        value: Number(formData?.ppaPrice),
      })
    }

    inputRows.push({
      input: "Changing Volume In PPA",
      value: pascalCase(formData?.changingVolumeInPPA),
    });

    if(formData?.changingVolumeInPPA === 'no') {
      inputRows.push({
        input: "Annual PPA Volume Capacity",
        value: Number(formData?.annualPPAVolumeCapacity),
      });
    }

    inputRows = [
      ...inputRows,
      {
        input: "Unbundling Approach",
        value: pascalCase(formData?.unbundlingApproach, '_'),
      },
      {
        input: "Curtailment Factor",
        value: Number(formData?.curtailmentFactor),
      },
      {
        input: "RET Scheme End Date",
        value: formData?.RETSchemeEndDate,
      },
      {
        input: "Excess Cashflow Difference Factor",
        value: Number(formData?.excessCashflowDifferenceFactor),
      },
      {
        input: "Low Tolerance",
        value: Number(formData?.lowTolerance),
      },
      {
        input: "High Tolerance",
        value: Number(formData?.highTolerance),
      },
    ]

    data.rows = inputRows;
    writeExcelSheet(worksheet, data);
    // worksheet.addRows(inputRows);
  };

  const extractDataWithForecastedAndActualValues = (data, title) => {
    let rows = [];
    const forecastValueRow = data?.reduce((accumulator, item) => {
      accumulator[item?.timestamp] = item?.forecastedValue || '';
      accumulator['type'] = 'Forecasted Value';
      accumulator['name'] = title;
      return accumulator;
    }, {});

    const actualValueRow = data?.reduce((accumulator, item) => {
      accumulator[item?.timestamp] = item?.actualValue || '';
      accumulator['type'] = 'Actual Value'
      accumulator['name'] = ''
      return accumulator;
    }, {});

    rows.push(forecastValueRow);
    rows.push(actualValueRow);

    return rows;
  }

  const extractCalculatedRowData = (data, title, key) => {
    let rows = [];
    const calculatedRow = data?.reduce((accumulator, item) => {
      accumulator[item?.timestamp] = item?.[key] || '';
      accumulator['type'] = '#';
      accumulator['name'] = title;
      return accumulator;
    }, {});

    rows.push(calculatedRow);

    return rows;
  }

  const getEmptyValuesForTimestamps = (name, type='') => {
    return resultData?.timeSeries?.reduce((accumulator, timestamp) => {
      accumulator['name']= name;
      accumulator['type']= type;
      accumulator[timestamp]= '';
      return accumulator;
    }, {});
  }

  const generateUnbundlingWorkSheet = (worksheet) => {

    let columns = resultData?.timeSeries.map(timestamp => {
      return {
        header: timestamp,
        key: timestamp
      };
    });

    columns = [
      { header: 'Name', key: 'name'},
      { header: 'Value Type', key: 'type'},
      ...columns
    ];


    let calculateCustomBlackMarketRevenue = false;
    let calculateCustomGreenMarketRevenue = false;

    if(formData?.valuationAtInception === 'yes') {
      if(formData?.blackMarketPrice === 'yes') {
        const blackMarketPriceAtInceptionRowData = extractDataWithForecastedAndActualValues(resultData?.blackMarketPriceCurveAtInception, 'Custom Benchmark - Inception black market price');
        writeExcelSheet(worksheet, { columns, rows: blackMarketPriceAtInceptionRowData });
        worksheet.addRow({});

        calculateCustomBlackMarketRevenue = true;
      }

      const benchmarkBlackMarketPriceAtInceptionRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkBlackMarketPriceCurve, 'EY Benchmark -  Inception black market price');
      writeExcelSheet(worksheet, { columns, rows: benchmarkBlackMarketPriceAtInceptionRowData });
      worksheet.addRow({});
    } else {
        const blackMarketPriceAtInceptionRowData = extractDataWithForecastedAndActualValues(resultData?.blackMarketPriceCurveAtInception, 'Custom Benchmark - Inception black market price');
        writeExcelSheet(worksheet, { columns, rows: blackMarketPriceAtInceptionRowData });
        worksheet.addRow({});
    }

    if(formData?.valuationAtInception === 'yes') {
      if(formData?.greenMarketPrice === 'yes') {
        const greenMarketPriceAtInceptionRowData = extractDataWithForecastedAndActualValues(resultData?.greenMarketPriceCurveAtInception, 'Custom Benchmark - Inception LGC market price');
        writeExcelSheet(worksheet, { rows: greenMarketPriceAtInceptionRowData }, false);
        worksheet.addRow({});

        calculateCustomGreenMarketRevenue = true;
      }

      const benchmarkGreenMarketPriceAtInceptionRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkGreenMarketPriceCurve, 'EY Benchmark - Inception LGC market price');
      writeExcelSheet(worksheet, { rows: benchmarkGreenMarketPriceAtInceptionRowData }, false);
      worksheet.addRow({});
    } else {
      const greenMarketPriceAtInceptionRowData = extractDataWithForecastedAndActualValues(resultData?.greenMarketPriceCurveAtInception, 'Custom Benchmark - Inception LGC market price');
      writeExcelSheet(worksheet, { rows: greenMarketPriceAtInceptionRowData }, false);
      worksheet.addRow({});
    }

    const volumeCurveRowData = extractDataWithForecastedAndActualValues(resultData?.volumeCurve, 'Generation volume sent out');
    writeExcelSheet(worksheet, { rows: volumeCurveRowData }, false);
    addBlankRows(worksheet);

    // Check whether custom DLF curve has been provided
    if(formData?.uploadDLFCurve === 'yes') {
      const dlfCurveRowData = extractDataWithForecastedAndActualValues(resultData?.dlfCurve, 'Custom Benchmark - LGC Adjustment (DLF)');
      writeExcelSheet(worksheet, { rows: dlfCurveRowData }, false);
      calculateCustomGreenMarketRevenue = true;
    }

    const benchmarkDLFCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkDLFCurve, 'EY Benchmark - LGC Adjustment (DLF)');
    writeExcelSheet(worksheet, { rows: benchmarkDLFCurveRowData }, false);
    addBlankRows(worksheet);

    if(formData?.uploadDLFCurve === 'yes') {
      const volumeCurveWithDLFRowData = extractCalculatedRowData(resultData?.volumeCurveWithDLF, 'Custom Benchmark - Total for LGCs', 'volume');
      writeExcelSheet(worksheet, { rows: volumeCurveWithDLFRowData }, false);

      const benchmarkVolumeCurveWithDLFRowData = extractCalculatedRowData(resultData?.benchmarkVolumeCurveWithDLF, 'EY Benchmark - Total for LGCs', 'volume');
      writeExcelSheet(worksheet, { rows: benchmarkVolumeCurveWithDLFRowData }, false);
      addBlankRows(worksheet, 2);
    } else {
      const volumeCurveWithDLFRowData = extractCalculatedRowData(resultData?.volumeCurveWithDLF, 'EY Benchmark - Total for LGCs', 'volume');
      writeExcelSheet(worksheet, { rows: volumeCurveWithDLFRowData }, false);
      addBlankRows(worksheet, 2);
    }

    const lgcActiveFlagRowData = extractCalculatedRowData(resultData?.LGCActiveFlag, 'LGCs active flag', 'activeFlag');
    writeExcelSheet(worksheet, { rows: lgcActiveFlagRowData }, false);
    addBlankRows(worksheet);

    if(calculateCustomBlackMarketRevenue) {
      const blackMarketRevenueRowData = extractCalculatedRowData(resultData?.blackMarketRevenue, 'Custom Benchmark - Black market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: blackMarketRevenueRowData }, false);

      const benchmarkBlackMarketRevenueRowData = extractCalculatedRowData(resultData?.benchmarkBlackMarketRevenue, 'EY Benchmark - Black market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: benchmarkBlackMarketRevenueRowData }, false);
    } else {
      const benchmarkBlackMarketRevenueRowData = extractCalculatedRowData(resultData?.blackMarketRevenue, 'EY Benchmark - Black market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: benchmarkBlackMarketRevenueRowData }, false);
    }

    if(calculateCustomGreenMarketRevenue) {
      const greenMarketRevenueRowData = extractCalculatedRowData(resultData?.greenMarketRevenue, 'Custom Benchmark - Green market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: greenMarketRevenueRowData }, false);

      const benchmarkGreenMarketRevenueRowData = extractCalculatedRowData(resultData?.benchmarkGreenMarketRevenue, 'EY Benchmark - Green market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: benchmarkGreenMarketRevenueRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const greenMarketRevenueRowData = extractCalculatedRowData(resultData?.greenMarketRevenue, 'EY Benchmark - Green market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: greenMarketRevenueRowData }, false);
    }

    if(calculateCustomGreenMarketRevenue || calculateCustomBlackMarketRevenue) {
      const totalMarketRevenueRowData = extractCalculatedRowData(resultData?.totalMarketRevenue, 'Custom Benchmark - Total market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: totalMarketRevenueRowData }, false);

      const benchmarkTotalMarketRevenueRowData = extractCalculatedRowData(resultData?.benchmarkTotalMarketRevenue, 'EY Benchmark - Total market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: benchmarkTotalMarketRevenueRowData }, false);
      addBlankRows(worksheet);

      worksheet.addRow(getEmptyValuesForTimestamps('Simple Average'));
      const simpleAverageBlackProportionRowData = extractCalculatedRowData(resultData?.simpleAverageBlackProportion, 'Custom Benchmark - Black proportion', 'blackProportion');
      writeExcelSheet(worksheet, { rows: simpleAverageBlackProportionRowData }, false);
      const benchmarkSimpleAverageBlackProportionRowData = extractCalculatedRowData(resultData?.benchmarkSimpleAverageBlackProportion, 'EY Benchmark - Black proportion', 'blackProportion');
      writeExcelSheet(worksheet, { rows: benchmarkSimpleAverageBlackProportionRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Custom Benchmark - Average Black Proportion', resultData?.avgOfsimpleAverageBlackProportion));
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Average Black Proportion', resultData?.benchmarkAvgOfsimpleAverageBlackProportion));
      addBlankRows(worksheet);

      worksheet.addRow(getEmptyValuesForTimestamps('Weighted Average'));
      const weightedAveragePeriodCounterRowData = extractCalculatedRowData(resultData?.weightedAveragePeriodCounter, 'Weighted-average period counter', 'value');
      writeExcelSheet(worksheet, { rows: weightedAveragePeriodCounterRowData }, false);

      const weightedAverageBlackProportionRowData = extractCalculatedRowData(resultData?.weightedAverageBlackProportion, 'Custom Benchmark - Black proportion', 'blackProportion');
      writeExcelSheet(worksheet, { rows: weightedAverageBlackProportionRowData }, false);
      const benchmarkWeightedAverageBlackProportionRowData = extractCalculatedRowData(resultData?.benchmarkWeightedAverageBlackProportion, 'EY Benchmark - Black proportion', 'blackProportion');
      writeExcelSheet(worksheet, { rows: benchmarkWeightedAverageBlackProportionRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Custom Benchmark - Total Black Proportion', resultData?.totalWeightedAverageBlackProportion));
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Total Black Proportion', resultData?.benchmarkTotalWeightedAverageBlackProportion));
      addBlankRows(worksheet);

      const unbundlingFactorRowData = extractCalculatedRowData(resultData?.unbundlingFactor, `Custom Benchmark - ${pascalCase(formData?.unbundlingApproach, '_')}`, 'factor');
      writeExcelSheet(worksheet, { rows: unbundlingFactorRowData }, false);
      const benchmarkUnbundlingFactorRowData = extractCalculatedRowData(resultData?.benchmarkUnbundlingFactor, `EY Benchmark - ${pascalCase(formData?.unbundlingApproach, '_')}`, 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkUnbundlingFactorRowData }, false);
    } 
    else {
      const totalMarketRevenueRowData = extractCalculatedRowData(resultData?.totalMarketRevenue, 'EY Benchmark - Total market revenue', 'revenue');
      writeExcelSheet(worksheet, { rows: totalMarketRevenueRowData }, false);
      addBlankRows(worksheet);

      worksheet.addRow(getEmptyValuesForTimestamps('Simple Average'));
      const simpleAverageBlackProportionRowData = extractCalculatedRowData(resultData?.simpleAverageBlackProportion, 'EY Benchmark - Black proportion', 'blackProportion');
      writeExcelSheet(worksheet, { rows: simpleAverageBlackProportionRowData }, false);
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Average Black Proportion', resultData?.avgOfsimpleAverageBlackProportion));
      addBlankRows(worksheet);

      worksheet.addRow(getEmptyValuesForTimestamps('Weighted Average'));
      const weightedAveragePeriodCounterRowData = extractCalculatedRowData(resultData?.weightedAveragePeriodCounter, 'Weighted-average period counter', 'value');
      writeExcelSheet(worksheet, { rows: weightedAveragePeriodCounterRowData }, false);

      const weightedAverageBlackProportionRowData = extractCalculatedRowData(resultData?.weightedAverageBlackProportion, 'EY Benchmark - Black proportion', 'blackProportion');
      writeExcelSheet(worksheet, { rows: weightedAverageBlackProportionRowData }, false);
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Total Black Proportion', resultData?.totalWeightedAverageBlackProportion));
      addBlankRows(worksheet);

      const unbundlingFactorRowData = extractCalculatedRowData(resultData?.unbundlingFactor, `EY Benchmark - ${pascalCase(formData?.unbundlingApproach, '_')}`, 'factor');
      writeExcelSheet(worksheet, { rows: unbundlingFactorRowData }, false);
    }
  }

  const addBlankRows = (worksheet, count = 3) => {
    for(let i=0; i<count; i++) {
      worksheet.addRow({});
    }
  }

  const generateCalculationWorkSheet = (worksheet) => {

    let columns = resultData?.timeSeries.map(timestamp => {
      return {
        header: timestamp,
        key: timestamp
      };
    });

    columns = [
      { header: 'Name', key: 'name'},
      { header: 'Value Type', key: 'type'},
      ...columns
    ];

    writeExcelSheet(worksheet, { columns }, true, false);

    worksheet.addRow({});
    worksheet.addRow(getEmptyValuesForTimestamps('Volumes'));
    worksheet.addRow(getEmptyValuesForTimestamps('PPA Allocation to Counterparty', Number(formData?.counterPartyAllocation)));
    const volumeCurveRowData = extractDataWithForecastedAndActualValues(resultData?.volumeCurve, 'Selected MW forecast');
    writeExcelSheet(worksheet, { rows: volumeCurveRowData }, false);
    worksheet.addRow(getEmptyValuesForTimestamps('Selected curtailment factor', Number(formData?.curtailmentFactor)));

    let displayCustomTotalGenerationAtNodeRowData = false;

    // Check whether custom MLF curve has been provided
    if(formData?.uploadMLFCurve === 'yes') {
      addBlankRows(worksheet, 1);
      const mlfCurveRowData = extractDataWithForecastedAndActualValues(resultData?.mlfCurve, 'Custom Benchmark - marginal loss factor');
      writeExcelSheet(worksheet, { rows: mlfCurveRowData }, false);
      displayCustomTotalGenerationAtNodeRowData = true;
    }
    const benchmarkMLFCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkMLFCurve, 'EY Benchmark - marginal loss factor');
    writeExcelSheet(worksheet, { rows: benchmarkMLFCurveRowData }, false);
    addBlankRows(worksheet, 1);

    if(formData?.uploadDLFCurve === 'yes') {
      displayCustomTotalGenerationAtNodeRowData = true;
      const dlfCurveRowData = extractDataWithForecastedAndActualValues(resultData?.dlfCurve, 'Custom Benchmark - distribution loss factor');
      writeExcelSheet(worksheet, { rows: dlfCurveRowData }, false);
    }

    const benchmarkDlfCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkDLFCurve, 'EY Benchmark - distribution loss factor');
    writeExcelSheet(worksheet, { rows: benchmarkDlfCurveRowData }, false);
    addBlankRows(worksheet, 1);

    if(displayCustomTotalGenerationAtNodeRowData) {
      const totalGenerationAtNodeRowData = extractCalculatedRowData(resultData?.totalGenerationAtNodePerTimestamp, 'Custom Benchmark - Total generation at node', 'volume');
      writeExcelSheet(worksheet, { rows: totalGenerationAtNodeRowData }, false);
      const benchmarkTotalGenerationAtNodeRowData = extractCalculatedRowData(resultData?.benchmarkTotalGenerationAtNodePerTimestamp, 'EY Benchmark - Total generation at node', 'volume');
      writeExcelSheet(worksheet, { rows: benchmarkTotalGenerationAtNodeRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Custom Benchmark - Total Volume', Number(resultData?.totalGenerationAtNode )));
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Total Volume', Number(resultData?.benchmarkTotalGenerationAtNode )));
      addBlankRows(worksheet);
    } else {
      const totalGenerationAtNodeRowData = extractCalculatedRowData(resultData?.totalGenerationAtNodePerTimestamp, 'EY Benchmark - Total generation at node', 'volume');
      writeExcelSheet(worksheet, { rows: totalGenerationAtNodeRowData }, false);
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Total Volume', Number(resultData?.totalGenerationAtNode )));
      addBlankRows(worksheet);
    }

    worksheet.addRow(getEmptyValuesForTimestamps('PPA Price'));
    addBlankRows(worksheet, 1);

    if(resultData?.calculateBenchmarkUnbundlingFactor) {
      const unbundlingFactorRowData = extractCalculatedRowData(resultData?.unbundlingFactor, 'Custom Benchmark - Unbundling Factor', 'factor');
      writeExcelSheet(worksheet, { rows: unbundlingFactorRowData }, false);
      const benchmarkUnbundlingFactorRowData = extractCalculatedRowData(resultData?.benchmarkUnbundlingFactor, 'EY Benchmark - Unbundling Factor', 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkUnbundlingFactorRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const unbundlingFactorRowData = extractCalculatedRowData(resultData?.unbundlingFactor, 'EY Benchmark - Unbundling Factor', 'factor');
      writeExcelSheet(worksheet, { rows: unbundlingFactorRowData }, false);
    }

    const ppaPriceRowData = extractDataWithForecastedAndActualValues(resultData?.ppaPriceCurve, 'PPA Price');
    writeExcelSheet(worksheet, { rows: ppaPriceRowData }, false);
    const ppaEscalationCurveRowData = extractDataWithForecastedAndActualValues(resultData?.ppaEscalationCurve, 'PPA escalation curve');
    writeExcelSheet(worksheet, { rows: ppaEscalationCurveRowData }, false);

    if(resultData?.calculateBenchmarkUnbundlingFactor) {
      addBlankRows(worksheet, 1);
      const appliedPPAPriceRowData = extractCalculatedRowData(resultData?.appliedPPAPrice, 'Custom Benchmark - Applied PPA price', 'price');
      writeExcelSheet(worksheet, { rows: appliedPPAPriceRowData }, false);
      const benchmarkAppliedPPAPriceRowData = extractCalculatedRowData(resultData?.benchmarkAppliedPPAPrice, 'EY Benchmark - Applied PPA price', 'price');
      writeExcelSheet(worksheet, { rows: benchmarkAppliedPPAPriceRowData }, false);
      addBlankRows(worksheet);
    } else {
      const appliedPPAPriceRowData = extractCalculatedRowData(resultData?.appliedPPAPrice, 'EY Benchmark - Applied PPA price', 'price');
      writeExcelSheet(worksheet, { rows: appliedPPAPriceRowData }, false);
      addBlankRows(worksheet);
    }
    

    worksheet.addRow(getEmptyValuesForTimestamps('Black Market Price'));
    addBlankRows(worksheet, 1);

    let calculateBenchmarkAppliedBlackMarketPrice = false;

    if(formData?.blackMarketPrice === 'yes') {
      const blackMarketPricCurveRowData = extractDataWithForecastedAndActualValues(resultData?.blackMarketPriceCurve, 'Custom Benchmark - Black Market price (100%)');
      writeExcelSheet(worksheet, { rows: blackMarketPricCurveRowData }, false);
      calculateBenchmarkAppliedBlackMarketPrice = true;
    } 

    const benchmarkBlackMarketPricCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkBlackMarketPriceCurve, 'EY Benchmark - Black Market price (100%)');
    writeExcelSheet(worksheet, { rows: benchmarkBlackMarketPricCurveRowData }, false);
    addBlankRows(worksheet, 1);
    
    worksheet.addRow(getEmptyValuesForTimestamps('Calibration adjustment', resultData?.calibrationAdjustment));

    if(formData?.inflation === 'yes') {
      calculateBenchmarkAppliedBlackMarketPrice = true;
      addBlankRows(worksheet, 1);
      const inflationCurveRowData = extractDataWithForecastedAndActualValues(resultData?.inflationCurve, 'Custom Benchmark - Inflation curve');
      writeExcelSheet(worksheet, { rows: inflationCurveRowData }, false);
      const benchmarkInflationCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkInflationCurve, 'EY Benchmark - Inflation curve');
      writeExcelSheet(worksheet, { rows: benchmarkInflationCurveRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const inflationCurveRowData = extractDataWithForecastedAndActualValues(resultData?.inflationCurve, 'EY Benchmark - Inflation curve');
      writeExcelSheet(worksheet, { rows: inflationCurveRowData }, false);
      addBlankRows(worksheet, 1);
    }

    if(calculateBenchmarkAppliedBlackMarketPrice) {
      const appliedBlackMarketPriceRowData = extractCalculatedRowData(resultData?.appliedBlackMarketPrice, 'Custom Benchmark - Applied black market price', 'price');
      writeExcelSheet(worksheet, { rows: appliedBlackMarketPriceRowData }, false);
      const benchmarkAppliedBlackMarketPriceRowData = extractCalculatedRowData(resultData?.benchmarkAppliedBlackMarketPrice, 'EY Benchmark - Applied black market price', 'price');
      writeExcelSheet(worksheet, { rows: benchmarkAppliedBlackMarketPriceRowData }, false);
      addBlankRows(worksheet);
    } else {
      const appliedBlackMarketPriceRowData = extractCalculatedRowData(resultData?.appliedBlackMarketPrice, 'EY Benchmark - Applied black market price', 'price');
      writeExcelSheet(worksheet, { rows: appliedBlackMarketPriceRowData }, false);
      addBlankRows(worksheet);
    }

    worksheet.addRow(getEmptyValuesForTimestamps('Cash flows'));

    let showBenchmarkCashflows = false;

    // Check whether we need to display both custom and EY benchmark 'Revenue PPA'
    if(displayCustomTotalGenerationAtNodeRowData || resultData?.calculateBenchmarkUnbundlingFactor) {
      showBenchmarkCashflows = true;
      addBlankRows(worksheet, 1);
      const revenuePPARowData = extractCalculatedRowData(resultData?.revenuePPA, 'Custom Benchmark - Revenue PPA', 'revenue');
      writeExcelSheet(worksheet, { rows: revenuePPARowData }, false);

      const benchmarkRevenuePPARowData = extractCalculatedRowData(resultData?.benchmarkRevenuePPA, 'EY Benchmark - Revenue PPA', 'revenue');
      writeExcelSheet(worksheet, { rows: benchmarkRevenuePPARowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const revenuePPARowData = extractCalculatedRowData(resultData?.revenuePPA, 'EY Benchmark - Revenue PPA', 'revenue');
      writeExcelSheet(worksheet, { rows: revenuePPARowData }, false);
    }

    // Check whether we need to display both custom and EY benchmark 'Revenue spot market'
    if(displayCustomTotalGenerationAtNodeRowData || calculateBenchmarkAppliedBlackMarketPrice) {
      showBenchmarkCashflows = true;
      const revenueSpotMarketRowData = extractCalculatedRowData(resultData?.revenueSpotMarket, 'Custom Benchmark - Revenue spot market', 'revenue');
      writeExcelSheet(worksheet, { rows: revenueSpotMarketRowData }, false);

      const benchmarkRevenueSpotMarketRowData = extractCalculatedRowData(resultData?.benchmarkRevenueSpotMarket, 'EY Benchmark - Revenue spot market', 'revenue');
      writeExcelSheet(worksheet, { rows: benchmarkRevenueSpotMarketRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const revenueSpotMarketRowData = extractCalculatedRowData(resultData?.revenueSpotMarket, 'EY Benchmark - Revenue spot market', 'revenue');
      writeExcelSheet(worksheet, { rows: revenueSpotMarketRowData }, false);
    }

    if(showBenchmarkCashflows) {
      const cashflowVarianceRowData = extractCalculatedRowData(resultData?.cashflowVariance, 'Custom Benchmark - Cash flow (variance)', 'cashflow');
      writeExcelSheet(worksheet, { rows: cashflowVarianceRowData }, false);
      const benchmarkCashflowVarianceRowData = extractCalculatedRowData(resultData?.benchmarkCashflowVariance, 'EY Benchmark - Cash flow (variance)', 'cashflow');
      writeExcelSheet(worksheet, { rows: benchmarkCashflowVarianceRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Custom Benchmark - Total Cash flow (variance)', resultData?.totalCashflowVariance));
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Total Cash flow (variance)', resultData?.benchmarkTotalCashflowVariance));
      addBlankRows(worksheet, 1);

      const bankGuaranteeRowData = extractCalculatedRowData(resultData?.bankGuarantee, 'Custom Benchmark - Bank guarantee', 'amount');
      writeExcelSheet(worksheet, { rows: bankGuaranteeRowData }, false);
      const benchmarkBankGuaranteeRowData = extractCalculatedRowData(resultData?.benchmarkBankGuarantee, 'EY Benchmark - Bank guarantee', 'amount');
      writeExcelSheet(worksheet, { rows: benchmarkBankGuaranteeRowData }, false);
      addBlankRows(worksheet, 1);

      const excessCashFlowRowData = extractCalculatedRowData(resultData?.excessCashFlow, 'Custom Benchmark - Excess cash flow', 'cashflow');
      writeExcelSheet(worksheet, { rows: excessCashFlowRowData }, false);
      const benchmarkExcessCashFlowRowData = extractCalculatedRowData(resultData?.benchmarkExcessCashFlow, 'EY Benchmark - Excess cash flow', 'cashflow');
      writeExcelSheet(worksheet, { rows: benchmarkExcessCashFlowRowData }, false);
      addBlankRows(worksheet, 1);

      const coveredCashFlowRowData = extractCalculatedRowData(resultData?.coveredCashflow, 'Custom Benchmark - Covered cash flow', 'cashflow');
      writeExcelSheet(worksheet, { rows: coveredCashFlowRowData }, false);
      const benchmarkCoveredCashFlowRowData = extractCalculatedRowData(resultData?.benchmarkCoveredCashflow, 'EY Benchmark - Covered cash flow', 'cashflow');
      writeExcelSheet(worksheet, { rows: benchmarkCoveredCashFlowRowData }, false);
      addBlankRows(worksheet);
    } else {
      const cashflowVarianceRowData = extractCalculatedRowData(resultData?.cashflowVariance, 'EY Benchmark - Cash flow (variance)', 'cashflow');
      writeExcelSheet(worksheet, { rows: cashflowVarianceRowData }, false);
      worksheet.addRow(getEmptyValuesForTimestamps('EY Benchmark - Total Cash flow (variance)', resultData?.totalCashflowVariance));
      const bankGuaranteeRowData = extractCalculatedRowData(resultData?.bankGuarantee, 'EY Benchmark - Bank guarantee', 'amount');
      writeExcelSheet(worksheet, { rows: bankGuaranteeRowData }, false);
      const excessCashFlowRowData = extractCalculatedRowData(resultData?.excessCashFlow, 'EY Benchmark - Excess cash flow', 'cashflow');
      writeExcelSheet(worksheet, { rows: excessCashFlowRowData }, false);
      const coveredCashFlowRowData = extractCalculatedRowData(resultData?.coveredCashflow, 'EY Benchmark - Covered cash flow', 'cashflow');
      writeExcelSheet(worksheet, { rows: coveredCashFlowRowData }, false);
      addBlankRows(worksheet);
    }

    const periodFactorRowData = extractCalculatedRowData(resultData?.periodFactor, 'Period factor', 'factor');

    worksheet.addRow(getEmptyValuesForTimestamps('Discounting (end-point)'));
    addBlankRows(worksheet, 2);
    worksheet.addRow(getEmptyValuesForTimestamps('Excess of Bank Guarantee'));
    addBlankRows(worksheet, 1);

    worksheet.addRow(getEmptyValuesForTimestamps('Net asset present value factor'));
    worksheet.addRow(getEmptyValuesForTimestamps('Val date', formData?.valuationDate ));
    writeExcelSheet(worksheet, { rows: periodFactorRowData }, false);

    if(formData?.riskFreeRate === 'yes') {
      addBlankRows(worksheet, 1);
      const discountRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.riskFreeRateCurve, 'Custom Benchmark - Discount rate');
      writeExcelSheet(worksheet, { rows: discountRateCurveRowData }, false);
      const benchmarkDiscountRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkRiskFreeRateCurve, 'EY Benchmark - Discount rate');
      writeExcelSheet(worksheet, { rows: benchmarkDiscountRateCurveRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const discountRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.riskFreeRateCurve, 'EY Benchmark - Discount rate');
      writeExcelSheet(worksheet, { rows: discountRateCurveRowData }, false);
    }
    
    if(formData?.assetCreditRiskAdjustment === 'yes') {
      const assetCreditRiskAdjustmentCurveRowData = extractDataWithForecastedAndActualValues(resultData?.assetCreditRiskAdjustmentCurve, 'Custom Benchmark - Credit risk rate');
      writeExcelSheet(worksheet, { rows: assetCreditRiskAdjustmentCurveRowData }, false);
      const benchmarkAssetCreditRiskAdjustmentCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkAssetCreditRiskAdjustmentCurve, 'EY Benchmark - Credit risk rate');
      writeExcelSheet(worksheet, { rows: benchmarkAssetCreditRiskAdjustmentCurveRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const assetCreditRiskAdjustmentCurveRowData = extractDataWithForecastedAndActualValues(resultData?.assetCreditRiskAdjustmentCurve, 'EY Benchmark - Credit risk rate');
      writeExcelSheet(worksheet, { rows: assetCreditRiskAdjustmentCurveRowData }, false);
    }

    if(showBenchmarkCashflows) {
      const netAssetPresentValueFactorRowData = extractCalculatedRowData(resultData?.netAssetPresentValueFactor, 'Custom Benchmark - Net asset present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: netAssetPresentValueFactorRowData }, false);
      const benchmarkNetAssetPresentValueFactorRowData = extractCalculatedRowData(resultData?.benchmarkNetAssetPresentValueFactor, 'EY Benchmark - Net asset present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkNetAssetPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const netAssetPresentValueFactorRowData = extractCalculatedRowData(resultData?.netAssetPresentValueFactor, 'EY Benchmark - Net asset present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: netAssetPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 1);
    }

    worksheet.addRow(getEmptyValuesForTimestamps('Net liability present value factor'));
    worksheet.addRow(getEmptyValuesForTimestamps('Val date', formData?.valuationDate ));
    writeExcelSheet(worksheet, { rows: periodFactorRowData }, false);

    if(formData?.riskFreeRate === 'yes') {
      addBlankRows(worksheet, 1);
      const riskFreeRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.riskFreeRateCurve, 'Custom Benchmark - Risk free rate');
      writeExcelSheet(worksheet, { rows: riskFreeRateCurveRowData }, false);
      const benchmarkRiskFreeRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkRiskFreeRateCurve, 'EY Benchmark - Risk free rate');
      writeExcelSheet(worksheet, { rows: benchmarkRiskFreeRateCurveRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const riskFreeRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.riskFreeRateCurve, 'EY Benchmark - Risk free rate');
      writeExcelSheet(worksheet, { rows: riskFreeRateCurveRowData }, false);
    }

    if(formData?.liabilityCreditRiskAdjustment === 'yes') {
      const liabilityCreditRiskAdjustmentCurveRowData = extractDataWithForecastedAndActualValues(resultData?.liabilityCreditRiskAdjustmentCurve, 'Custom Benchmark - Credit risk rate');
      writeExcelSheet(worksheet, { rows: liabilityCreditRiskAdjustmentCurveRowData }, false);
      const benchmarkLiabilityCreditRiskAdjustmentCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkLiabilityCreditRiskAdjustmentCurve, 'EY Benchmark - Credit risk rate');
      writeExcelSheet(worksheet, { rows: benchmarkLiabilityCreditRiskAdjustmentCurveRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const liabilityCreditRiskAdjustmentCurveRowData = extractDataWithForecastedAndActualValues(resultData?.liabilityCreditRiskAdjustmentCurve, 'EY Benchmark - Credit risk rate');
      writeExcelSheet(worksheet, { rows: liabilityCreditRiskAdjustmentCurveRowData }, false);
    }

    if(showBenchmarkCashflows) {
      const netLiabilityPresentValueFactorRowData = extractCalculatedRowData(resultData?.netLiabilityPresentValueFactor, 'Custom Benchmark - Net liability present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: netLiabilityPresentValueFactorRowData }, false);
      const benchmarkNetLiabilityPresentValueFactorRowData = extractCalculatedRowData(resultData?.benchmarkNetLiabilityPresentValueFactor, 'EY Benchmark - Net liability present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkNetLiabilityPresentValueFactorRowData }, false);

      addBlankRows(worksheet, 1);
      worksheet.addRow(getEmptyValuesForTimestamps('Period by Period', formData?.periodByPeriod === 'yes' ? 1 : 0));
      const excessPresentValueFactorRowData = extractCalculatedRowData(resultData?.excessPresentValueFactor, 'Custom Benchmark - Selected present value factor - excess', 'factor');
      writeExcelSheet(worksheet, { rows: excessPresentValueFactorRowData }, false);
      const benchmarkExcessPresentValueFactorRowData = extractCalculatedRowData(resultData?.benchmarkExcessPresentValueFactor, 'EY Benchmark - Selected present value factor - excess', 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkExcessPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 2);
    } else {
      const netLiabilityPresentValueFactorRowData = extractCalculatedRowData(resultData?.netLiabilityPresentValueFactor, 'EY Benchmark - Net liability present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: netLiabilityPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Period by Period', formData?.periodByPeriod === 'yes' ? 1 : 0));
      const excessPresentValueFactorRowData = extractCalculatedRowData(resultData?.excessPresentValueFactor, 'EY Benchmark - Selected present value factor - excess', 'factor');
      writeExcelSheet(worksheet, { rows: excessPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 2);
    }

    worksheet.addRow(getEmptyValuesForTimestamps('Covered by Bank Guarantee'));
    addBlankRows(worksheet, 1);

    worksheet.addRow(getEmptyValuesForTimestamps('BBSW DR calc'));
    worksheet.addRow(getEmptyValuesForTimestamps('Val date', formData?.valuationDate ));
    writeExcelSheet(worksheet, { rows: periodFactorRowData }, false);


    if(formData?.riskFreeRate === 'yes') {
      addBlankRows(worksheet, 1);
      const riskFreeRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.riskFreeRateCurve, 'Custom Benchmark - Risk free rate');
      writeExcelSheet(worksheet, { rows: riskFreeRateCurveRowData }, false);
      const benchmarkRiskFreeRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.benchmarkRiskFreeRateCurve, 'EY Benchmark - Risk free rate');
      writeExcelSheet(worksheet, { rows: benchmarkRiskFreeRateCurveRowData }, false);
      addBlankRows(worksheet, 1);
    } else {
      const riskFreeRateCurveRowData = extractDataWithForecastedAndActualValues(resultData?.riskFreeRateCurve, 'EY Benchmark - Risk free rate');
      writeExcelSheet(worksheet, { rows: riskFreeRateCurveRowData }, false);
    }

    if(showBenchmarkCashflows) {
      const BBSW_DRRowData = extractCalculatedRowData(resultData?.BBSW_DR, 'Custom Benchmark - Net liability present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: BBSW_DRRowData }, false);
      const benchmarkBBSW_DRRowData = extractCalculatedRowData(resultData?.benchmarkBBSW_DR, 'EY Benchmark - Net liability present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkBBSW_DRRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Period by Period', formData?.periodByPeriod === 'yes' ? 1 : 0));
      const coveredPresentValueFactorRowData = extractCalculatedRowData(resultData?.BBSW_DR, 'Custom Benchmark - Present value factor - covered', 'factor');
      writeExcelSheet(worksheet, { rows: coveredPresentValueFactorRowData }, false);
      const benchmarkCoveredPresentValueFactorRowData = extractCalculatedRowData(resultData?.benchmarkBBSW_DR, 'EY Benchmark - Present value factor - covered', 'factor');
      writeExcelSheet(worksheet, { rows: benchmarkCoveredPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 3);

      worksheet.addRow(getEmptyValuesForTimestamps('Discounted Cash flow'));
      addBlankRows(worksheet, 1);

      const excessPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.excessPresentValueOfVariance, 'Custom Benchmark - Present value of variance - excess', 'variance');
      writeExcelSheet(worksheet, { rows: excessPresentValueOfVarianceRowData }, false);
      const benchmarkExcessPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.benchmarkExcessPresentValueOfVariance, 'EY Benchmark - Present value of variance - excess', 'variance');
      writeExcelSheet(worksheet, { rows: benchmarkExcessPresentValueOfVarianceRowData }, false);
      addBlankRows(worksheet, 1);

      const coveredPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.coveredPresentValueOfVariance, 'Custom Benchmark - Present value of variance - covered', 'variance');
      writeExcelSheet(worksheet, { rows: coveredPresentValueOfVarianceRowData }, false);
      const benchmarkCoveredPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.benchmarkCoveredPresentValueOfVariance, 'EY Benchmark - Present value of variance - covered', 'variance');
      writeExcelSheet(worksheet, { rows: benchmarkCoveredPresentValueOfVarianceRowData }, false);
      addBlankRows(worksheet, 1);

      const presentValueOfVarianceRowData = extractCalculatedRowData(resultData?.presentValueOfVariance, 'Custom Benchmark - Present value of variance', 'variance');
      writeExcelSheet(worksheet, { rows: presentValueOfVarianceRowData }, false);
      const benchmarkPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.benchmarkPresentValueOfVariance, 'EY Benchmark - Present value of variance', 'variance');
      writeExcelSheet(worksheet, { rows: benchmarkPresentValueOfVarianceRowData }, false);
      addBlankRows(worksheet, 2);
    } else {
      const BBSW_DRRowData = extractCalculatedRowData(resultData?.BBSW_DR, 'EY Benchmark - Net liability present value factor', 'factor');
      writeExcelSheet(worksheet, { rows: BBSW_DRRowData }, false);
      addBlankRows(worksheet, 1);

      worksheet.addRow(getEmptyValuesForTimestamps('Period by Period', formData?.periodByPeriod === 'yes' ? 1 : 0));
      const coveredPresentValueFactorRowData = extractCalculatedRowData(resultData?.BBSW_DR, 'EY Benchmark - Present value factor - covered', 'factor');
      writeExcelSheet(worksheet, { rows: coveredPresentValueFactorRowData }, false);
      addBlankRows(worksheet, 3);

      worksheet.addRow(getEmptyValuesForTimestamps('Discounted Cash flow'));
      addBlankRows(worksheet, 1);

      const excessPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.excessPresentValueOfVariance, 'EY Benchmark - Present value of variance - excess', 'variance');
      writeExcelSheet(worksheet, { rows: excessPresentValueOfVarianceRowData }, false);
      const coveredPresentValueOfVarianceRowData = extractCalculatedRowData(resultData?.coveredPresentValueOfVariance, 'EY Benchmark - Present value of variance - covered', 'variance');
      writeExcelSheet(worksheet, { rows: coveredPresentValueOfVarianceRowData }, false);
      const presentValueOfVarianceRowData = extractCalculatedRowData(resultData?.presentValueOfVariance, 'EY Benchmark - Present value of variance', 'variance');
      writeExcelSheet(worksheet, { rows: presentValueOfVarianceRowData }, false);
      addBlankRows(worksheet, 2);
    }

    worksheet.addRow(getEmptyValuesForTimestamps('Low Tolerance', Number(formData?.lowTolerance)));
    const presentValueOfVarianceWithLowToleranceRowData = extractCalculatedRowData(resultData?.presentValueOfVarianceWithLowTolerance, 'Present value of variance - Low Tolerance', 'variance');
    writeExcelSheet(worksheet, { rows: presentValueOfVarianceWithLowToleranceRowData }, false);
    addBlankRows(worksheet, 2);

    worksheet.addRow(getEmptyValuesForTimestamps('High Tolerance', Number(formData?.highTolerance)));
    const presentValueOfVarianceWithHighToleranceRowData = extractCalculatedRowData(resultData?.presentValueOfVarianceWithHighTolerance, 'Present value of variance - High Tolerance', 'variance');
    writeExcelSheet(worksheet, { rows: presentValueOfVarianceWithHighToleranceRowData }, false);
    addBlankRows(worksheet, 3);
    
    worksheet.addRow(getEmptyValuesForTimestamps('Total value - Medium Tolerance', resultData?.totalValuation));
    worksheet.addRow(getEmptyValuesForTimestamps('Total value - Low Tolerance', resultData?.totalValuationWithLowTolerance));
    worksheet.addRow(getEmptyValuesForTimestamps('Total value - High Tolerance', resultData?.totalValuationWithHighTolerance));
    if(resultData?.performBenchmarkCalculations) {
      worksheet.addRow(getEmptyValuesForTimestamps('Total value - EY', resultData?.benchmarkTotalValuation));
    }
    
  }

  const handleYearChange = ({ target }) => {
    configureGraphData(target.value, ChartScenario);
    setChartYear(target.value);
  };

  const handleScenarioChange = ({ target }) => {
    configureGraphData(ChartYear, target.value);
    setChartScenario(target.value);
  };

  return (
    <>
        <div className="ppa_result_container">
            <div className="result-selection-wrapper">
                <div className="admin__contract__form__group col-md-3">
                <label htmlFor="year" className="avf-label ">
                    Year
                </label>
                <select
                    className="admin__contract__input ppa_param_select"
                    id="year"
                    name="year"
                    value={ChartYear}
                    onChange={(e) => handleYearChange(e)}
                >
                    <option id="-1" value="All" key="-1">ALL</option>
                    {valuationYears.map((year, index) => (
                    <option id={index} value={year} key={index}>
                        {year}
                    </option>
                    ))}
                </select>
                </div>

                <div className="admin__contract__form__group col-md-3">
                <label htmlFor="scenario" className="avf-label ">
                    Scenario
                </label>
                <select
                    className="admin__contract__input ppa_param_select"
                    id="scenario"
                    name="scenario"
                    value={ChartScenario}
                    onChange={(e) => handleScenarioChange(e)}
                >
                    <option id="-1" value="All" key="-1">
                    ALL
                    </option>
                    {existingPPASenarioList.map((scenario, index) => (
                    <option id={index} value={scenario} key={index}>
                        {scenario}
                    </option>
                    ))}
                </select>
                </div>
            </div>
            {/* TABLE DATA */}
            <div className="ppa_result_wrapper">
                <div className="ppa_result_table_container">
                    <div>
                      {
                        formData?.valuationAtInception ==='yes' && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Calibration Adjustment</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Value</td>
                                        <td>{resultData?.calibrationAdjustment}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                        }
                        {
                          resultData?.totalValuationWithLowTolerance && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Low Scenario</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>NPV</td>
                                        <td>{resultData?.totalValuationWithLowTolerance.toLocaleString("en")} {currency}</td>
                                    </tr>
                                    <tr>
                                        <td>NPV per MWh</td>
                                        <td>{resultData?.lowValuationPerMWh} {currency}/MWh</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Break Even Price</td>
                                        <td>{resultData?.npvData?.low?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                                    </tr> */}
                                </tbody>
                            </table>
                          )
                        }
                        {
                          resultData?.totalValuation && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Medium Scenario</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>NPV</td>
                                        <td>{resultData?.totalValuation.toLocaleString("en")} {currency}</td>
                                    </tr>
                                    <tr>
                                        <td>NPV per MWh</td>
                                        <td>{resultData?.valuationPerMWh} {currency}/MWh</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Break Even Price</td>
                                        <td>{resultData?.npvData?.low?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                                    </tr> */}
                                </tbody>
                              </table>
                          )
                        }
                        {
                          resultData?.totalValuationWithHighTolerance && (
                              <table>
                                  <thead>
                                      <tr>
                                          <th>High Scenario</th>
                                          <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>NPV</td>
                                          <td>{resultData?.totalValuationWithHighTolerance.toLocaleString("en")} {currency}</td>
                                      </tr>
                                      <tr>
                                          <td>NPV per MWh</td>
                                          <td>{resultData?.highValuationPerMWh} {currency}/MWh</td>
                                      </tr>
                                      {/* <tr>
                                          <td>Break Even Price</td>
                                          <td>{resultData?.npvData?.low?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                                      </tr> */}
                                  </tbody>
                              </table>
                          )
                        }
                        {
                          resultData?.performBenchmarkCalculations && (
                              <table>
                                  <thead>
                                      <tr>
                                          <th>EY</th>
                                          <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>NPV</td>
                                          <td>{resultData?.benchmarkTotalValuation.toLocaleString("en")} {currency}</td>
                                      </tr>
                                      <tr>
                                          <td>NPV per MWh</td>
                                          <td>{resultData?.benchmarkValuationPerMWh} {currency}/MWh</td>
                                      </tr>
                                      {/* <tr>
                                          <td>Break Even Price</td>
                                          <td>{resultData?.npvData?.low?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                                      </tr> */}
                                  </tbody>
                              </table>
                          )
                        }
                    </div>
                </div>

                <div className="ppa_result_graphs_container">
                    <div className="results_graphs">
                        <div className="card mb-3">
                            <div className="graph-heading">
                                <h3>Undiscounted Cashflow</h3>
                            </div>
                            <Chart type="bar" ref={UnDiscountedChartRef} data={UnDiscountedCFChart} options={ChartOptions} />
                        </div>
                        <div className="card mb-3">
                            <div className="graph-heading">
                                <h3>Cumulative Discounted Cashflow</h3>
                            </div>
                            <Chart type="line" ref={QDisCountedChartRef} data={QDisCountedCFChart} options={ChartOptions} />
                        </div>
                        {
                          resultData?.performBenchmarkCalculations && (
                            <div className="card mb-3">
                              <div className="graph-heading">
                                  <h3>Discounted Cashflow - EY vs Custom</h3>
                              </div>
                              <Chart type="bar" ref={benchmarkDiscountedChartRef} data={benchmarkDiscountedChart} options={ChartOptions} />
                          </div>
                          )
                        }
                    </div>
                </div>
            </div>
            <div className="ppa__result__options ">
                <button className="forward__option btn-secondary mr-2" onClick={navigateBack}>Back</button>
                <button className="forward__option btn-secondary mr-2" onClick={newPPACalculation}>New PPA</button>
                <button className="forward__option btn-primary" onClick={exportResult}>Export</button>
            </div>
        </div>
    </>
  );
}
