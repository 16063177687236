import React, { useContext, useRef } from "react";
import PPAContext from "../../../contexts/PPA/PPAContext";
import { MessageContext } from "../../../context/MessageContext";

export default function CustomFileUpload({
  UploadIconColor = "green",
  UploadBackgroundColor = "lightGrey",
  Title = "Upload file",
  FormName = "",
  UploadButtonText = "",
}) {
  const hiddenFileInput = useRef(null);
  const { ErrorToast } = useContext(MessageContext);
  const { formData, SetFormData, templateNames, ValidateTemplate } = useContext(PPAContext);

  const TriggerUpload = (event) => {
    hiddenFileInput.current.click();
    event.preventDefault();
  };

  const resetUpload = () => {
    SetFormData({ ...formData, [FormName]: undefined });
  };

  const ToastError = (message) => {
    resetUpload();
    ErrorToast(message);
  };

  const HandleFileChange = async (event) => {
    if (event && event.target && event.target.files) {
      const fileUploaded = event.target.files[0];
      if (fileUploaded) {
        await ValidateFileAndSetForm(fileUploaded, FormName);
      }
    }
  };

  const ValidateFileAndSetForm = async (file, FormName) => {
    if (
      file &&
      (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type == "application/vnd.ms-excel")
    ) {
      var templateName = templateNames[FormName] ? templateNames[FormName] : null;
      checkTemplate(file, templateName);
    } else {
      ToastError("Invalid file format. Please try again.");
    }
  };

  const checkTemplate = async (file, templateName) => {
    if (!file) {
      ToastError("File not found. Please try again.");
      return;
    }

    if (!templateName) {
      ToastError("Template not identified. Please try again.");
      return;
    }

    await ValidateTemplate(file, templateName)
      .then((result) => {
        if (result == true) {
          SetFormData({ ...formData, [FormName]: file });
        } else if (result == "Not Valid") {
          ErrorToast(`Please Select Start And End Date To Procced.`);
        } else if(result === "bad_request") {
          ErrorToast(`Please Select Model Start Date, Contract End Date, Active Scenario At Inception And Valuation Freequency To Procced.`);
        } else {
          ToastError("Incorrect template please check the instructions and try again.");
        }
      })
      .catch((err) => {
        ToastError("Unhandled error in validating template. Please try again.");
        console.log(err);
      });
  };

  return (
    <>
      {!formData[FormName] ? (
        <button title={Title} onClick={(e) => TriggerUpload(e)} style={{ backgroundColor: UploadBackgroundColor }}>
          {UploadButtonText === "" ? (
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="ic/ file/ cloud_upload">
                <g id="Icon 24px">
                  <path
                    id="Color"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.025 15.06C28.005 9.885 23.46 6 18 6C13.665 6 9.9 8.46 8.025 12.06C3.51 12.54 0 16.365 0 21C0 25.965 4.035 30 9 30H28.5C32.64 30 36 26.64 36 22.5C36 18.54 32.925 15.33 29.025 15.06ZM21 19.5V25.5H15V19.5H10.5L18 12L25.5 19.5H21Z"
                    fill={UploadIconColor}
                  />
                </g>
              </g>
            </svg>
          ) : (
            <span
              style={{
                fontSize: "xx-large",
                color: UploadIconColor,
              }}
            >
              {UploadButtonText}
            </span>
          )}
        </button>
      ) : (
        <div className="file-container">
          <p className="file-path">
            <img className="file-icon" src={"/icons/file_16x16.svg"} alt="file" />
            <span className="upload-file-name">{formData[FormName].name}</span>
            <img className="close-icon" src={"/icons/close_24x24.svg"} alt="file" onClick={resetUpload} />
          </p>
        </div>
      )}

      <div className="display-none">
        <input
          type="file"
          ref={hiddenFileInput}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={HandleFileChange}
          onClick={(event) => {
            event.target.value = null;
          }}
        ></input>
      </div>
    </>
  );
}
