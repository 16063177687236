import React, { useState, useEffect, useContext } from 'react';
import axios from '../../interceptors/axiosInstance';
// import * as pbi from 'powerbi-client';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import './PowerBiReport.css'
import HorizontalTabNavigation from '../HorizontalTabNavigation';
import { config } from '../../config';
import { MessageContext } from '../../context/MessageContext';

export default function PowerBIReport({ type = 'Portfolio View', showTab = false, tabs = [], contractTitle = '' }) {
  const [activeTab,setActiveTab] = useState('tab1');
  const [powerBIType,setPowerBIType] = useState(type);
  const { ErrorToast } = useContext(MessageContext);
  const [reportConfig, setReportConfig] = useState({
    type: 'report',
    embedUrl: "",
    accessToken: "",
    id: "",
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        },
        pageNavigation: {
          visible: false
        }
      },
      background: models.BackgroundType.Transparent,
    }
  });

  const handleChange = (tab) => {
    setActiveTab(tab.id);
    setPowerBIType(tab.type);
  }

  const  getPowerBIUrl = async () =>{
    try{
      const res = await axios.get(`${config.BASE_URL}/api/powerbi/embed-url`,{
        params:{
          type:powerBIType
        }
      });
      if(contractTitle){
        const url = res.data.embedUrl;
        const embedUrl = `${url}&filter=Contracts/contractTitle eq '${contractTitle}'`;
        setReportConfig({...reportConfig, embedUrl: embedUrl, accessToken: res.data.embedToken, id:res.data.reportId })
      } else{
        setReportConfig({...reportConfig, embedUrl: res.data.embedUrl, accessToken: res.data.embedToken, id:res.data.reportId })
      }
    }catch(error){
      if(error.response && error.response.data && error.response.data.message){
          ErrorToast(error.response.data.message);
      } else {
          ErrorToast("Something Went Wrong With PowerBI Reports!. Please Try Again");
      }
    }
  }

    useEffect(() => {
      getPowerBIUrl();
    }, [activeTab, powerBIType, contractTitle]);

  return (
    <>
      {showTab &&
        <div className="powerbi-dashboard-wrapper">
          <div className="powerbi-dashboard-tabs">
            <HorizontalTabNavigation tabs={tabs} initialTab={activeTab} onTabChange={handleChange} disableSearch={true} />
            <div className="PowerBi-Container">
              <PowerBIEmbed
                embedConfig = {reportConfig}
                cssClassName = 'power-bi-report-class'
              />
            </div>
          </div>
        </div>}
      {!showTab &&
        <div className="PowerBi-Container">
          <PowerBIEmbed
            embedConfig = {reportConfig}
            cssClassName = 'power-bi-report-class'
          />
        </div>
      }
    </>
  )
}
