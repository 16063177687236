export const pascalCase = (value, seperator = " ") => {
  const valueArr = value.split(seperator);
  return valueArr
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
};

export const upperCase = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const twoDecimal = (value, convertInteger = true) => {
  const twoDecimalValue = Math.floor(value * 100) / 100;
  return convertInteger ? twoDecimalValue.toFixed(2) : twoDecimalValue;
};
