import { useEffect, useState } from "react";
import "./SimpleContractEdit.css";
// import CustomCalendar from "../../../../utils/components/CustomCalendar";
// import ContractSimpleEditModel from "../../../../components/ContractSimpleEditModel";
// import EditConfirmationModal from "../../../../components/EditConfirmationModal";
// import ContractTerminationModal from "../../../../components/ContractTerminationModal";
// import axios from "axios";
// import { handleDateFormat } from "../../../../utils/DateFormating";
// import SuccessModal from "../../../../components/SuccessModal";
// import ContractTerminationConfirmationModal from "../../../../components/ContractTerminationConfirmationModal";
import {FormWithComment} from "../../../../components/General/DataEntry/FormWithComment/FormWithComment"



export default function SimpleContractEdit({ contract, tileFeilds, updatingValue, label }){
    const [updatedContractData, setUpdatedContractData]= useState({})
    const [showModal, setShowModal] = useState(false);
    const [finalModal, setFinalModal] = useState(false);    
    const [showCofirmationMessage, setShowConfirmationMessage] = useState(false)
    const [contractData, setContractData] = useState({})
    const [loading, setLoading] = useState(true)

    let isDateFeild = false

    const placeHolderMessage = "Enter " + label + "*"

    if(updatingValue == "End date"){
        isDateFeild = true

    }

    const handleContractParamsChange = (e) => {
        setUpdatedContractData(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const clearTextFields = () => {
        setUpdatedContractData({ volume: '', comment: '' });
    };

    useEffect(() => {
        setLoading(true)
        setContractData(contract)
        setLoading(false)
    }, [])

    return(
        <>            
            <div className="generator__edit__contract__container">
            <div className="contract__param__details">
                    <div className="contract__param__cards">
                        <div className="contract__param__card">
                            <p className="param__name">{tileFeilds[0].TileName}</p>
                            <p className="param__value">{tileFeilds[0].value}</p>
                        </div>
                        <div className="contract__param__card">
                            <p className="param__name">{tileFeilds[1].TileName}</p>
                            <p className="param__value">{tileFeilds[1].value}</p>
                        </div>
                        <div className="contract__param__card">
                            <p className="param__name">{tileFeilds[2].TileName}</p>
                            <p className="param__value">{tileFeilds[2].value}</p>
                        </div>
                    </div>
                </div>
            </div>
            <FormWithComment updatingValue = {updatingValue} label={label} placeHolderMessage={placeHolderMessage} contract = {contract} isDateFeild = {isDateFeild}></FormWithComment>
        </>
        
    )
}