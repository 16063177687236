import { useState } from "react";
import HorizontalTabNavigation from "../../../components/HorizontalTabNavigation";

import "./UploadData.css";
import { EnergyGenerationData } from "./EnergyGenerationData/EnergyGenerationData";

const tabs = [
    { id: 'energy-gen-data', label: 'Energy Generation Data'},
]

export const UploadData = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const [tabTitle, setTabtitle] = useState("");

    const handleChange = (tab) => {
        setActiveTab(tab.id);
        setTabtitle(tab.label)
    }

    return (
        <div className="upload-data-container">
            <HorizontalTabNavigation tabs={tabs} initialTab={activeTab} onTabChange={handleChange} disableSearch={true}/>
            {activeTab === 'energy-gen-data' &&  <EnergyGenerationData/>}
        </div>
    )
}

