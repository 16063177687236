import { useState, useEffect, useRef } from 'react';


export default function SideNavItems({menu, isCollapsed, handleNavClick, handleSubNavClick, highlight, subHighlighted, itr}){
  const [submenuCollapse, setSubmenuCollapse] = useState(false);
  const [arrowIcon, setArrowIcon] = useState("/icons/page_next_1_white.svg");

  const toggleSubMenu = () => {
    setSubmenuCollapse(!submenuCollapse)
  }

  const handleMenuClick = (url, itr, menu) => {
    toggleSubMenu();
    handleNavClick(url, itr, menu);
  }


    useEffect(() => {
        submenuCollapse ?  setArrowIcon("/icons/arrow_down_white.svg") : setArrowIcon("/icons/page_next_1_white.svg") 
    }, [submenuCollapse])

   

    return (
        <li className='nav__item_sidebar'>
            <div  className={`nav__item_sidebar__menu ${(highlight && subHighlighted == null) ? "highlight": ""}`} onClick={(e) => handleMenuClick(menu.url, itr, menu)}>
                <div className='nav__item_menu__option'>
                    <img className="sidenav__icon" src={menu.icon}  alt={menu.label} title={menu.label}/>
                    {!isCollapsed ? <span>{menu.label}</span> : ''}
                </div>
                {menu.submenu && !isCollapsed ?  <button className="submenu__button"><img   onClick={toggleSubMenu} className="arrow__down__icon" src={arrowIcon}  alt="sidenav__icon" /></button> : null}
            </div>
            <div className="sidebar__subsection">
                        {
                            menu.submenu && submenuCollapse && !isCollapsed? <div className={` sidebar__submenu ${menu.submenu && submenuCollapse && !isCollapsed} ? "": "close"`}>
                                {menu.submenu.map((sm,i) => (
                                    <div className={`sidebar__submenu__item_list ${highlight && subHighlighted == i ? "submenu_highlight" : ""}`}><p onClick={(e) => handleSubNavClick(sm.url, itr, i)} className={`sidebar__submenu__item ${highlight && subHighlighted == i ? "submenu_highlight" : ""}`} key={i}>
                                    {sm.label}
                                    </p>
                                    </div>
                                ))}
                            </div>: null
                        }
            </div>
        </li>
    )
}