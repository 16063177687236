import React, { useState, useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import axios from "../../../../interceptors/axiosInstance";
import SuccessModal from "../../../../components/SuccessModal";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { LoaderContext } from "../../../../context/LoaderContext";
import { config } from '../../../../config'
import "./modifyUser.css";
import { emailRegEx } from "../../../../utils/ValidationRules";
import { pascalCase } from '../../../../utils/display';
import { MessageContext } from "../../../../context/MessageContext";

export const ModifyUser = ({title}) => {
    const [showSuccess, setShowScuccess] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [user, setUser] = useState({});
    const [oldState,setOldState] = useState({});
    const { setLoading } = useContext(LoaderContext);
    const location = useLocation();
    const { ErrorToast } = useContext(MessageContext);

    const { email }= formData;

    useEffect(()=>{
        if(emailRegEx.test(email)){
            try {
                const identifier = setTimeout(async()=>{
                    setLoading(true);
                    const res = await axios.get(`${config.BASE_URL}/api/user?email=${email}`);
                    if(res.data.length) {
                        setUser(res.data[0]);
                        setOldState(res.data[0]);
                    } else {
                        ErrorToast('User not found');
                        setUser({ userName: '',accessLevel: '', status: '' });
                        setOldState({});
                    }
                    setLoading(false);
                },1000);
                return ()=>{
                    clearTimeout(identifier)
                }
            } catch (error) {
                setLoading(false);
                if(error.response && error.response.data && error.response.data.message){
                    ErrorToast(error.response.data.message);
                } else {
                    ErrorToast("Something Went Wrong!. Please Try Again");
                }
            }
        }
    },[email]);

    const handleModifyUser = async () => {
        try{
            setLoading(true);
            const res = await axios.post(`${config.BASE_URL}/api/user/update-status`, { email: user.email, status: formData.status });
            setShowScuccess(true);
            setShowModal(false)

            // Once user has been added, reset the form
            setFormData({
                email: '',
                status: ''
            });
            setUser({userName: '',accessLevel: '', status: '' });
            setOldState({});
            setLoading(false);
            if(location && location.state && location.state.id){
                const res = await axios.patch(`${config.BASE_URL}/api/support/${location.state.id}`, {status:"Approved"});
            }
        } catch(error){
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        } 
    }

    const handleSubmit = async () => {
        console.log(`The data to be sent is ${JSON.stringify({...formData })}`)
        setShowModal(true);
    }

    const handleChange = ({target}) => {
        setFormData(prev => ({...prev, [target.name]: target.value}));
    }

    const resetForm = () => {
        setFormData({
            email: '',
            status: ''
        });
        setUser({userName: '',accessLevel: '', status: '' });
    }

    const handleValidation = (e) => {
        if(e.target.name === 'email' && e.target.value){
            emailRegEx.test(e.target.value) ? setEmailError(false): setEmailError(true); 
        }
    }
    
    return(
        <div className="admin-permission_wrapper"> 
            <div className="admin-permission-title">Modify User</div>
            <div className="admin-permission-form-container">        
                <form className="admin-permission-form" onSubmit={() => {}} autocomplete="off">
                    <div className="admin-permission-form-group">
                        <label htmlFor="email-address">Email Address *</label>
                        <input 
                            onChange={handleChange} 
                            onKeyUp={handleValidation}
                            value={formData.email} 
                            className="admin-permission-input" 
                            type="text" 
                            id="email-address" 
                            name="email"
                            placeholder="Enter Email Address" 
                            required 
                        />
                        {emailError && <p class="text_error">Enter Valid Email</p>}
                    </div>
                    <div className="admin-permission-form-group">
                        <label htmlFor="username">Name Of User</label>
                        <input 
                            disabled={true} 
                            value={user.userName} 
                            className="admin-permission-input" 
                            type="text" id="username" 
                            name="userName" 
                            placeholder="Enter Name" 
                            required 
                        />
                    </div>
                    <div className="admin-permission-form-group">
                        <label htmlFor="access-type">Permission Type</label>
                        <select 
                            disabled={true} 
                            value={user.accessLevel} 
                            className="admin-permission-select-input" 
                            id="permission-type" 
                            name="permissionType" 
                            required
                        >
                            <option value="">Select Type Of Permission</option>
                            <option value="admin">Admin</option>
                            <option value="ppa_valuation">PPA Valuation</option>
                            <option value="ey_consultant">EY Consultant</option>
                            <option value="generator">Generator</option>
                            <option value="offtaker">Offtaker</option>
                        </select>
                    </div>
                    <div className="admin-permission-form-group">
                        <label htmlFor="access-type">Status *</label>
                        <select 
                            onChange={handleChange} 
                            value={formData.status || user.status} 
                            className="admin-permission-select-input" 
                            type="text" 
                            id="permission-level" 
                            name="status" 
                            disabled={!user.status}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="in-active">In Active</option>
                        </select>
                    </div>
                </form>
                { 
                    showModal && 
                    <ConfirmModal 
                        data={[
                            {
                                label: 'Name',
                                value: pascalCase(user.userName)
                            },
                            {
                                label: 'Email',
                                value: user.email
                            },
                            {
                                label: 'Permission Type',
                                value: pascalCase(user.accessLevel)
                            },
                            {
                                label: 'Status',
                                value: pascalCase(formData.status)
                            },
                        ]} 
                        toggleModal={setShowModal} 
                        handleSubmit={handleModifyUser}
                    />
                }
                {
                    showSuccess ? <SuccessModal title="User Modified" message="User Modified Successfully!" toggleModal={setShowScuccess}/>: null 
                }
                <div className="admin-permission-actions">
                    <button 
                        disabled={ !formData.status || !user._id || !formData.email || oldState.status === formData.status  } 
                        className="confirm-button" 
                        onClick={handleSubmit}
                    >
                        Confirm
                    </button>
                    <button className="cancel-button"  onClick={resetForm}>Cancel</button>
                </div>
            </div>
            <div class="info-container">
                <span>NOTE: Fields marked (*) are mandatory</span>
            </div>
        </div>
    )
}