import React,{ useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { BrowserAuthError, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';

import { Home } from './pages/Home/Home';
import * as actions from './actions';
import { setTokenInterval } from './utils/AuthTokenFetch';
import { setAccessToken, getAccessToken, removeAccessToken } from './utils/AuthUtils';
import { setIsOnline } from './utils/OnlineUtils';
import { storeToken } from './utils/StoreToken';
import { MessageContext } from './context/MessageContext';
import ToastList from './components/General/ErrorHandling/Toastlist/ToastList';
import './App.css';

function App() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken ]= useState(getAccessToken());
  const [tokenExpiration, setTokenExpiration] = useState(null);
  const inactivityTimeout = 30 * 60 * 1000; 
  const [messages, setMessages] = useState([]);

  const ErrorToast=(msg, options={ duration : 10000 })=>{
    const obj = {
        id: Date.now(),
        message:msg,
        type:"failure"
    }
    setMessages(prev=>[...prev,obj]);
    setTimeout(() => {
        removeToast(obj.id);
      }, options.duration);
  }

  const removeToast = (id) =>{
    setMessages(prev => prev.filter(item => item.id !== id));
  }

  // Handle token storage
  useEffect(()=>{
    instance.enableAccountStorageEvents();
    const msalEvent = instance.addEventCallback(async event => {
      if(event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.HANDLE_REDIRECT_END) {
        if(event.payload && event.payload.idToken){
          if(event.eventType === EventType.LOGIN_SUCCESS){
            localStorage.setItem('lastLoggedIn',new Date().getTime());
          }
          setAccessToken(event.payload.idToken);
          const result = await storeToken(event.payload.idToken);
          if(result == "Failed to authenticate. User not registered with PPA. Contact EY admin." || result == "User is Inactive. Please contact admin!"){
            ErrorToast(result)
            const delay = await new Promise((resolve, reject) => {
              setTimeout(()=>{
                resolve(true)
              },5000)
            })
            instance.logoutRedirect()
          }
          setToken(event.payload.idToken);
          setTokenInterval(instance);
          instance.setActiveAccount(event.payload.account);
        }
      } else if (event.eventType === EventType.LOGOUT_SUCCESS){
        localStorage.clear();
        removeAccessToken();
        setToken('');
        instance.setActiveAccount(null);
      } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
        instance.logoutRedirect();
      }
      return () => {
        instance.removeEventCallback(msalEvent);
      };
    })

  },[])

  // Handle user inactivity
  useEffect(() => {
    let inactivityTimer;
    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      startInactivityTimer();
    };
    const startInactivityTimer = () => {
      inactivityTimer = setTimeout(() => {
        setTokenExpiration(new Date().getTime());
      }, inactivityTimeout);
    };

    const setOnline = ()=>{
      setIsOnline(true);
    }
    const setOffline = async() => {
      setIsOnline(false);
      ErrorToast("No internet connection!.");
      const delay = await new Promise((resolve, reject) => {
        setTimeout(()=>{
          resolve(true)
        },5000)
      });
      window.location.reload();
    }

    window.addEventListener('online',setOnline);
    window.addEventListener('offline', setOffline);
    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
    startInactivityTimer();

    return () => {
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
      clearTimeout(inactivityTimer);
      window.addEventListener('online',setOnline);
      window.addEventListener('offline', setOffline);
    };
  }, []);

  // Logut user, if inactive time crossed 30 min
  useEffect(async() => {
    if (tokenExpiration && tokenExpiration < new Date().getTime()) {
      ErrorToast("User Sessioned Out Due to Inactivity");
      const delay = await new Promise((resolve, reject) => {
        setTimeout(()=>{
          resolve(true)
        },5000)
      })
      instance.logout();
    }
  }, [tokenExpiration]);

  const result = async () => {
    await instance.initialize();
    if ((!isAuthenticated || token == '')){
      let tokenResponse = null;

      try {
        tokenResponse = await instance.acquireTokenSilent({ scopes: ["user.read", "openid"] });
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
          try {
            const redirectResponse = await instance.handleRedirectPromise();
            if (!redirectResponse && inProgress !== 'logout') {
              instance.loginRedirect({
                scopes: ["user.read", "openid"],
              });
            } 
          } catch (err) {
            console.log("Auth Error 2", err);
          }
        }
      }
    }
  };
  
  useEffect(() => {
    result();
  }, [isAuthenticated]);

  return (
    (
      <>
        <MessageContext.Provider value={{ messages, ErrorToast }}>
          <div className='main__layout'>
            <div className="main__container">
              { messages.length > 0 && <ToastList data={messages} removeToast={removeToast}></ToastList>}
              {
                isAuthenticated && token &&
                <Home />
              }
            </div>
          </div>
        </MessageContext.Provider>
      </>  
    )     
  );
}

export default connect(null, actions) (App);
