import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import {BrowserRouter as Router} from 'react-router-dom';
import reduxThunk from 'redux-thunk';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";

import App from './App';
import reducers from './reducers';
import { Toaster } from 'react-hot-toast';
import { setTokenInterval } from './utils/AuthTokenFetch';
import { setAccessToken } from './utils/AuthUtils';
import { pca } from './msalConfig'
import './index.css';
import { storeToken } from './utils/StoreToken';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router  basename={process.env.PUBLIC_URL}>
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
        <Toaster />
      </Router>
    </Provider>
  </React.StrictMode>
);

