import { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";

import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
  } from '@tanstack/react-table'
  import { rankItem } from '@tanstack/match-sorter-utils';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faSortUp,faSortDown } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from "./Pagination/Pagination";
import "./Table.css";
import { MessageContext } from "../../context/MessageContext";


export const Table = ({
    columns,
    data, 
    setData, 
    placeholder="Search By Name or Email", 
    showGlobalSearch = true, 
    internalSearchFilter, 
    setInternalSearchFilter,
    setDisableInternalSearch = () => {},
    enableInternalSearch= () => {}
}) => {
    const { ErrorToast } = useContext(MessageContext);
    const [sorting, setSorting] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const [disableGlobalSearch, setDisableGlobalSearch] = useState(false)
    const handleSearch = (e) => {
        console.log(`the value entered is ${e.target.value}`)
        setGlobalFilter(String(e.target.value))
    }

  

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: showGlobalSearch ? setGlobalFilter : setInternalSearchFilter,
        onSortingChange: setSorting,
        globalFilterFn: fuzzyFilter,
        autoResetPageIndex,
        meta: getTableMeta(setData, skipAutoResetPageIndex),
        state: {
            sorting,
            globalFilter: showGlobalSearch ? globalFilter : internalSearchFilter,
        },
    });

    const enableGlobalSearch = () => {
        setDisableGlobalSearch(false)
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if(table.getRowModel().rows.length === 0 && ((showGlobalSearch && globalFilter) || (!showGlobalSearch && internalSearchFilter ) )) {
                ErrorToast("Not found")
            }
          }, 500);
          return () => clearTimeout(debounceTimer);
    }, [ table.getRowModel().rows, globalFilter, internalSearchFilter])

    const ref = useRef(null);


    return(
        <>
            {
                showGlobalSearch && (
                    <div className="tab__search">
                        <input 
                            className="table-search-box" 
                            type="text" 
                            placeholder={placeholder} 
                            value={globalFilter ? globalFilter : ''}
                            onChange={event =>  handleSearch(event) }
                            disabled={disableGlobalSearch}
                        />
                        <img onClick={() => console.log('clicked')} className="search-icon" src={ '/icons/search_white.svg'} alt="searchicon" />
                    </div>
                )
            }
            <table className="table" ref={ref}>
                <thead>
                {
                    table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    <div
                                        {...{
                                            className: header.column.getCanSort()
                                                ? 'column-header-container'
                                                : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                        }}
                                    >
                                        <span class="column-header">
                                        { 
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        }
                                        </span>
                                        {
                                            header.column.getCanSort() ? header.column.getIsSorted()? header.column.getIsSorted() === "asc" ? <FontAwesomeIcon className="fa-icon" icon={faSortUp}/>:<FontAwesomeIcon className="fa-icon " icon={faSortDown}/> :  <img className="column-header-icon" src={"/icons/sort.svg"} alt="sort" /> : null
                                        }
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))
                }
                </thead>
                { data.length > 0 &&
                    <tbody>
                    { 
                        table.getRowModel().rows.map(row => (
                            <tr className="change__request__data__row" key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    <p className="column-data" style={{maxWidth:`${(ref.current?.clientWidth||(window.innerWidth*.8))/table.getAllColumns().length}px`}} title={cell.getValue()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </p>
                                </td>
                                ))}
                            </tr>
                        ))
                    }
                    </tbody>
                }
            </table>
            {
                    data.length === 0 && <p className="table-norecords">No Records To Show/Display</p>
            }
            {table.getPageCount()>1 && <div className="table-footer">
                <Pagination
                    getSelectedRowModel={table.getSelectedRowModel}
                    hasNextPage={table.getCanNextPage()}
                    hasPreviousPage={table.getCanPreviousPage()}
                    nextPage={table.nextPage}
                    pageCount={table.getPageCount()}
                    pageIndex={table.getState().pagination.pageIndex}
                    pageSize={table.getState().pagination.pageSize}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                    setPageSize={table.setPageSize}
                    totalRows={table.getPrePaginationRowModel().rows.length}
                />
            </div>}
        </>
    )
}


function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;
  
    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
      shouldSkipRef.current = false
    }, [])
  
    useEffect(() => {
      shouldSkipRef.current = true
    })
  
    const result = useMemo(
      () => [Boolean(shouldSkip), skip],
      [shouldSkip, skip]
    )
  
    return result
}


function getTableMeta (
    setData,
    skipAutoResetPageIndex
){
    return ({
        updateData: (rowIndex, columnId, value) => {
          // Skip age index reset until after next rerender
          skipAutoResetPageIndex()
          setData(old =>
            old.map((row, index) => {
              if (index !== rowIndex) return row
    
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            })
          )
        },
    })
}

function fuzzyFilter(
    row,
    columnId,
    value,
    addMeta
){
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)
  
    // Store the ranking info
    addMeta(itemRank)
  
    // Return if the item should be filtered in/out
    return itemRank.passed
}