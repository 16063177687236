import { useState, useEffect, useContext } from "react"
import "./contractTerminationModal.css"
import CustomCalendar from "../../utils/components/CustomCalendar";

import axios from "../../interceptors/axiosInstance";
import { handleDateFormat } from "../../utils/DateFormating";
import { DatePicker } from "../General/DataEntry/DatePicker/DatePicker";
import { LoaderContext } from "../../context/LoaderContext";
import { config } from '../../config';
import moment from "moment";
import { twoDecimal } from "../../utils/display";
import { MessageContext } from "../../context/MessageContext";

export default function ContractTerminationModal({toggleModal, contract, showNextStep, setTerminateContractParams, updateContractData}){
    const [showCal, setShowCal] = useState(false);
    const [calValue, setCalValue] = useState(contract.contractSunsetDate.slice(0, 10) || null)
    const [fee, setFee] = useState("");
    const [selectTerms, setSelectTerms] = useState(false)
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const toggleCal = () => {
        setShowCal(!showCal)
    }

    const handleTermsSelection = () => {
        setSelectTerms(!selectTerms)
    }

    const handleDateChange = (sDate) => {
        setCalValue(handleDateFormat(sDate))
    }

    const handleConfirmation = async () => {
        const {_id} = contract
        try {
            setLoading(true);
            const res = await axios.post(`${config.BASE_URL}/api/contract/terminate`, {id: _id})
            setLoading(false);
            console.log(`**********Response Termination Date update: ${JSON.stringify(res, null, 2)}*******`)
            updateContractData(prev => ({...prev, activeSince: res.data.activeSince}))
            showNextStep(true)
            toggleModal(false) 
        }catch(error) {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }   

    const fetchTerminationFee = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${config.BASE_URL}/api/contract/termination/fee`)
            setFee("Mark to market exposure value is based on various indicators.Please request us for the exact termination fee.")
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setFee(0) 
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }

    useEffect(() => {
        setTerminateContractParams((prev) => ({...prev, early_end_date:calValue, termination_fee: fee}))
    }, [fee, calValue])
    const duration = `${(moment(contract.contractSunsetDate).diff(moment().utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}),'days',false)+1)} days`
    return (
        <>
        <div className="modal__wrapper">
        <div className="contract__termination__modal__container">
            <div className="contract__termination__header">
                <p className="contract__termination__title">Request Termination Of Contract</p>
                <p className="close-modal" onClick={() => toggleModal(false)}>X</p>
            </div>
            <div className="contract__current__param__cards">
                <div className="contract__current__param__card">
                    <p className="contract__param__name">&nbsp;&nbsp;Contract Sunset Date</p>
                    <p className="contract__param__value">&nbsp;&nbsp;{handleDateFormat(contract.contractSunsetDate)}</p>
                </div>
                <div className="contract__current__param__card">
                    <p className="contract__param__name">&nbsp;&nbsp;Outstanding Contract Period</p>
                    <p className="contract__param__value">&nbsp;&nbsp;{duration}</p>
                </div>
            </div>
            <div className="contract__termination__req__params">
                <form className="contract__termination__req__form">
                    {/* <div className="contract__termination__form__group">
                        <label htmlFor="early_termination_date">Early termination date</label>
                        <img className="cal__icon" onClick={toggleCal} src={"/icons/calendar.svg"} alt="cal" />
                         {showCal && <CustomCalendar value={calValue} onChange={(event) => handleDateChange(event)}/>}
                        <input className="contract__termination__cal" value={calValue} type="text" id="early_termination_date" name="early_termination_date" placeholder="Enter Date*" required />
                    </div> */}
                    <div className="contract__termination__form__group_date">
                        <DatePicker label={"Early Termination Date"} id={"early_termination_date"}  placeholder={"Enter Date*"} CalValue={calValue} setCalValue={setCalValue} formClass="early__term__date" 
                        minDate={new Date()}
                        />
                    </div>
                    <div className="contract__termination__form__group">
                        <label htmlFor="termination_fee">Termination Fee</label>
                        <textarea wrap="off" className="contract__termination__input" type="text" id="termination_fee" value={fee} disabled={true} name="termination_fee" placeholder="-" required />
                    </div>
                    
                </form>
                <div className="contract_termination__button">
                        <button className={`check__fee__button ${calValue!==null? "active" : "inactive"}`} onClick={() => fetchTerminationFee()}>Check Early Termination Fees</button>
                </div>
            </div>
            <div className="contract__termination__terms">
                <p className="contract__termination__header">Terms & Conditions</p>
                <p className="contract__termination__desc">
                    Each party agrees that the calculation of the Early Termination Payment is a genuine pre-estimate of the 
                    Non-Liable Party's anticipated losses arising from the termination of this agreement prior to the End Date.
                    The Early Termination Payment is the sole remedy of the terminating party for the loss of bargain resulting 
                    from termination of this agreement. The party that is entitled to receive an Early Termination Payment 
                    (Non-Liable Party) must provide an invoice to the party required to make an Early Termination Payment 
                    (Liable Party) setting out the Early Termination Payment as determined by the Expert for the Early 
                    Termination Payment within the later of 30 Business Days of termination of this agreement or 10 Business 
                    Days of the Expert determining the Early Termination Payment.
                </p>
            </div>
            <div className="contract__terms__checkbox">
                {/* <label htmlFor="termination_terms_checkbox"></label> */}
                <input className="contract__termination__checkbox" onChange={handleTermsSelection} type="checkbox" id="termination_terms_checkbox" name="termination_terms_checkbox"  required />
                <p>I agree to the terms</p>
            </div>
            <div className="divider__section"></div>
            <div className="contract__termination__confirmation__button">
                    <button className="termination__confirmation" disabled={!selectTerms} onClick={() => handleConfirmation(contract._id)}>Submit</button>
                    <button className="termination__cancel" onClick={() => toggleModal(false)}>Cancel</button>
            </div>
        </div>
        </div>
        </>
    )
}