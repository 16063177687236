import React, { useContext } from "react";

import UserContext from "../../../../context/UserContext";
import PPAContext from "../../../../contexts/PPA/PPAContext";
import "../Style/PPAStyles.css";
import NewPPA from "../NewPPA/NewPPA";
import ExistingPPA from "../ExistingPPA/ExistingPPA";

function StartEvaluation() {
  const { formData } = useContext(PPAContext);
  const { authUser } = useContext(UserContext);

  return (
    <>
      {formData?.modelType === 'new_PPA' && <NewPPA />}
      {formData?.modelType === 'existing_PPA' && <ExistingPPA />}
    </>
  );
}
export default StartEvaluation;
