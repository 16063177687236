import {TailSpin} from "react-loader-spinner";
import './loader.css';

const LoaderComp = ({visible}) => {
    return (
        <>
            <div className="loader-wrapper">
                <TailSpin
                    color="#FFE600"
                    height={80}
                    width={80}
                    visible={visible}
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    ariaLabel="loading"
                />
            </div>
        </>
 
    );
}
export default LoaderComp;