import "./FormWithComment.css"
import { useEffect, useState } from "react";
import axios from '../../../../interceptors/axiosInstance';
// import ContractSimpleEditModel from "../../../ContractSimpleEditModel";
import ContractSimpleEditModel from "../../../ContractSimpleEditModel";
import EditConfirmationModal from "../../../../components/EditConfirmationModal";
import SuccessModal from "../../../../components/SuccessModal";
import CustomCalendar from "../../../../utils/components/CustomCalendar";
import { handleDateFormat } from "../../../../utils/DateFormating";
import { ConfirmModal } from "../../../ConfirmModal/ConfirmModal";
import { config } from "../../../../config";
import { LoaderContext } from "../../../../context/LoaderContext";
import { useContext } from "react";
import { decimalNumberRegex } from "../../../../utils/ValidationRules";
import { MessageContext } from "../../../../context/MessageContext";

export const FormWithComment = ({updatingValue, placeHolderMessage, label='', contract, isDateFeild = false}) => {

    const [updatedContractData, setUpdatedContractData]= useState({})
    const [showModal, setShowModal] = useState(false);
    const [isvalid, setIsValid] = useState('');
    const [finalModal, setFinalModal] = useState(false);    
    const [showCofirmationMessage, setShowConfirmationMessage] = useState(false)
    const [contractData, setContractData] = useState({})
    const [showCal, setShowCal] = useState(false);
    const {setLoading} = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const submitRequest = async () => {
        const requestString = `${config.BASE_URL}/api/contract/update`
        let req
        if(updatingValue == "strikePrice"){
            req = { 
                    id: contract._id,
                    value: updatedContractData.simplechangevalue,
                    updatingContent: "strikePrice"
                }

        }
        if(updatingValue == "volume"){
            req = {
                id: contract._id,
                value: updatedContractData.simplechangevalue,
                updatingContent: "volume"
            }
        }
        // if(updatingValue == "End date"){
        //     req = {value: updatedContractData.simplechangevalue,
        //         updatingContent: "endDate",
        //         updateMessage: updatedContractData.simplechangevalueComments}

        // }
        try{
            setLoading(true);
            const res = await axios.post(requestString, req);
            setLoading(false);
            setFinalModal(true);
            setUpdatedContractData({ simplechangevalue: ''});
            setShowModal(false);
        }catch(error){
            setLoading(false);
            setShowModal(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }

    const handleContractParamsChange = (e) => {
        if(!decimalNumberRegex.test(e.target.value)){
            setIsValid('Invalid');
        } else{
            setIsValid('');
        }
        setUpdatedContractData(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleConfirmationBox = () =>{
        setShowModal(false);
        setShowConfirmationMessage(true);
    }

    const toggleCal = () => {
        setShowCal(!showCal)
    }
    const handleDateChange = (sDate) => {
        setUpdatedContractData(prev => ({...prev, simplechangevalue: handleDateFormat(sDate)}))
        console.log(updatedContractData)
    }

    const clearTextFields = () => {
        setIsValid('');
        setUpdatedContractData({ simplechangevalue: '' });
    };

    useEffect(() => {
        setLoading(true)
        setContractData(contract)
        setLoading(false)
    }, [])

    
        return (
            <div>
                <div className="generator__contract__form__group">
                    <div className="keyInputTile">
                        <form className="offtaker__contract__edit__form" onSubmit={() => {}} autocomplete="off">
                            <div className="update_header">
                                Update {label}
                            </div>
                            <div class="calendar-container">
                                <input className="key_input__contract__input" type="text" id="simplechangevalue" name="simplechangevalue" placeholder={placeHolderMessage} value={updatedContractData.simplechangevalue} onChange={(e) => handleContractParamsChange(e)} required />
                                {isDateFeild &&<img className="cal__icon_simple" onClick={toggleCal} src={"/icons/calendar.svg"} alt="cal" />}
                                {showCal && <CustomCalendar value={updatedContractData.simplechangevalue} onChange={(event) => handleDateChange(event)}/>}
                                {isvalid != '' && <p class="text_error"> Enter valid number upto 2 decimal places</p>}
                            </div>
                            {/* <div className="comments_header">
                                Comments
                            </div>
                            <textarea rows="20" cols="50" className="key_input__contract__input comments_text_feild" type="text" id="simplechangevalueComments" name="simplechangevalueComments" placeholder="Type here" value={updatedContractData.simplechangevalueComments} onChange={(e) => handleContractParamsChange(e)} required >
                            </textarea> */}
                        </form>
                        <div className="form_controls">
                            <button className="contract__update__button" onClick={() => {window.scrollTo(0,0);setShowModal(true)}}
                            disabled={ contract.status === 'expired' || contract.status === 'terminated'|| !updatedContractData.simplechangevalue || isvalid=='Invalid'}
                            >Request Update</button>
                            <button className="contract__cancel__button" onClick={clearTextFields}>Cancel</button>
                        </div>
                    </div>
                </div>
            { showModal ? 
            <div className="confirmation__modal__placeholder_offtaker">
                <ConfirmModal 
                        data={[
                            {
                                label: updatingValue == "strikePrice"? "Strike Price": "Volume",
                                value: updatedContractData.simplechangevalue
                            }
                            // {
                            //     label: 'Comments',
                            //     value: updatedContractData.simplechangevalueComments
                            // }
                        ]} 
                        toggleModal={setShowModal} 
                        handleSubmit={handleConfirmationBox}
                    />
                {/* <ContractSimpleEditModel toggleModal={setShowModal} contract={updatedContractData} contractID={contract._id} updateContractData={setContractData} showNextStep={setShowConfirmationMessage} updatingValue={updatingValue} /> */}
            </div>
            : showCofirmationMessage ? 
            <div className="confirmation__modal__placeholder_offtaker"><EditConfirmationModal toggleModal={setShowConfirmationMessage} submitRequest={submitRequest}/> </div>
            : null }
            {finalModal ?  <SuccessModal title = "Success" message="Request For Change Has Been Sent To Admin" toggleModal={setFinalModal}/>:null }
            </div>
            
        );
    
}