import { useState, useEffect, useContext } from "react";

import axios from '../../../../interceptors/axiosInstance';
import { createColumnHelper } from '@tanstack/react-table'
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { Table } from "../../../../components/Table/Table";
import { config } from "../../../../config";
import { LoaderContext } from "../../../../context/LoaderContext";
import "./RemoveClient.css";
import { upperCase } from '../../../../utils/display';
import { MessageContext } from "../../../../context/MessageContext";
import SuccessModal from "../../../../components/SuccessModal";


export const RemoveClient = ({title =''}) => {
    const [showModal, setShowModal] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [showSuccess, setShowScuccess] = useState(false);
    const { loading, setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);  

    const columnHelper = createColumnHelper();
      
    const columns = [
        columnHelper.accessor('taxId', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Tax Id',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('clientName', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Name',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('personName', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Person Name',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('emailAddress', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Email Address',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('type', {
            cell: info => info.getValue()? upperCase(info.getValue()):'',
            footer: info => info.column.id,
            header: 'Customer Type',
            enableGlobalFilter: false,
        }),
        columnHelper.display({
            id: 'actions',
            cell: props => <img src="/icons/ic_highlight_off.svg" title="Remove" alt="remove" class="remove-client-icon" onClick={() => {
                console.log(props.row.original);
                // confirmData(props.row.original)
            }} />,
            header: 'Remove Client'
        }),
    ];

    const fetchClients = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${config.BASE_URL}/api/client`);
            setClients(res.data)
            setLoading(false)
        } catch(error) {
            setClients([])
            setLoading(false)
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }  
    }

    useEffect(() => {
        fetchClients();
    }, []);

    const confirmData = (client) => {
        setSelectedClient(client);
        setShowModal(true);
    }

    const deleteClient = async () => {
        // try{
        //     setLoading(true);
        //     const res = await axios.delete(`${config.BASE_URL}/api/client/${selectedClient._id}`);
        //     setShowScuccess(true);
        //     setShowModal(false);
        //     setSelectedClient({});
        //     setLoading(false)
        //     fetchClients();
            
        // } catch(err){
        //     setLoading(false)
        //     console.log(`Error: Add client ${JSON.stringify(err, null, 2)}`)
        // } 
    }


    return(
        <div className="remove-client_wrapper"> 
            <div className="remove-client-title">Select a Client To Remove</div>
            <div className="remove-client-table-container">
                <Table columns={columns} data={clients} setData={setClients} placeholder="Search By Tax Id or Name"/>
            </div>
            { 
                showModal &&  
                <ConfirmModal 
                    data={[
                        {
                            label: 'Tax Id',
                            value: selectedClient.taxId
                        },
                        {
                            label: 'Name',
                            value: selectedClient.clientName
                        },
                        {
                            label: 'Person Name',
                            value: selectedClient.personName
                        },
                        {
                            label: 'Email Address',
                            value: selectedClient.emailAddress
                        }
                    ]} 
                    toggleModal={setShowModal} 
                    handleSubmit={deleteClient}
                /> 
                }
                {
                    showSuccess ? <SuccessModal title="Client Removed" message="Client has been removed successfully!" toggleModal={setShowScuccess}/>: null 
                }
        </div>
    )
}