import { toast } from 'react-hot-toast';
import "./CustomToast.css";


export const ErrorToast = (message, options={}, callback = () =>{}) => {
    const handleDismiss = (toast,id) => {
        callback()
        toast.dismiss(id)
    }
    toast.error(
        (t) => (
            <div className="toast-container">
                {message}
                <button onClick={() => handleDismiss(toast, t.id)}>
                    <img className="toast-close-icon" src={"/icons/close_24x24.svg"} alt="close"/>
                </button>
            </div>
        ),
        {
            position: 'top-right',
            icon: <img className="toast-error-icon" src={"/icons/error_24x24.svg"} alt="error"/>,
            ...options,
        }
    );
}

export const SuccessToast = (message, options) => {
    toast.success(
        (t) => (
            <div className="toast-container">
                {message}
                <button onClick={() => toast.dismiss(t.id)}>
                    <img className="toast-close-icon" src={"/icons/close_24x24.svg"} alt="close"/>
                </button>
            </div>
        ),
        {
            position: 'top-right',
            icon: <img className="toast-success-icon" src={"/icons/success.svg"} alt="success"/>,
            ...options,
        }
    );
}