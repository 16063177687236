import { ErrorToast } from "../../components/General/ErrorHandling/CustomToast/CustomToast";
import { decimalNumberRegex } from "../ValidationRules";

export const priceValidator = (oldValue, newValue, fieldName = "") => {
    console.log(`the old param is ${oldValue} and the new param is ${newValue}`)
    let error;
    if(newValue === "" || newValue === null){
         error = `${fieldName} cannot be empty`
    }
    if(newValue < 0){
         error = `${fieldName} cannot be negative`
    }
    if(fieldName == "strike price" && newValue == 0){
        error = `${fieldName} cannot be zero`
    }
    if(!decimalNumberRegex.test(newValue)){
        error = `Enter valid ${fieldName} upto 2 decimal places`
    }
    if(oldValue == newValue) {
        error =  `Enter a different ${fieldName}`
    }
  
    if(error) {
        ErrorToast(error,  {duration: 4000})
    }
    return error === null ? undefined: error;
}

export const dateValidator = (oldValue, newValue, fieldName = "") => {
    let error
    const oldDate = new Date(oldValue);
    const newDate = new Date(newValue);
    console.log(`old date is ${oldDate} and new date is ${newDate}`)
    if(newDate.valueOf() === "" || newDate.valueOf() === null){
        error = `${fieldName} cannot be empty`
    }
    if(oldDate.valueOf() === newDate.valueOf()) {
        error = `Enter a new ${fieldName}`
    }

    if(oldDate.valueOf() > newDate.valueOf()) {
        error =  `Enter a future ${fieldName}`
    }
    if(error) {
        ErrorToast(error, {duration: 40000})
    }
    return error;
}