import {useState, useRef, useEffect } from 'react';
import * as moment from 'moment';

import "./adminModifyContractModal.css";
import { pascalCase } from '../../utils/display';

export default function AdminModifyContractModal({toggleModal, contract, setShowConfirmation, setUpdatedContractParams}){
    const [toggle, setToggle] = useState({title: true, description: true}) 
    const [description, setDescription] = useState("");
    const inputRef = useRef(null);

    const handleToggle =  (type) => {
        setToggle(prev => ({...prev, [type]: !prev[type]}))
    }

    useEffect(() => {
        setDescription(inputRef.current.innerText)
        console.log(inputRef.current.innerText)
    })

    const handleInputChange = (e) => {
        setUpdatedContractParams(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleModify = () => {
        setUpdatedContractParams(prev => ({...prev, description}))
        setShowConfirmation(true)
        toggleModal(false)
    }


    return(
        <>
            <div className="modal__wrapper"></div>
            <div className="admin__modify__contract__modal__container">
                <div className="admin__modify__contract__header">
                    <p className="admin__modify__contract__title">View Contract Details</p>
                    <p className="close-modal"onClick={() => toggleModal(false)}>X</p>
                </div>
                <div className="admin__modify__contract__content">
                    <div className="admin__modify__contract_col_1">
                        <div className="admin__contract__modify__form__group">
                            <label htmlFor="edit_contract_title" className="contract__edit__label">
                                Contract Title
                            </label>
                            <input  disabled onChange={(e) =>handleInputChange(e)} className="admin__contract__modify__input" type="text" id="edit_contract_title" name="title" placeholder="Contract Title"  value={contract.contractTitle} />
                        </div>
                        <div className="admin__contract__modify__form__group">
                            <label htmlFor="edit_contract_desc" className="contract__edit__label">
                                Contract Description
                            </label>
                            <div  ref={inputRef} className="custom__input" contentEditable="false">{contract.contractDescription}</div>
                        </div>
                    </div>
                    <div className="admin__modify__contract_col_2">
                        <div className="admin__contract__modify__form__group">
                            <label htmlFor="edit_strike_price" className='contract__edit__label'>Strike Price</label>
                            <input 
                                disabled 
                                className="admin__contract__modify__input_col_2" 
                                onChange={(e) =>handleInputChange(e)} 
                                type="text" id="edit_volume" 
                                name="strikePrice" 
                                placeholder="Enter strike price"  
                                value={contract.strikePrice}
                            />
                        </div>
                        <div className="admin__contract__modify__form__group">
                            <label htmlFor="edit_volume" className='contract__edit__label'>Volume</label>
                            <input 
                                className="admin__contract__modify__input_col_2" 
                                onChange={(e) =>handleInputChange(e)} 
                                type="text" 
                                id="edit_volume" 
                                name="volume" 
                                placeholder="Enter volume" 
                                value={contract.volume}
                                disabled
                            />
                        </div>
                        <div className="admin__contract__modify__form__group">
                            <label htmlFor="edit_duration" className='contract__edit__label'>Contract End Date</label>
                            <input 
                                className="admin__contract__modify__input_col_2" 
                                onChange={(e) =>handleInputChange(e)} 
                                type="text" 
                                id="edit_duration" 
                                name="duration" 
                                placeholder="Enter Contract End Date" 
                                value={contract.contractSunsetDate ? moment(contract.contractSunsetDate).format('YYYY-MM-DD') : ''} 
                                disabled
                            />
                        </div>
                        <div className="admin__contract__modify__form__group">
                            <label htmlFor="edit_payment" className='contract__edit__label'>Terms Of Payment</label>
                            <input  
                                className="admin__contract__modify__input_col_2" 
                                onChange={(e) =>handleInputChange(e)} 
                                type="text" 
                                id="edit_payment" 
                                name="payment" 
                                placeholder="Enter Terms Of Payment"
                                value={pascalCase(contract.paymentFrequency)}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="admin__contract__modify__option__button">
                    <button disabled className="option__button__forward" onClick={() => handleModify()}>Modify</button>
                    <button className="option__button__cancel" onClick={() => toggleModal(false)}>Cancel</button>
                </div>
            </div>
        </>
    )
}