import { useState, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';

import axios from '../../../interceptors/axiosInstance';
import { createColumnHelper } from '@tanstack/react-table'
import { ConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import { Table } from '../../../components/Table/Table';
import { config } from '../../../config'
import { LoaderContext } from "../../../context/LoaderContext";
import "./RemoveUser.css";
import {pascalCase} from '../../../utils/display';
import { MessageContext } from "../../../context/MessageContext";
import SuccessModal from "../../../components/SuccessModal";

export const RemoveUser = ({title =''}) => {
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [showSuccess, setShowScuccess] = useState(false);
    const { loading, setLoading } = useContext(LoaderContext);
    const location = useLocation();
    const { ErrorToast } = useContext(MessageContext);
      
    const columnHelper = createColumnHelper();
      
    const columns = [
        columnHelper.accessor('userName', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Name',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('email', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Email Address',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor( 
            row => {
                if(row.organization && row.organization.clientName)
                    return row.organization.clientName;
                return '';
            },
            {
                cell: info => info.getValue(),
                footer: info => info.column.id,
                header: 'Organization',
                enableGlobalFilter: false,
            }
        ),
        columnHelper.accessor('accessLevel', {
            cell: info => {
                const value= info.getValue() || '';
                return <span>{pascalCase(value)}</span>
            },
            footer: info => info.column.id,
            header: 'Access Type',
            enableGlobalFilter: false,
        }),
        columnHelper.display({
            id: 'actions',
            cell: props => <img src="/icons/ic_highlight_off.svg" title="Remove" alt="Remove" class="remove-user-icon" onClick={() => {
                confirmData(props.row.original)
            }} />,
            header: 'Remove User'
        }),
    ];

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${config.BASE_URL}/api/user`);
            setUsers(res.data)
            setLoading(false)
        } catch(error) {
            setUsers([])
            setLoading(false)
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }  
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    const confirmData = (user) => {
        window.scrollTo(0,0);
        setSelectedUser(user);
        setShowModal(true);
    }

    const deleteUser = async () => {
        try{
            setLoading(true);
            const res = await axios.post(`${config.BASE_URL}/api/user/delete-user`, {email: selectedUser.email});
            setShowScuccess(true);
            setShowModal(false);
            setSelectedUser({});
            setLoading(false)
            fetchUsers();
            if(location && location.state && location.state.id){
                const res = await axios.patch(`${config.BASE_URL}/api/support/${location.state.id}`, {status:"Approved"});
            }
        } catch(error){
            setLoading(false);
            setShowModal(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        } 
    }


    return(
        <div className="remove-user_wrapper"> 
            <div className="remove-user-title">Select a User To Remove</div>
            <div className="remove-user-table-container">
                <Table columns={columns} data={users} setData={setUsers} />
            </div>
            { 
                showModal &&  
                <ConfirmModal 
                    data={[
                        {
                            label: 'Name',
                            value: selectedUser.userName
                        },
                        {
                            label: 'Email Address',
                            value: selectedUser.email
                        },
                        {
                            label: 'Organization',
                            value: selectedUser.organization.clientName
                        },
                        {
                            label: 'Type Of Access',
                            value: pascalCase(selectedUser.accessLevel)
                        },
                    ]} 
                    toggleModal={setShowModal} 
                    handleSubmit={deleteUser}
                /> 
                }
                {
                    showSuccess ? <SuccessModal title="User removed" message="User has been removed successfully!" toggleModal={setShowScuccess}/>: null 
                }
        </div>
    )
}