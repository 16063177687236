import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { setAccessToken } from './AuthUtils';
import { storeToken } from './StoreToken';

let acquireTokenInterval = null;

const handleRedirect = async (instance) => {
  console.log('Token handle process inside timer triggered');
  let tokenResponse = null;

  try {
    console.log('acquireTokenSilent start ********');
    tokenResponse = await instance.acquireTokenSilent({ scopes: ["user.read", "openid"] });
    console.log('acquireTokenSilent end ********', tokenResponse);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      try {
        console.log('handleRedirectPromise start *****');
        const redirectResponse = await instance.handleRedirectPromise();
        console.log('handleRedirectPromise end *****', redirectResponse);
        if (!redirectResponse) {
          console.log('loginRedirect ******');
          instance.loginRedirect({
            scopes: ["user.read", "openid"]
          });
        }
      } catch (err) {
        console.log('Auth Error 2', err);
      }
    }
  }
}

export const setTokenInterval = (instance) => {
  if (acquireTokenInterval) {
    console.log('Old interval cleared');
    clearInterval(acquireTokenInterval);
  }
  console.log('New interval set');
  acquireTokenInterval = setInterval(() => {
    handleRedirect(instance);
  }, 50*60*1000);
}