import { useState, useReducer, useContext, useEffect } from "react";

import axios from "../../../../interceptors/axiosInstance";
import SuccessModal from "../../../../components/SuccessModal";
import { config } from '../../../../config'
import { CountryList } from "../../../../utils/CountryList";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { LoaderContext } from "../../../../context/LoaderContext";
import { emailRegEx, whiteSpaceStringRegex,phoneNumberRegex } from "../../../../utils/ValidationRules";
import "./OnboardClient.css";
import { pascalCase } from "../../../../utils/display";
import { MessageContext } from "../../../../context/MessageContext";


const initialClientFormState = {
    "clientName": "",
    "type":"generator",
    "location":"Australia",
    "personName":"",
    "emailAddress":"",
    "contactNumber":"",
    "taxId":"",
    "generatorId": ""
}

const initialValidityState = {
    "clientNameError": '',
    "typeError":false,
    "locationError":false,
    "personNameError":'',
    "emailAddressError":'',
    "contactNumberError":'',
    "taxIdError":'',
    "generatorIdError":'',
    "isFormValid": false
}

const formReducer = (state, action) => {
    if(action.type === "RESET_FORM"){
        return initialClientFormState
    } else{
        const {name, value} = action.type
        return{
            ...state, [name]: value, 
        }
    }
}

const formValidityReducer = (state , action)=> {
    let isValid= false;
    const {clientName='', personName='', emailAddress='', contactNumber='', taxId='', type='', generatorId=''} = action.payLoad || {};
    if(action.type === "RESET_FORM_VALIDITY"){
        return action.payLoad;
    } else{
        let errorData = {...state};
        switch(action.type){
            case "VALIDATE_CLIENT_NAME": {}
                isValid = clientName.length > 0  && whiteSpaceStringRegex.test(clientName)? true: false;
                errorData = {...errorData, clientNameError: isValid? '': 'Enter Client Name'};
                break;
            case "VALIDATE_PERSON_NAME": 
                isValid = personName.length > 0 && whiteSpaceStringRegex.test(personName)? true: false;
                errorData = {...errorData, personNameError: isValid? '': 'Enter Person Name'};
                break;
            case "VALIDATE_EMAIL": 
                isValid = (emailAddress.length > 0 && emailRegEx.test(emailAddress)) ? true: false
                errorData = {...errorData, emailAddressError: isValid? '': 'Enter Valid Email'};
                break;
            case "VALIDATE_CONTACT": 
                isValid = contactNumber.length > 0 && phoneNumberRegex.test(contactNumber)? true: false;
                errorData = {...errorData, contactNumberError: isValid? '': 'Enter Valid Contact Number'};
                break;
            case "VALIDATE_TAXID": 
                isValid = taxId.length > 0 && whiteSpaceStringRegex.test(taxId) ? true: false;
                errorData = {...errorData, taxIdError: isValid? '': 'Enter Valid Tax Id'};
                break;
            case "VALIDATE_GENERATORID": 
                isValid = generatorId.length > 0 && whiteSpaceStringRegex.test(generatorId) ? true: false;
                errorData = {...errorData, generatorIdError: isValid? '': 'Enter Valid Generator Id'};
                break;
        }
        if(type === 'generator'){
            const formValidity = clientName && personName && emailAddress && contactNumber && taxId && generatorId
            && !errorData.clientNameError && !errorData.personNameError && !errorData.emailAddressError && !errorData.contactNumberError
            && !errorData.taxIdError && !errorData.generatorIdError;
            errorData.isFormValid = formValidity;
        } else {
            const formValidity = clientName && personName && emailAddress && contactNumber && taxId
            && !errorData.clientNameError && !errorData.personNameError && !errorData.emailAddressError && !errorData.contactNumberError
            && !errorData.taxIdError;
            errorData.isFormValid = formValidity;
        }
        return errorData;
    }
}

export default function OnboardClient({title}) {
    const [showModal, setShowModal] = useState(false);
    const [showSuccess, setShowScuccess] = useState(false)
    const [clientParams, setClientParams] = useReducer(formReducer, initialClientFormState)
    const [clientValidity, setClientValidity] = useReducer(formValidityReducer, initialValidityState)
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const {type, emailAddress} = clientParams;

    useEffect(()=>{
        const identifier = setTimeout(() =>{
            if(emailAddress && emailRegEx.test(emailAddress) && type === 'admin'){
                const domainIndex = emailAddress.indexOf('@');
                const domainStr = emailAddress.slice(domainIndex + 1);
                if(!domainStr.toLowerCase().includes('ey.com')){
                    setClientParams({type:{name:'type',value:'generator'}})
                    setClientValidity({type:"VALIDATE_TYPE", payLoad:{...clientParams,type:'generator'}})
                    ErrorToast("Only EY Users Can Have Admin Access");
                }
            }
        },1000)
        return ()=>{
            clearTimeout(identifier)
        }
    },[type, emailAddress])

    const labels = [{label: "Client Name", key: "clientName"}, {label: "Customer Type", key: "type"}, {label: "Location", key: "location"},
    {label: "Person Name", key: "personName"}, {label: "Email Address", key: "emailAddress"}, {label: "Contact Number", key: "contactNumber"},
    {label: "Tax Id", key: "taxId"}
    ]
    if(type==='generator'){
        labels.push({label: "Generator Id", key: "generatorId"})
    }

    const handleSubmit = () => {
        setShowModal(true)
    }

    const handleCancel = () => {
        setShowModal(false);
        setClientParams({type:"RESET_FORM"});
        setClientValidity({type:"RESET_FORM_VALIDITY", payLoad: initialValidityState});
    }

    const submitClientOnboarding = async () => {
        try{
            setLoading(true);
            const res = await axios.post(`${config.BASE_URL}/api/client`, {...clientParams});
            setShowScuccess(true);
            setShowModal(false);
            setClientParams({type:"RESET_FORM"});
            setClientValidity({type:"RESET_FORM_VALIDITY", payLoad: initialValidityState});
            setLoading(false);
        }catch(err){
            setShowModal(false);
            setLoading(false);
            if(err.response && err.response.data && err.response.data.message){
                if(err.response.data.message.trim() === "already exist"){
                    ErrorToast('Client is already onboarded with the given Tax ID');
                } else {
                    ErrorToast(err.response.data.message);
                }
            }else{
                ErrorToast('Something Went Wrong!');
            }
        }
    }

    const handleChange = (e) => {
        setClientParams({type:e.target})
        if(e.target.name === "type"){
            setClientValidity({type:"VALIDATE_TYPE", payLoad:{...clientParams,type:e.target.value}});
            if(e.target.value === "offtaker" || e.target.value === "admin" ){
                setClientParams({type:{name:'generatorId',value:''}})
            }
        }
    }

    const handleValidation = (e) => {
        switch(e.target.name){
            case "clientName":{
                setClientValidity({type:"VALIDATE_CLIENT_NAME", payLoad:clientParams})
                break;
            }
            case "personName":{
                setClientValidity({type:"VALIDATE_PERSON_NAME", payLoad:clientParams})
                break;
            }
            case "emailAddress":{
                setClientValidity({type:"VALIDATE_EMAIL", payLoad:clientParams})
                break;
            }
            case "contactNumber":{
                setClientValidity({type:"VALIDATE_CONTACT", payLoad:clientParams})
                break;
            }
            case "taxId":{
                setClientValidity({type:"VALIDATE_TAXID", payLoad:clientParams})
                break;
            }
            case "generatorId":{
                setClientValidity({type:"VALIDATE_GENERATORID", payLoad:clientParams})
                break;
            }
        }
    }
    
    return(
        <><div className="admin__client__onboarding__wrapper"> 
        <div className="admin__client__title">Add Client Details</div>
        <div className="admin__client__onboarding__form__container">
            <form className="admin__client__onboarding__form" onSubmit={() => {}} autocomplete="off">
                <div className="input_row">
                        <div className="admin__client__form__group">
                            <label htmlFor="clientName">Client Name *</label>
                            <input value={clientParams.clientName} onChange={handleChange} onKeyUp={handleValidation} className="admin__client__input" type="text" id="clientName" name="clientName" placeholder="Enter Name" required />
                            {clientValidity.clientNameError && <span className="admin_input_error">{clientValidity.clientNameError}</span>}
                        </div>
                        <div className="admin__client__form__group">
                            <label htmlFor="type">Customer Type *</label>
                            <select className="admin__client__input" id="type"  name="type" value={clientParams.type} onChange={handleChange} required>
                                {
                                    [
                                        {label:"Generator", value: "generator"}, 
                                        {label: "Offtaker", value: "offtaker"},
                                        {label: "Admin", value: "admin"},
                                    ]
                                    .map((custtype, index) =>  <option id={index} value={custtype.value}>{custtype.label}</option>)
                                }
                            </select>
                        </div>
                        <div className="admin__client__form__group">
                            <label htmlFor="location">Location *</label>
                            <select className="admin__client__input" id="location"  name="location" value={clientParams.location.value} onChange={handleChange} required>
                                    {
                                        CountryList.map((country, index) =>  <option id={index} value={country} selected={country=="Australia"? true: null}>{country}</option>)
                                    }
                            </select>
                        </div>
                </div>
                <div className="input_row">
                    <div className="admin__client__form__group">
                        <label htmlFor="personName">Person Name *</label>
                        <input value={clientParams.personName} onChange={handleChange} onKeyUp={handleValidation} className="admin__client__input" type="text" id="personName" name="personName" placeholder="Full Name" required />
                        {clientValidity.personNameError && <span className="admin_input_error">{clientValidity.personNameError}</span>}
                    </div>
                    <div className="admin__client__form__group">
                        <label htmlFor="emailAddress">Email Address *</label>
                        <input value={clientParams.emailAddress} onChange={handleChange} onKeyUp={handleValidation} className="admin__client__input" type="email" id="emailAddress" name="emailAddress" placeholder="Email Address" required />
                        {clientValidity.emailAddressError  && <span className="admin_input_error">{clientValidity.emailAddressError}</span>}
                    </div>
                    <div className="admin__client__form__group" >
                        <label htmlFor="contactNumber">Contact Number *</label>
                        <input value={clientParams.contactNumber} onChange={handleChange} onKeyUp={handleValidation} className="admin__client__input" type="text" id="contactNumber" name="contactNumber" placeholder="Contact Number" required />
                        {clientValidity.contactNumberError  && <span className="admin_input_error">{clientValidity.contactNumberError}</span>}
                    </div>
                </div>
                <div className="input_row_two_column">
                    <div className="admin__client__form__group_two_Column">
                        <label htmlFor="taxId">Tax Id *</label>
                        <input value={clientParams.taxId} onChange={handleChange} onKeyUp={handleValidation} className="admin__client__input" type="text" id="taxId" name="taxId" placeholder="Tax Id" required />
                        {clientValidity.taxIdError && <span className="admin_input_error">{clientValidity.taxIdError}</span>}
                    </div>
                    {
                            clientParams.type === 'generator' &&
                            <div className="admin__client__form__group_two_Column">
                                <label htmlFor="clientName">Generator Id *</label>
                                <input value={clientParams.generatorId} onChange={handleChange} onKeyUp={handleValidation} className="admin__client__input" type="text" id="generatorId" name="generatorId" placeholder="Enter Generator Id" required />
                                {clientValidity.generatorIdError && <span className="admin_input_error">{clientValidity.generatorIdError}</span>}
                            </div>
                        }
                </div>
            </form>
            </div>
            {title === "Onboard" && showModal && <ConfirmModal params={{...clientParams, type:pascalCase(clientParams.type)}} data={null} toggleModal={setShowModal} labels={labels} handleSubmit={submitClientOnboarding} />}
            {showSuccess ? <SuccessModal title="Client Onboarded" message="Client Onboarded Successfully!" toggleModal={setShowScuccess}/>: null}
            <div className="ppa__client__options">
                <button disabled={!clientValidity.isFormValid} className="forward__option" onClick={() => handleSubmit()}>{title}</button>
                <button className="cancel__option"  onClick={() => handleCancel()}>Cancel</button>
            </div>
        </div>
        </>
    )
}