import axios from "axios";
import { pca } from "../msalConfig";
import { getAccessToken, removeAccessToken } from "../utils/AuthUtils";
import { getIsOnline } from "../utils/OnlineUtils";

const instance = axios.create();

const unauthorizedMessages = [
    "Failed to authenticate. User not registered with PPA. Contact EY admin.",
    "User is Inactive. Please contact admin!"
]

instance.interceptors.request.use(
    config => {
        const loginTime = localStorage.getItem('lastLoggedIn');
        const currentTime = new Date().getTime();

        if((currentTime - loginTime) > 10*60*60*1000){
            pca.logoutRedirect();
            return;
        }
        const isOnline = getIsOnline();
        if(!isOnline){
            return;
        }
        const token = getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        Promise.reject(error)
});

instance.interceptors.response.use(function (response) {
    return response;
  }, async function (error) {
    if (error && error.response && error.response.status === 401) {
        if( error.response.data && unauthorizedMessages.includes(error.response.data.message)){
            return Promise.reject(error);
        } else {
            pca.logoutRedirect();
        }
    }
    return Promise.reject(error);
  });

export default instance;