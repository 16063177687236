import React, { useContext } from "react";
import "../Style/PPAStyles.css";

import PPAContext from "../../../../contexts/PPA/PPAContext";

const modelTypes = [
    { label: 'New PPA - Strategy Phase', value: 'new_PPA', disabled: false },
    { label: 'Existing PPA Valuation', value: 'existing_PPA', disabled: false  },
    { label: 'Risk Adjusted Return', value: 'risk_adjusted_return', disabled: true },
    { label: 'Monte Carlo Simulation', value: 'monte_carlo', disabled: true },
    { label: 'PPA Options- Contract Extension', value: 'options_contract_extension', disabled: true },
    { label: 'PPA Options- Early Termination', value: 'options_early_termination', disabled: true },
    { label: 'Energy Modelling', value: 'energy_modelling', disabled: true  },
]

const SelectModel = () => {
  const { formData, SetFormData, SetActiveTabId, errors } = useContext(PPAContext);

  const handleChange = ({ target }) => {
    if(target.name === 'modelType') {
        const newState = { ...formData, [target.name]: target.value };
        SetFormData(newState);
        if(target.value) {
            SetActiveTabId("2");
        }
    }
  };

  const startValuation = () => {
    SetActiveTabId("2");
  }

  return (
    <div className="add-evaluation_wrapper">
      <div className="add-evaluation-form-container col-md-12">
        <form className="add-evaluation-form col-md-12" onSubmit={() => {}}>
          <div className="col-md-3">
            <label className="avf-label" htmlFor="model-type">Select Model Type *</label>
            <div className="input-wrapper">
              <select
                className="admin__contract__input"
                id="model-type"
                name="modelType"
                value={formData?.modelType}
                onChange={handleChange}
                required
              >
                <option value="">Please Select A Model</option>
                {modelTypes.map((model) => (
                  <option id={ model.value } value={ model.value } key={ model.value } disabled={ model.disabled }>
                    {model.label}
                  </option>
                ))}
              </select>
              <span className="cppa-custom-tooltip">
                Select Model Type
              </span>
            </div>
            {errors.modelType ? <p className="error">Please select a model type</p> : null}
          </div>
        </form>
        <div className="ppa-actions">
          <button disabled={!formData?.modelType} className="forward__option btn-primary" onClick={startValuation}>
            Next
          </button>
        </div>
      </div>
      <div className="info-container">
        <span>
          NOTE: Fields marked (*) are mandatory.
        </span>
      </div>
    </div>
  );
}
export default SelectModel;
