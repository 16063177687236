import { useEffect, useState } from "react";
import "./ContractOverview.css"
import { useLocation } from "react-router-dom";
import { config } from '../../../../config'
import { twoDecimal } from "../../../../utils/display";
import PowerBI from "../../../../components/PowerBI";
import PowerBIReport from "../../../../components/PowerBIReport";

export default function ContractOverview({contract}) {
    const invoices = contract.invoices;
    let totalLgc = 0;
    let totalEnergyReceived = 0;
    let totalSpotPrice = 0;

    if(invoices && invoices.length!=0){
        const filteredInvoices = invoices.filter(invoice => invoice.retry == false);
        filteredInvoices.forEach((invoice)=>{
            totalEnergyReceived = totalEnergyReceived+ invoice.calculatedSuppliedVolume;
            totalSpotPrice = totalSpotPrice + invoice.spotPrice
        })
        totalSpotPrice= totalSpotPrice/filteredInvoices.length
    }

    return (
        <div className="tabs__container__box">
            <div className="actions_box">
            <div className="contract_id_banner"> 
                <span className="banner_title">Contract ID</span> 
                <span className="banner_id"> {contract._id} </span>
            </div>
             <div className="contract__param__details contract__param__details_overview">
                <div className="contract__param__cards">
                    <div className="contract__param__card">
                        <p className="param__name_overview">Spot Price</p>
                        <p className="param__value_overview">{totalSpotPrice >0 ? `${twoDecimal(totalSpotPrice)} ${contract.currency}`: 'NA'}
                            <span className="greenTriangle"></span>
                        </p>
                    </div>
                    <div className="contract__param__card">
                        <p className="param__name_overview">Strike Price</p>
                        <p className="param__value_overview"> {contract.strikePrice} {contract.currency}</p>
                    </div>
                    <div className="contract__param__card">
                        <p className="param__name_overview">Volume Of Energy Recieved</p>
                        <p className="param__value_overview">{totalEnergyReceived >0 ? `${twoDecimal(totalEnergyReceived)} MWh`: 'NA'}</p>
                    </div>
                    <div className="contract__param__card">
                        <p className="param__name_overview">LGC's/REC's Received</p>
                        <p className="param__value_overview">{totalEnergyReceived >0 ? `${twoDecimal(totalEnergyReceived)} `: 'NA'}
                            {
                                /**
                                 * TODO: uncomment once the logic has been implemented for green and red
                                 */
                            }
                            {/* <span className="redTriangle"></span> */}
                        </p>
                        
                    </div>
                </div>
            </div>
            {/* <div className="contract_graph_title">
                Forecasted Volume vs Actual Volume
            </div> */}
             {/* <iframe title="PowerChain Dashboard Mockup ExampleBar Page" className="offtaker-powerBI_report" src="https://app.powerbi.com/reportEmbed?reportId=07e709e2-0901-40ac-8f21-1633e9a4098d&autoAuth=true&ctid=5b973f99-77df-4beb-b27d-aa0c70b8482c" frameborder="0" allowFullScreen="true"></iframe> */}
             
                {/* <div className="contract_tile_legend">
                
                    <span className="legend_item">
                        <span className="rectangle light-blue"></span>
                        <span>Forecasted volume</span>
                    </span>
                    
                    <span className="legend_item">
                        <span className="rectangle dark-blue"></span>
                        <span>Actual volume</span>
                    </span>
                    
                </div> */}
            </div>
            <div className="powerBi-report-container">
                <PowerBIReport showTab={false} type="Multi_Tenancy_Contract_View - Offtaker" contractTitle={contract.contractTitle}/>
            </div>
        </div>
        
    )
}