import React, { useContext } from "react";

import PPAContext from "../../../../contexts/PPA/PPAContext";
import Result from './Result';
import ExistingPPAValuationResult from "./ExistingPPAValuationResult";
import "../Style/PPAStyles.css";

function ValuationResult() {
  const { formData } = useContext(PPAContext);

  return (
    <>
      {formData?.modelType === 'new_PPA' && <Result />}
      {formData?.modelType === 'existing_PPA' && <ExistingPPAValuationResult />}
    </>
  );
}
export default ValuationResult;
