export const CountryList = [
  "Australia New South Wales",
  "Australia Queensland",
  "Australia Tasmania",
  "Australia Victoria",
  "Australia Western Australia",
  "Brazil Central-West",
  "Brazil North",
  "Brazil Northeast",
  "Brazil South",
  "Brazil Southeast",
  "Finland",
  "France",
  "Germany",
  "India Eastern Region",
  "India Northeastern Region",
  "India Northern Region",
  "India Southern Region",
  "India Western Region",
  "Italy C.North",
  "Italy C. South",
  "Italy Calabria",
  "Italy North",
  "Italy Sardinia",
  "Italy Sicily",
  "Italy South",
  "Netherlands",
  "New Zealand - North",
  "New Zealand - South",
  "Poland",
  "Portugal",
  "Romania",
  "Singapore",
  "Spain",
  "Sweden 1",
  "Sweden 2",
  "Sweden 3",
  "Sweden 4",
  "Turkey",
  "U.S. CAISO",
  "U.S. ERCOT",
  "U.S. ISO-NE",
  "U.S. MISO",
  "U.S. Northwest",
  "U.S. NYISO",
  "U.S. PJM",
  "U.S. Southeast",
  "U.S. Southwest",
  "U.S. SPP",
  "United Kingdom",
];

export const TechnologyList = ["PV (fixed)", "Wind (onshore)", "Mix PV (fixed) & Wind (onshore)"];

export const PPAVolumeStructureList = ["Pay as produced", "Baseload"];

export const PPAVolumeCapacityUnit = ["MW", "MWh/yr"];

export const PPAPricingList = ["Fix", "Indexed with CPI", "Custom"];

export const DegradationList = ["0.5 %", "0.25 %", "Custom fixed"];

export const DiscountingFactorList = ["Risk free rate", "Custom fixed"];

export const ChangingVolumeList = ["Yes", "No"];

export const UseBenchMarkList = ["Yes", "No"];

export const PowerPriceCurveList = ["Yes", "No"];

export const GOOPriceCurveList = ["Yes", "No"];

export const CarbonIntensityOfGridList = ["Yes", "No"];

export const PPAYearList = [
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
  "2041",
  "2042",
  "2043",
  "2044",
  "2045",
  "2046",
  "2047",
  "2048",
  "2049",
  "2050",
  "2051",
  "2052",
  "2053",
  "2054",
  "2055",
  "2056",
  "2057",
  "2058",
  "2059",
  "2060",
  "2061",
  "2062",
  "2063",
  "2064",
  "2065",
  "2066",
  "2067",
  "2068",
  "2069",
  "2070",
  "2071",
  "2072",
  "2073",
  "2074",
  "2075",
  "2076",
  "2077",
  "2078",
  "2079",
  "2080",
];

export const PPASenarioList = ["Medium", "Low", "High"];

export const CountryLocationList = [
  "Australia New South Wales",
  "Australia Queensland",
  "Australia Tasmania",
  "Australia Victoria",
  "Australia Western Australia",
  "Brazil Central-West",
  "Brazil North",
  "Brazil Northeast",
  "Brazil South",
  "Brazil Southeast",
  "Finland",
  "France",
  "Germany",
  "India Eastern Region",
  "India Northeastern Region",
  "India Northern Region",
  "India Southern Region",
  "India Western Region",
  "Italy C.North",
  "Italy C. South",
  "Italy Calabria",
  "Italy North",
  "Italy Sardinia",
  "Italy Sicily",
  "Italy South",
  "Netherlands",
  "New Zealand - North",
  "New Zealand - South",
  "Poland",
  "Portugal",
  "Romania",
  "Singapore",
  "Spain",
  "Sweden 1",
  "Sweden 2",
  "Sweden 3",
  "Sweden 4",
  "Turkey",
  "U.S. CAISO",
  "U.S. ERCOT",
  "U.S. ISO-NE",
  "U.S. MISO",
  "U.S. Northwest",
  "U.S. NYISO",
  "U.S. PJM",
  "U.S. Southeast",
  "U.S. Southwest",
  "U.S. SPP",
  "United Kingdom",
];

export const CountryCurrencyList = [
  {country:"Australia New South Wales",currency:"AUD"},
  {country:"Australia Queensland",currency:"AUD"},
  {country:"Australia Tasmania",currency:"AUD"},
  {country:"Australia Victoria",currency:"AUD"},
  {country:"Australia Western Australia",currency:"AUD"},
  {country:"Brazil Central-West",currency:"BRL"},
  {country:"Brazil North",currency:"BRL"},
  {country:"Brazil Northeast",currency:"BRL"},
  {country:"Brazil South",currency:"BRL"},
  {country:"Brazil Southeast",currency:"BRL"},
  {country:"Finland",currency:"EUR"},
  {country:"France",currency:"EUR"},
  {country:"Germany",currency:"EUR"},
  {country:"India Eastern Region",currency:"INR"},
  {country:"India Northeastern Region",currency:"INR"},
  {country:"India Northern Region",currency:"INR"},
  {country:"India Southern Region",currency:"INR"},
  {country:"India Western Region",currency:"INR"},
  {country:"Italy C.North",currency:"EUR"},
  {country:"Italy C. South",currency:"EUR"},
  {country:"Italy Calabria",currency:"EUR"},
  {country:"Italy North",currency:"EUR"},
  {country:"Italy Sardinia",currency:"EUR"},
  {country:"Italy Sicily",currency:"EUR"},
  {country:"Italy South",currency:"EUR"},
  {country:"Netherlands",currency:"EUR"},
  {country:"New Zealand - North",currency:"NZD"},
  {country:"New Zealand - South",currency:"NZD"},
  {country:"Poland",currency:"PLN"},
  {country:"Portugal",currency:"EUR"},
  {country:"Romania",currency:"RON"},
  {country:"Singapore",currency:"SGD"},
  {country:"Spain",currency:"EUR"},
  {country:"Sweden 1",currency:"SEK"},
  {country:"Sweden 2",currency:"SEK"},
  {country:"Sweden 3",currency:"SEK"},
  {country:"Sweden 4",currency:"SEK"},
  {country:"Turkey",currency:"TRY"},
  {country:"U.S. CAISO",currency:"USD"},
  {country:"U.S. ERCOT",currency:"USD"},
  {country:"U.S. ISO-NE",currency:"USD"},
  {country:"U.S. MISO",currency:"USD"},
  {country:"U.S. Northwest",currency:"USD"},
  {country:"U.S. NYISO",currency:"USD"},
  {country:"U.S. PJM",currency:"USD"},
  {country:"U.S. Southeast",currency:"USD"},
  {country:"U.S. Southwest",currency:"USD"},
  {country:"U.S. SPP",currency:"USD"},
  {country:"United Kingdom",currency:"GBP"},
];


export const yesOrNoList = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]

export const modelTypes = [
  { label: 'New PPA - Strategy Phase', value: 'new_PPA', disabled: false },
  { label: 'Existing PPA Valuation', value: 'existing_PPA', disabled: false  },
  { label: 'Risk Adjusted Return', value: 'risk_adjusted_return', disabled: true },
  { label: 'Monte Carlo Simulation', value: 'monte_carlo', disabled: true },
  { label: 'PPA Options- Contract Extension', value: 'options_contract_extension', disabled: true },
  { label: 'PPA Options- Early Termination', value: 'options_early_termination', disabled: true },
  { label: 'Energy Modelling', value: 'energy_modelling', disabled: true  },
];

export const existingPPACurveTypes = [
  { label: 'Black Market Price Curve', value: 'blackMarketPriceCurve' },
  { label: 'Green Market Price Curve', value: 'greenMarketPriceCurve' },
  { label: 'MLF Curve', value: 'mlfCurve'},
  { label: 'DLF Curve', value: 'dlfCurve'},
  { label: 'Inflation Curve', value: 'inflationCurve'},
  { label: 'RiskFree Rate Curve', value: 'riskFreeRateCurve'},
  { label: 'Asset Credit Risk Adjustment Curve', value: 'assetCreditRiskAdjustmentCurve'},
  { label: 'Liability Credit Risk Adjustment Curve', value: 'liabilityCreditRiskAdjustmentCurve'},
];

export const existingPPASenarioList = ["Medium", "Low", "High", 'EY', 'Custom'];
 