import { PublicClientApplication } from '@azure/msal-browser';
export const pca = new PublicClientApplication({
    auth: {
      clientId: "8a4bf8a7-cce2-41e4-b930-feb30e48cd6f",
      authority: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c",
      redirectUri: "/"
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPII) => {
          console.log('System auth error *****', message);
        },
        logLevel: "info"
      },
      tokenRenewalOffsetSeconds: 1800
    }
  });