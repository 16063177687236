import {useState, useReducer, useContext} from 'react';

import axios from '../../../../interceptors/axiosInstance';
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { handleDateFormat } from "../../../../utils/DateFormating";
import { DatePicker } from '../../../../components/General/DataEntry/DatePicker/DatePicker';
import { CountryList } from "../../../../utils/CountryList";
import { decimalNumberRegex } from "../../../../utils/ValidationRules";
import { LoaderContext } from '../../../../context/LoaderContext';
import { config } from '../../../../config';
import "./EnergyGenerationData.css"
import { MessageContext } from '../../../../context/MessageContext';
import SuccessModal from '../../../../components/SuccessModal';

const initialState = {
    source:'',
    location:'',
    generatedVolume:'',
    accessEndDate:'',
    duration:'',
    variables:''
}

const initialErrorState = {
    source:'',
    location:'',
    generatedVolume:'',
    accessEndDate:'',
    duration:'',
    variables:''
}


const formReducer = (state, action) => {
    if (action.type === "RESET_FORM") {
        return initialState;
    } else {
        const { name, value } = action.type;
        return {
            ...state, [name]: value,
        }
    }
}

const requiredFields = ["location","generatedVolume","source","accessEndDate"];
const selectFields = ["source","location"];
const sourceOptions = [
    {name: 'Solar (Fixed)', value: 'solar_Fixed'},
    {name: 'Onshore Wind', value: 'wind_onshore'},
    {name: 'Offshore Wind', value: 'wind_offshore'},
    {name: 'Solar (Tilt/Tracker)', value: 'solar_tilt'},
    {name: 'Battery', value: 'battery'},
    {name: 'Other', value: 'other'}
]

const formValidityReducer = (state, action) => {
    let isValid = false;
    if (action.type === "RESET_FORM_VALIDITY") {
        return action.payload;
    } else {
        let errorData = { ...state };
        switch (action.type) {
            case "SOURCE": {
                isValid = action.payload.source.trim().length > 0 ? true : false;
                errorData = { ...errorData, source: isValid ? '' : 'Select Energy Source' };
                break;
            }
            case "LOCATION": {
                isValid = action.payload.location.trim().length > 0 ? true : false;
                errorData = { ...errorData, location: isValid ? '' : 'Select Location' };
                break;
            }
            case "GENERATEDVOLUME": {
                isValid = decimalNumberRegex.test(action.payload.generatedVolume) ? true : false;
                errorData = { ...errorData, generatedVolume: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "ACCESSENDDATE": {
                isValid = action.payload && action.payload.accessEndDate && action.payload.accessEndDate.trim().length > 0 ? true : false;
                errorData = { ...errorData, accessEndDate: isValid ? '' : 'Select Date' };
                break;
            }
            case "DURATION": {
                isValid = action.payload.duration === '' || decimalNumberRegex.test(action.payload.duration) ? true : false;
                errorData = { ...errorData, duration: isValid ? '' : 'Enter Valid Data' };
                break;
            }
            case "VARIABLES": {
                isValid = action.payload.variables === '' || action.payload.variables.trim().length > 0 ? true : false;
                errorData = { ...errorData, variables: isValid ? '' : 'Enter Valid Data' };
                break;
            }
        }
        const { isFormValid, ...errors } = errorData;
        if (requiredFields.some((key) => (action.payload[key] === '')) || Object.keys(errors).some((key) => (errors[key] !== ''))) {
            errorData.isFormValid = false;
        } else {
            errorData.isFormValid = true;
        }

        return errorData;
    }
}

export const EnergyGenerationData = () => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [accessEndDate, setAccessEndDate] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)
    // const [dataParams, setdataParams] = useState({});
    const [dataParams, setDataParams] = useReducer(formReducer, initialState);
    const [dataValidity, setDataValidity] = useReducer(formValidityReducer, initialErrorState);
    const {setLoading} = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const handleChange = (e) => {
        if(selectFields.includes(e.target.name)){
            setDataParams({ type: e.target })
            const { name, value } = e.target;
            setDataValidity({ type: name.toUpperCase(), payload: { ...dataParams, [name]: value} })
        } else{
            setDataParams({ type: e.target })
        }
    }

    const handleCalendarChange = (value) => {
        const fdate = value ? handleDateFormat(value):value;
        setDataParams({type: {name:"accessEndDate", value:fdate}})
        setDataValidity({type:"ACCESSENDDATE", payload: { ...dataParams, "accessEndDate": fdate }});
        setShowCalendar(false);
    }

    const submitEnergyDetails = async () => {
        try {
            setLoading(true);
            const result = await axios.post(`${config.BASE_URL}/api/uploadData`, {...dataParams});
            setShowSuccess(true);
            setShowModal(false);
            setDataParams({ type: "RESET_FORM" });
            setDataValidity({ type: "RESET_FORM_VALIDITY", payload: initialErrorState });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setShowModal(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }

    const handleSubmit =  () => {
        console.log(`The data to be sent is ${JSON.stringify({accessEndDate, ...dataParams })}`)
        setShowModal(true);
    }

    const handleValidation = (e) => {
        setDataValidity({ type: e.target.name.toUpperCase(), payload: dataParams });
    }

    const handleCancel = () => {
        setShowModal(false);
        setDataParams({ type: "RESET_FORM" });
        setDataValidity({ type: "RESET_FORM_VALIDITY", payload: initialErrorState });
    }
    return (
        <div className="add-user_wrapper"> 
        <div className="add-energy-form-container">        
            <form className="add-energy-form" autocomplete="off">
            <div className="add-energy-form-group">
                    <label htmlFor="energy-source">Energy Source * </label>
                    <select onChange={handleChange} value={dataParams.source} className="add-energy-input add-energy-select-input" type="text" id="source" name="source" required>
                        <option value="">Select Energy Source</option>
                        {
                        sourceOptions.map((item) => <option id={item.value} value={item.value} >{item.name}</option>)
                        }
                    </select>
                    {dataValidity.source && <p class="text_error">{dataValidity.source}</p>}
                </div>
                <div className="add-energy-form-group">
                    <label htmlFor="location">Location * </label>
                    <select onChange={handleChange} value={dataParams.location} className="add-energy-input add-energy-select-input" type="text" id="location" name="location" required>
                    <option value="">Select location</option>
                    {
                        CountryList.map((country, index) => <option id={index} value={country} >{country}</option>)
                    }
                    </select>
                    {dataValidity.location && <p class="text_error">{dataValidity.location}</p>}
                </div>
                <div className="add-energy-form-group">
                    <label htmlFor="generatedVolume">Generated Volume (MWh)*</label>
                    <input 
                        onChange={handleChange} 
                        onKeyUp={handleValidation}
                        value={dataParams.generatedVolume} 
                        className="add-energy-input" 
                        type="text" id="generatedVolume" 
                        name="generatedVolume" 
                        placeholder="Enter Volume" 
                        required 
                    />
                    {dataValidity.generatedVolume && <p class="text_error">{dataValidity.generatedVolume}</p>}
                </div>
                <div className="add-energy-form-group">
                    {/* <label htmlFor="access-end-date">Date *</label>
                    <img className="cal__icon" onClick={() => setShowCalendar(true) } src={"/icons/calendar.svg"} alt="cal" />
                    {showCalendar && <CustomCalendar  value={dataParams.accessEndDate} onChange={handleCalendarChange} />}
                    <input className="add-energy-input" value={dataParams.accessEndDate} type="text" id="access-end-date" name="accessEndDate" placeholder="Enter End Date" required />
                    {dataValidity.accessEndDate && <p class="text_error">{dataValidity.accessEndDate}</p>} */}
                    <DatePicker formClass={"add-energy-date-selector"} label="Date *" CalValue={dataParams.accessEndDate} setCalValue={handleCalendarChange} id={"accessEndDate"} placeholder={"Enter End Date"} category={"accessEndDate"} />
                </div>
                <div className="add-energy-form-group">
                    <label htmlFor="duration">Duration To Generate (Days)</label>
                    <input 
                        onChange={handleChange} 
                        onKeyUp={handleValidation}
                        value={dataParams.duration} 
                        className="add-energy-input" 
                        type="text" id="duration" 
                        name="duration" 
                        placeholder="Enter Duration" 
                        required 
                    />
                    {dataValidity.duration && <p class="text_error">{dataValidity.duration}</p>}

                </div>
                <div className="add-energy-form-group">
                    <label htmlFor="variables">Variables</label>
                    <input 
                        onChange={handleChange} 
                        onKeyUp={handleValidation}
                        value={dataParams.variables} 
                        className="add-energy-input" 
                        type="text" id="variables" 
                        name="variables" 
                        placeholder="Enter Variables" 
                        required 
                    />
                    {dataValidity.variables && <p class="text_error">{dataValidity.variables}</p>}
                </div>
              </form>
              { 
                showModal && 
                <ConfirmModal
                    title = "Please Confirm Before Proceeding"
                    data={[
                        {
                            label: 'Energy Source',
                            value: sourceOptions.filter(item=> item.value === dataParams.source)[0].name
                        },
                        {
                            label: 'Location',
                            value: dataParams.location
                        },
                        {
                            label: 'Generated Volume (MWh)',
                            value: dataParams.generatedVolume
                        },
                        {
                            label: 'Date',
                            value: dataParams.accessEndDate
                        },
                        {
                            label: 'Duration To Generate (Days)',
                            value: dataParams.duration
                        },
                        {
                            label: 'Variables',
                            value: dataParams.variables
                        },
                    ]} 
                    toggleModal={setShowModal} 
                    handleSubmit={()  =>submitEnergyDetails()}
                />
            }
            {
                showSuccess ? <SuccessModal title="Successfully uploaded" message="Energy generation data has been added successfully" toggleModal={setShowSuccess}/>: null 
            }
              <div className="add-energy-actions">
                <button 
                    disabled={!dataValidity.isFormValid}
                    className="confirm-button" 
                    onClick={handleSubmit}
                >
                    Submit
                </button>
                <button className="cancel-button"  onClick={handleCancel}>Cancel</button>
            </div>
            </div>
            <div class="info-container">
                <span>NOTE: Fields marked (*) are mandatory</span>
            </div>
            </div>
    )

}