import { useState, useContext } from "react";
import * as moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';

import axios from "../../../../interceptors/axiosInstance";
import AdminModifyContractModal from "../../../../components/AdminModifyContractModal/AdminModifyContractModal";
import AdminDeleteContractModal from "../../../../components/AdminDeleteContractModal";
import AdminModifyContractConfirmationModal from "../../../../components/AdminModifyContractConfirmationModal/AdminModifyContractConfirmationModal";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { Table } from "../../../../components/Table/Table";
import { LoaderContext } from "../../../../context/LoaderContext";
import { config } from '../../../../config';
import "./modifyContract.css";
import { pascalCase } from "../../../../utils/display";
import { MessageContext } from "../../../../context/MessageContext";
import SuccessModal from "../../../../components/SuccessModal";

export const ModifyContract= ({ title, contracts = [], setContracts, fetchContracts }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedContract, setSelectedContract] = useState({});
    const [updatedContractParams, setUpdatedContractParams] = useState({})
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showSuccess, setShowScuccess] = useState(false);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);

    const columnHelper = createColumnHelper();
    const actionIconPath = 
    {
    "View": {actionIcon: "edit_green.svg", btnIcon: "edit_black.svg", btnColor: "#8DE8AD"} ,
    "Delete": {actionIcon: "trash_red.svg", btnIcon: "trash_black.svg", btnColor: "#FF9A91"}, 
    "Terminate": {actionIcon: "archive_blue.svg" , btnIcon: "archive_black.svg", btnColor: "#87D3F2"}
    } 
    const columns = [
        columnHelper.accessor('_id', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Contract ID',
            enableGlobalFilter: true,
        }),
        columnHelper.accessor('contractTitle', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Contract Title',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row => {
                if(row.generator && row.generator.clientName)
                    return row.generator.clientName;
                return '';
            },
            {
                cell: info => info.getValue(),
                footer: info => info.column.id,
                header: 'Generator',
                enableGlobalFilter: true,
            }
        ),
        columnHelper.accessor(
            row => {
                if(row.offtaker && row.offtaker.clientName)
                    return row.offtaker.clientName;
                return '';
            }, 
            {
                cell:  info => info.getValue(),
                footer: info => info.column.id,
                header: 'Client',
                enableGlobalFilter: true 
            }
        ),
        columnHelper.accessor('location', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Location',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('contractSunsetDate', {
            cell: info => {
                const endDate= info.getValue();                
                return endDate ? moment(endDate).format('YYYY-MM-DD') : '';
            },
            footer: info => info.column.id,
            header: 'Contract End Date',
            enableGlobalFilter: false,
        }),
        columnHelper.accessor(
            row =>{
                if(row.status === 'active'){
                    const currentDate = moment(new Date());
                    const contractEndDate = moment(row.contractSunsetDate); 
                    const duration = contractEndDate.diff(currentDate, 'months');
                    if(duration < 12){
                        return 'Near Renewal';
                    }
                }
                return  pascalCase(row.status, '-');
            }, {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: 'Status',
            enableGlobalFilter: false,
        }),
        columnHelper.display({
            id: 'actions',
            cell: props => {
                if(title === 'Terminate' && (props.row.original.status === 'terminated' || props.row.original.status === 'expired')) {
                    return '';
                } 
                
                return (
                    <img 
                        src={`/icons/${actionIconPath[title].actionIcon}`} 
                        alt="remove" 
                        class="remove-user-icon" 
                        onClick={() => { selectData(props.row.original) }} 
                    />
                )
            },
            header: 'Actions'
        }),
    ];

    const selectData = (contract) => {
        window.scrollTo(0,0);
        setSelectedContract(contract);
        setShowModal(true);
    }

    const hanldeDeleteContract = () => {
        setShowScuccess(true)
        setShowModal(false)
        // todo call api to delete contract here
    }

    const hanldeTerminateContract = async () => {
        try {
            setLoading(true);
            await axios.post(`${config.BASE_URL}/api/contract/terminate`, {id: selectedContract._id});
            setLoading(false);
            setShowScuccess(true);
            setShowModal(false);
            fetchContracts();
        } catch(error) {
            setLoading(false);
            setShowModal(false);
             if(error.response && error.response.data && error.response.data.message){
                ErrorToast('Failed To Terminate Contract');
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }
    }


    return(
        <div className="contract__change__request__container">
            <Table columns={columns} data={contracts} placeholder="Search By Generator Or Offtaker Name" setData={setContracts} />
            {title==="View" && showModal &&  <AdminModifyContractModal toggleModal={setShowModal} setShowConfirmation={setShowConfirmation} contract={selectedContract} setUpdatedContractParams={setUpdatedContractParams}/>}
           { title==="Delete" && showModal && <ConfirmModal
                    title="Are You Sure You Want To Delete The Below Contract" 
                    type = "Delete contract"
                    data={[
                        {
                            label: 'Contract ID',
                            value: selectedContract.contractID
                        },
                        {
                            label: 'Generator',
                            value: selectedContract.generator
                        },
                        {
                            label: 'Client',
                            value: selectedContract.client
                        },
                        {
                            label: 'Contract End Date',
                            value: selectedContract.contractSunsetDate
                        },
                        {
                            label: 'Status',
                            value: pascalCase(selectedContract.status)
                        },
                    ]} 
                    toggleModal={setShowModal} 
                    handleSubmit={hanldeDeleteContract}
                /> 
                }

{ title==="Terminate" && showModal && <ConfirmModal
                    title="Are You Sure You Want To Terminate The Below Contract" 
                    type = "Terminate Contract"
                    data={[
                        {
                            label: 'Contract ID',
                            value: selectedContract._id
                        },
                        {
                            label: 'Generator',
                            value: selectedContract.generator.clientName
                        },
                        {
                            label: 'Client',
                            value: selectedContract.offtaker.clientName
                        },
                        {
                            label: 'Contract End Date',
                            value: moment(selectedContract.contractSunsetDate).format('YYYY-MM-DD')
                        },
                        {
                            label: 'Status',
                            value: pascalCase(selectedContract.status,'-')
                        },
                    ]} 
                    toggleModal={setShowModal} 
                    handleSubmit={hanldeTerminateContract}
                /> 
                }
            {showConfirmation ? <AdminModifyContractConfirmationModal toggleModal={setShowConfirmation} contract={updatedContractParams}  setShowScuccess={setShowScuccess} title={"Please confirm the below details"}/> : null}
            {title==="View" && showSuccess && <SuccessModal title="Success" message="Contract modified successfully!" toggleModal={setShowScuccess}/>}
            {title==="Delete" && showSuccess && <SuccessModal title="Deleted" message="Contract deleted successfully!" toggleModal={setShowScuccess}/>}
            {title==="Terminate" && showSuccess && <SuccessModal title="Terminated" message="Contract terminated successfully!" toggleModal={setShowScuccess}/>}
        </div>
    )
}