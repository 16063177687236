export const emailRegEx =  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,9}$/;
export const decimalNumberRegex = /^\d*(?:[.]\d{1,2})?$/;
export const negativeNumberRegex = /^-?\d+(?:[.]\d{1,2})?$/;
export const whiteSpaceStringRegex = /^(?! )[A-Za-z0-9+-=.& ]*(?<! )$/;
export const wholeNumberRegex = /^(?<![-.])\b[0-9]+\b(?!\.[0-9])$/;
export const textAreaRegex = /^[A-Za-z0-9+-=.&  \n]*$/;
export const negativeNumberWithHigherLimitRegex = /^-?\d+(?:[.]\d{1,8})?$/;
export const decimalNumberWithHigherLimitRegex = /^\d+(?:[.]\d{1,8})?$/;
export const decimalNumberWith2DecimalRegex = /^\d+(?:[.]\d{1,2})?$/;
