import { useState,React, useEffect } from "react"
import "./horizontaltabnav.css";

export default function HorizontalTabNavigation({tabs, initialTab, onTabChange, disableSearch}) {
    const [activeTab, setActiveTab] = useState(initialTab);
 
    const handleTabClick = (tab) => {
        console.log(`this is the tab clicked ${JSON.stringify(tab, null, 2)}`)
        setActiveTab(tab.id)
        onTabChange(tab)
    }

    useEffect(()=>{
        setActiveTab(initialTab)
    },[initialTab])
   
    return(
        <div className="tab__nav__container">
             <div className="tab__navigation" >
                    {
                        tabs.map((tab) => (
                            <div id={tab.id} 
                                key={tab.id} 
                                className={`tab-item ${tab.id === activeTab ? 'active': ''}`}
                                onClick={() => handleTabClick(tab)}
                            >
                                <p>{tab.label}</p>
                            </div>
                        ))
                    }
             </div>
        </div>
       
    )
}