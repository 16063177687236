import { React, useEffect, useState } from "react";
import "./HorizontalTabNav.css";

export default function HorizontalTabNavigation({ tabs, initialTab, onTabChange, disableSearch, innerRef }) {
  const [activeTab, setActiveTab] = useState(initialTab);
  //const tabRef= useRef();

  const handleTabClick = (tab) => {
    console.log(`this is the tab clicked ${JSON.stringify(tab, null, 2)}`);
    setActiveTab(tab.id);
    onTabChange(tab);
  };

  useEffect(() => {
    if (initialTab) {
      var list = innerRef.current.children;

      Array.from(innerRef.current.children).forEach((element) => {
        if (element.id === initialTab) {
          element.className = "tab-item active";
        } else {
          element.className = "tab-item readonly";
        }
      });
    }
  }, [initialTab]);

  return (
    <div className="tab__nav__container">
      <div className="add-evaluation_navigation tab__navigation" ref={innerRef}>
        {tabs.map((tab) => (
          <div
            id={tab.id}
            key={tab.id}
            className={`tab-item ${tab.id === activeTab ? "active" : "readonly"}`}
            onClick={() => handleTabClick(tab)}
          >
            <p>{tab.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
