import { useState, useEffect, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Header from '../../components/Header/header';
import CollapsibleSideNav from '../../components/CollapsibleSideNav/CollapsibleSideNav';
import OnboardClient from "../../pages/Admin/ManageClient/OnboardClient/OnboardClient";
import { ModifyUser } from "../../pages/Admin/ManageUsers/ModifyUser/ModifyUser"
import AdminContractOnboarding from "../../pages/Admin/OnboardContract/OnboardContract";
import ListOfftakerContract from "../../pages/Offtaker/ContractsManagement/ListContract/ListContract"
import ListGeneratorContract from '../../pages/Generator/ContractsManagement/ListContract/ListContract';
import GeneratorContractDetailedTabView from '../../pages/Generator/ContractsManagement/ContractDetailedTabView/contractDetailedTabView';
import OffTakerContractDetailedOverview from '../../pages/Offtaker/ContractsManagement/ContractDetailedTabView/contractDetailedTabView';
import Support from '../../pages/Support/support';
import { AddUser } from '../../pages/ManageUsers/AddUser/AddUser';
import { RemoveUser } from '../../pages/ManageUsers/RemoveUser/RemoveUser';
import { UploadData } from '../../pages/Generator/UploadData/UploadData'
import { InvoiceReconciliationDetails } from '../../pages/Offtaker/Invoices/ViewInvoice/ViewInvoice'
import ContractRequest from '../../components/ContractRequests/ContractRequests';
import UserRequest from '../../components/UserRequests/userRequests';
import ManageContract from '../../pages/Admin/ManageContract/ManageContract';
import { CommonFooter } from '../../components/CommonFooter/CommonFooter';
import Loader from '../../components/General/Loader/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { RemoveClient } from '../../pages/Admin/ManageClient/RemoveClient/RemoveClient';
import axios from '../../interceptors/axiosInstance';
import { config } from '../../config'
import { DataUpload } from '../Admin/DataUpload/DataUpload';
import Consultant from "../../pages/PPA/Consultant/Consultant";
import PPAValuation from "../../pages/PPA/Valuation/PPAValuation";
import { PPAContextProvider } from "../../contexts/PPA/PPAContext";
import { UserContext } from "../../context/UserContext";
import PowerBIReport from '../../components/PowerBIReport';
import { MessageContext } from '../../context/MessageContext';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';

export const Home = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [authUser, setAuthUser] = useState({});
    const [acceptedPolicy, setAcceptedPolicy] = useState(true);
    const [policy,setPolicy] = useState('');
    const { ErrorToast } = useContext(MessageContext);
    const [showPrivacyPolicy,setShowPrivacyPolicy] = useState(false);

    const fetchUserDetails = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${config.BASE_URL}/api/user/detail`);
            setAuthUser(res.data)
            setAcceptedPolicy(res.data.policyAccepted);
            setPolicy(res.data.policyContent)
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.status == 403) {
                ErrorToast('Failed to authenticate. User not registered with PPA. Contact EY admin.');
            } else {
                ErrorToast('Failed to authenticate user.');
            }
            setAuthUser({});
            setLoading(false);
        }
    }

    const acceptPolicy = async () => {
        setLoading(true);
        try {
            const res = await axios.post(`${config.BASE_URL}/api/paans/accept`);
            setAcceptedPolicy(true);
            setLoading(false)
        } catch (error) {
            ErrorToast('Something Went Wrong');
            setLoading(false);
        }
    }

    const setPolicyContentData = () =>{
        window.scrollTo(0,0);
        setShowPrivacyPolicy(true);
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const toggleSideNav = (collapse) => {
        setIsCollapsed(!collapse)
    }

    const powerBITabs = [
        { id: 'tab1', type: "Multi_Tenancy_Portfolio_View - Offtaker", label: 'Portfolio' },
        { id: 'tab2', type: "Multi_Tenancy_Contract_View - Offtaker", label: 'Summary' }
    ]

    return (
        <>
                <Header user={authUser} toggleSideNav={toggleSideNav} isCollapsed={isCollapsed} />  
                    <div className="main__nav">
                    <CollapsibleSideNav toggleSideNav={toggleSideNav} isCollapsed={isCollapsed} role={authUser.accessLevel || ''} />
                    {loading && <Loader visible={loading} />}
                    { acceptedPolicy &&  authUser._id && authUser.accessLevel && (
                        <div className="main__page">
                            <LoaderContext.Provider value={{ loading, setLoading }}>
                                <UserContext.Provider value={{ authUser, setAuthUser }}>
                                    <PPAContextProvider>
                                        {
                                            authUser.accessLevel === 'generator' && (
                                                <Routes>
                                                    <Route path="/" element={<PowerBIReport showTab={false} type='Multi_Tenancy_Portfolio_View'/>} />
                                                    <Route path="/contracts" element={<ListGeneratorContract />} />
                                                    <Route path="/contract/requests" element={<ContractRequest />} />
                                                    <Route path="/manage/contract" element={<GeneratorContractDetailedTabView />} />
                                                    <Route path="/manage/contract/Invoice/details" element={<InvoiceReconciliationDetails />} />
                                                    <Route path="/upload-data" element={<UploadData />} />
                                                    <Route path="/support" element={<Support />} />
                                                    <Route path="*" element={<Navigate replace to="/" />} />
                                                </Routes>
                                            )
                                        }
                                        {
                                            authUser.accessLevel === 'offtaker' && (
                                                <Routes>
                                                    <Route path="/" element={<PowerBIReport type="Multi_Tenancy_Portfolio_View - Offtaker" showTab={true} tabs={powerBITabs} />} />
                                                    <Route path="/contracts" element={<ListOfftakerContract />} />
                                                    <Route path="/contract/requests" element={<ContractRequest />} />
                                                    <Route path="/manage/contract" element={<OffTakerContractDetailedOverview />} />
                                                    <Route path="/manage/contract/invoice/details" element={<InvoiceReconciliationDetails />} />
                                                    <Route path="/support" element={<Support />} />
                                                    <Route path="*" element={<Navigate replace to="/" />} />
                                                </Routes>
                                            )
                                        }
                                        {
                                            (authUser.accessLevel === 'admin') && (
                                                <Routes>
                                                    <Route path="/" element={<ManageContract />} />
                                                    <Route path="/PPAValuation" element={<PPAValuation />} />
                                                    <Route path="/Consultant" element={<Consultant />} />
                                                    <Route path="/contract/manage" element={<ManageContract />} />
                                                    <Route path="/contract/request" element={<ContractRequest type="admin" />} />
                                                    <Route path="/user/request" element={<UserRequest />} />
                                                    <Route path="/manage-users/add" element={<AddUser />} />
                                                    <Route path="/manage-users/remove" element={<RemoveUser />} />
                                                    <Route path="/manage-users/modify" element={<ModifyUser title="Modify User" />} />
                                                    <Route path="/contract/onboard" element={<AdminContractOnboarding title="Onboard" />} />
                                                    <Route path="/client/onboard" element={<OnboardClient title="Onboard" />} />
                                                    <Route path="/client/remove" element={<RemoveClient title="Remove" />} />
                                                    <Route path="/data/upload" element={<DataUpload />} />
                                                    <Route path="*" element={<Navigate replace to="/" />} />
                                                </Routes>
                                            )
                                        }
                                        {
                                            (authUser.accessLevel === 'ppa_valuation') && (
                                                <Routes>
                                                    <Route path="/PPAValuation" element={<PPAValuation />} />
                                                    <Route path="/Consultant" element={<Consultant />} />
                                                    <Route path="*" element={<Navigate replace to="/PPAValuation" />} />
                                                </Routes>
                                            )
                                        }
                                        {
                                            (authUser.accessLevel === 'ey_consultant') && (
                                                <Routes>
                                                    <Route path="/PPAValuation" element={<PPAValuation />} />
                                                    <Route path="*" element={<Navigate replace to="/PPAValuation" />} />
                                                </Routes>
                                            )
                                        }
                                    </PPAContextProvider>
                                </UserContext.Provider>
                            </LoaderContext.Provider>
                            <CommonFooter policyContentClicked={setPolicyContentData}/>
                        </div>
                    )}
                    { showPrivacyPolicy && <ConfirmModal showTermsAndCondition={true} content={policy} showAcceptButton={false} toggleModal={setShowPrivacyPolicy}></ConfirmModal>}
                    {
                        !acceptedPolicy &&
                        <ConfirmModal showTermsAndCondition={true} type="Accept" content={policy} handleSubmit={acceptPolicy}></ConfirmModal>
                    }
                </div>
        </>
    )
}