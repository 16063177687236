import { useContext, useRef } from "react";
import PPAContext from "../../../contexts/PPA/PPAContext";
import PPAParameter from "../../../pages/PPA/Valuation/PPAParameter/PPAParameter";
import ValuationResult from "../../../pages/PPA/Valuation/Result/ValuationResult";
import StartEvaluation from "../../../pages/PPA/Valuation/StartEvaluation/StartEvaluation";
import HorizontalTabNavigation from "../HorizontalTabNavigation/HorizontalTabNav";
import "./Tabs.css";
import Loader from "../Loader/Loader";
import SelectModel from "../../../pages/PPA/Valuation/SelectModel/SelectModel";

export default function Tabs() {
  const childRef = useRef();

  const { tabs, activeTabId, SetActiveTabId, isLoading } = useContext(PPAContext);

  const handleTabChange = (tab) => {
    SetActiveTabId(tab.id);
  };

  return (
    <div className="tabs__container__box">
      <HorizontalTabNavigation
        innerRef={childRef}
        tabs={tabs}
        initialTab={activeTabId}
        onTabChange={handleTabChange}
      />
      { activeTabId === "1" && <SelectModel /> }
      {activeTabId === "2" && <StartEvaluation />}
      {activeTabId === "3" && <PPAParameter />}
      {activeTabId === "4" && <ValuationResult />}
      {isLoading && <Loader></Loader>}
    </div>
  );
}
