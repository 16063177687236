import React from "react";
import "./FileUpload.css";

export const FileUpload = ({ getRootProps, getInputProps, header = "", infoText = "" }) => {
  return (
    <div className="ppa-file-upload-container">
      <h4 className="file-upload-label">{header}</h4>
      <div {...getRootProps({ className: "file-upload" })}>
        <input {...getInputProps()} />
        <div className="file-upload-placeholder-container">
          <img className="file-upload-icon" src={"/icons/cloud_upload.svg"} alt="upload" />
          <p className="file-upload-placeholder">
            Drag and drop a document, or <span className="text-highlight">browse</span>
          </p>
        </div>
      </div>
      <div className="info-container">
        <span>{infoText}</span>
      </div>
    </div>
  );
};
