import React from "react";
import "./Icons.css";

const CloseIcon = () => (
    <img className="support_icon" src="/icons/cross_black.svg" alt="sidenav__icon" />
  );
  
  const SuccessIcon = () => (
    <img className="support_icon" src="/icons/cross_black.svg" alt="sidenav__icon" />
  );
  
  const FailureIcon = () => (
    <img className="support_icon" src="/icons/error_24x24.svg" alt="sidenav__icon" />
  );
  
  const WarningIcon = () => (
    <svg>...</svg>
  );
  
  export { CloseIcon, SuccessIcon, FailureIcon, WarningIcon };