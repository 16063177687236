import "./commonFooter.css"

export const CommonFooter = ({policyContentClicked}) => {
 return(
    <div className="footer__container">
        <img className="footer__logo" src={ '/EY_footer_logo.svg'} alt="footer__logo" />
        <p>
            EY refers to the global organization, and may refer to one or more, of the member firms of Ernst & Young Global 
            Limited, each of which is a separate legal entity. Ernst & Young Global Limited, a UK company limited by 
            guarantee, does not provide services to clients.
        </p>
        <div className="footer_content_right">
            <div className="footer__options">
                <p>Terms & Conditions</p>
            </div>
            <div className="footer__options">
                <p>Support</p>
            </div>
            <div className="footer__options">
                <p onClick={policyContentClicked}>Privacy Policy</p>
            </div>
        </div>

            {/* <div className="footer_content_left">
                <img className="footer__logo" src={ '/EY_footer_logo.svg'} alt="footer__logo" />
                <p>
                     EY refers to the global organization, and may refer to one or more, of the member firms of Ernst & Young Global 
                     Limited, each of which is a separate legal entity. Ernst & Young Global Limited, a UK company limited by 
                     guarantee, does not provide services to clients.
                </p>
            </div>
            <div className="footer_content_right">
                <div className="footer__options">
                    <p>Terms & Conditions</p>
                </div>
                <div className="footer__options">
                    <p>Support</p>
                </div>
                <div className="footer__options">
                    <p onClick={policyContentClicked}>Privacy Policy</p>
                </div>
            </div> */}
        </div>
 )
}
        