import axios from "../../../interceptors/axiosInstance";
import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";

import { FileUpload } from "../../../components/PPA/FileUpload/FileUpload";
import { DatePicker } from "../../../components/General/DataEntry/DatePicker/DatePicker";
import SuccessModal from "../../../components/SuccessModal";
import { config } from "../../../config";
import PPAContext from "../../../contexts/PPA/PPAContext";
import { CountryList, modelTypes, existingPPACurveTypes } from "../../../utils/PPA/PPAMasterList";
import { MessageContext } from "../../../context/MessageContext";
import Loader from "../../../components/PPA/Loader/Loader";
import "../Valuation/Style/PPAStyles.css";


export default function Consultant() {
  const [country, setCountry] = useState("");
  const [modelType, setModelType] = useState('');
  const [benchmarkType, setBenchmarkType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { ErrorToast } = useContext(MessageContext);

  const { DownloadBenchmarkTemplate, ValidateTemplate, templateNames, SetIsLoading, isLoading } = useContext(PPAContext);

  const onDrop = useCallback((files) => {
    if (files.length > 0) {
      const templateName = modelType === 'existing_PPA' ? templateNames[benchmarkType] : templateNames.benchmarkFile 
      checkTemplate(files[0], templateName);
      resetUpload(false);
    }
  }, [modelType, benchmarkType, startDate, endDate]);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml": [".xlsx"],
    },
    maxFiles: 1,
  });

  const checkTemplate = async (file, templateName) => {
    if (!file) {
      ErrorToast("File not found. Please try again.");
      setCurrentFile(undefined);
      return;
    }

    if (!templateName) {
      ErrorToast("Template not identified. Please try again.");
      setCurrentFile(undefined);
      return;
    }

    await ValidateTemplate(file, templateName, true, { modelType, benchmarkType, startDate, endDate })
      .then((result) => {
        if (result == true) {
          setCurrentFile(file);
        }else if (result == "Not Valid") {
          ErrorToast(`Please Select Start And End Date To Procced.`);
        }
         else {
          ErrorToast("Incorrect template. Please try again.");
          setError(true);
          setCurrentFile(undefined);
        }
      })
      .catch((err) => {
        ErrorToast("Unhandled error in validating template. Please try again.");
        setError(true);
        setCurrentFile(undefined);
        console.log(err);
      });
  };

  const resetUpload = (clearFile = false) => {
    if (clearFile) {
      setCurrentFile(undefined);
    }
    setError(false);
    setProgress(0);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    resetUpload(true);
  };

  const downloadTemplate = (e) => {
    e.preventDefault();
    modelType === 'existing_PPA' ? DownloadBenchmarkTemplate(country, modelType, benchmarkType, startDate, endDate) : DownloadBenchmarkTemplate(country);
  };

  const onUploadProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleSubmit = async () => {
    try {
      if (currentFile) {
        resetUpload(false);

        let form = new FormData();
        let url = '';

        if(modelType === 'existing_PPA') {
          url = `${config.BASE_URL}/api/ppa/upload-valuation-benchmark-data`;
          form.append("location", country);
          form.append("startDate", startDate);
          form.append("endDate", endDate);
          form.append("templateName", benchmarkType);
          form.append("template", !currentFile ? null : currentFile);
        } else {
          url = `${config.BASE_URL}/api/ppa/upload`
          form.append("country", !country ? null : country);
          form.append("benchMarkFile", !currentFile ? null : currentFile);
        }
        SetIsLoading(true);
        await axios
          .post(url, form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
          })
          .then((res) => {
            SetIsLoading(false);
            console.log(res);
            if (res.data) {
              if (res.data.isSuccess) {
                setShowSuccess(true);
              } else {
                setShowSuccess(false);
                setError(true);
                ErrorToast(res.data.message);
              }
              setCurrentFile(undefined);
              setProgress(0);
            }
          }).catch(err => {
            SetIsLoading(false);
            ErrorToast(err?.response?.data?.message || 'Something went wrong. Please try again');
          });
      } else {
        ErrorToast("Upload a file.");
        setProgress(0);
        setError(true);
      }
    } catch (error) {
      setProgress(0);
      setError(true);
      setCurrentFile(undefined);
    }
  };

  const handleChange = ({ target }) => {
    if(target.name === 'modelType') {
      setModelType(target.value);
    } else if(target.name === 'benchmarkType') {
      setBenchmarkType(target.value);
    }
    else if(target.name === 'startDate') {
      setStartDate(target.value);
    }
    else if(target.name === 'endDate') {
      setEndDate(target.value);
    }
  }

  const handleCalValue = (value, category) => {
    handleChange({ target: { name: category, value }});
  }

  const uploadDisabled = !modelType || !country || (modelType === 'existing_PPA' && (!benchmarkType || !startDate || !endDate));

  return (
    <div className="add-evaluation_wrapper benchmark_data_wrapper">
      <div className="benchmark-title">Benchmark Data Upload</div>
      <div className="benchmark-form-container">
        <div>
          <div className="info-container ppa-info-container-right">
            <span>
              NOTE: Downloaded Benchmark Data XLSX can be used as Template for Data Upload.
              <br />
            </span>
          </div>
          <div className="admin__contract__form__group benchmark-data-ip-wrapper">
            <label className="avf-label" htmlFor="model-type">Select Model Type *</label>
            <div className="input-wrapper">
              <select
                className="admin__contract__input benchmark_data_selec"
                id="model-type"
                name="modelType"
                value={modelType}
                onChange={handleChange}
                required
              >
                <option value="">Please Select A Model</option>
                {modelTypes.map((model) => (
                  <option id={ model.value } value={ model.value } key={ model.value } disabled={ model.disabled }>
                    {model.label}
                  </option>
                ))}
              </select>
              { !modelType && (
                <p className="error">Please select a model.</p>
              )}
            </div>
          </div>
        </div>
        <div className="add-evaluation-form col-md-12">
          {
            modelType && (
              <div className="col-md-3">
                <label htmlFor="country" className="avf-label">Country For Benchmark Data </label>
                <div className="input-wrapper">
                  <select
                    className="admin__contract__input benchmark_data_select"
                    id="country"
                    value={country}
                    onChange={(e) => handleCountryChange(e)}
                    required
                  >
                    <option id="-1" value="" key="-1">
                      Please Select Any Value
                    </option>
                    {CountryList.map((country, index) => (
                      <option id={index} value={country} key={index}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {(!country || country == "") && (
                    <p className="error">Please select a country to proceed with Benchmark Data.</p>
                  )}
                </div>
              </div>
            )
          }
          
          {
            modelType === 'existing_PPA' && (
              <>
                <div className="col-md-3">
                  <label htmlFor="benchmark-type" className="avf-label"> Benchmark Type *</label>
                  <div className="input-wrapper">
                    <select
                      className="admin__contract__input benchmark_data_select"
                      id="benchmark-type"
                      name="benchmarkType"
                      value={benchmarkType}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value="">Please Select Any Value</option>
                      {existingPPACurveTypes.map(curves => (
                        <option value={curves?.value} key={curves?.value}>{curves?.label}</option>
                      ))}
                    </select>
                    {(!benchmarkType || benchmarkType == "") && (
                      <p className="error">Please select a benchmark type.</p>
                    )}
                  </div>
                </div>

                <DatePicker 
                  error={!startDate ? 'Please select a start date' : ''} 
                  formClass={"col-md-3"}
                  label="Start Date * " 
                  CalValue={startDate} 
                  setCalValue={handleCalValue} 
                  id={"start-date"} 
                  placeholder={"Select Date (Required)"} 
                  category={"startDate"}
                  helperText="Please provide value"
                />

                <DatePicker 
                  error={!endDate ? 'Please select an end date' : ''} 
                  formClass={"col-md-3"}
                  label="End Date * " 
                  CalValue={endDate} 
                  setCalValue={handleCalValue} 
                  id={"end-date"} 
                  placeholder={"Select Date (Required)"} 
                  category={"endDate"}
                  helperText="Please provide value"
                />
              </>
            )
          }

        </div>
        <div className={uploadDisabled ? "upload-readonly " : ""}>
          <div className="benchmark_data_upload_block">
            <div className="expanded-download-btn download_link " onClick={(e) => downloadTemplate(e)}>
              <img className="" src={"/icons/download.png"} alt="Download" />
              <a>Download Benchmark Data</a>
            </div>
            <div>
              <FileUpload
                onDrop={onDrop}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                header="Upload EY Benchmark Data File (.xlsx)"
                infoText="File format supported: File should be a .xlsx file"
              />

              {error && (
                <div className="upload-error-container error-msg-container">
                  <img className="error-icon" src={"/icons/error_24x24.svg"} alt="error" />
                  <span className="error">Something went wrong during upload.</span>
                </div>
              )}
            </div>
            {currentFile && (
              <div className="active-invoice-container">
                <div className="invoice-meta-container">
                  <p className="invoice-path">
                    <img className="invoice-file-icon" src={"/icons/file_16x16.svg"} alt="file" />
                    <span>{currentFile.path}</span>
                  </p>
                  <img className="invoice-close-icon" src={"/icons/close_24x24.svg"} alt="file" onClick={resetUpload} />
                </div>
                {progress !== 0 && (
                  <progress value={progress} max="100">
                    {progress}%
                  </progress>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="file-upload-actions">
          <button
            className="cancel-button btn-secondary"
            onClick={() => {
              setCurrentFile(undefined);
            }}
          >
            Cancel
          </button>
          <button className="confirm-button btn-primary" onClick={handleSubmit} disabled={error}>
            Upload
          </button>
        </div>
      </div>
      {showSuccess ? (
        <SuccessModal
          title="Benchmark Data Upload"
          message="Benchmark Data Uploaded Successfully"
          toggleModal={setShowSuccess}
        />
      ) : null}
      {isLoading && <Loader></Loader>}
    </div>
  );
}
