import axios from '../interceptors/axiosInstance';
import { config } from '../config';

export const storeToken = async (token) => {
    try {
        const result = await axios.post(`${config.BASE_URL}/api/token/store`, {token});
        return result;
    } catch (error) {
        if( error.response && error.response.data && error.response.data.message){
            return error.response.data.message;
        } else {
            return "Something Went Wrong"
        }
    }
}