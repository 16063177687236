import { useContext, useEffect, useState } from "react";
import axios from "../../../../interceptors/axiosInstance";
import "./editContract.css";
import { handleDateFormat } from "../../../../utils/DateFormating";
import EditConfirmationModal from "../../../../components/EditConfirmationModal";
import ContractTerminationModal from "../../../../components/ContractTerminationModal/contractTerminationModal";
import ContractTerminationConfirmationModal from "../../../../components/ContractTerminationConfirmationModal";
import { DatePicker } from "../../../../components/General/DataEntry/DatePicker/DatePicker";
import { priceValidator, dateValidator } from "../../../../utils/Validators/validators";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { config } from "../../../../config";
import { twoDecimal } from "../../../../utils/display";
import { decimalNumberRegex } from "../../../../utils/ValidationRules";
import { MessageContext } from "../../../../context/MessageContext";
import SuccessModal from "../../../../components/SuccessModal";

const editableFields = ['volume', 'strikePrice', 'contractSunsetDate'];

export default function EditContract({contract}){
    const [calValue, setCalValue] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [showCofirmationMessage, setShowConfirmationMessage] = useState(false)
    const [showTerminationModal, setShowTerminationModal] = useState(false)
    const [contractData, setContractData] = useState({})
    const [updatedContractData, setUpdatedContractData]= useState({
        strikePrice: {
            value: '',
            disabled: false
        },
        volume: {
            value: '',
            disabled: false
        },
        contractSunsetDate: {
            value: '',
            disabled: false
        }
    })
    const [isStrikePriceValid, setIsStrikePriceValid] = useState('');
    const [isVolumeValid, setIsVolumeValid] = useState('');
    const [finalModal, setFinalModal] = useState(false)
    const [showTerminiateConfirmationModal, setShowTerminateConfirmationModal] = useState(false)
    const [showTerminalFinalModal, setShowTerminalFinalModal] = useState(false)
    const [terminateContractParams, setTerminateContractParams] = useState({early_end_date: "", termination_fee: ""})
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState([])
    const [validated, setValidated] = useState(false)
    const { ErrorToast } = useContext(MessageContext);
    
    const handleTerminationModal = () => {
        console.log('invoked termination modal')
        setShowTerminationModal(!showTerminationModal)
    }

    // const handleDateChange = (sDate) => {
    //     handleContractParamsChange({
    //         target: {
    //             name: 'contractSunsetDate',
    //             value: sDate
    //         }
    //     })
    // }

    const handleContractParamsChange = (e) => {
        setUpdatedContractData(prev => {
            const newState = {}
            if(e.target.value) {
                checkValidity(e.target);
                editableFields.forEach(property => {
                    if(property === e.target.name) {
                        newState[property] = {
                            value: e.target.value,
                            disabled: false
                        }
                    } else {
                        newState[property] = {
                            value: '',
                            disabled: true
                        }
                    }
                })
            } else {
                editableFields.forEach(property => {
                    newState[property] = {
                        value: '',
                        disabled: false
                    }
                })
                setIsStrikePriceValid('');
                setIsVolumeValid('');
            }
            return newState;
        })
    }

    const checkValidity = (item) => {
        if(item.name === "strikePrice"){
            decimalNumberRegex.test(item.value) ? setIsStrikePriceValid(''): setIsStrikePriceValid('Invalid');
        } else {
            decimalNumberRegex.test(item.value) ? setIsVolumeValid(''): setIsVolumeValid('Invalid');
        }
    }

    const handleEditConfiramtion = ()=>{
        setShowConfirmationMessage(true);
        setShowModal(false);
    }

    const submitRequest = async () => {
        let payload = {};

        editableFields.forEach(property => {
            if(updatedContractData[property].value) {
                payload= {
                    id: contractData._id,
                    value: updatedContractData[property].value,
                    updatingContent: property
                }
            }
        })

        try{
            const res = await axios.post(`${config.BASE_URL}/api/contract/update`, payload)
            console.log(`Response: ${JSON.stringify(res.data, null, 2)}`)
            setFinalModal(true);
            setContractData(contract);
            setUpdatedContractData({
                strikePrice: {
                    value: '',
                    disabled: false
                },
                volume: {
                    value: '',
                    disabled: false
                },
                contractSunsetDate: {
                    value: '',
                    disabled: false
                }
            })
        } catch(error){
            setShowConfirmationMessage(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }  
    }

    useEffect(() => {
            console.log(`the updated contract data is ${JSON.stringify(updatedContractData, null, 2)}`)
    })

    console.log('value ******', updatedContractData)
   

    const checkErrors = () => {
      setValidated(prev => !prev.validated)
      if(!updatedContractData.volume.disabled) {
        setErrors(prev => [priceValidator(contract.volume, updatedContractData.volume.value, "volume")])
      } else if(!updatedContractData.strikePrice.disabled) {
        setErrors(prev => [priceValidator(contract.strikePrice, updatedContractData.strikePrice.value, "strike price") ])
      } else if (!updatedContractData.contractSunsetDate.disabled) {
        setErrors(prev => [dateValidator(contract.contractSunsetDate, updatedContractData.contractSunsetDate.value, "contract end date")])
      }
      setValidated(true)  
    } 
    
    useEffect(() => {
        setLoading(true)
        setContractData(contract)
        setLoading(false)
    }, [])

    const handleSubmit = () => {
        checkErrors()    
    }

    useEffect(() => {
        console.log(`the errors are ${JSON.stringify(errors)}`)
        const allError = errors.filter(error => error != null)
        console.log(`the length of all non null errors is ${JSON.stringify(allError)}`)
        if(errors.length > 0){
            if(allError.length > 0){
                console.log(`**************correct the errors ${JSON.stringify(errors)}************* has a length ${errors.length}`)
                setErrors([])
                setShowModal(false)
            }else if(validated === true  && allError.length === 0) {
                console.log(`******************corrected the errors ${JSON.stringify(errors)}************* has a length ${errors.length}`)
                setShowModal(true)
            }
        }   
    },[errors])

    return(
        <div className="generator__edit__contract__container">
            <div className="client__details">
                <p><span className="client__name">Client Name: </span><span className="client__name__value">{contract.offtaker.clientName} </span></p>
                <div className="contract__description">
                    <p className="contract__terms__title">Terms Of Contract</p>
                    <p className="contract__terms__desc">Generator A proposes that the Buyer would enter into a bundled PPA with Renewable Asset X as trustee for Renewable Asset X Trust (Seller). The Seller is a wholly owned subsidiary of Generator A and so have the backing of a strong and reputable parent company. 
                        The Seller will be responsible for notionally supplying electricity and electronically transferring via the REC Registry large-scale generation certificates (LGCs) and Green Products (where the supply term extends beyond 203x) to the Buyer from the solar farm.
                    </p>
                </div>
            </div>
            <div className="contract__param__details">
                <p className="contract__param__title">Details</p>
                <div className="contract__param__cards">
                    <div className="contract__param__card">
                        <p className="param__name">Strike Price</p>
                        <p className="param__value">{twoDecimal(contractData.strikePrice)} {contractData.currency}</p>
                    </div>
                    <div className="contract__param__card">
                        <p className="param__name">Volume</p>
                        <p className="param__value">{twoDecimal(contractData.volume)} MWh</p>
                    </div>
                    <div className="contract__param__card">
                        <p className="param__name">Contract Sunset Date</p>
                        <p className="param__value">{handleDateFormat(contractData.contractSunsetDate)}</p>
                    </div>
                </div>
            </div>
            <div className="section__divider"></div>
            <div className="contract__update__detail__section">
                <p className="contract__update__title">Revise Details</p>
                <div className="generator__contract__edit__form__container">
                    <form className="generator__contract__edit__form" onSubmit={() => {}} autocomplete="off">
                        <div className="generator__contract__form__group">
                            <input disabled={updatedContractData.strikePrice.disabled} className="editContract-generator__contract__input" type="text" id="strikePrice" name="strikePrice" placeholder="New Strike Price*" value={updatedContractData.strikePrice.value} onChange={handleContractParamsChange} required />
                            {isStrikePriceValid != '' && <p class="editContract_text_error"> Enter valid number upto 2 decimal places</p>}
                        </div>
                        <div className="generator__contract__form__group">
                            <input disabled={updatedContractData.volume.disabled} className="editContract-generator__contract__input" type="text" id="volume" name="volume" placeholder="New Volume*" value={updatedContractData.volume.value} onChange={handleContractParamsChange} required />
                            {isVolumeValid != '' && <p class="editContract_text_error"> Enter valid number upto 2 decimal places</p>}
                        </div>
                        {/* <DatePicker label={""} disabled={updatedContractData.contractSunsetDate.disabled} id="contractSunsetDate" placeholder={"New End Date*"} CalValue={updatedContractData.contractSunsetDate.value} setCalValue={handleDateChange}/> */}
                    </form>
                    <button 
                        className="edit__contract__upate__button" 
                        onClick={handleSubmit}
                        disabled={ contract.status === 'expired' || contract.status === 'terminated' || (!updatedContractData.strikePrice.value && !updatedContractData.volume.value && !updatedContractData.contractSunsetDate.value) || isStrikePriceValid == "Invalid" || isVolumeValid == "Invalid"} 
                    >Request Update</button>
                    <p className="edit_contract_note__on__params">Note: Only one field can be updated at a time.</p>
                </div>
                { 
                    showModal && (
                        <ConfirmModal 
                            data={ updatedContractData.strikePrice.value != '' ? 
                            [
                                {
                                    label: 'Strike Price',
                                    value: updatedContractData.strikePrice.value
                                }
                            ]:
                            [
                                {
                                    label: 'Volume',
                                    value: updatedContractData.volume.value
                                }
                            ]
                        } 
                        toggleModal={setShowModal} 
                        handleSubmit={handleEditConfiramtion}
                    />)
                }
                {
                    showCofirmationMessage && (
                        <div className="confirmation_modal_outer_container"><EditConfirmationModal toggleModal={setShowConfirmationMessage} submitRequest={submitRequest}/> </div>
                    )
                }
            {finalModal ?  <SuccessModal title = "Success" message="Your request to change / modify the terms of the contract  is sent to admin for review" toggleModal={setFinalModal}/>:null }
            {showTerminationModal ? <div className="confirmation_modal_outer_container"><ContractTerminationModal toggleModal={handleTerminationModal} contract={contractData} updateContractData={setContractData} setTerminateContractParams={setTerminateContractParams} showNextStep={setShowTerminateConfirmationModal}/></div>: null}
            </div>
            {showTerminiateConfirmationModal? <div className="confirmation_modal_outer_container"> <ContractTerminationConfirmationModal toggleModal={setShowTerminateConfirmationModal} showFinalStep={setShowTerminalFinalModal} contract={terminateContractParams}/> </div>: null}
            {showTerminalFinalModal ? <div className="confirmation_modal_outer_container"> <SuccessModal title = "Request submitted" message="Your request to terminate contract is submitted" toggleModal={setShowTerminalFinalModal}/></div>: null}
            {/* <div className="contract__termination__option">
                <p className="termination__notes">Initiate Actions To Terminate This Contract</p>
                <button disabled={contract.status === 'expired' || contract.status === 'terminated'} className="editContract_contract__termination__button" onClick={handleTerminationModal}>Request Termination Of Contract</button>
            </div> */}
        </div>
    )
}