import 'regenerator-runtime';
import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { Chart } from "primereact/chart";
import React, { useContext, useEffect, useRef, useState } from "react";
import PPAContext from "../../../../contexts/PPA/PPAContext";
import { PPASenarioList, PPAYearList,CountryCurrencyList } from "../../../../utils/PPA/PPAMasterList";
import "../Style/PPAStyles.css";

export default function Result(props) {
  const [QDisCountedCFChart, setQDisCountedCFChart] = useState({});
  const [UnDiscountedCFChart, setUnDiscountedCFChart] = useState({});
  const [CarbonReductionChart, setCarbonReductionChart] = useState({});
  const [ChartOptions, setChartOptions] = useState({});
  const [ChartYear, setChartYear] = useState("All");
  const [ChartScenario, setChartScenario] = useState("All");
  const[currency, setCurrency] = useState("EUR");
  const { resultData, SetActiveTabId, SetFormData, formData } = useContext(PPAContext);

  const UnDiscountedChartRef = useRef(null);
  const QDisCountedChartRef = useRef(null);
  const CarbonReductionChartRef = useRef(null);

  useEffect(() => {
    getCurrency();
    configureGraphData("All", "All");
    console.log("data", formData);
  }, []);

  const getCurrency = () => {
    CountryCurrencyList.find((x) => {
      if (x.country == formData.location) {
        setCurrency(x.currency);
      }
    });
  }

  const configureGraphData = (year, scenario) => {
    var graphData;
    if (year != "All") graphData = processGraphData(resultData.chartData.filter((x) => x.year === parseInt(year)));
    else graphData = processGraphData(resultData.chartData);

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const QDisCountedCFData = {
      labels: graphData.Labels,
      datasets: [
        {
          label: "High",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "High" || scenario === "All" ? graphData.QDiscountedCFHigh : null,
        },
        {
          label: "Medium",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: scenario === "Medium" || scenario === "All" ? graphData.QDiscountedCFMed : null,
        },
        {
          label: "Low",
          backgroundColor: documentStyle.getPropertyValue("--orange-500"),
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          data: scenario === "Low" || scenario === "All" ? graphData.QDiscountedCFLow : null,
        },
      ],
    };

    const QDisCountedCustomCFData = {
      labels: graphData.Labels,
      datasets: [
        {
          label: "Custom",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "High" || scenario === "All" ? graphData.QDiscountedCFHigh : null,
        },
      ],
    };

    const UnDiscountedCFData = {
      labels: graphData.Labels,
      datasets: [
        {
          label: "High",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "High" || scenario === "All" ? graphData.UnDisCountedCFHigh : null,
        },
        {
          label: "Medium",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: scenario === "Medium" || scenario === "All" ? graphData.UnDisCountedCFMed : null,
        },
        {
          label: "Low",
          backgroundColor: documentStyle.getPropertyValue("--orange-500"),
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          data: scenario === "Low" || scenario === "All" ? graphData.UnDisCountedCFLow : null,
        },
      ],
    };

    const UnDiscountedCFCustomData = {
      labels: graphData.Labels,
      datasets: [
        {
          label: "Custom",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: scenario === "High" || scenario === "All" ? graphData.UnDisCountedCFHigh : null,
        },
      ],
    };

    const CarbonReductionData = {
      labels: graphData.Labels,
      datasets: [
        {
          label: "Carbon reduction cumulated",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: graphData.CarbonReduction,
        },
      ],
    };

    const options = {
      maintainAspectRatio: true,
      aspectRatio: 0.0,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            drawBorder: true,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: true,
          },
        },
      },
    };

    setQDisCountedCFChart(
      resultData?.npvData?.low?.npv != resultData?.npvData?.base?.npv &&
        resultData?.npvData?.low?.npv != resultData?.npvData?.high?.npv
        ? QDisCountedCFData
        : QDisCountedCustomCFData
    );
    setUnDiscountedCFChart(
      resultData?.npvData?.low?.npv != resultData?.npvData?.base?.npv &&
        resultData?.npvData?.low?.npv != resultData?.npvData?.high?.npv
        ? UnDiscountedCFData
        : UnDiscountedCFCustomData
    );
    setCarbonReductionChart(CarbonReductionData);

    setChartOptions(options);
  };

  const processGraphData = (graphRawData) => {
    var result = {};
    result.Labels = [];
    result.CarbonReduction = [];
    result.QDiscountedCFLow = [];
    result.QDiscountedCFMed = [];
    result.QDiscountedCFHigh = [];
    result.UnDisCountedCFLow = [];
    result.UnDisCountedCFMed = [];
    result.UnDisCountedCFHigh = [];
    result.VolumeMw = [];
    result.PPAPriceWithIndex = [];
    result.Degradation = [];
    result.Discountingfactor = [];
    result.CarbonReductionMonth = [];
    result.CarbonReductionCumulated = [];
    result.DiscountedCFDLow = [];
    result.DiscountedCFDMed = [];
    result.DiscountedCFDHigh = [];
    result.BaseLeg1PPAOutflow = [];
    result.BaseLeg2Marketleg = [];
    result.LowLeg1PPAOutflow = [];
    result.LowLeg2Marketleg = [];
    result.HighLeg1PPAOutflow = [];
    result.HighLeg2Marketleg = [];
    result.BaseCaptureCurve = [];
    result.BaseCaptureCurvePV = [];
    result.BaseCaptureCurveWind = [];
    result.LowCaptureCurve = [];
    result.LowCaptureCurvePV = [];
    result.LowCaptureCurveWind = [];
    result.HighCaptureCurve = [];
    result.HighCaptureCurvePV = [];
    result.HighCaptureCurveWind = [];
    result.Technology = [];
    result.Volume = [];
    result.VolumePV = [];
    result.VolumeWind = [];
    result.MW_PV_Fixed = [];
    result.MWH_PV_Fixed = [];
    result.MWH_Wind_Onshore = [];
    result.MW_Wind_Onshore = [];
    result.volumeMWHPV = [];
    result.volumeMWHOnshore = [];
    result.ppaPrice = [];
    result.Indexfixed = [];
    result.customnpv = [];
    result.riskfreerate = [];
    result.CarbonIntensityFactor = [];
    result.BasePowerPrice = [];
    result.BaseGooPrice = [];
    result.BaseRatePV = [];
    result.BaseRateWind = [];
    result.LowPowerPrice = [];
    result.LowGooPrice = [];
    result.LowRatePV = [];
    result.LowRateWind = [];
    result.HighPowerPrice = [];
    result.HighGooPrice = [];
    result.HighRatePV = [];
    result.HighRateWind = [];

    if (graphRawData && graphRawData.length > 0) {
      graphRawData.forEach((data) => {
        result.Labels.push("" + data.timeStamp);
        result.CarbonReduction.push(data.carbon_reduction_cumulated);
        result.QDiscountedCFLow.push(data.discountedcfdcummulated_low);
        result.QDiscountedCFMed.push(data.discountedCFDcummulated_base);
        result.QDiscountedCFHigh.push(data.discountedcfdcummulated_high);
        result.UnDisCountedCFLow.push(data.cfd_low);
        result.UnDisCountedCFMed.push(data.cfd_base);
        result.UnDisCountedCFHigh.push(data.cfd_high);
        result.VolumeMw.push(data.volumeMw);
        result.PPAPriceWithIndex.push(data.ppapriceindexation);
        result.Degradation.push(data.degradation);
        result.Discountingfactor.push(data.discountfactor);
        result.CarbonReductionMonth.push(data.carbon_reduction_month);
        result.CarbonReductionCumulated.push(data.carbon_reduction_cumulated);
        result.CarbonReductionCumulated.push(data.carbon_reduction_cumulated);
        result.BaseLeg1PPAOutflow.push(data.leg1PPA);
        result.BaseLeg2Marketleg.push(data.leg2Marketleg);
        result.LowLeg1PPAOutflow.push(data.leg1PPA_low);
        result.LowLeg2Marketleg.push(data.leg2Marketleg_low);
        result.HighLeg1PPAOutflow.push(data.leg1PPA_high);
        result.HighLeg2Marketleg.push(data.leg2Marketleg_high);
        result.DiscountedCFDMed.push(data.discountedCFD_base);
        result.DiscountedCFDLow.push(data.discountedCFD_low);
        result.DiscountedCFDHigh.push(data.discountedCFD_high);
        result.BaseCaptureCurve.push(data.capture_curve);
        result.BaseCaptureCurvePV.push(data.capture_curve_pv);
        result.BaseCaptureCurveWind.push(data.capture_curve_wind);
        result.LowCaptureCurve.push(data.capture_curve_low);
        result.LowCaptureCurvePV.push(data.capture_curve_pv_low);
        result.LowCaptureCurveWind.push(data.capture_curve_wind_low);
        result.HighCaptureCurve.push(data.capture_curve_high);
        result.HighCaptureCurvePV.push(data.capture_curve_pv_high);
        result.HighCaptureCurveWind.push(data.capture_curve_wind_high);
        result.Technology.push(data.technology);
        result.Volume.push(data.volume);
        result.VolumePV.push(data.volumePV);
        result.VolumeWind.push(data.volumeWind);
        result.volumeMWHPV.push(data.volumeMWHPV);
        result.volumeMWHOnshore.push(data.volumeMWHOnshore);
        result.MW_PV_Fixed.push(data.mw_pv_fixed);
        result.MWH_PV_Fixed.push(data.mwh_pv_fixed);
        result.MWH_Wind_Onshore.push(data.mwh_wind_onshore);
        result.MW_Wind_Onshore.push(data.mw_wind_onshore);
        result.ppaPrice.push(data.ppaPrice);
        result.Indexfixed.push(data.indexfixed);
        result.customnpv.push(data.customnpv);
        result.riskfreerate.push(data.riskfreerate);
        result.CarbonIntensityFactor.push(data.carbon_intensity_factor);
        result.BasePowerPrice.push(data.base_power_price);
        result.BaseGooPrice.push(data.base_goo_price);
        result.BaseRatePV.push(data.base_rate_pv);
        result.BaseRateWind.push(data.base_rate_wind);
        result.LowPowerPrice.push(data.low_power_price);
        result.LowGooPrice.push(data.low_goo_price);
        result.LowRatePV.push(data.low_rate_pv);
        result.LowRateWind.push(data.low_rate_wind);
        result.HighPowerPrice.push(data.high_power_price);
        result.HighGooPrice.push(data.high_goo_price);
        result.HighRatePV.push(data.high_rate_pv);
        result.HighRateWind.push(data.high_rate_wind);
      });
    }
    return result;
  };

  const exportResult = () => {
    saveAsExcel("Result_Export");
  };

  const newPPACalculation = () => {
    SetFormData({});
    SetActiveTabId("1");
  };

  const saveAsExcel = async (workBookName) => {
    const workbook = new Excel.Workbook();

    try {
      const fileName = workBookName;

      //create wprksheet for inputs
      const worksheetInput = workbook.addWorksheet("PPA Inputs");
      generatePPAInputSheet(workbook, worksheetInput);
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet("PPA Results");
      generatePPAResultsSheet(workbook, worksheet);

      var graphData = processGraphData(resultData.chartData);

      // creating new worksheet in workbook
      const worksheet2 = workbook.addWorksheet("UnDiscounted Cashflow");
      generateUnDiscountedCashflowDataDump(workbook, worksheet2, graphData);

      const worksheet3 = workbook.addWorksheet("Cumulative Discounted Cashflow");
      generateCummulativeDiscountedCashflowDataDump(workbook, worksheet3, graphData);

      const worksheet4 = workbook.addWorksheet("CO² Reduction Level");
      generateCarbonReductionDataDump(workbook, worksheet4, graphData);

      const worksheet5 = workbook.addWorksheet("Calculation Result");
      generateCalculationDataDump(workbook, worksheet5, graphData);

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
    }
  };

  const writeExcelSheet = (workbook, worksheet, data) => {
    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = data.columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach((column) => {
      column.width = column.header.length + 5;
      column.alignment = { horizontal: "center" };
    });

    // loop through data and add each one to worksheet
    data.tableData.forEach((singleData) => {
      worksheet.addRow(singleData);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      // store each cell to currentCell
      const currentCell = row._cells;
      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;
        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
  };

  const GenerateTableDataForExport = (data) => {
    if (!data || data.length == 0) {
      return [];
    }

    let columns = [
      { header: "Scenario", key: "SC" },

      { header: "NPV", key: "NPV" },

      { header: "NPV per MWh", key: "NPVperMWH" },

      { header: "Break Even Price", key: "BreakEvenPrice" },

      { header: "CO² Reduction", key: "COReduction" },
    ];

    let tableData = [
      {
        SC: "LOW",

        NPV: Math.ceil(data.low?.npv).toLocaleString("en"),

        NPVperMWH: data.low?.npvpermwh,

        BreakEvenPrice: data.low?.breakevenprice.toFixed(2),

        COReduction: "",
      },
      {
        SC: "MEDIUM",

        NPV: Math.ceil(data.base?.npv).toLocaleString("en"),

        NPVperMWH: data.base?.npvpermwh,

        BreakEvenPrice: data.base?.breakevenprice.toFixed(2),

        COReduction: "",
      },
      {
        SC: "HIGH",

        NPV: Math.ceil(data.high?.npv).toLocaleString("en"),

        NPVperMWH: data.high?.npvpermwh,

        BreakEvenPrice: data.high?.breakevenprice.toFixed(2),

        COReduction: "",
      },
      {
        SC: "CUSTOM",

        NPV: "",

        NPVperMWH: "",

        BreakEvenPrice: "",

        COReduction: Math.ceil(data.high?.co2Reduction).toLocaleString("en"),
      },
    ];

    return { tableData, columns };
  };

  const generatePPAResultsSheet = (workbook, worksheet) => {
    let data = GenerateTableDataForExport(resultData.npvData);
    writeExcelSheet(workbook, worksheet, data);

    const UnDiscountedChart = workbook.addImage({
      base64: UnDiscountedChartRef.current.getBase64Image(),
      extension: "png",
    });

    const QDisCountedChart = workbook.addImage({
      base64: QDisCountedChartRef.current.getBase64Image(),
      extension: "png",
    });

    const CarbonReductionChart = workbook.addImage({
      base64: CarbonReductionChartRef.current.getBase64Image(),
      extension: "png",
    });

    worksheet.mergeCells("G4:J4");
    worksheet.getCell("G4").value = "Undiscounted Cashflow";
    worksheet.getCell("G4").alignment = { horizontal: "center" };
    worksheet.getCell("G4").font = { bold: true };

    worksheet.addImage(UnDiscountedChart, "G5:AA35");

    worksheet.mergeCells("G39:J39");
    worksheet.getCell("G39").value = "Cumulative Discounted Cashflow";
    worksheet.getCell("G39").alignment = { horizontal: "center" };
    worksheet.getCell("G39").font = { bold: true };

    worksheet.addImage(QDisCountedChart, "G40:AA70");

    worksheet.mergeCells("G74:J74");
    worksheet.getCell("G74").value = "CO² Reduction Level";
    worksheet.getCell("G74").alignment = { horizontal: "center" };
    worksheet.getCell("G74").font = { bold: true };

    worksheet.addImage(CarbonReductionChart, "G75:AA105");
  };

  const generatePPAInputSheet = (workbook, worksheet) => {
    //  let data = GenerateTableDataForInput();
    // writeExcelSheet(workbook, worksheet, data);
    worksheet.columns = [
      { header: "InputField", key: "input" },
      { header: "Value", key: "value" },
    ];
    worksheet.addRows([
      {
        input: "Location",
        value: formData.location,
      },
      {
        input: "Consumption Location",
        value: formData.conslocation,
      },
      {
        input: "PPA Start Date",
        value: moment(formData.ppastartdate).format("YYYY-MM"),
      },
      {
        input: "PPA End Date",
        value: moment(formData.ppaenddate).format("YYYY-MM"),
      },
      {
        input: "PPA Volume Structure",
        value: formData?.ppavolstructure,
      },
      {
        input: "Use Benchmark Data For Volume",
        value: formData?.benchmarkvolume,
      },
      {
        input: "Changing Volumes in PPA",
        value: formData?.changevolume,
      },
      {
        input: "Annual PPA Volume Capacity",
        value: formData?.annualvolcapacity,
      },
      {
        input: "Annual PPA Volume Capacity Unit",
        value: formData?.annualVolumeUnit,
      },
      {
        input: "Technology",
        value: formData?.technology,
      },
      {
        input: "PV Share On Total Production",
        value: formData?.pvshare,
      },
      {
        input: "Degradation P.A (P.V)",
        value: formData?.degradation,
      },
      {
        input: "Custom Percentage",
        value: formData?.cuspercentage,
      },
      {
        input: "PPA Pricing ",
        value: formData?.ppapricing,
      },
      {
        input: "PPA Price",
        value: formData?.ppaprice,
      },
      {
        input: "Discount Factor (NPV)",
        value: formData?.discountfactor,
      },
      {
        input: "Custom Discount Factor",
        value: formData?.customnpv,
      },
    ]);
  };

  const generateUnDiscountedCashflowDataDump = (workbook, worksheet, data) => {
    var UnDiscountedCashflowDump = GenerateTableDataForDataDump(
      data.Labels,
      data.UnDisCountedCFHigh,
      data.UnDisCountedCFMed,
      data.UnDisCountedCFLow
    );
    writeExcelSheet(workbook, worksheet, UnDiscountedCashflowDump);
  };

  const generateCummulativeDiscountedCashflowDataDump = (workbook, worksheet, data) => {
    var CummulativeDiscountedCashflowDataDump = GenerateTableDataForDataDump(
      data.Labels,
      data.QDiscountedCFHigh,
      data.QDiscountedCFMed,
      data.QDiscountedCFLow
    );
    writeExcelSheet(workbook, worksheet, CummulativeDiscountedCashflowDataDump);
  };

  const generateCarbonReductionDataDump = (workbook, worksheet, data) => {
    var CarbonReductionDataDump = GenerateDataDumpForCarbonReduction(data.Labels, data.CarbonReduction);
    writeExcelSheet(workbook, worksheet, CarbonReductionDataDump);
  };

  const GenerateTableDataForDataDump = (Labels, High, Medium, Low) => {
    let columns = [
      { header: "Time Stamp", key: "time" },

      { header: "High", key: "high" },

      { header: "Medium", key: "medium" },

      { header: "Low", key: "low" },
    ];

    let tableData = [];
    let data = {};

    Labels.map((label, index) => {
      data = {
        time: label,
        high: High[index],
        medium: Medium[index],
        low: Low[index],
      };

      tableData.push(data);
    });

    return { tableData, columns };
  };

  const generateCalculationDataDump = (workbook, worksheet, data) => {
    var CalculationDataDump = GenerateCalculationDataDump(data.Labels, data);
    writeExcelSheet(workbook, worksheet, CalculationDataDump);
  };

  const GenerateDataDumpForCarbonReduction = (Labels, Data) => {
    let columns = [
      { header: "Time Stamp", key: "time" },

      { header: "CO² Reduction Cumulated", key: "co" },
    ];

    let tableData = [];
    let data = {};

    Labels.map((label, index) => {
      data = {
        time: label,
        co: Data[index],
      };

      tableData.push(data);
    });

    return { tableData, columns };
  };

  const GenerateCalculationDataDump = (Labels, Data) => {
    let columns = [
      { header: "Time Stamp", key: "time" },

      { header: "Degradation", key: "degradation" },

      { header: "Volume MW", key: "volumemw" },

      { header: "Volume MWh/yr", key: "volumemwh" },

      { header: "Volume profile per 1 MW PV fixed", key: "mwpvfixed" },

      { header: "Volume profile per 1 MWH PV fixed", key: "mwhpvfixed" },

      { header: "Volume profile per 1 MW Wind Onshore", key: "mwwind" },

      { header: "Volume profile per 1 MWH Wind Onshore", key: "mwhwind" },

      { header: "Volume MWh PV", key: "volumepv" },

      { header: "Volume MWh Wind", key: "volumewind" },

      { header: "Volume MWh PPA", key: "volumemwhppa" },

      { header: " Volume MWh Total", key: "volumemwhtotal" },

      { header: "PPA price w/o indexation", key: "ppaprice" },

      { header: "Indexation fixed", key: "indexfixed" },

      { header: "PPA price w/ indexation", key: "ppapricewithindexation" },

      { header: "Discounting Factor Input", key: "customnpv" },

      { header: "Discountingfactor", key: "discountingfactor" },

      { header: "Carbon Intensity Factor", key: "carbonfactor" },

      { header: "Carbon reduction per month", key: "co2month" },

      { header: "Carbon reduction cumulated", key: "co2cumulated" },

      { header: "Carbon reduction total", key: "co2total" },

      { header: "Medium Power Price", key: "basepower" },

      { header: "Medium Capture Rate PV", key: "baseratepv" },

      { header: "Medium Capture Rate Wind", key: "baseratewind" },

      { header: "Medium Capture Curve PV", key: "baseccvpv" },

      { header: "Medium Capture Curve Wind", key: "baseccvwind" },

      { header: "Medium Capture Curve", key: "baseccv" },

      { header: "Medium Goo Price", key: "basegoo" },

      { header: "Medium Leg 1: PPA Outflow", key: "Baseleg1" },

      { header: "Medium Leg 2: Market leg", key: "Baseleg2" },

      { header: "Medium CFD", key: "baseCFD" },

      { header: "Medium Discounted CFD", key: "discountedCFDMed" },

      { header: "Medium Discounted CFD cumulated", key: "discountedcumulatedMed" },

      { header: "Medium NPV", key: "basenpv" },

      { header: "Medium NPV per MWH", key: "basenpvmwh" },

      { header: "Medium Break Even Price", key: "basebreakevenprice" },

      { header: "Low Power Price", key: "lowpower" },

      { header: "Low Capture Rate PV", key: "lowratepv" },

      { header: "Low Capture Rate Wind", key: "lowratewind" },

      { header: "Low Capture Curve PV", key: "lowccvpv" },

      { header: "Low Capture Curve Wind", key: "lowccvwind" },

      { header: "Low Capture Curve", key: "lowccv" },

      { header: "Low Goo Price", key: "lowgoo" },

      { header: "Low Leg 1: PPA Outflow", key: "Lowleg1" },

      { header: "Low Leg 2: Market leg", key: "Lowleg2" },

      { header: "Low CFD", key: "lowCFD" },

      { header: "Low Discounted CFD", key: "discountedCFDLow" },

      { header: "Low Discounted CFD cumulated", key: "discountedcumulatedLow" },

      { header: "Low NPV", key: "lownpv" },

      { header: "Low NPV per MWH", key: "lownpvmwh" },

      { header: "Low Break Even Price", key: "lowbreakevenprice" },

      { header: "High Power Price", key: "highpower" },

      { header: "High Capture Rate PV", key: "highratepv" },

      { header: "High Capture Rate Wind", key: "highratewind" },

      { header: "High Capture Curve PV", key: "highccvpv" },

      { header: "High Capture Curve Wind", key: "highccvwind" },

      { header: "High Capture Curve", key: "highccv" },

      { header: "High Goo Price", key: "highgoo" },

      { header: "High Leg 1: PPA Outflow", key: "Highleg1" },

      { header: "High Leg 2: Market leg", key: "Highleg2" },

      { header: "High CFD", key: "HighCFD" },

      { header: "High Discounted CFD", key: "discountedCFDHigh" },

      { header: "High Discounted CFD cumulated", key: "discountedcumulatedHigh" },

      { header: "High NPV", key: "highnpv" },

      { header: "High NPV per MWH", key: "highnpvmwh" },

      { header: "High Break Even Price", key: "highbreakevenprice" },
    ];

    let tableData = [];
    let data = {};

    Labels.map((label, index) => {
      data = {
        time: label,
        degradation:
          (Data.Technology[index] === "PV" || Data.Technology[index] === "Mix PV") && formData.benchmarkvolume === "Yes"
            ? Data.Degradation[index]
            : null,
        volumemw: formData.annualVolumeUnit === "MW"?Data.Volume[index]:null,
        volumemwh:formData.annualVolumeUnit === "MWh/yr"?Data.Volume[index]:null,
        mwpvfixed: Data.MW_PV_Fixed[index],
        mwhpvfixed: Data.MWH_PV_Fixed[index],
        mwwind: Data.MW_Wind_Onshore[index],
        mwhwind: Data.MWH_Wind_Onshore[index],
        volumepv: Data.volumeMWHPV[index],
        volumewind: Data.volumeMWHOnshore[index],
        volumemwhppa: Data.VolumeMw[index],
        volumemwhtotal: index === 0 ? resultData?.npvData?.base?.totalVolumeMw : null,
        ppaprice: Data.ppaPrice[index],
        indexfixed: Data.Indexfixed[index],
        ppapricewithindexation: Data.PPAPriceWithIndex[index],
        customnpv: Data.customnpv[index],
        discountingfactor: Data.Discountingfactor[index],
        carbonfactor: Data.CarbonIntensityFactor[index],
        co2month: Data.CarbonReductionMonth[index],
        co2cumulated: Data.CarbonReductionCumulated[index],
        co2total: index === 0 ? resultData?.npvData?.base?.co2Reduction : null,
        basepower: Data.BasePowerPrice[index],
        baseratepv: Data.BaseRatePV[index],
        baseratewind: Data.BaseRateWind[index],
        baseccvpv:
          Data.Technology[index] === "Mix PV"
            ? Data.BaseCaptureCurvePV[index]
            : Data.Technology[index] === "PV"
            ? Data.BaseCaptureCurve[index]
            : null,
        baseccvwind:
          Data.Technology[index] === "Mix PV"
            ? Data.BaseCaptureCurveWind[index]
            : Data.Technology[index] === "Wind"
            ? Data.BaseCaptureCurve[index]
            : null,
        baseccv: formData.ppavolstructure === "Baseload" ? Data.BaseCaptureCurve[index] : null,
        basegoo: Data.BaseGooPrice[index],
        Baseleg1: Data.BaseLeg1PPAOutflow[index],
        Baseleg2: Data.BaseLeg2Marketleg[index],
        baseCFD: Data.UnDisCountedCFMed[index],
        discountedCFDMed: Data.DiscountedCFDMed[index],
        discountedcumulatedMed: Data.QDiscountedCFMed[index],
        basenpv: index === 0 ? resultData?.npvData?.base?.npv : null,
        basenpvmwh: index === 0 ? resultData?.npvData?.base?.npvpermwh : null,
        basebreakevenprice: index === 0 ? resultData?.npvData?.base?.breakevenprice : null,
        lowpower: Data.LowPowerPrice[index],
        lowratepv: Data.LowRatePV[index],
        lowratewind: Data.LowRateWind[index],
        lowccvpv:
          Data.Technology[index] === "Mix PV"
            ? Data.LowCaptureCurvePV[index]
            : Data.Technology[index] === "PV"
            ? Data.LowCaptureCurve[index]
            : null,
        lowccvwind:
          Data.Technology[index] === "Mix PV"
            ? Data.LowCaptureCurveWind[index]
            : Data.Technology[index] === "Wind"
            ? Data.LowCaptureCurve[index]
            : null,
        lowccv: formData.ppavolstructure === "Baseload" ? Data.LowCaptureCurve[index] : null,
        lowgoo: Data.LowGooPrice[index],
        Lowleg1: Data.LowLeg1PPAOutflow[index],
        Lowleg2: Data.LowLeg2Marketleg[index],
        lowCFD: Data.UnDisCountedCFLow[index],
        discountedCFDLow: Data.DiscountedCFDLow[index],
        discountedcumulatedLow: Data.QDiscountedCFLow[index],
        lownpv: index === 0 ? resultData?.npvData?.low?.npv : null,
        lownpvmwh: index === 0 ? resultData?.npvData?.low?.npvpermwh : null,
        lowbreakevenprice: index === 0 ? resultData?.npvData?.low?.breakevenprice : null,
        highpower: Data.HighPowerPrice[index],
        highratepv: Data.HighRatePV[index],
        highratewind: Data.HighRateWind[index],
        highccvpv:
          Data.Technology[index] === "Mix PV"
            ? Data.HighCaptureCurvePV[index]
            : Data.Technology[index] === "PV"
            ? Data.HighCaptureCurve[index]
            : null,
        highccvwind:
          Data.Technology[index] === "Mix PV"
            ? Data.HighCaptureCurveWind[index]
            : Data.Technology[index] === "Wind"
            ? Data.HighCaptureCurve[index]
            : null,
        highccv: formData.ppavolstructure === "Baseload" ? Data.HighCaptureCurve[index] : null,
        highgoo: Data.HighGooPrice[index],
        Highleg1: Data.HighLeg1PPAOutflow[index],
        Highleg2: Data.HighLeg2Marketleg[index],
        HighCFD: Data.UnDisCountedCFHigh[index],
        discountedCFDHigh: Data.DiscountedCFDHigh[index],
        discountedcumulatedHigh: Data.QDiscountedCFHigh[index],
        highnpv: index === 0 ? resultData?.npvData?.high?.npv : null,
        highnpvmwh: index === 0 ? resultData?.npvData?.high?.npvpermwh : null,
        highbreakevenprice: index === 0 ? resultData?.npvData?.high?.breakevenprice : null,
      };

      tableData.push(data);
    });

    return { tableData, columns };
  };

  const handleYearChange = ({ target }) => {
    configureGraphData(target.value, ChartScenario);
    setChartYear(target.value);
  };

  const handleScenarioChange = ({ target }) => {
    configureGraphData(ChartYear, target.value);
    setChartScenario(target.value);
  };

  return (
    <>
      <div className="ppa_result_container">
        <div className="result-selection-wrapper">
          <div className="admin__contract__form__group col-md-3">
            <label htmlFor="year" className="avf-label ">
              Year
            </label>
            <select
              className="admin__contract__input ppa_param_select"
              id="year"
              name="year"
              value={ChartYear}
              onChange={(e) => handleYearChange(e)}
            >
              <option id="-1" value="All" key="-1">
                ALL
              </option>
              {PPAYearList.map((year, index) => (
                <option id={index} value={year} key={index}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="admin__contract__form__group col-md-3">
            <label htmlFor="scenario" className="avf-label ">
              Scenario
            </label>
            <select
              className="admin__contract__input ppa_param_select"
              id="scenario"
              name="scenario"
              value={ChartScenario}
              onChange={(e) => handleScenarioChange(e)}
            >
              <option id="-1" value="All" key="-1">
                ALL
              </option>
              {PPASenarioList.map((scenario, index) => (
                <option id={index} value={scenario} key={index}>
                  {scenario}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* TABLE DATA */}
        <div className="ppa_result_wrapper">
          <div className="ppa_result_table_container">
            {resultData?.npvData?.low?.npv != resultData?.npvData?.base?.npv &&
              resultData?.npvData?.low?.npv != resultData?.npvData?.high?.npv && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Low Scenario</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>NPV</td>
                        <td>{Math.ceil(resultData?.npvData?.low?.npv).toLocaleString("en")} {currency}</td>
                      </tr>
                      <tr>
                        <td>NPV per MWh</td>
                        <td>{resultData?.npvData?.low?.npvpermwh} {currency}/MWh</td>
                      </tr>
                      <tr>
                        <td>Break Even Price</td>
                        <td>{resultData?.npvData?.low?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                      </tr>
                      {/* <tr>
                  <td>CO² reduction</td>
                  <td>{Math.ceil(resultData?.npvData?.low?.co2Reduction).toLocaleString("en")}</td>
                </tr> */}
                    </tbody>
                  </table>

                  <table>
                    <thead>
                      <tr>
                        <th>Medium Scenario</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>NPV</td>
                        <td>{Math.ceil(resultData?.npvData?.base?.npv).toLocaleString("en")} {currency}</td>
                      </tr>
                      <tr>
                        <td>NPV per MWh</td>
                        <td>{resultData?.npvData?.base?.npvpermwh} {currency}/MWh</td>
                      </tr>
                      <tr>
                        <td>Break Even Price</td>
                        <td>{resultData?.npvData?.base?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                      </tr>
                      {/* <tr>
                  <td>CO² reduction</td>
                  <td>{Math.ceil(resultData?.npvData?.base?.co2Reduction).toLocaleString("en")}</td>
                </tr> */}
                    </tbody>
                  </table>

                  <table>
                    <thead>
                      <tr>
                        <th>High Scenario</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>NPV</td>
                        <td>{Math.ceil(resultData?.npvData?.high?.npv).toLocaleString("en")} {currency}</td>
                      </tr>
                      <tr>
                        <td>NPV per MWh</td>
                        <td>{resultData?.npvData?.high?.npvpermwh} {currency}/MWh</td>
                      </tr>
                      <tr>
                        <td>Break Even Price</td>
                        <td>{resultData?.npvData?.high?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                      </tr>
                      {/* <tr>
                  <td>CO² reduction</td>
                  <td>{Math.ceil(resultData?.npvData?.high?.co2Reduction).toLocaleString("en")}</td>
                </tr> */}
                    </tbody>
                  </table>
                </div>
              )}

            {resultData?.npvData?.low?.npv == resultData?.npvData?.base?.npv &&
              resultData?.npvData?.low?.npv == resultData?.npvData?.high?.npv && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Custom Scenario</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>NPV</td>
                        <td>{Math.ceil(resultData?.npvData?.low?.npv).toLocaleString("en")} {currency}</td>
                      </tr>
                      <tr>
                        <td>NPV per MWh</td>
                        <td>{resultData?.npvData?.low?.npvpermwh} {currency}/MWh</td>
                      </tr>
                      <tr>
                        <td>Break Even Price</td>
                        <td>{resultData?.npvData?.low?.breakevenprice.toFixed(2)} {currency}/MWh</td>
                      </tr>
                      {/* <tr>
                  <td>CO² reduction</td>
                  <td>{Math.ceil(resultData?.npvData?.low?.co2Reduction).toLocaleString("en")}</td>
                </tr> */}
                    </tbody>
                  </table>
                </div>
              )}

            <table>
              <thead>
                <tr>
                  <th>CO² Reduction Level</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total CO² Reduction</td>
                  <td>{Math.ceil(resultData?.npvData?.high?.co2Reduction).toLocaleString("en")} kg</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="ppa_result_graphs_container">
            <div className="results_graphs">
              <div className="card mb-3">
                <div className="graph-heading">
                  <h3>Undiscounted Cashflow</h3>
                </div>
                <Chart type="bar" ref={UnDiscountedChartRef} data={UnDiscountedCFChart} options={ChartOptions} />
              </div>
              <div className="card mb-3">
                <div className="graph-heading">
                  <h3>Cumulative Discounted Cashflow</h3>
                </div>
                <Chart type="line" ref={QDisCountedChartRef} data={QDisCountedCFChart} options={ChartOptions} />
              </div>
              <div className="card mb-3">
                <div className="graph-heading">
                  <h3>CO² Reduction Level</h3>
                </div>
                <Chart type="line" ref={CarbonReductionChartRef} data={CarbonReductionChart} options={ChartOptions} />
              </div>
            </div>
          </div>
        </div>
        <div className="ppa__result__options ">
          <button className="forward__option btn-secondary mr-2" onClick={newPPACalculation}>
            New PPA
          </button>

          <button className="forward__option btn-primary" onClick={exportResult}>
            Export
          </button>
        </div>
      </div>
    </>
  );
}
