import { useState, useEffect, useReducer, useContext, useRef } from "react";
import "./OnboardContract.css";
import axios from "../../../interceptors/axiosInstance";
import { DatePicker } from "../../../components/General/DataEntry/DatePicker/DatePicker";
import { config } from '../../../config'
import { CountryList } from "../../../utils/CountryList";
import { CurrencyList } from "../../../utils/CurrencyList";
import { ConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import { LoaderContext } from "../../../context/LoaderContext";
import { decimalNumberRegex, negativeNumberRegex, whiteSpaceStringRegex, wholeNumberRegex } from "../../../utils/ValidationRules";
import { pascalCase } from "../../../utils/display";
import { MessageContext } from "../../../context/MessageContext";
import SuccessModal from "../../../components/SuccessModal";

const initialContractState = {
    contractTitle: '',
    contractDescription:'',
    contractStartDate: '',
    contractSunsetDate: '',
    ppaTargetStartDate: '',
    firstInvoiceDate:'',
    generator: '',
    offtaker: '',
    projectName: '',
    projectLocation: '',
    projectStartDate: '',
    projectFinishDate: '',
    technologyType: '',
    maximumGeneration: '',
    pricingStructure: '',
    ceilingPrice: '',
    ppaBundlePrice:'',
    strikePrice: '',
    floatingAmount: '',
    fixedAmount: '',
    location: 'Australia',
    regionId:'',
    negativePricing: '',
    settlementAndDelivery: '',
    currency: 'AUD',
    paymentFrequency: '',
    performanceGuarantees: '',
    LGC: '',
    volume: '',
    environmentalAttributeHandling: '',
    shortFallThreshold: '',
    surplusThreshold: '',
    metering: '',
    electricityContractVolume: '',
    buyersPercentage: '',
    terminationPayment: '',
    projectOps: '',
    numberOfConsecutivePeriodsForShortfall:'',
    numberOfConsecutivePeriodsForSurplus:'',
    deployInBlockchain:''
}

const initialFormValidity = {
    contractTitle: '',
    contractDescription:'',
    contractStartDate: '',
    contractSunsetDate: '',
    ppaTargetStartDate: '',
    firstInvoiceDate:'',
    generator: '',
    offtaker: '',
    projectName: '',
    projectLocation: '',
    projectStartDate: '',
    projectFinishDate: '',
    technologyType: '',
    maximumGeneration: '',
    pricingStructure: '',
    ceilingPrice: '',
    ppaBundlePrice:'',
    strikePrice: '',
    floatingAmount: '',
    fixedAmount: '',
    location: '',
    regionId:'',
    negativePricing: '',
    settlementAndDelivery: '',
    currency: '',
    paymentFrequency: '',
    performanceGuarantees: '',
    LGC: '',
    volume: '',
    environmentalAttributeHandling: '',
    shortFallThreshold: '',
    surplusThreshold: '',
    metering: '',
    electricityContractVolume: '',
    buyersPercentage: '',
    contractFile: null,
    terminationPayment: '',
    projectOps: '',
    numberOfConsecutivePeriodsForShortfall:'',
    numberOfConsecutivePeriodsForSurplus:'',
    deployInBlockchain:'',
    isFormValid: false
}

const formReducer = (state, action) => {
    if (action.type === "RESET_FORM") {
        return initialContractState;
    } else {
        const { name, value } = action.type;
        return {
            ...state, [name]: name === 'regionId' ? value?.toUpperCase() : value,
        }
    }
}

const requiredFields = ["contractTitle","contractStartDate","contractSunsetDate","contractDescription","ppaTargetStartDate",
"generator","offtaker","technologyType","maximumGeneration","pricingStructure","strikePrice","fixedAmount",
"floatingAmount","negativePricing","location","currency","paymentFrequency","settlementAndDelivery",
"LGC","volume","ceilingPrice","electricityContractVolume","buyersPercentage","terminationPayment",
"projectOps","shortFallThreshold","surplusThreshold","regionId", "ppaBundlePrice", "numberOfConsecutivePeriodsForShortfall",
"numberOfConsecutivePeriodsForSurplus","deployInBlockchain", "firstInvoiceDate"
];

const selectFields = ["offtaker","generator","location","technologyType","pricingStructure","paymentFrequency","regionId","deployInBlockchain"];

const countryRegionList = [{
    region:'Australia', regionIds:["NSW1", "QLD1", "SA1", "TAS1", "NT1", "WA1", "VIC1"]
}]

const isValidDate = (category,data) => {
    let dateValidity = true;
    let dateError = '';
    let startDate,sunsetDate,ppaDate, projectStartDate, projectFinishDate,firstInvoiceDate;
    if(category === 'contractStartDate'){
        if(data.contractStartDate){
            startDate = new Date(data.contractStartDate).getTime();
            if(data.projectStartDate){
                projectStartDate = new Date(data.projectStartDate).getTime();
                if(startDate <= projectStartDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Start Date';
                }
            }
            if(data.projectFinishDate){
                projectFinishDate = new Date(data.projectFinishDate).getTime();
                if(startDate <= projectFinishDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Finish Date';
                }
            }
            if(data.ppaTargetStartDate){
                ppaDate = new Date(data.ppaTargetStartDate).getTime();
                if(startDate > ppaDate){
                    dateValidity = false;
                    dateError = 'Date should be less than PPA Start Date';
                }
            }
            if(data.contractSunsetDate){
                sunsetDate = new Date(data.contractSunsetDate).getTime();
                if(startDate >= sunsetDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Sunset Date';
                }
            }
            if(data.firstInvoiceDate){
                firstInvoiceDate = new Date(data.firstInvoiceDate).getTime();
                if(startDate >= firstInvoiceDate){
                    dateValidity = false;
                    dateError = 'Date should be less than First Invoice Date';
                }
            }
        } else {
            dateValidity = false;
            dateError = 'Select Contract Start Date';
        }
    } else if(category === 'contractSunsetDate') {
        if(data.contractSunsetDate){
            sunsetDate = new Date(data.contractSunsetDate).getTime();
            if(data.projectStartDate){
                projectStartDate = new Date(data.projectStartDate).getTime();
                if(sunsetDate <= projectStartDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Start Date';
                }
            }
            if(data.projectFinishDate){
                projectFinishDate = new Date(data.projectFinishDate).getTime();
                if(sunsetDate <= projectFinishDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Finish Date';
                }
            }
            if(data.ppaTargetStartDate){
                const targetDate = new Date(data.ppaTargetStartDate);
                ppaDate = new Date(targetDate.getFullYear(),targetDate.getMonth()+1,targetDate.getDate());
                if(sunsetDate <= ppaDate){
                    dateValidity = false;
                    dateError = 'Date should be atleast 1 month greater than PPA Start Date';
                }
            }
            if(data.contractStartDate){
                startDate = new Date(data.contractStartDate).getTime();
                if(sunsetDate <= startDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Start Date';
                }
            }
            if(data.firstInvoiceDate){
                firstInvoiceDate = new Date(data.firstInvoiceDate).getTime();
                if(sunsetDate <= firstInvoiceDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than First Invoice Date';
                }
            }
        } else {
            dateValidity = false;
            dateError = 'Select Contract Sunset Date';
        }
    } else if(category === 'ppaTargetStartDate') {
        if(data.ppaTargetStartDate){
            ppaDate = new Date(data.ppaTargetStartDate).getTime();
            if(data.projectStartDate){
                projectStartDate = new Date(data.projectStartDate).getTime();
                if(ppaDate <= projectStartDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Start Date';
                }
            }
            if(data.projectFinishDate){
                projectFinishDate = new Date(data.projectFinishDate).getTime();
                if(ppaDate <= projectFinishDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Finish Date';
                }
            }
            if(data.contractSunsetDate){
                sunsetDate = new Date(data.contractSunsetDate).getTime();
                if(ppaDate >= sunsetDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Sunset Date';
                }
            }
            if(data.contractStartDate){
                startDate = new Date(data.contractStartDate).getTime();
                if(ppaDate < startDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Start Date';
                }
            }
            if(data.firstInvoiceDate){
                firstInvoiceDate = new Date(data.firstInvoiceDate).getTime();
                if(ppaDate >= firstInvoiceDate){
                    dateValidity = false;
                    dateError = 'Date should be less than First Invoice Date';
                }
            }
        } else {
            dateValidity = false;
            dateError = 'Select PPA Target Start Date';
        }
    } else if(category === 'projectStartDate') {
        if(data.projectStartDate){
            projectStartDate = new Date(data.projectStartDate).getTime();
            if(data.projectFinishDate){
                projectFinishDate = new Date(data.projectFinishDate).getTime();
                if(projectStartDate >= projectFinishDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Project Finish Date';
                }
            }
            if(data.ppaTargetStartDate){
                ppaDate = new Date(data.ppaTargetStartDate).getTime();
                if(projectStartDate >= ppaDate){
                    dateValidity = false;
                    dateError = 'Date should be less than PPA Start Date';
                }
            }
            if(data.contractSunsetDate){
                sunsetDate = new Date(data.contractSunsetDate).getTime();
                if(projectStartDate >= sunsetDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Sunset Date';
                }
            }
            if(data.contractStartDate){
                startDate = new Date(data.contractStartDate).getTime();
                if(projectStartDate >= startDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Contract Start Date';
                }
            }
            if(data.firstInvoiceDate){
                firstInvoiceDate = new Date(data.firstInvoiceDate).getTime();
                if(projectStartDate >= firstInvoiceDate){
                    dateValidity = false;
                    dateError = 'Date should be less than First Invoice Date';
                }
            }
        }
    } else if(category === 'projectFinishDate') {
        if(data.projectFinishDate){
            projectFinishDate = new Date(data.projectFinishDate).getTime();
            if(data.projectStartDate){
                projectStartDate = new Date(data.projectStartDate).getTime();
                if(projectFinishDate <= projectStartDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Start Date';
                }
            }
            if(data.ppaTargetStartDate){
                ppaDate = new Date(data.ppaTargetStartDate).getTime();
                if(projectFinishDate >= ppaDate){
                    dateValidity = false;
                    dateError = 'Date should be less than PPA Start Date';
                }
            }
            if(data.contractSunsetDate){
                sunsetDate = new Date(data.contractSunsetDate).getTime();
                if(projectFinishDate >= sunsetDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Sunset Date';
                }
            }
            if(data.contractStartDate){
                startDate = new Date(data.contractStartDate).getTime();
                if(projectFinishDate >= startDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Contract Start Date';
                }
            }
            if(data.firstInvoiceDate){
                firstInvoiceDate = new Date(data.firstInvoiceDate).getTime();
                if(projectFinishDate >= firstInvoiceDate){
                    dateValidity = false;
                    dateError = 'Date should be less than First Invoice Date';
                }
            }
        }
    } else {
        if(data.firstInvoiceDate){
            firstInvoiceDate = new Date(data.firstInvoiceDate).getTime();
            if(data.projectStartDate){
                projectStartDate = new Date(data.projectStartDate).getTime();
                if(firstInvoiceDate <= projectStartDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Start Date';
                }
            }
            if(data.projectFinishDate){
                projectFinishDate = new Date(data.projectFinishDate).getTime();
                if(firstInvoiceDate <= projectFinishDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Project Finish Date';
                }
            }
            if(data.ppaTargetStartDate){
                ppaDate = new Date(data.ppaTargetStartDate).getTime();
                if(firstInvoiceDate <= ppaDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than PPA Start Date';
                }
            }
            if(data.contractSunsetDate){
                sunsetDate = new Date(data.contractSunsetDate).getTime();
                if(firstInvoiceDate >= sunsetDate){
                    dateValidity = false;
                    dateError = 'Date should be less than Sunset Date';
                }
            }
            if(data.contractStartDate){
                startDate = new Date(data.contractStartDate).getTime();
                if(firstInvoiceDate <= startDate){
                    dateValidity = false;
                    dateError = 'Date should be greater than Contract Start Date';
                }
            }
        }
    }
    return ({isValid:dateValidity, error:dateError});
}

const formValidityReducer = (state, action) => {
    let isValid = false;
    if (action.type === "RESET_FORM_VALIDITY") {
        return action.payload;
    } else {
        let errorData = { ...state };
        switch (action.type) {
            case "CONTRACTFILESUCCESS": {
                errorData = { ...errorData, contractFile: '' };
                break;
            }
            case "CONTRACTFILEFAILED": {
                errorData = { ...errorData, contractFile: 'Error: Upload the contract file' };
                break;
            }
            case "CONTRACTSTARTDATE":
            case "CONTRACTSUNSETDATE":
            case "PPATARGETSTARTDATE":
            case "FIRSTINVOICEDATE":
            case "PROJECTSTARTDATE":
            case "PROJECTFINISHDATE":{
                const requiredDate = ['contractStartDate','contractSunsetDate','ppaTargetStartDate','projectStartDate','projectFinishDate', 'firstInvoiceDate']
                var errObj={}
                requiredDate.forEach(item=>{
                    const data = action.payload[item]? isValidDate(item, action.payload):['projectStartDate','projectFinishDate'].includes(item)?{isValid:true}:{};
                    errObj[item]= data.isValid ? '' : data.error
                })
                errorData = { ...errorData, ...errObj };
                break;
            }
            case "CONTRACTTITLE": {
                isValid = action.payload.contractTitle.length && whiteSpaceStringRegex.test(action.payload.contractTitle) > 0 ? true : false;
                errorData = { ...errorData, contractTitle: isValid ? '' : 'Enter Contract Title' };
                break;
            }
            case "CONTRACTDESCRIPTION": {
                isValid = action.payload.contractDescription.length > 0 && whiteSpaceStringRegex.test(action.payload.contractDescription) ? true : false;
                errorData = { ...errorData, contractDescription: isValid ? '' : 'Enter Contract Description' };
                break;
            }
            case "GENERATOR": {
                isValid = action.payload.generator.length > 0 ? true : false;
                errorData = { ...errorData, generator: isValid ? '' : 'Select Generator' };
                break;
            }
            case "OFFTAKER": {
                isValid = action.payload.offtaker.length > 0 ? true : false;
                errorData = { ...errorData, offtaker: isValid ? '' : 'Select Offtaker' };
                break;
            }
            case "TECHNOLOGYTYPE": {
                isValid = action.payload.technologyType.length > 0 ? true : false;
                errorData = { ...errorData, technologyType: isValid ? '' : 'Select Technology Type' };
                break;
            }
            case "MAXIMUMGENERATION": {
                isValid = decimalNumberRegex.test(action.payload.maximumGeneration) ? true : false;
                errorData = { ...errorData, maximumGeneration: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "PRICINGSTRUCTURE": {
                isValid = action.payload.pricingStructure.length > 0 ? true : false;
                errorData = { ...errorData, pricingStructure: isValid ? '' : 'Select Pricing Structure' };
                break;
            }
            case "CEILINGPRICE": {
                isValid = decimalNumberRegex.test(action.payload.ceilingPrice) ? true : false;
                errorData = { ...errorData, ceilingPrice: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "STRIKEPRICE": {
                isValid = decimalNumberRegex.test(action.payload.strikePrice) && action.payload.strikePrice > 0 ? true : false;
                var errorObj={strikePrice: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places'};
                if(parseFloat(action.payload.ppaBundlePrice)>0 && parseFloat(action.payload.strikePrice) > parseFloat(action.payload.ppaBundlePrice))
                    errorObj.strikePrice = 'Strike Price should be less than Bundle Price'
                else errorObj.ppaBundlePrice=''
                errorData = { ...errorData, ...errorObj };
                break;
            }
            case "PPABUNDLEPRICE": {
                isValid = decimalNumberRegex.test(action.payload.ppaBundlePrice) ? true : false;
                var errorObj={ppaBundlePrice: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places'};
                if(parseFloat(action.payload.ppaBundlePrice)>0 && parseFloat(action.payload.strikePrice) > parseFloat(action.payload.ppaBundlePrice))
                    errorObj.ppaBundlePrice = 'Bundle Price should be greater than Strike Price'
                else errorObj.strikePrice=''

                errorData = { ...errorData, ...errorObj };
                break;
            }
            case "FIXEDAMOUNT": {
                isValid = decimalNumberRegex.test(action.payload.fixedAmount) ? true : false;
                errorData = { ...errorData, fixedAmount: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "FLOATINGAMOUNT": {
                isValid = decimalNumberRegex.test(action.payload.floatingAmount) ? true : false;
                errorData = { ...errorData, floatingAmount: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "NEGATIVEPRICING": {
                isValid = negativeNumberRegex.test(action.payload.negativePricing) && action.payload.negativePricing <= 0 ? true : false;
                errorData = { ...errorData, negativePricing: isValid ? '' : 'Enter Valid Negative Number Upto 2 Decimal Places' };
                break;
            }
            case "LOCATION": {
                isValid = action.payload.location.length > 0 ? true : false;
                errorData = { ...errorData, location: isValid ? '' : 'Enter Location' };
                break;
            }
            case "REGIONID": {
                isValid = action.payload.regionId.length > 0 && whiteSpaceStringRegex.test(action.payload.regionId) ? true : false;
                errorData = { ...errorData, regionId: isValid ? '' : 'Provide Region Id' };
                break;
            }
            case "CURRENCY": {
                isValid = action.payload.currency.length > 0 ? true : false;
                errorData = { ...errorData, currency: isValid ? '' : 'Enter Currency' };
                break;
            }
            case "PAYMENTFREQUENCY": {
                isValid = action.payload.paymentFrequency.length > 0 ? true : false;
                errorData = { ...errorData, paymentFrequency: isValid ? '' : 'Select Payment Frequency' };
                break;
            }
            case "SETTLEMENTANDDELIVERY": {
                isValid = action.payload.settlementAndDelivery.length > 0  && whiteSpaceStringRegex.test(action.payload.settlementAndDelivery) ? true : false;
                errorData = { ...errorData, settlementAndDelivery: isValid ? '' : 'Enter Settlement And Delivery' };
                break;
            }
            case "PERFORMANCEGUARANTEES": {
                isValid = decimalNumberRegex.test(action.payload.performanceGuarantees) ? true : false;
                errorData = { ...errorData, performanceGuarantees: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "LGC": {
                isValid = decimalNumberRegex.test(action.payload.LGC) ? true : false;
                errorData = { ...errorData, LGC: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "VOLUME": {
                isValid = decimalNumberRegex.test(action.payload.volume) ? true : false;
                errorData = { ...errorData, volume: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "SHORTFALLTHRESHOLD": {
                isValid = decimalNumberRegex.test(action.payload.shortFallThreshold) ? true : false;
                errorData = { ...errorData, shortFallThreshold: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "SURPLUSTHRESHOLD": {
                isValid = decimalNumberRegex.test(action.payload.surplusThreshold) ? true : false;
                errorData = { ...errorData, surplusThreshold: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "ENIVIRONMENTALATTRIBUTEHANDLING": {
                isValid = action.payload.environmentalAttributeHandling.length > 0  ? true : false;
                errorData = { ...errorData, environmentalAttributeHandling: isValid ? '' : 'Select Environmental Attribute Handling' };
                break;
            }
            case "ELECTRICITYCONTRACTVOLUME": {
                isValid = decimalNumberRegex.test(action.payload.electricityContractVolume) ? true : false;
                errorData = { ...errorData, electricityContractVolume: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "BUYERSPERCENTAGE": {
                isValid = decimalNumberRegex.test(action.payload.buyersPercentage) ? true : false;
                errorData = { ...errorData, buyersPercentage: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "TERMINATIONPAYMENT": {
                isValid = decimalNumberRegex.test(action.payload.terminationPayment) ? true : false;
                errorData = { ...errorData, terminationPayment: isValid ? '' : 'Enter Valid Number Upto 2 Decimal Places' };
                break;
            }
            case "PROJECTOPS": {
                isValid = action.payload.projectOps.length > 0  && whiteSpaceStringRegex.test(action.payload.projectOps) ? true : false;
                errorData = { ...errorData, projectOps: isValid ? '' : 'Enter Project Ops' };
                break;
            }
            case "METERING": {
                isValid = action.payload.metering.length > 0  && whiteSpaceStringRegex.test(action.payload.metering) ? true : false;
                errorData = { ...errorData, metering: isValid ? '' : 'Enter Metering' };
                break;
            }
            case "NUMBEROFCONSECUTIVEPERIODSFORSHORTFALL": {
                isValid = wholeNumberRegex.test(action.payload.numberOfConsecutivePeriodsForShortfall) ? true : false;
                errorData = { ...errorData, numberOfConsecutivePeriodsForShortfall: isValid ? '' : 'Enter Valid Number' };
                break;
            }
            case "NUMBEROFCONSECUTIVEPERIODSFORSURPLUS": {
                isValid = wholeNumberRegex.test(action.payload.numberOfConsecutivePeriodsForSurplus) ? true : false;
                errorData = { ...errorData, numberOfConsecutivePeriodsForSurplus: isValid ? '' : 'Enter Valid Number' };
                break;
            }
            case "DEPLOYINBLOCKCHAIN": {
                isValid = action.payload.deployInBlockchain.length > 0 ? true : false;
                errorData = { ...errorData, deployInBlockchain: isValid ? '' : 'Select Any Of The Value' };
                break;
            }
        }
        const { isFormValid, ...errors } = errorData;
        if (requiredFields.some((key) => (action.payload[key] === '')) || Object.keys(errors).some((key) => (errors[key] !== ''))) {
            errorData.isFormValid = false;
        } else {
            errorData.isFormValid = true;
        }

        return errorData;
    }
}

const technologies = [
    { name: "Solar", value: "solar" },
    { name: "Wind", value: "wind" },
    { name: "Hydro", value: "hydro" }
];

const pricing = [
    { name: "Fixed Pricing", value: "fixed_pricing" },
    { name: "Variable Pricing", value: "variable_pricing" }
];

const paymentFrequencies = [
    { name: "Monthly", value: "monthly" },
    { name: "Quarterly", value: "quarterly" },
    { name: "Annually", value: "annually" }
]

const attributes = [
    { name: "RECs", value: "RECs" },
    { name: "LGC", value: "LGC" },
    { name: "Carbon Credits", value: "carbon_credits" }
];

export default function AdminContractOnboarding({ title }) {
    // const DATEREGEX = /^\d{2}-\d{2}-\d{4}$/;
    const [showModal, setShowModal] = useState(false);
    const [showSuccess, setShowScuccess] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [generators, setGenerators] = useState([]);
    const [offtakers, setOfftakers] = useState([]);
    const [regionIdList, setRegionIdList] = useState([]);
    const [contractParams, setContractParams] = useReducer(formReducer, initialContractState);
    const [contractFormValidity, setContractFormValidity] = useReducer(formValidityReducer, initialFormValidity);
    const { setLoading } = useContext(LoaderContext);
    const { ErrorToast } = useContext(MessageContext);
    const fileRef = useRef(null);
    const payloadValidator = [
        { key: "contractTitle", label: 'Contract Title' },
        { key:"contractDescription",label:'Contract Description'},
        { key: "contractStartDate", label: 'Contract Start Date' },
        { key: "contractSunsetDate", label: 'Contract Sunset Date' },
        { key: "ppaTargetStartDate", label: 'PPA Target Start Date' },
        { key: 'firstInvoiceDate', label: 'First Invoice Date' },
        { key: 'generator', label: 'Generator' },
        { key: 'offtaker', label: 'Offtaker' },
        { key: 'projectName', label: 'Project Name' },
        { key: "projectLocation", label: 'Project Location' },
        { key: "projectStartDate", label: 'Project Start Date' },
        { key: 'projectFinishDate', label: 'Project Finish Date' },
        { key: 'technologyType', label: 'Technology Type' },
        { key: 'maximumGeneration', label: 'Maximum Generation (MWh)' },
        { key: 'pricingStructure', label: 'Pricing Structure' },
        { key: 'ceilingPrice', label: 'Ceiling Price' },
        { key: 'ppaBundlePrice', label: 'PPA Bundle Price' },
        { key: 'strikePrice', label: 'Strike Price' },
        { key: 'floatingAmount', label: 'Floating Amount' },
        { key: 'fixedAmount', label: 'Fixed Amount' },
        { key: 'location', label: 'Location' },
        { key: 'regionId', label: 'Region Id' },
        { key: 'negativePricing', label: 'Negative Pricing' },
        { key: 'settlementAndDelivery', label: 'Settlement And Delivery' },
        { key: 'currency', label: 'Currency' },
        { key: 'paymentFrequency', label: 'Payment Frequency' },
        { key: 'performanceGuarantees', label: 'Performance Guarantees' },
        { key: 'LGC', label: 'LGC/REC (Volume)' },
        { key: 'volume', label: 'Volume (MWh)' },
        { key: "environmentalAttributeHandling", label: 'Environmental Attribute Handling' },
        { key: "shortFallThreshold", label: 'Shortfall Threshold' },
        { key: "surplusThreshold", label: 'Surplus Threshold' },
        { key: "metering", label: 'Metering' },
        { key: "electricityContractVolume", label: 'Electricity Contract Volume (MWh)' },
        { key: "buyersPercentage", label: 'Buyers Percentage' },
        { key: "contractFile", label: 'Contract File' },
        { key: "terminationPayment", label: 'Termination Payment' },
        { key: "projectOps", label: 'Project Operation' },
        { key: 'numberOfConsecutivePeriodsForShortfall', label: 'Number Of Consecutive Periods For Shortfall' },
        { key: 'numberOfConsecutivePeriodsForSurplus', label: 'Number Of Consecutive Periods For Surplus' },
        { key: 'deployInBlockchain', label: 'Deploy In Blockchain' }
    ]

    const { location, ppaBundlePrice, strikePrice } = contractParams;

    useEffect(()=>{
        const list = countryRegionList.find((item)=>{return item.region === location});
        setRegionIdList(list? list.regionIds: []);
        setContractParams({type:{name:'regionId', value:''}});
        setContractFormValidity({type:'LOCATION', payload: {...contractParams, regionId:''}});
    },[location])

    const removeFile = () =>{
        setSelectedContract(null);
        fileRef.current.value = '';
        setContractFormValidity({ type: "CONTRACTFILEFAILED", payload: contractParams });
    }

    const getClients = async () => {
        try {
            setLoading(true);
            const [generators, offtakers] = await Promise.all([axios.get(`${config.BASE_URL}/api/client?type=generator`), axios.get(`${config.BASE_URL}/api/client?type=offtaker`)]);
            setGenerators(generators.data);
            setOfftakers(offtakers.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }

    }

    useEffect(() => {
        setLoading(true);
        getClients();
        setLoading(false);
    }, [])

    const handleSubmit = () => {
        window.scrollTo(0,0);
        setLoading(true);
        setShowModal(true);
        setLoading(false);
    }

    const handleCancel = () => {
        setShowModal(false);
        setSelectedContract(null);
        setContractParams({ type: "RESET_FORM" });
        setContractFormValidity({ type: "RESET_FORM_VALIDITY", payload: initialFormValidity });
        fileRef.current.value = '';
    }

    const submitContractOnboarding = async () => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append("contractFile", selectedContract);
            const payload = { ...contractParams }
            Object.keys(payload).forEach(item =>{
                if(requiredFields.includes(item)){
                    formData.append(item, payload[item])
                } else if(payload[item]) {
                    formData.append(item, payload[item])
                }
            })
            await axios.post(`${config.BASE_URL}/api/contract`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setSelectedContract(null);
            setShowScuccess(true);
            setShowModal(false);
            setContractParams({ type: "RESET_FORM" });
            setContractFormValidity({ type: "RESET_FORM_VALIDITY", payload: initialFormValidity });
            setLoading(false);
            fileRef.current.value = '';
        } catch (error) {
            setLoading(false);
            setShowModal(false);
            if(error.response && error.response.data && error.response.data.message){
                ErrorToast(error.response.data.message);
            } else {
                ErrorToast("Something Went Wrong!. Please Try Again");
            }
        }

    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedContract(file);
            setContractFormValidity({ type: "CONTRACTFILESUCCESS", payload: contractParams });
        } else {
            if(selectedContract){
                let list = new DataTransfer();
                let file = new File([selectedContract], selectedContract.name);
                list.items.add(file);
                let fileList = list.files;
                fileRef.current.files = fileList;
            } else {
                setContractFormValidity({ type: "CONTRACTFILEFAILED", payload: contractParams });
            }
        }
    }

    const handleCalValue = (value, category) => {
        setContractParams({ type: {name:category, value}});
        setContractFormValidity({type:category.toUpperCase(), payload: { ...contractParams, [category]: value }})
    }

    const handleChange = (e) => {
        if(selectFields.includes(e.target.name) && e.target?.type !== "text"){
            setContractParams({ type: e.target })
            const { name, value } = e.target;
            setContractFormValidity({ type: name.toUpperCase(), payload: { ...contractParams, [name]: value} })
        } else{
            setContractParams({ type: e.target })
        }
    }

    const handleValidation = (e) => {
        setContractFormValidity({ type: e.target.name.toUpperCase(), payload: contractParams });
    }

    return (
        <>
            {/* <p className="admin_contract_greeting">Hi John, Lorem ipsum dolor sit amet, consectetur adipiscing elit </p> */}
            <div className="admin__contract__onboarding__wrapper">
                <div className="admin__contract__title">Onboarding Contract Form</div>
                <div className="admin__contract__onboarding__form__container">

                    <form className="admin__contract__onboarding__form" onSubmit={() => { }} autocomplete="off">
                        <div class="admin_contract_form_column">
                            <div className="admin__contract__form__group">
                                <label htmlFor="contractTitle">Contract Title *</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.contractTitle} className="admin__contract__input" type="text" id="contractTitle" name="contractTitle" placeholder="Type here" />
                                {contractFormValidity.contractTitle && <p class="text_error">{contractFormValidity.contractTitle}</p>}
                            </div>
                            <DatePicker minDate={new Date()} error={contractFormValidity.contractSunsetDate} formClass={"admin__contract__form__group"} label="Contract Sunset Date *" CalValue={contractParams.contractSunsetDate} setCalValue={handleCalValue} id={"contractSunsetDate"} placeholder={"Select Date (Required)"} category={"contractSunsetDate"} />
                            <div className="admin__contract__form__group">
                                <label htmlFor="generator">Generator *</label>
                                <select className="admin__contract__input" id="generator" name="generator" value={contractParams.generator} onChange={handleChange} required>
                                    <option value="">Select Generator</option>
                                    {
                                        generators.map((_generator, index) => <option id={_generator._id} value={_generator._id}>{_generator.clientName}</option>)
                                    }
                                </select>
                                {contractFormValidity.generator && <p class="text_error">{contractFormValidity.generator}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="projectLocation">Project Location</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.projectLocation} className="admin__contract__input" type="text" id="projectLocation" name="projectLocation" placeholder="Type here" />
                                {contractFormValidity.projectLocation && <p class="text_error">{contractFormValidity.projectLocation}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="technologyType">Technology Type *</label>
                                <select className="admin__contract__input" id="technologyType" name="technologyType" value={contractParams.technologyType} onChange={handleChange} required>
                                    <option value="">Select Technology Type</option>
                                    {
                                        technologies.map((technology, index) => <option id={technology.value} value={technology.value}>{technology.name}</option>)
                                    }
                                </select>
                                {contractFormValidity.technologyType && <p class="text_error">{contractFormValidity.technologyType}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="ceilingPrice">Ceiling Price *</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.ceilingPrice} className="admin__contract__input" type="text" id="ceilingPrice" name="ceilingPrice" placeholder="0.00" required />
                                {contractFormValidity.ceilingPrice && <p class="text_error">{contractFormValidity.ceilingPrice}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="floatingAmount">Floating Amount *</label>
                                <input onChange={handleChange} value={contractParams.floatingAmount} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="floatingAmount" name="floatingAmount" placeholder="0.00" required />
                                {contractFormValidity.floatingAmount && <p class="text_error">{contractFormValidity.floatingAmount}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="regionId">Region Id *</label>
                                {
                                    contractParams.location === 'Australia' ? (
                                        <select className="admin__contract__input" id="regionId" name="regionId" value={contractParams.regionId} onChange={handleChange} required>
                                            <option value=''>Select Region Id</option>
                                            {
                                                regionIdList.map((region, index) => <option id={index} value={region}>{region}</option>)
                                            }
                                        </select>
                                    ) : (
                                        <input onChange={handleChange} value={contractParams.regionId} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="regionId" name="regionId" placeholder="Type here" required />
                                    )
                                }
                                {contractFormValidity.regionId && <p class="text_error">{contractFormValidity.regionId}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="currency">Currency *</label>
                                <select className="admin__contract__input" id="currency" name="currency" value={contractParams.currency} onChange={handleChange} required>
                                    {
                                        CurrencyList.map((currency, index) => <option id={index} value={currency}>{currency}</option>)
                                    }
                                </select>
                                {contractFormValidity.currency && <p class="text_error">{contractFormValidity.currency}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="LGC">LGC/REC (Volume) *</label>
                                <input onChange={handleChange} value={contractParams.LGC} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="LGC" name="LGC" placeholder="0.00" required />
                                {contractFormValidity.LGC && <p class="text_error">{contractFormValidity.LGC}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="shortFallThreshold">Shortfall Threshold (MWh)*</label>
                                <input onChange={handleChange} value={contractParams.shortFallThreshold} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="shortFallThreshold" name="shortFallThreshold" placeholder="0.00" required />
                                {contractFormValidity.shortFallThreshold && <p class="text_error">{contractFormValidity.shortFallThreshold}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="electricityContractVolume">Electricity Contract Volume (MWh)*</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.electricityContractVolume} className="admin__contract__input" type="text" id="electricityContractVolume" name="electricityContractVolume" placeholder="0.00" required />
                                {contractFormValidity.electricityContractVolume && <p class="text_error">{contractFormValidity.electricityContractVolume}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="terminationPayment">Termination Payment *</label>
                                <input onChange={handleChange} value={contractParams.terminationPayment} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="terminationPayment" name="terminationPayment" placeholder="0.00" required />
                                {contractFormValidity.terminationPayment && <p class="text_error">{contractFormValidity.terminationPayment}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="numberOfConsecutivePeriodsForSurplus">Consecutive Periods For Surplus *</label>
                                <input onChange={handleChange} value={contractParams.numberOfConsecutivePeriodsForSurplus} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="numberOfConsecutivePeriodsForSurplus" name="numberOfConsecutivePeriodsForSurplus" placeholder="Type here" required />
                                {contractFormValidity.numberOfConsecutivePeriodsForSurplus && <p class="text_error">{contractFormValidity.numberOfConsecutivePeriodsForSurplus}</p>}
                            </div>
                        </div>
                        <div class="admin_contract_form_column">
                            <div className="admin__contract__form__group">
                                <label htmlFor="contractDescription">Contract Description *</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.contractDescription} className="admin__contract__input" type="text" id="contractDescription" name="contractDescription" placeholder="Enter Contract Description" />
                                {contractFormValidity.contractDescription && <p class="text_error">{contractFormValidity.contractDescription}</p>}
                            </div>
                            <DatePicker error={contractFormValidity.ppaTargetStartDate} formClass={"admin__contract__form__group"} label="PPA Target Start Date *" CalValue={contractParams.ppaTargetStartDate} setCalValue={handleCalValue} id={"ppaTargetStartDate"} placeholder={"Select Date (Required)"} category={"ppaTargetStartDate"} />
                            <div className="admin__contract__form__group">
                                <label htmlFor="offtaker">Offtaker *</label>
                                <select className="admin__contract__input" id="offtaker" name="offtaker" value={contractParams.offtaker} onChange={handleChange} required>
                                    <option value="">Select Offtaker</option>
                                    {
                                        offtakers.map((_offtaker, index) => <option id={_offtaker._id} value={_offtaker._id}>{_offtaker.clientName}</option>)
                                    }
                                </select>
                                {contractFormValidity.offtaker && <p class="text_error">{contractFormValidity.offtaker}</p>}
                            </div>
                            <DatePicker error={contractFormValidity.projectStartDate} formClass={"admin__contract__form__group"} label="Project Start Date" CalValue={contractParams.projectStartDate} setCalValue={handleCalValue} id={"projectStartDate"} placeholder={"Select Date"} category={"projectStartDate"} />
                            <div className="admin__contract__form__group">
                                <label htmlFor="maximumGeneration">Maximum Generation (MWh)*</label>
                                <input onChange={handleChange} value={contractParams.maximumGeneration} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="maximumGeneration" name="maximumGeneration" placeholder="0.00" required />
                                {contractFormValidity.maximumGeneration && <p class="text_error">{contractFormValidity.maximumGeneration}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="ppaBundlePrice">PPA Bundle Price (Green + Black Energy)*</label>
                                <input onChange={handleChange} value={contractParams.ppaBundlePrice} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="ppaBundlePrice" name="ppaBundlePrice" placeholder="0.00" required />
                                {contractFormValidity.ppaBundlePrice && <p class="text_error">{contractFormValidity.ppaBundlePrice}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="fixedAmount">Fixed Amount *</label>
                                <input onChange={handleChange} value={contractParams.fixedAmount} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="fixedAmount" name="fixedAmount" placeholder="0.00" required />
                                {contractFormValidity.fixedAmount && <p class="text_error">{contractFormValidity.fixedAmount}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="negativePricing">Negative Pricing *</label>
                                <input onChange={handleChange} value={contractParams.negativePricing} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="negativePricing" name="negativePricing" placeholder="0.00" required />
                                {contractFormValidity.negativePricing && <p class="text_error">{contractFormValidity.negativePricing}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="paymentFrequency">Payment Frequency *</label>
                                <select className="admin__contract__input" id="paymentFrequency" name="paymentFrequency" value={contractParams.paymentFrequency} onChange={handleChange} required>
                                    <option value="">Select Payment Frequency</option>
                                    {
                                        paymentFrequencies.map((element, index) => <option id={element.value} value={element.value}>{element.name}</option>)
                                    }
                                </select>
                                {contractFormValidity.paymentFrequency && <p class="text_error">{contractFormValidity.paymentFrequency}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="volume">Volume (MWh) *</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.volume} className="admin__contract__input" type="text" id="volume" name="volume" placeholder="0.00" required />
                                {contractFormValidity.volume && <p class="text_error">{contractFormValidity.volume}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="surplusThreshold">Surplus Threshold (MWh)*</label>
                                <input onChange={handleChange} value={contractParams.surplusThreshold} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="surplusThreshold" name="surplusThreshold" placeholder="0.00" required />
                                {contractFormValidity.surplusThreshold && <p class="text_error">{contractFormValidity.surplusThreshold}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="buyersPercentage">Buyer's Percentage *</label>
                                <input onChange={handleChange} value={contractParams.buyersPercentage} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="buyersPercentage" name="buyersPercentage" placeholder="0.00" required />
                                {contractFormValidity.buyersPercentage && <p class="text_error">{contractFormValidity.buyersPercentage}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="projectOps">Project Operation *</label>
                                <input onChange={handleChange} value={contractParams.projectOps} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="projectOps" name="projectOps" placeholder="Type here" required />
                                {contractFormValidity.projectOps && <p class="text_error">{contractFormValidity.projectOps}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="deployInBlockchain">Deploy In Blockchain *</label>
                                <select className="admin__contract__input" id="deployInBlockchain" name="deployInBlockchain" value={contractParams.deployInBlockchain} onChange={handleChange} required>
                                    <option value="">Select Value</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                                {contractFormValidity.deployInBlockchain && <p class="text_error">{contractFormValidity.deployInBlockchain}</p>}
                            </div>
                        </div>
                        <div class="admin_contract_form_column">
                            <DatePicker error={contractFormValidity.contractStartDate} formClass={"admin__contract__form__group"} label="Contract Start Date *" CalValue={contractParams.contractStartDate} setCalValue={handleCalValue} id={"contractStartDate"} placeholder={"Select Date (Required)"} category={"contractStartDate"} />
                            <DatePicker error={contractFormValidity.firstInvoiceDate} formClass={"admin__contract__form__group"} label="First Invoice Date *" CalValue={contractParams.firstInvoiceDate} setCalValue={handleCalValue} id={"firstInvoiceDate"} placeholder={"Select Date (Required)"} category={"firstInvoiceDate"} />
                            <div className="admin__contract__form__group">
                                <label htmlFor="projectName">Project Name</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} value={contractParams.projectName} className="admin__contract__input" type="text" id="projectName" name="projectName" placeholder="Enter Project Name" />
                                {contractFormValidity.projectName && <p class="text_error">{contractFormValidity.projectName}</p>}
                            </div>
                            <DatePicker error={contractFormValidity.projectFinishDate} formClass={"admin__contract__form__group"} label="Project Finish Date" CalValue={contractParams.projectFinishDate} setCalValue={handleCalValue} id={"projectFinishDate"} placeholder={"Select Date"} category={"projectFinishDate"} />
                            <div className="admin__contract__form__group">
                                <label htmlFor="pricingStructure">Pricing Structure *</label>
                                <select className="admin__contract__input" id="pricingStructure" name="pricingStructure" value={contractParams.pricingStructure} onChange={handleChange} required>
                                    <option value="">Select Pricing Structure</option>
                                    {
                                        pricing.map((element, index) => <option id={element.value} value={element.value}>{element.name}</option>)
                                    }
                                </select>
                                {contractFormValidity.pricingStructure && <p class="text_error">{contractFormValidity.pricingStructure}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="strikePrice">Strike Price *</label>
                                <input onChange={handleChange} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="strikePrice" name="strikePrice" placeholder="0.00" value={contractParams.strikePrice} required />
                                {contractFormValidity.strikePrice && <p class="text_error">{contractFormValidity.strikePrice}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="location">Location *</label>
                                <select className="admin__contract__input" id="location" name="location" value={contractParams.location} onChange={handleChange} required>
                                    {
                                        CountryList.map((country, index) => <option id={index} value={country} selected={country == "Australia" ? true : null}>{country}</option>)
                                    }
                                </select>
                                {contractFormValidity.location && <p class="text_error">{contractFormValidity.location}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="settlementAndDelivery">Settlement And Delivery *</label>
                                <input onChange={handleChange} value={contractParams.settlementAndDelivery} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="settlementAndDelivery" name="settlementAndDelivery" placeholder="Type here" required />
                                {contractFormValidity.settlementAndDelivery && <p class="text_error">{contractFormValidity.settlementAndDelivery}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="performanceGuarantees">Performance Guarantees</label>
                                <input onChange={handleChange} value={contractParams.performanceGuarantees} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="performanceGuarantees" name="performanceGuarantees" placeholder="0.00" />
                                {contractFormValidity.performanceGuarantees && <p class="text_error">{contractFormValidity.performanceGuarantees}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="environmentalAttributeHandling">Environmental Attribute Handling</label>
                                <select className="admin__contract__input" id="environmentalAttributeHandling" name="environmentalAttributeHandling" value={contractParams.environmentalAttributeHandling} onChange={handleChange}>
                                    <option value="">Select Environmental Attribute</option>
                                    {
                                        attributes.map((element, index) => <option id={element.value} value={element.value}>{element.name}</option>)
                                    }
                                </select>
                                {contractFormValidity.environmentalAttributeHandling && <p class="text_error">{contractFormValidity.environmentalAttributeHandling}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="metering">Metering *</label>
                                <input onChange={handleChange} value={contractParams.metering} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="metering" name="metering" placeholder="Type here" required />
                                {contractFormValidity.metering && <p class="text_error">{contractFormValidity.metering}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="contractFile">Contract Upload *</label>
                                <div className="close_icon">{selectedContract ? <img className="cross__icon" onClick={removeFile} src={"/icons/cross_black.svg"} alt="clear_cross" /> : null}</div>
                                <input ref={fileRef} accept="application/pdf" onChange={(e) => handleFileChange(e)} className="admin__contract__input__file" type="file" id="contractFile" name="contractFile" placeholder="Contract Upload" required />
                                {contractFormValidity.contractFile && <p class="text_error">{contractFormValidity.contractFile}</p>}
                            </div>
                            <div className="admin__contract__form__group">
                                <label htmlFor="numberOfConsecutivePeriodsForShortfall">Consecutive Periods For Shortfall *</label>
                                <input onChange={handleChange} value={contractParams.numberOfConsecutivePeriodsForShortfall} onKeyUp={handleValidation} className="admin__contract__input" type="text" id="numberOfConsecutivePeriodsForShortfall" name="numberOfConsecutivePeriodsForShortfall" placeholder="Type here" required />
                                {contractFormValidity.numberOfConsecutivePeriodsForShortfall && <p class="text_error">{contractFormValidity.numberOfConsecutivePeriodsForShortfall}</p>}
                            </div>
                        </div>
                    </form>
                    {/* {title === "Onboard" &&  showModal && <AdminOnBoardingContractModal contractParams={{ ...contractParams}} toggleModal={setShowModal} submitContractOnboarding={submitContractOnboarding}/>} */}
                    {title === "Onboard" && showModal && <ConfirmModal 
                        data={null} params={{ ...contractParams, 
                        generator: generators.filter((item)=> item._id === contractParams.generator)[0].clientName,
                        offtaker: offtakers.filter(item => item._id === contractParams.offtaker)[0].clientName,
                        contractFile: selectedContract.name, paymentFrequency: pascalCase(contractParams.paymentFrequency),
                        technologyType: pascalCase(contractParams.technologyType,'_'), pricingStructure: pascalCase(contractParams.pricingStructure,"_"),
                        deployInBlockchain: pascalCase(contractParams.deployInBlockchain), environmentalAttributeHandling: contractParams.environmentalAttributeHandling? pascalCase(contractParams.environmentalAttributeHandling,'_'):''
                    }} 
                        labels={payloadValidator} toggleModal={setShowModal} handleSubmit={submitContractOnboarding} />}
                    {showSuccess ? <SuccessModal title="Onboarded" message="Contract onboarded successfully!" toggleModal={setShowScuccess} /> : null}
                    <div className="ppa__contract__options">
                        <button className="forward__option" disabled={!contractFormValidity.isFormValid} onClick={() => handleSubmit()}>{title}</button>
                        <button className="cancel__option" onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}