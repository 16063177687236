import React from "react";
import "./Pagination.css";

export const Pagination = ({
    hasNextPage,
    hasPreviousPage,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageIndex,
    setPageSize,
  }) => {
    return (
      <div className="pagination-container">
          <select
              className="page-size-select"
              value={pageSize}
              onChange={e => {
                  setPageSize(Number(e.target.value))
              }}
          >
              {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                  </option>
              ))}
          </select>
          <button
              className="button"
              onClick={() => setPageIndex(0)}
              disabled={!hasPreviousPage}
              >
              <img src={ '/icons/page_prev.svg'} alt="page prev" />
          </button>
          <button
              className="button"
              onClick={() => previousPage()}
              disabled={!hasPreviousPage}
          >
              <img src={'/icons/page_prev_1.svg'} alt="page_prev_1" />
          </button>
          <span className="page-count"> {pageIndex + 1} / {pageCount}</span>
          <button
              className="button"
              onClick={() => nextPage()}
              disabled={!hasNextPage}
          >
              <img src={'/icons/page_next_1.svg'} alt="page_next_1" />
          </button>
          <button
              className="button"
              onClick={() => setPageIndex(pageCount - 1)}
              disabled={!hasNextPage}
          >
              <img src={ '/icons/page_next.svg'} alt="page next" />
          </button>
      </div>
    )
  }